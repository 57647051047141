import React from 'react';
import "./../HomePage/HomePage.css";
import "./../HomePage/responsivelandingsite.css";
import "./../HomePage/stylelandingsite.css";
import "./../HomePage/mybootstrap.css";
import { Service } from '../Common/Services.service';
// import Home_Icon_WH from './../../Assests/img/Home_Icon_WH.svg';
import { Table, Select, Modal, Row, Col, Switch, Button } from 'antd';
//import { Button } from 'react-bootstrap';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import StickyText from '../Common/StikeyText';

const { Option } = Select;

interface Price {
  AdminDashboardData: any,
  DatabaseData: any
  subscriptionData: any
  selectedRowKeys: any
  DBcountry: string
  DBCountryName: string
  setIsModalVisible: boolean
  Apps_MST_Account_ID: string
  BillingAddress: string
  City: string
  CompanyRegNo: string
  Country: string
  Password: any
  PlanCode: string
  PlanName: string
  Salutation: string
  State: string
  TaxationNo: string
  Zip: string
  Apps_MST_User_ID: number
  ZohoCustomer_ID: string
  company_name: string
  currency_code: string
  display_name: string
  email: string
  first_name: string
  last_name: string
  mobile: string
  phone: string
  elpassword: string
  ssouser: string
  spin_loading: boolean
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
  tableId: number,
}
export default class AdminDashboard extends React.Component<any, Price> {
  private Service: Service;
  columns: any;
  columnsmodel: any
  Subscriptioncolumns: any
  AwsEntityObject: any = []
  SubscriptionEntityObject: any = []
  subscriptionData: readonly any[] | undefined;
  constructor(props: any) {
    super(props);
    this.Service = new Service();
    this.state = {
      AdminDashboardData: [],
      DatabaseData: [],
      subscriptionData: [],
      selectedRowKeys: [],
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
      DBcountry: "",
      DBCountryName: "Select DB",
      setIsModalVisible: false,
      Apps_MST_Account_ID: "",
      Apps_MST_User_ID: 0,
      BillingAddress: "",
      City: "",
      CompanyRegNo: "",
      Country: "",
      Password: "",
      PlanCode: "",
      PlanName: "",
      Salutation: "",
      State: "",
      TaxationNo: "",
      Zip: "",
      ZohoCustomer_ID: "",
      company_name: "",
      currency_code: "",
      display_name: "",
      email: "",
      first_name: "",
      last_name: "",
      mobile: "",
      phone: "",
      elpassword: "",
      ssouser: "",
      spin_loading: false,
      tableId: 0,
    }
    this.columns = [
      {
        title: 'Company Name',
        dataIndex: 'company_name',
        width: 150
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        wdith: 300
      },
      {
        title: 'Country',
        dataIndex: 'Country',
        key: 'Country',
        width: 180
      },
      {
        title: 'State',
        dataIndex: 'State',
        key: 'State',
        width: 180
      },
      {
        title: 'City',
        dataIndex: 'City',
        key: 'City',
        width: 180
      },
      {
        title: 'Plan Code',
        dataIndex: 'PlanCode',
        width: 150
      },
      {
        title: 'Plan Name',
        dataIndex: 'PlanName',
        key: 'PlanName',
        width: 100
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        width: 85,
        fixed: 'right',
        align: 'center',
        render: (text: any, record: any, index: any) =>
          <span>
            <Button className='ViewButton' onClick={() => this.view(record)}>View</Button>
          </span>,
      }
    ]
    this.columnsmodel = [
      {
        title: 'Company Name',
        dataIndex: 'company_name',
        key: 'display_name',

      },
      {
        title: 'Plan Code',
        dataIndex: 'PlanCode',
        key: 'display_name',

      },
      {
        title: 'Plan Name',
        dataIndex: 'PlanName',
        key: 'display_name',

      },
      {
        title: 'Select Country',
        dataIndex: 'PlanName',
        key: 'display_name',
        render: (text: any, record: any, index: any) =>
          <span>
            <Select
              labelInValue
              defaultValue={{ value: '0', label: 'Select DB' }}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              {
                this.state.DatabaseData ?
                  this.state.DatabaseData.map((item: any) =>
                    <Option value={item.Databaseid}>{item.CountryID}</Option>
                  ) : <></>
              }
            </Select>
          </span>,
      },
      {
        title: 'Action',
        dataIndex: '',
        key: '',
        width: 105,
        fixed: 'right',
        align: 'center',
        render: (text: any, record: any, index: any) =>
          <span>
            <Button className='ViewButton' onClick={() => this.submit()}>submit</Button>
          </span>,
      }
    ]

    this.Subscriptioncolumns = [
      {
        title: 'Company Name',
        dataIndex: 'company_name',
        width: 180
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 180
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        wdith: 180
      },
      {
        title: 'Plan Name',
        dataIndex: 'planname',
        key: 'planname',
        width: 180
      },
      {
        title: 'Subscription Validfrom',
        dataIndex: 'subscriptionvalidfrom',
        key: 'subscriptionvalidfrom',
        width: 200
      },
      {
        title: 'Subscription Validto',
        dataIndex: 'subscriptionvalidto',
        width: 200
      },
    ]
  }

  getDataFromSticky(show: boolean) {
    this.setState({ sticky_show: show })
  }

  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }

  handleChange = (value: any) => {

    this.setState({ DBcountry: value.value, sticky_show: false, DBCountryName: value.label })
  };
  onChangeTable = (checked: boolean) => {

    this.setState({ tableId: checked ? 0 : 1 })
  }

  componentDidMount = async () => {

    this.setState({ spin_loading: true, sticky_show: false })
    let res: any = await this.Service.GetAdminDashboardDataService();
    const tempDashboadData = res.data ? res.data : [];
    let res1: any = await this.Service.GetDatabaseDeatilsService();
    const tempDBDetails = res1.data ? res1.data : [];
    this.SubscriptionEntityObject = {};
    let subscriptionres: any = await this.Service.GetExpiredSubscriptionDetailsService(this.SubscriptionEntityObject);
    const subscriptiontempData = subscriptionres.data ? JSON.parse(subscriptionres.data.entityObject.result) : [];
    this.setState({ AdminDashboardData: tempDashboadData, DatabaseData: tempDBDetails, subscriptionData: subscriptiontempData, ssouser: tempDashboadData[0].Apps_MST_User_ID, spin_loading: false, sticky_show: false })
  }
  view = (record: any) => {
    this.setState({
      setIsModalVisible: true,
      Apps_MST_Account_ID: record.Apps_MST_Account_ID,
      Apps_MST_User_ID: record.Apps_MST_User_ID,
      BillingAddress: record.BillingAddress,
      City: record.City,
      CompanyRegNo: record.CompanyRegNo,
      Country: record.Country,
      Password: record.Password,
      PlanCode: record.PlanCode,
      PlanName: record.PlanName,
      Salutation: record.Salutation,
      State: record.State,
      TaxationNo: record.TaxationNo,
      Zip: record.Zip,
      ZohoCustomer_ID: record.ZohoCustomer_ID,
      company_name: record.company_name,
      currency_code: record.currency_code,
      display_name: record.display_name,
      email: record.email,
      first_name: record.first_name,
      last_name: record.last_name,
      mobile: record.mobile,
      phone: record.phone,
      sticky_show: false,
      DBcountry: '',
      DBCountryName: 'Select DB'
    })
  }
  submit = async () => {

    if (this.state.DBcountry === '' || this.state.DBcountry === undefined || this.state.DBcountry === null) {
      this.stickyText(true, 'error', 'Error', 'Please select Database');
      return false;
    }
    this.setState({ setIsModalVisible: false, spin_loading: true })
    this.AwsEntityObject =
    {
      AccountCode: this.state.display_name
      , CompanyLegalName: this.state.company_name
      , ZohoAccountId: this.state.ZohoCustomer_ID
      , SSOAccountID: this.state.Apps_MST_Account_ID
      , Email: this.state.email
      , Password: ""
      , EnPassword: this.state.Password
      , SSOUserID: String(this.state.Apps_MST_User_ID)
      , Mobile: this.state.mobile
      , FirstName: this.state.first_name
      , LastName: this.state.last_name
      , databaseid: this.state.DBcountry
    }

    const res: any = await this.Service.SaveAWSAccountCreationService(this.AwsEntityObject)

    this.setState({ spin_loading: false, DBcountry: '', })
    if (res.data === "1") {
      this.stickyText(true, 'success', 'Success', 'Account created successfully in WMS');
      setTimeout(() => {
        this.componentDidMount()
      }, 3000)
    }
    else {
      this.stickyText(true, 'error', 'Error', 'Something went wrong');
      return false;
    }
    //  res.=
  };

  handleCancel = () => {
    this.setState({ setIsModalVisible: false, sticky_show: false })
  };
  logout = () => {
    setTimeout(() => {
      window.localStorage.clear();
      // sessionStorage.clear()
      window.location.href = "/";
    }, 1000);
  };

  render() {
    // const { selectedRowKeys } = this.state;

    return (
      <>
        <section id='Pricing' className="loginpagebg pricing-area gray-bg  pt-100 pb-70" style={{ paddingBottom: "50px", paddingTop: '50px', height: '100vh' }} >
          <div className='stikey'>
            <>
              {
                this.state.sticky_show ?
                  <StickyText
                    display={this.state.sticky_show}
                    sticky_type={this.state.sticky_type}
                    sticky_title={this.state.sticky_title}
                    sticky_message={this.state.sticky_message}
                    sendData={this.getDataFromSticky}
                    key={this.state.sticky_title}
                  />
                  :
                  <></>
              }
            </>
          </div>
          <div className={this.state.spin_loading ? "spin_class_login" : "spin_class1"}>
            <img src={skubiq_loader} alt="loading..." height={80} width={80} />
          </div>
          <div className="container">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="web-hosting" role="tabpanel" aria-labelledby="web-hosting-tab">
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-9">
                    <div className="section-title text-center mb-70">
                      <h2 className="title">Admin Dashboard</h2>
                    </div>
                  </div>
                </div>
                <div className="SwithTables">
                  <span>Subscription Expiry Data</span>&nbsp;&nbsp;
                  <span>
                    <Switch defaultChecked onChange={this.onChangeTable} checkedChildren="Open" unCheckedChildren="Close" />
                  </span>
                </div>
                <div className="row pricing-box-wrap justify-content-center">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    {this.state.tableId === 0 ?
                      <Table
                        dataSource={this.state.AdminDashboardData}
                        className='tablestriped'
                        columns={this.columns}
                        pagination={false}
                        scroll={{ x: 1300, y: `calc(100vh - 250px)` }}
                      />
                      : <Table
                        dataSource={this.state.subscriptionData}
                        className='tablestriped'
                        columns={this.Subscriptioncolumns}
                        pagination={false}
                        scroll={{ x: 1300, y: `calc(100vh - 250px)` }}
                      />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          title="Account Details"
          className='adminaccountgrid'
          visible={this.state.setIsModalVisible}
          onCancel={() => this.handleCancel()}
        >
          <Row gutter={16}>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Company Name : </span> {this.state.company_name}</p>
            </Col>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Company Reg. No. : </span> {this.state.CompanyRegNo}</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Email : </span> {this.state.email}</p>
            </Col>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Taxation No. : </span> {this.state.TaxationNo}</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>First Name : </span> {this.state.first_name}</p>
            </Col>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Last Name : </span> {this.state.last_name}</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Display Name : </span> {this.state.display_name}</p>
            </Col>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Currency Code : </span> {this.state.currency_code}</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Plan Name : </span> {this.state.PlanName}</p>
            </Col>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Plan Code : </span> {this.state.PlanCode}</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Country : </span> {this.state.Country}</p>
            </Col>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>State : </span> {this.state.State}</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>City : </span> {this.state.City}</p>
            </Col>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Zip Code : </span> {this.state.Zip}</p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p><span style={{ fontWeight: 600 }}>Mobile : </span> {this.state.mobile}</p>
            </Col>
            <Col span={12}>
              {/* <p><span style={{ fontWeight: 600 }}>Phone : </span> {this.state.phone}</p> */}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <p>
                <span style={{ fontWeight: 600 }}><span className="required-label">*</span>Select Database : </span>
                <Select
                  labelInValue
                  // defaultValue={{ value: '0', label: 'Select DB' }}
                  value={this.state.DBCountryName}
                  style={{ width: 120 }}
                  onChange={this.handleChange}
                >
                  {
                    this.state.DatabaseData ?
                      this.state.DatabaseData.map((item: any) =>
                        <Option value={item.Databaseid}>{item.CountryID}</Option>
                      ) : <></>
                  }
                </Select>
              </p>
            </Col>
            <Col span={12}>
              <Button className='SubmitButton' onClick={() => this.submit()}>Submit</Button>
            </Col>
          </Row>
        </Modal>
      </>
    )
  }
}