import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { Service } from '../Common/Services.service';
import "./MyProfile.css";
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Card } from 'antd';

interface profiledata {
  profiledata: any
  spin_loading: boolean
}
export default class myprofile extends Component<any, profiledata> {
  private Service: Service;
  constructor(props: any) {
    super(props)
    this.Service = new Service();
    this.state = {
      profiledata: [],
      spin_loading: false
    }
  }
  componentDidMount = async () => {
    const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
    // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    
    if (localTemp.length) {
      const local: any = localTemp ? JSON.parse(localTemp) : []
      this.setState({ spin_loading: true })
      let GetManageAddOnDataEntityObject: any = {
        Apps_MST_Account_ID: local.Apps_MST_Account_ID
      }
      let res: any = await this.Service.GetProfileDataService(GetManageAddOnDataEntityObject)
      
      this.setState({ spin_loading: false })
      var tempdata = [];
      if(res.status === 200) {
        tempdata = res.data.length ? res.data : [];
        this.setState({ profiledata: tempdata })
      }      
    }
  }
  render() {
    const  profiledata  = this.state.profiledata
    return (
      <>
        <div className={this.state.spin_loading ? "spin_class2" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>
        <Card>
          <div style={{ display: "flow-root" }}>
            <h3>User Profile</h3>
            <div >
              <div style={{ width: "50%", float: "left" }}>
                <div className='mail-parent'>
                  <span className='mail'><b>Email</b></span> : <span className='mail'>{ profiledata.length ? profiledata[0].email : "" }</span>
                </div>
                <div className='mail-parent'>
                  <span className='mail'><b>Mobile</b></span> : <span className='mail'>{ profiledata.length ? profiledata[0].mobile : "" }</span>
                </div>
                <div className='mail-parent'>
                  <span className='mail'><b>Company Name</b></span> : <span className='mail'>{ profiledata.length ? profiledata[0].company_name : "" }</span>
                </div>
                <div className='mail-parent'>
                  <span className='mail'><b>Company Reg. No.</b></span> : <span className='mail'>{ profiledata.length ? profiledata[0].CompanyRegNo : "" }</span>
                </div>
                <div className='mail-parent'>
                  <span className='mail'><b>Taxation No.</b></span> : <span className='mail'>{ profiledata.length ? profiledata[0].TaxationNo : "" }</span>
                </div>
              </div>
              <div style={{ width: "50%", float: "left" }}>
                <div className='addressbilling'>
                  <span className='addressdetails'>Address Details</span>
                  <div style={{ padding: "5px 0px 34px 0px" }}>
                    <span className='biling'>
                      Biling Address :
                    </span>
                    <br />
                    <span className='billino'>
                      <span>{ profiledata.length ? profiledata[0].BillingAddress : "" },</span><span>{ profiledata.length ? profiledata[0].City : "" },</span><span>{ profiledata.length ? profiledata[0].State : "" },</span><span>{ profiledata.length ? profiledata[0].Zip : "" },</span><span>{ profiledata.length ? profiledata[0].Country : "" }</span>
                    </span>
                    <br /><br />
                    <span className='biling'>
                      Shipping Address :
                    </span>
                    <br />
                    <span className='billino'>
                      <span>{ profiledata.length ? profiledata[0].ShippingAddress : "" },</span><span>{ profiledata.length ? profiledata[0].ShippingCity : "" },</span><span>{ profiledata.length ? profiledata[0].ShippingState : "" },</span><span>{ profiledata.length ? profiledata[0].ShippingZipCode : "" },</span><span>{ profiledata.length ? profiledata[0].ShippingCountry : "" }</span>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div style={{ width: "100%", marginTop: "20px" }}>
            <NavLink to="/EditAccount">
              <span className='btn-left1'>
                Edit Profile
              </span>
            </NavLink>
            <NavLink to="/ChangePassword">
              <span className='btn-right1' style={{ float: "right" }}>
                <span className=''>
                  Change Password
                </span>
              </span>
            </NavLink>
          </div>
        </Card>
      </>
    )
  }
}
