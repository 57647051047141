import { Card, Table } from 'antd';
import React, { Component } from 'react'
import { Service } from '../Common/Services.service';
import { TransationEntityObject , GetInvoiceURLEntityObject} from '../Common/Interface'
// import { LoginStoreObj } from '../Common/Store.store';
import { reactLocalStorage } from 'reactjs-localstorage';
import StickyText from '../Common/StikeyText';
import { DateFormatStore } from '../Common/DateConversion';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import { ArrowsAltOutlined } from '@ant-design/icons';


interface GetInvoiceData {
  GetInvoiceData: any
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
  spin_loading: boolean
}
export default class InvoiceHistory extends Component<any, GetInvoiceData>{
  public columns: any = []
  private Service: Service;
  constructor(props: any) {
    super(props)
    this.Service = new Service();
    this.state = {
      GetInvoiceData: [],
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
      spin_loading: false
    }
    this.columns = [
      {
        title: <span style={{ fontWeight: 'bold' }}>Invoice No.</span>,
        dataIndex: 'number',
        key: 'number',
        render: (text: any, record: any, index: any) =>
          record.status === 'sent' ||  record.status === 'Partially Paid' || record.status === 'overdue' ? <span className='invoice_overdue' onClick={() => this.GetInvoiceURL(record)}>{record.number} <ArrowsAltOutlined /></span> : <span>{record.number}</span>
      },
      {
        title: <span style={{ fontWeight: 'bold' }}>Invoice Date</span>,
        dataIndex: 'invoice_date',
        key: 'invoice_date',
        render: (text: any, record: any, index: any) =>
          <span>{record.invoice_date ? DateFormatStore.DateStringToNum(record.invoice_date) : ""}</span>
      },
      {
        title: <span style={{ fontWeight: 'bold' }}>Created Time</span>,
        dataIndex: 'created_time',
        key: 'created_time',
        render: (text: any, record: any, index: any) =>
          <span>{record.created_time ? DateFormatStore.DateStringToNum1(record.created_time) : ""}</span>
      },
      {
        title: <span style={{ fontWeight: 'bold' }}>Due Date</span>,
        dataIndex: 'due_date',
        key: 'due_date',
        render: (text: any, record: any, index: any) =>
          <span>{record.due_date ? DateFormatStore.DateStringToNum(record.due_date) : ""}</span>
      },
      {
        title: <span style={{ fontWeight: 'bold' }}>Currency</span>,
        dataIndex: 'currency_code',
        key: 'currency_code'
      },
      {
        title: <span style={{ fontWeight: 'bold' }}>Status</span>,
        dataIndex: 'status',
        key: 'status'
      },
      {
        title: <span style={{ fontWeight: 'bold' }}>Amount</span>,
        dataIndex: 'total',
        key: 'total',
        align: 'right' as 'right'
      },
      {
        title: <span style={{ fontWeight: 'bold' }}>Balance</span>,
        dataIndex: 'balance',
        key: 'balance',
        align: 'right' as 'right'
      },
    ];
  }
  getDataFromSticky(show: boolean) {
    
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  GetInvoiceURL  = async(record:any) => {
    let GetInvoiceURLEntity: GetInvoiceURLEntityObject = {
      InvoiceID: record.invoice_id
    }
    let res: any = await this.Service.GetInvoiceURLService(GetInvoiceURLEntity);
    if(res.data.message === 'success') {
      window.open(res.data.invoice.invoice_url)
    }
    else {
      this.stickyText(true, 'error', 'Error', 'Something went wrong');
    }
    
  } 
  componentDidMount = async () => {
    this.props.parentCallback('3');
    this.setState({ spin_loading: true })
    // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
    
    if (localTemp.length) {
      const local: any = localTemp ? JSON.parse(localTemp) : []
      let InvoiceEntity: TransationEntityObject = {
        ZohoCustomer_ID: local.ZOHO_Customer_ID
      }
      let res: any = await this.Service.GetInvoiceDataService(InvoiceEntity);
      this.setState({ spin_loading: false })
      const tempdata = res.data ? res.data : [];
      if (!tempdata.length) {
        this.stickyText(true, 'error', 'Error', 'No data found');
      }
      else{
        this.setState({ GetInvoiceData: tempdata })
      }
    }
  }
  render() {

    return (
      <div>
        <div className={this.state.spin_loading ? "spin_class2" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />

                :
                <></>
            }
          </>
        </div>
        <Card className='mb_30' style={{margin:"10px"}}>
          <Table
            columns={this.columns}
            dataSource={this.state.GetInvoiceData}
            className='tablestriped'
            pagination={{
              // pageSizeOptions: [],
              showSizeChanger: true,
              defaultPageSize: 25,
              hideOnSinglePage: this.state.GetInvoiceData !== undefined ? this.state.GetInvoiceData.length <= 25 ? true : false : true
            }}
          />
        </Card>
      </div>
    )
  }
}
