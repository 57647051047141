import React, { Component } from 'react';
import { Service } from '../Common/Services.service';
import "./CreateAccountTrial.css";
import StickyText from '../Common/StikeyText';
import {
  CheckEmail,
  CheckOTP,
  IKeyValueData, IKeyValueData1,
  BillingCountryAutoCompleteEntityObject,
  BillingStateAutoCompleteEntityObject
} from '../Common/Interface';
import LeftArrow from './../../Assests/img/icon/arrow-left-w.svg';
import Autocomplete from '../Common/AutoComplete/MerlinAutoComplete';
import { Col, Row, Modal, Button, Select } from 'antd';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import { CheckCircleOutlined, EyeFilled, CloseOutlined } from '@ant-design/icons';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
// import { END_POINT_LandingSite } from '../Common/EndPoints';
const { Option } = Select;
interface IState {
  CompanyCode: string
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
  FirstName: string
  Email: string
  LastName: string
  ContactNumber: string
  ConfirmPassword: string
  Password: string
  PlanName: string
  PlanName1: string
  BillingCountryDropdownData: IKeyValueData[],
  BillingStateDropdownData: IKeyValueData[],
  BillingStateDropdownDataTemp: IKeyValueData1[],
  ShippingCountryDropdownData: IKeyValueData[],
  ShippingStateDropdownData: IKeyValueData[],
  CurrencyDropdownData: IKeyValueData[],
  MasterPlansDropdownData: IKeyValueData[],
  CompanyName: string
  CompanyReg: string
  TaxationNo: string
  BillingCity: string
  BillingZipCode: string
  shippingCity: string
  shipppingZipCode: string
  billingAddress: string
  shippingAddress: string
  BCountryID: number,
  BCountryValue: string,
  BStateID: number,
  BStateValue: string,
  StateCode: string,
  SCountryID: number,
  SCountryValue: string,
  SStateID: number,
  SStateValue: string
  CurrencyId: number
  CurrencyValue: string
  MasterPlansId: number
  MasterPlansValue: string
  MasterPlanId: number
  description: string
  PlanDurationsId: number
  PlanDurationsValue: string
  PlanPeriod: string
  IsSubmit: number
  PlanCode: string,
  plancode: number,
  TrialPlanCode: string,
  IsPaid: string,
  passwordShown1: boolean,
  passwordShown2: boolean,
  spin_loading: boolean
  GstTreatment: string
  OTP: string
  IsValidOTP: boolean
  IsShowOTP: boolean
  IsNext: boolean,
  visible: boolean
}
export default class CreateTrialAccount extends Component<any, IState> {
  Service: Service;
  constructor(props: any) {
    super(props)
    this.Service = new Service();
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      Password: "",
      ConfirmPassword: "",
      LastName: "",
      ContactNumber: "",
      CompanyCode: "",
      FirstName: "",
      Email: "",
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
      PlanName: '',
      PlanName1: '',
      shippingAddress: "",
      CompanyName: "",
      BillingCity: "",
      CompanyReg: "",
      TaxationNo: "",
      BillingZipCode: "",
      shippingCity: "",
      shipppingZipCode: "",
      billingAddress: "",
      BillingCountryDropdownData: [],
      BillingStateDropdownData: [],
      BillingStateDropdownDataTemp: [],
      ShippingCountryDropdownData: [],
      ShippingStateDropdownData: [],
      CurrencyDropdownData: [],
      MasterPlansDropdownData: [],
      CurrencyId: 0,
      CurrencyValue: "",
      BCountryID: 0,
      BCountryValue: "",
      BStateValue: '',
      BStateID: 0,
      StateCode: "",
      SCountryID: 0,
      SCountryValue: "",
      MasterPlanId: 0,
      description: "",
      MasterPlansId: 0,
      MasterPlansValue: "",
      PlanDurationsId: 0,
      PlanDurationsValue: "",
      PlanPeriod: "",
      SStateValue: '',
      SStateID: 0,
      IsSubmit: 0,
      TrialPlanCode: '',
      PlanCode: "",
      plancode: 0,
      IsPaid: 'false',
      passwordShown1: false,
      passwordShown2: false,
      spin_loading: false,
      GstTreatment: 'GST Treatment',
      OTP: "",
      IsValidOTP: false,
      IsShowOTP: false,
      IsNext: true,
      visible: false
    }
  }
  getDataFromSticky(show: boolean) {
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  handleChange1 = (e: any) => {
    this.setState({ ...this.state, GstTreatment: e, sticky_show: false })
  }

  handleChange = (e: any) => {
    var input = e.target.value.replace(/  +/g, ' ');
    if (input === ' ') {
      input = '';
    }
    if (e.target.name === 'ContactNumber' || e.target.name === 'shipppingZipCode' || e.target.name === 'BillingZipCode') {
      if (e.target.value === "") {
        this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
      }
      else {
        const re = /^([1-9+][-0-9]*)$/;
        if (re.test(e.target.value)) {
          this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
        }
      }
    }
    else if (e.target.name === 'FirstName' || e.target.name === 'LastName' || e.target.name === 'CompanyCode') {
      const re = /^[a-zA-Z0-9\s]*$/;
      if (re.test(e.target.value)) {
        this.setState({ ...this.state, [e.target.name]: input, sticky_show: false })
      }
    }
    else if (e.target.name === 'BillingCity' || e.target.name === 'shippingCity') {
      const re = /^[a-zA-Z0-9 ]*$/;
      if (re.test(e.target.value)) {
        this.setState({ ...this.state, [e.target.name]: input, sticky_show: false })
      }
    }
    else if (e.target.name === 'CompanyCode') {
      const re = /^[a-zA-Z0-9]*$/;
      if (re.test(e.target.value)) {
        this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
      }
    }
    else {
      this.setState({ ...this.state, [e.target.name]: input, sticky_show: false })
    }
  };
  ClearDropdownData = () => {
    this.setState({
      sticky_show: false,
      BillingCountryDropdownData: [],
      BillingStateDropdownData: [],
      ShippingCountryDropdownData: [],
      ShippingStateDropdownData: [],
      CurrencyDropdownData: [],
      MasterPlansDropdownData: [],
    })
  }
  nextext = async () => {
    if (this.state.FirstName === "") {
      this.stickyText(true, 'error', 'Error', 'Please enter First Name');
      return false;
    }
    if (this.state.LastName === "") {
      this.stickyText(true, 'error', 'Error', 'Please enter Last Name');
      return false;
    }
    if (this.state.Email === "") {
      this.stickyText(true, 'error', 'Error', 'Please enter Email');
      return false;
    }
    if (!this.state.Email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      this.stickyText(true, 'error', 'Error', 'Please enter correct Email Address');
      return false;
    }
    if (this.state.ContactNumber === "") {
      this.stickyText(true, 'error', 'Error', 'Please enter Contact Number');
      return false;
    }
    if (this.state.Password === "") {
      this.stickyText(true, 'error', 'Error', 'Please enter Password');
      return false;
    }
    var regularExpretion = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/;
    if (this.state.Password.length < 8 || this.state.Password.length > 16) {
      this.stickyText(true, 'error', 'Error', 'Weak Password! Password must be between 8 Char to 16 Char');
      return false;
    }
    if (!this.state.Password.match(regularExpretion)) {
      this.stickyText(true, 'error', 'Error', 'Password must contain at least 8 characters, Including 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character(!@#$%^&*)');
      return false;
    }
    if (this.state.ConfirmPassword === "") {
      this.stickyText(true, 'error', 'Error', 'Please enter Confirm Password');
      return false;
    }
    if (this.state.ConfirmPassword.length < 8 || this.state.ConfirmPassword.length > 16) {
      this.stickyText(true, 'error', 'Error', 'Weak password! Confirm Password must be between 8 Char to 16 Char');
      return false;
    }
    if (!this.state.ConfirmPassword.match(regularExpretion)) {
      this.stickyText(true, 'error', 'Error', 'Confirm Password must contain at least 8 characters, including 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character(!@#$%^&*)');
      return false;
    }
    if (this.state.Password !== this.state.ConfirmPassword) {
      this.stickyText(true, 'error', 'Error', 'Password and Confirm Password are not same');
      return false;
    }
    let CheckEmail: CheckEmail = {
      displayname: '',
      firstname: this.state.FirstName,
      lastname: this.state.LastName,
      email: this.state.Email,
      password: this.state.Password,
      mobileno: this.state.ContactNumber,
      IsPaid: this.state.IsPaid === "false" ? "0" : "1"
    }
    this.setState({ spin_loading: true, sticky_show: false })
    const res: any = await this.Service.CheckEmailService(CheckEmail)
    this.setState({ spin_loading: false, sticky_show: false })
    var ID = res.data.length ? res.data[0].N : 0
    if (ID === 1) {
      this.stickyText(true, 'error', 'Error', 'Company Code already exists')
    }
    else if (ID === 2) {
      this.stickyText(true, 'error', 'Error', 'Email already exists')
    }
    else if (ID === 3) {
      if (this.state.IsPaid === 'false')
        this.setState({ IsShowOTP: true, visible: true, IsNext: false, sticky_show: false })
      else
        this.setState({ IsSubmit: 1, sticky_show: false })
    }
    else {
      this.stickyText(true, 'error', 'Error', 'Please contact SKUBIQ Team')
    }
  }

  OTPSubmit = async () => {
    if (this.state.OTP === "") {
      this.stickyText(true, 'error', 'Error', 'Please enter OTP');
      return false;
    }
    let CheckOTP: CheckOTP = {
      otpno: this.state.OTP,
      email: this.state.Email,
    }
    this.setState({ spin_loading: true, sticky_show: false })
    const res: any = await this.Service.CheckOTPService(CheckOTP)
    
    this.setState({ spin_loading: false, sticky_show: false })
    var ID = res.data.length ? res.data[0].N : 0
    if (ID !== 2) {
      this.stickyText(true, 'error', 'Error', 'Please enter valid OTP')
    }
    else {
      
      this.setState({ IsValidOTP: true, sticky_show: false })
      setTimeout(() => {
        this.submit()
        this.setState({ visible: false, OTP: "" })
      }, 3000)
    }
  }

  ChangeEmail = async () => {
    this.setState({ IsShowOTP: false, IsNext: true, sticky_show: false })
  }
  BillingCountryAutoComplete = async (prefix: string) => {
    let BillingCountryAutoCompleteEntityObject: BillingCountryAutoCompleteEntityObject = {
      prefix: prefix,
    }
    if (prefix === '') {
      this.setState({ BCountryID: 0, BCountryValue: '', sticky_show: false })
    }
    this.ClearDropdownData();
    let res: any = await this.Service.GetCountry(BillingCountryAutoCompleteEntityObject)
    var getData = res.data.length ? res.data : [];
    var tempData = [];
    if (res.status === 200) {

      for (var i = 0; i < getData.length; i++) {
        tempData.push({
          key: getData[i].CountryMasterID,
          value: getData[i].CountryName,
        });
      }
    }
    this.setState({ BillingCountryDropdownData: tempData, sticky_show: false, });
  }

  onSelectBillingCountry = (data: any) => {
    var ID: number = data[0].key;
    this.setState({ BCountryID: data[0].key, BCountryValue: data[0].value, sticky_show: false })
    if (ID !== 5 && this.state.GstTreatment !== 'overseas' && this.state.GstTreatment !== 'GST Treatment')
      this.setState({ GstTreatment: 'overseas' })
  }
  BillingStateAutoComplete = async (prefix: string) => {

    if (this.state.BCountryID === 0 || this.state.BCountryID === undefined || this.state.BCountryID === null) {
      this.stickyText(true, 'error', 'Error', 'Please select Country')
      return false
    }
    let BillingStateAutoCompleteEntityObject: BillingStateAutoCompleteEntityObject = {
      CountryID: this.state.BCountryID,
      Prefix: prefix,
      Flag: ""
    }
    this.ClearDropdownData();
    let res: any = await this.Service.GetState(BillingStateAutoCompleteEntityObject)
    var getData = res.data.length ? res.data : [];

    var tempData = [];
    var tempData1 = [];
    if (res.status === 200) {
      if (!getData.length) {
        this.stickyText(true, 'error', 'Error', 'No data found')
      }
      else {
        for (var i = 0; i < getData.length; i++) {
          tempData.push({
            key: getData[i].StateMasterID,
            value: getData[i].StateName,
          });
          tempData1.push({
            StateMasterID: getData[i].StateMasterID,
            StateName: getData[i].StateName,
            StateCode: getData[i].StateCode
          });
        }
        this.setState({ BillingStateDropdownData: tempData, sticky_show: false, BillingStateDropdownDataTemp: tempData1 });
      }
    }
  }
  onSelectBillingState = (data: any) => {
    var newArray = this.state.BillingStateDropdownDataTemp.filter(function (el: any) {
      return el.StateMasterID === data[0].key
    });

    this.setState({ BStateID: data[0].key, BStateValue: data[0].value, sticky_show: false, StateCode: newArray[0].StateCode })
  }
  ShippingCountryAutoComplete = async (prefix: string) => {
    let BillingCountryAutoCompleteEntityObject: BillingCountryAutoCompleteEntityObject = {
      prefix: prefix,
    }
    this.ClearDropdownData();
    let res: any = await this.Service.GetCountry(BillingCountryAutoCompleteEntityObject)
    var getData = res.data.length ? res.data : [];
    var tempData = [];
    if (res.status === 200) {
      for (var i = 0; i < getData.length; i++) {
        tempData.push({
          key: getData[i].CountryMasterID,
          value: getData[i].CountryName,
        });
      }
    }
    this.setState({ ShippingCountryDropdownData: tempData, sticky_show: false, });
  }
  onSelectShippingCountry = (data: any) => {
    this.setState({ SCountryID: data[0].key, SCountryValue: data[0].value, sticky_show: false })
  }
  ShippingStateAutoComplete = async (prefix: string) => {
    if (this.state.SCountryID === 0 || this.state.SCountryID === undefined || this.state.SCountryID === null) {
      this.stickyText(true, 'error', 'Error', 'Please select Country')
      return false
    }
    let BillingStateAutoCompleteEntityObject: BillingStateAutoCompleteEntityObject = {
      CountryID: this.state.SCountryID,
      Prefix: prefix,
      Flag: ""
    }
    this.ClearDropdownData();
    let res: any = await this.Service.GetState(BillingStateAutoCompleteEntityObject)
    var getData = res.data.length ? res.data : [];
    var tempData = [];
    if (res.status === 200) {
      if (!getData.length) {
        this.stickyText(true, 'error', 'Error', 'No data found')
      } else {
        for (var i = 0; i < getData.length; i++) {
          tempData.push({
            key: getData[i].StateMasterID,
            value: getData[i].StateName,
          });
        }

        this.setState({ ShippingStateDropdownData: tempData, sticky_show: false });
      }

    }
  }
  onSelectShippingState = (data: any) => {
    this.setState({ SStateID: data[0].key, SStateValue: data[0].value, sticky_show: false })
  }


  CurrencyAutoComplete = async (prefix: string) => {

    this.ClearDropdownData();
    var getData = [{ "currrency": "INR", "key": 1 },
    { "currrency": "USD", "key": 1 }]
    var tempData = [];
    for (var i = 0; i < getData.length; i++) {
      tempData.push({
        key: getData[i].key,
        value: getData[i].currrency,
      });
    }
    this.setState({ CurrencyDropdownData: tempData, sticky_show: false, });
  }
  onSelectCurrency = (data: any) => {
    this.setState({ CurrencyId: data[0].key, CurrencyValue: data[0].value, sticky_show: false })
  }
  addresscheck(e: any) {

    var check = e.currentTarget.checked
    if (check === true) {
      this.setState({ shippingAddress: this.state.billingAddress, SCountryValue: this.state.BCountryValue, SStateValue: this.state.BStateValue, shippingCity: this.state.BillingCity, shipppingZipCode: this.state.BillingZipCode, sticky_show: false, SCountryID: this.state.BCountryID });
    }
    else if (check === false) {
      this.setState({ shippingAddress: "", SCountryValue: "", SStateValue: "", shippingCity: "", shipppingZipCode: "", sticky_show: false, SCountryID: 0 });
    }
  }

  MasterPlansAutoComplete = async (prefix: string) => {

    if (prefix === '') {
      this.setState({ MasterPlansId: 0, MasterPlansValue: '', sticky_show: false })
    }
    this.ClearDropdownData();
    let MasterPlansAutoCompleteEntityObject: any = {
      "prefix": this.state.PlanCode,
      "currencycode": this.state.CurrencyValue
    }
    let res: any = await this.Service.GetMasterPlansList(MasterPlansAutoCompleteEntityObject)
    var getData = res.data.length ? res.data : [];
    var tempData = [];
    if (res.status === 200) {

      for (var i = 0; i < getData.length; i++) {
        tempData.push({
          key: getData[i].plancode,
          value: getData[i].description
          ,
        });
      }
    }
    this.setState({ MasterPlansDropdownData: tempData, sticky_show: false, });
  }

  onSelectMasterPlans = (data: any) => {
    this.setState({ plancode: data[0].key, description: data[0].value, sticky_show: false })
  }



  submit = async () => {
    
    if (this.state.IsPaid === "false" && this.state.IsValidOTP === false) {
      this.stickyText(true, 'error', 'Error', 'Please enter valid OTP');
      return false;
    }
    var submitdata: any = []
    if (this.state.IsPaid === "false") {
      submitdata = {
        displayname: '',
        firstname: this.state.FirstName.trim(),
        lastname: this.state.LastName.trim(),
        email: this.state.Email,
        password: this.state.Password,
        repassword: this.state.ConfirmPassword,
        planCode: this.state.TrialPlanCode,
        isPaid: this.state.IsPaid,
        billingStreet: "Trial",
        billingCity: "HYD",
        billingState: "TL",
        StateCode: "Trial",
        billingCountry: "India",
        billingZipCode: "Trial",
        shippingStreet: "Trial",
        shippingCity: "HYD",
        shippingState: "TL",
        shippingCountry: "India",
        shippingZipCode: "Trial",
        companyRegNo: "Trial",
        taxationNo: "Trial",
        GstTreatment: "Trial",
        companyname: "Trail Inventrax",
        mobileno: this.state.ContactNumber,
        currencycode: "INR",
        ipAddress: "",
        hostName: "",
      }
    }
    else {
      if (this.state.CompanyName === "") {
        this.stickyText(true, 'error', 'Error', 'Please enter Company Name');
        return false;
      }
      if (this.state.CompanyReg === "") {
        this.stickyText(true, 'error', 'Error', 'Please enter Company Reg. No.');
        return false;
      }
      if (this.state.IsPaid !== 'false') {
        if (this.state.GstTreatment === '' || this.state.GstTreatment === 'GST Treatment') {
          this.stickyText(true, 'error', 'Error', 'Please select Business Type');
          return false;
        }
        if (this.state.GstTreatment === 'business_gst' && this.state.TaxationNo === "") {
          this.stickyText(true, 'error', 'Error', 'Please enter Taxation No.(VAT/GST)');
          return false;
        }
        if (this.state.BCountryID === 5 && this.state.GstTreatment === 'overseas') {
          this.stickyText(true, 'error', 'Error', 'Please change Business GST type');
          return false;
        }
      }
      if (this.state.CurrencyValue === "") {
        this.stickyText(true, 'error', 'Error', 'Please select Currency');
        return false;
      }
      console.log(this.state.CurrencyValue)
      if (this.state.BCountryValue === "") {
        this.stickyText(true, 'error', 'Error', 'Please select Billing Country');
        return false;
      }
      if (this.state.BStateValue === "") {
        this.stickyText(true, 'error', 'Error', 'Please select Billing State');
        return false;
      }
      if (this.state.BillingCity === "") {
        this.stickyText(true, 'error', 'Error', 'Please enter Billing City');
        return false;
      }
      if (this.state.BillingZipCode === "") {
        this.stickyText(true, 'error', 'Error', 'Please enter Billing Zip Code');
        return false;
      }
      if (this.state.billingAddress === "") {
        this.stickyText(true, 'error', 'Error', 'Please enter Billing Address');
        return false;
      }
      if (this.state.SCountryValue === "") {
        this.stickyText(true, 'error', 'Error', 'Please select Shipping Country');
        return false;
      }
      if (this.state.SStateValue === "") {
        this.stickyText(true, 'error', 'Error', 'Please select Shipping State');
        return false;
      }
      if (this.state.shippingCity === "") {
        this.stickyText(true, 'error', 'Error', 'Please enter Shipping City');
        return false;
      }
      if (this.state.shipppingZipCode === "") {
        this.stickyText(true, 'error', 'Error', 'Please enter Shippping Zip Code');
        return false;
      }
      if (this.state.shippingAddress === "") {
        this.stickyText(true, 'error', 'Error', 'Please enter Billing Address');
        return false;
      }
      if (this.state.description === "") {
        this.stickyText(true, 'error', 'Error', 'Please Select Plan');
        return false;
      }
      submitdata = {
        displayname: '',
        firstname: this.state.FirstName.trim(),
        lastname: this.state.LastName.trim(),
        email: this.state.Email,
        password: this.state.Password,
        repassword: this.state.ConfirmPassword,
        planCode: this.state.plancode,
        isPaid: this.state.IsPaid,
        billingStreet: this.state.billingAddress.trim(),
        billingCity: this.state.BillingCity.trim(),
        billingState: this.state.BStateValue,
        StateCode: this.state.StateCode,
        billingCountry: this.state.BCountryValue,
        billingZipCode: this.state.BillingZipCode,
        shippingStreet: this.state.shippingAddress.trim(),
        shippingCity: this.state.shippingCity.trim(),
        shippingState: this.state.SStateValue,
        shippingCountry: this.state.SCountryValue,
        shippingZipCode: this.state.shipppingZipCode,
        companyRegNo: this.state.CompanyReg,
        taxationNo: this.state.TaxationNo,
        GstTreatment: this.state.GstTreatment,
        companyname: this.state.CompanyName.trim(),
        mobileno: this.state.ContactNumber,
        currencycode: this.state.CurrencyValue,
        ipAddress: "",
        hostName: "",
      }
    }
    
    this.setState({ spin_loading: true, sticky_show: false })
    const res: any = await this.Service.CreateAccountService(submitdata)
    this.setState({ spin_loading: false, sticky_show: false })
    
    if (res.status === 200) {
      if (res.data === 'Success') {
        window.location.href = './../../../../Success'
        window.localStorage.clear();
      }
      else if (res.data === 'Fail') {
        this.stickyText(true, 'error', 'error', 'Error while Account Creation');
        setTimeout(() => {
          window.location.href = '/Login';
        }, 3000)
        return false;
      }
      else if (res.data.includes('https://subscriptions.zoho.com/hostedpage')) {
        window.location.href = res.data;
      }
      else {
        this.stickyText(true, 'error', 'error', res.data);
      }
    }
    else {
      this.stickyText(true, 'error', 'error', 'Error while Account Creation');
    }
  }

  previous = () => {
    this.setState({ IsSubmit: 0, sticky_show: false });
  }
  ShowModal() {

    this.setState({
      visible: true,
      OTP: ""
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      OTP: ""
    })
  }

  componentDidMount() {
    var URL = window.location.pathname;
    var myArray: any = URL.split('/');
    var PlanCode = myArray.length ? myArray[3] : "";
    var TrialPlanCode = myArray.length ? myArray[2] : "";
    var IsPaid = 'false';
    var PlanName = myArray[2]
    var PlanName1 = ""
    this.setState({ CurrencyId: myArray.length ? myArray[5] : 0, CurrencyValue: myArray[5] === "1" ? "INR" : "USD" })
    if ("TP-1U1W" === PlanName) {
      PlanName = `${"Let's start your free trial"}`
      PlanName1 = "Lite Plan"
    }
    else if ("TP-1U1W" === PlanName) {
      PlanName = `${"Let's start your free trial "}`
      PlanName1 = "Silver Plan"
    }
    else if ("TP-1U1W" === PlanName) {
      PlanName = `${"Let's start your free trial "}`
      PlanName1 = "Gold Plan"
    }
    else if ("TP-1U1W" === PlanName) {
      PlanName = `${"Let's start your free trial "}`
      PlanName1 = "Platinum Plan"
    }
    else {
      PlanName = "Let's get started"
    }
    IsPaid = IsPaid === 'true' ? 'true' : 'false';
    this.setState({ PlanCode: PlanCode, IsPaid: IsPaid, sticky_show: false, TrialPlanCode: TrialPlanCode, PlanName: PlanName, PlanName1: PlanName1 })

  }
  togglePassword1 = () => {
    setTimeout(() => {
      this.setState({
        passwordShown1: !this.state.passwordShown1, sticky_show: false
      })
    }, 2000);
    this.setState({
      passwordShown1: !this.state.passwordShown1, sticky_show: false
    })
  }
  togglePassword2 = () => {
    setTimeout(() => {
      this.setState({
        passwordShown2: !this.state.passwordShown2, sticky_show: false
      })
    }, 2000);
    this.setState({
      passwordShown2: !this.state.passwordShown2, sticky_show: false
    })
  }
  render() {
    return (
      <div className='loginpagebg' style={{ overflow: 'auto' }}>

        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />
                :
                <></>
            }
          </>
        </div>
        <div className={this.state.spin_loading ? "spin_class_login" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>
        {
          this.state.IsSubmit === 0 ?
            <div className="container container1">

              <div className="login-f">
                <form action="#">
                  <div className="title"><h1>{this.state.PlanName} <br></br>
                    {this.state.PlanName1} </h1></div>
                  <div className='homeBtnstyle'>
                    <a href="https://www.skubiq.com">
                      <CloseOutlined />
                    </a>
                  </div>


                  <Row gutter={16} className="inputRow1">
                    <Col span={12}>
                      <div className="divFlex">
                        <input
                          type="text"
                          required
                          name="FirstName"
                          maxLength={20}
                          value={this.state.FirstName}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>First Name</label>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="divFlex">
                        <input
                          type="text"
                          required
                          name="LastName"
                          maxLength={20}
                          value={this.state.LastName}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>Last Name</label>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16} className="inputRow1">
                    <Col span={12}>
                      <div className="divFlex">
                        <input
                          type="text"
                          required
                          name="Email"
                          value={this.state.Email}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>Email</label>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="divFlex">
                        <input
                          type="text"
                          required
                          name="ContactNumber"
                          maxLength={16}
                          value={this.state.ContactNumber}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>Contact Number</label>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16} className="inputRow1">
                    <Col span={12}>
                      <div className="divFlex">
                        <input
                          type={this.state.passwordShown1 ? "text" : "password"}
                          required
                          name="Password"
                          maxLength={16}
                          value={this.state.Password}
                          onChange={this.handleChange}
                          autoComplete="off"
                        /><EyeFilled onClick={() => this.togglePassword1()} />
                        <label><span className="required-label">*</span>Password</label>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="divFlex">
                        <input
                          type={this.state.passwordShown2 ? "text" : "password"}
                          required
                          name="ConfirmPassword"
                          maxLength={16}
                          value={this.state.ConfirmPassword}
                          onChange={this.handleChange}
                          autoComplete="off"
                        /><EyeFilled onClick={() => this.togglePassword2()} />
                        <label><span className="required-label">*</span>Confirm Password</label>
                      </div>
                    </Col>
                  </Row><br />
                  <Row gutter={16}>
                    <Col span={24}>
                      {
                        this.state.IsNext === true ?
                          <span onClick={() => this.nextext()} className='btn-right' style={{ float: "right" }}>
                            Next <img src={LeftArrow} alt="LeftArrow" />
                          </span>
                          : ""
                      }
                    </Col>
                  </Row>
                </form>
                <div style={{ marginTop: "20px" }} >
                  {
                    this.state.IsShowOTP === true ?
                      <>
                        <Row gutter={16} className="inputRow1">

                          <Col span={24}>
                            {
                              <span onClick={() => this.nextext()} className='btn-right' style={{ float: "right" }}>
                                Next <img src={LeftArrow} alt="LeftArrow" />
                              </span>
                            }
                          </Col>
                        </Row>
                      </> : <></>
                  }
                </div>
              </div>
            </div>
            :
            <div className="container container1">

              <div className="login-f1 creactaccount">
                <form>
                  <div className="title" id='title'><h1>{this.state.PlanName}&nbsp;
                    {this.state.PlanName1} </h1></div>
                  <div className='homeBtnstyle1'>
                    <a href="https://www.skubiq.com">
                      <CloseOutlined />
                    </a>
                  </div>
                  <div>
                  </div>
                  <Row gutter={16} className="inputRow">
                    <Col span={12}>
                      <div className="divFlex">
                        <input
                          type="text"
                          required
                          name="CompanyName"
                          maxLength={99}
                          value={this.state.CompanyName}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>Company Name</label>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="divFlex">
                        <input
                          type="text"
                          required
                          name="CompanyReg"
                          maxLength={20}
                          value={this.state.CompanyReg}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>Company Reg. No.</label>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16} className="inputRow BType" >
                    {
                      this.state.IsPaid !== 'false' ?
                        <Col span={8} id='btype'>
                          <Select style={{ width: '100%' }}
                            placeholder="Select Business Type"
                            onChange={this.handleChange1}
                            value={this.state.GstTreatment}
                          >
                            <Option value="business_gst">Business GST</Option>
                            <Option value="business_none">Business None</Option>
                            <Option value="overseas">Overseas</Option>
                          </Select>
                          <label className="required-label-dropdown-label">Business Type</label>
                        </Col> : <></>
                    }
                    {
                      this.state.GstTreatment === 'business_gst' ?
                        <Col span={8}>
                          <div className="divFlex">
                            <input
                              type="text"
                              required
                              name="TaxationNo"
                              maxLength={20}
                              value={this.state.TaxationNo}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                            <label><span className="required-label">*</span>Taxation No.(VAT/GST)</label>
                          </div>
                        </Col> : <></>
                    }
                    <Col span={8}>
                      <div className="">
                        <Autocomplete
                          options={this.state.CurrencyDropdownData}
                          onValueSelect={this.onSelectCurrency}
                          defaultValue={this.state.CurrencyValue}
                          AutocompleteOnChange={this.CurrencyAutoComplete}
                          aPICallRequired={true}
                          PrefixMinLenght={0}
                          key={this.state.CurrencyValue}
                          label="Currency"
                        />
                        <SearchOutlined className="Icon_Align" />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16} className="inputRow">
                    <Col span={12}>
                      <span className='billing' >
                        Billing Address
                      </span>
                      <Row gutter={16} className="billingbottomrow">
                        <Col span={12}>
                          <Autocomplete
                            options={this.state.BillingCountryDropdownData}
                            onValueSelect={this.onSelectBillingCountry}
                            defaultValue={this.state.BCountryValue}
                            AutocompleteOnChange={this.BillingCountryAutoComplete}
                            aPICallRequired={true}
                            PrefixMinLenght={0}
                            key={this.state.BCountryValue}
                            label="Country"
                          />
                          <SearchOutlined className="Icon_Align" />
                        </Col>
                        <Col span={12}>
                          <Autocomplete
                            options={this.state.BillingStateDropdownData}
                            onValueSelect={this.onSelectBillingState}
                            defaultValue={this.state.BStateValue}
                            AutocompleteOnChange={this.BillingStateAutoComplete}
                            aPICallRequired={true}
                            PrefixMinLenght={0}
                            key={this.state.BStateValue}
                            label="State"
                          />
                          <SearchOutlined className="Icon_Align" />
                        </Col>
                        <Col span={12}>
                          <div className="divFlex inputRow">
                            <input
                              type="text"
                              required
                              name="BillingCity"
                              maxLength={20}
                              value={this.state.BillingCity}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                            <label><span className="required-label">*</span>City</label>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="divFlex inputRow">
                            <input
                              type="text"
                              required
                              name="BillingZipCode"
                              maxLength={20}
                              value={this.state.BillingZipCode}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                            <label><span className="required-label">*</span>Zip Code</label>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="divFlex inputRow">
                            <input
                              type="text"
                              required
                              name="billingAddress"
                              value={this.state.billingAddress}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                            <label><span className="required-label">*</span>Address</label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <span className='billing' style={{ paddingRight: "50px" }}>
                        Shipping Address
                      </span>
                      <span>
                        <input type="checkbox" onChange={(e) => this.addresscheck(e)} style={{ marginRight: "10px" }} />
                        Same as Billing Address
                      </span>
                      <br />
                      <Row gutter={16} className="billingbottomrow">
                        <Col span={12}>
                          <Autocomplete
                            options={this.state.ShippingCountryDropdownData}
                            onValueSelect={this.onSelectShippingCountry}
                            defaultValue={this.state.SCountryValue}
                            AutocompleteOnChange={this.ShippingCountryAutoComplete}
                            aPICallRequired={true}
                            PrefixMinLenght={0}
                            key={this.state.SCountryValue}
                            label="Country"
                          />
                          <SearchOutlined className="Icon_Align" />
                        </Col>
                        <Col span={12}>
                          <Autocomplete
                            options={this.state.ShippingStateDropdownData}
                            onValueSelect={this.onSelectShippingState}
                            defaultValue={this.state.SStateValue}
                            AutocompleteOnChange={this.ShippingStateAutoComplete}
                            aPICallRequired={true}
                            PrefixMinLenght={0}
                            key={this.state.SStateValue}
                            label="State"
                          />
                          <SearchOutlined className="Icon_Align" />
                        </Col>
                        <Col span={12}>
                          <div className="divFlex inputRow">
                            <input
                              type="text"
                              required
                              name="shippingCity"
                              maxLength={20}
                              value={this.state.shippingCity}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                            <label><span className="required-label">*</span>City</label>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="divFlex inputRow">
                            <input
                              type="text"
                              required
                              name="shipppingZipCode"
                              maxLength={20}
                              value={this.state.shipppingZipCode}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                            <label><span className="required-label">*</span>Zip Code</label>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="divFlex inputRow">
                            <input
                              type="text"
                              required
                              name="shippingAddress"
                              value={this.state.shippingAddress}
                              onChange={this.handleChange}
                              autoComplete="off"
                            />
                            <label><span className="required-label">*</span>Address</label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={16} className="inputRow">
                    <Col span={12}>
                      <span className='billing' >
                        Plan Details
                      </span>
                    </Col>
                  </Row>
                  <Row gutter={16} className="billingbottomrow">
                    <Col span={12} >
                      <div id="MasterPlansAutoComplete">
                        <Autocomplete
                          options={this.state.MasterPlansDropdownData}
                          onValueSelect={this.onSelectMasterPlans}
                          defaultValue={this.state.description}
                          AutocompleteOnChange={this.MasterPlansAutoComplete}
                          aPICallRequired={true}
                          PrefixMinLenght={0}
                          key={this.state.description}
                          label="Select Plan"
                        />
                        <SearchOutlined className="Icon_Align" />
                      </div>
                    </Col>
                    {/* <Col span={8}>
                      <div className="certain-category-search-dropdown">
                        <Autocomplete
                          options={this.state.PlanDurationDropdownData}
                          onValueSelect={this.onSelectPlanDuration}
                          defaultValue={this.state.Duration}
                          AutocompleteOnChange={this.PlanDurationAutoComplete}
                          aPICallRequired={true}
                          PrefixMinLenght={0}
                          key={this.state.Duration}
                          label="Plan Duration"
                        />
                        <SearchOutlined className="Icon_Align" />
                      </div>
                    </Col> */}
                    {/* <Col span={8}>
                          <Select style={{ width: '100%' }}
                            placeholder="payment Period"
                            onChange={this.handleChange2}
                            value={this.state.PlanPeriod === "1" ? "1 Month" : this.state.PlanPeriod === "3" ? "3 Month" : this.state.PlanPeriod === "6" ? "6 Month" : this.state.PlanPeriod ==="1Y" ? "1 Year" : this.state.PlanPeriod === "5Y"? "5 Year": ""}
                          >
                            <Option value="1">1 Month</Option>
                            <Option value="3">3 Month</Option>
                            <Option value="6">6 Month</Option>
                            <Option value="1Y">1 Year</Option>
                            <Option value="5Y">5 Year</Option>
                          </Select>
                           <label className="required-label-dropdown-label">Plan Duration</label> 
                        </Col>   */}
                  </Row>
                </form>
                <div style={{ marginTop: "20px" }} >
                  <span className='btn-left' onClick={() => this.previous()}>
                    Previous
                  </span>
                  <span className='btn-right' style={{ float: "right" }} onClick={() => this.submit()}>
                    Submit
                  </span>
                </div>
              </div>
            </div>
        }
        <Modal
          visible={this.state.visible}
          title="OTP Verification"
          className='OTPModal'
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          footer={[
            <></>
          ]}
        >
          <Row gutter={16}>
            <Col className="gutter-row" span={24}>
              <div className="text-label">
                <p style={{ color: "#3f3f3f", fontWeight: "bold" }}>OTP has been sent to you registered Email</p>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={10} offset={7}>
              <div className="divFlex" style={{ margin: '0 0 20px 0' }}>
                <input style={{ textAlign: 'center' }} placeholder='One Time Password'
                  type="text"
                  required
                  name="OTP"
                  maxLength={10}
                  value={this.state.OTP}
                  onChange={this.handleChange}
                  autoComplete="off"
                  disabled={this.state.IsValidOTP === true}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16} className="otp-postion">
            {
              this.state.IsValidOTP === true ?
                <span className="OTP_Verified"><CheckCircleOutlined /> OTP Verified</span>
                :
                <Button key="Submit" className='Button-Postion' onClick={() => this.OTPSubmit()}>
                  Vaildate OTP
                </Button>
            }
          </Row>
        </Modal>
      </div>
    )
  }
}
