import { reactLocalStorage } from 'reactjs-localstorage';
import { END_POINT_SKUBIQWMSURL } from "./EndPoints";
import { Service } from './Services.service';

export class LoginStore extends Service {
    public jwtToken: string ="";
    async LoginStoreMethod(bodyFormData:any) {
        
        let res: any = await this.CheckLoginService(bodyFormData);
        if (res.status === 200) {
            // sessionStorage.setItem('LandingSiteTokenInfo', JSON.stringify(res.data));
            reactLocalStorage.set('LandingSiteTokenInfo', JSON.stringify(res.data));
            
            const LoginTempData :any = await this.GetLoginService();
            const LoginData :any = LoginTempData.data ? LoginTempData.data[0] : []
            // sessionStorage.setItem('LandingSiteLoginUserInfo', JSON.stringify(LoginData));
            reactLocalStorage.set('LandingSiteLoginUserInfo', JSON.stringify(LoginData));
            
            if(LoginData === undefined){
                // sessionStorage.clear()
                window.localStorage.clear();
                window.location.href = "/";
            } 
            else if(LoginData.IsZohoLogin === true) {
                window.location.href = "/DashBoard";
            }
            else {
                // var LoginDecData: any = JSON.parse(sessionStorage.getItem('LandingSiteLoginData')!);
                var LoginDecData: any = JSON.parse(reactLocalStorage.get('LandingSiteLoginData'));
                // window.location.href = `${END_POINT_SKUBIQWMSURL}login?p1=${LoginDecData.username}&&p2=${LoginDecData.password}`;
                window.location.href = `${END_POINT_SKUBIQWMSURL}login?p1=${LoginDecData.username}&&p2=${LoginDecData.password}`;
            }
            
        }
        else {
            if(res.response.status === 400){
                window.localStorage.clear();
                // sessionStorage.clear()
                 this.jwtToken = res.response.data.error_description
                // alert(res.response.data.error_description);
            }
        }
    }

    async GetLoginDataStore() {
       
       const LoginData :any = await this.GetLoginService();
       reactLocalStorage.set('LandingSiteLoginUserInfo', JSON.stringify(LoginData.data ? LoginData.data[0] : []));
    //    sessionStorage.setItem('LandingSiteLoginUserInfo', JSON.stringify(LoginData.data ? LoginData.data[0] : []));
    } 
}
export const LoginStoreObj = new LoginStore();