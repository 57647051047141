import React, { Component } from 'react';
import Autocomplete from '../Common/AutoComplete/MerlinAutoComplete';
import { Service } from '../Common/Services.service';
import "./EditAccount.css";
import StickyText from '../Common/StikeyText';
import { IKeyValueData, BillingCountryAutoCompleteEntityObject, BillingStateAutoCompleteEntityObject } from '../Common/Interface';
import { Col, Row } from 'antd';
import { reactLocalStorage } from 'reactjs-localstorage';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import { NavLink } from 'react-router-dom';

interface Companydetails {
   profiledata: any
   BillingCountryDropdownData: IKeyValueData[],
   BillingStateDropdownData: IKeyValueData[],

   ShippingCountryDropdownData: IKeyValueData[],
   ShippingStateDropdownData: IKeyValueData[],
   currencyDropdownData: IKeyValueData[],

   //    CompanyReg:string
   LastName: string
   FirstName: string
   DisplayName: string
   HostName: string
   MobileNo: string
   CompanyName: string
   Email: string
   CompanyReg: string
   TaxationNo: string
   BillingCity: string
   billingZipCode: string
   shippingCity: string
   shipppingZipCode: string
   billingAddress: string
   shippingAddress: string
   IPAddress: string
   BCountryID: number,
   BCountryValue: string,

   BStateID: number,
   BStateValue: string,

   SCountryID: number,
   SCountryValue: string,

   SStateID: number,
   SStateValue: string

   sticky_show: boolean
   sticky_message: string
   sticky_type: string
   sticky_title: string

   currencyid: number
   currencyvalue: string
   spin_loading: boolean
}
export default class EditAccount extends Component<any, Companydetails> {
   private Service: Service;


   constructor(props: any) {
      super(props);
      this.Service = new Service();
      this.state = {
         IPAddress: "",
         CompanyName: "",
         MobileNo: "",
         Email: "",
         LastName: "",
         profiledata: [],
         shippingAddress: "",
         DisplayName: "",
         HostName: "",
         FirstName: "",
         BillingCity: "",
         CompanyReg: "",
         TaxationNo: "",
         billingZipCode: "",
         shippingCity: "",
         shipppingZipCode: "",
         billingAddress: "",
         BillingCountryDropdownData: [],
         BillingStateDropdownData: [],
         ShippingCountryDropdownData: [],
         ShippingStateDropdownData: [],
         currencyDropdownData: [],
         currencyid: 0,
         currencyvalue: "",
         BCountryID: 0,
         BCountryValue: "",

         BStateValue: '',
         BStateID: 0,

         SCountryID: 0,
         SCountryValue: "",

         SStateValue: '',
         SStateID: 0,

         sticky_show: false,
         sticky_message: '',
         sticky_type: '',
         sticky_title: '',
         spin_loading: false
      }
   }
   handleChange = (e: any) => {   
      var input = e.target.value.replace(/  +/g, ' ');
        if (input === ' ') {
            input = '';
        }
    if (e.target.name === 'MobileNo' || e.target.name === 'OTP'||e.target.name === 'shipppingZipCode' ||e.target.name === 'billingZipCode') {
      if (e.target.value === "") {
        this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
      }
      else {
        const re = /^([1-9+][-0-9]*)$/;
        if (re.test(e.target.value)) {
          this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
        }
      }
    }
    else if (e.target.name === 'FirstName' || e.target.name === 'LastName') {
      const re = /^[a-zA-Z0-9\s]*$/;
      if (re.test(e.target.value)) {
        this.setState({ ...this.state, [e.target.name]: input, sticky_show: false })
      }
    }
    else if (e.target.name === 'BillingCity' || e.target.name === 'shippingCity') {
    const re = /^[a-zA-Z0-9 ]*$/;
      if (re.test(e.target.value)) {
        this.setState({ ...this.state, [e.target.name]: input, sticky_show: false })
      }
    }
    else {
      this.setState({ ...this.state, [e.target.name]: input, sticky_show: false })
    }
   };
   ClearDropdownData = () => {
      this.setState({
         sticky_show: false,
         BillingCountryDropdownData: [],
         BillingStateDropdownData: [],
         ShippingCountryDropdownData: [],
         ShippingStateDropdownData: [],
         currencyDropdownData: []
      })
   }
   getDataFromSticky(show: boolean) {
      
      this.setState({ sticky_show: show })
   }
   stickyText(show: boolean, type: string, title: string, message: string) {
      this.setState({
         sticky_show: show,
         sticky_type: type,
         sticky_title: title,
         sticky_message: message
      })
      return false;
   }

   BillingCountryAutoComplete = async (prefix: string) => {
      
      let BillingCountryAutoCompleteEntityObject: BillingCountryAutoCompleteEntityObject = {
         prefix: prefix,
      }
      this.ClearDropdownData();
      let res: any = await this.Service.GetCountry(BillingCountryAutoCompleteEntityObject)
      var getData = res.data.length ? res.data : [];
      
      var tempData = [];
      if (res.status === 200) {

         for (var i = 0; i < getData.length; i++) {
            tempData.push({
               key: getData[i].CountryMasterID,
               value: getData[i].CountryName,
            });
         }
      }
      this.setState({ BillingCountryDropdownData: tempData, sticky_show: false, });
   }
   addresscheck(e: any) {
      
      var check = e.currentTarget.checked
      if (check === true) {
         this.setState({ shippingAddress: this.state.billingAddress, SCountryValue: this.state.BCountryValue, SStateValue: this.state.BStateValue, shippingCity: this.state.BillingCity, shipppingZipCode: this.state.billingZipCode, sticky_show: false, SCountryID: this.state.BCountryID });
      }
      else if (check === false) {
         this.setState({ shippingAddress: "", SCountryValue: "", SStateValue: "", shippingCity: "", shipppingZipCode: "", sticky_show: false, SCountryID: 0 });
      }
      //   var shippingAddress=this.state.shippingAddress
   }
   onSelectBillingCountry = (data: any) => {
      //console.log(data)
      this.setState({ BCountryID: data[0].key, BCountryValue: data[0].value, sticky_show: false })
   }
   BillingStateAutoComplete = async (prefix: string) => {
      
      if (this.state.BCountryValue === "" || this.state.BCountryValue === undefined || this.state.BCountryValue === null) {
         this.stickyText(true, 'error', 'Error', 'Please select Country')
         return false
      }
      // this.stickyText(true, 'error', 'Error', 'Please contact skubiq team')
      let BillingStateAutoCompleteEntityObject: BillingStateAutoCompleteEntityObject = {
         CountryID: this.state.BCountryID,
         Prefix: prefix,
         Flag: ""
      }
      
      this.ClearDropdownData();
      let res: any = await this.Service.GetState(BillingStateAutoCompleteEntityObject)
      var getData = res.data.length ? res.data : [];
      
      var tempData = [];
      if (res.status === 200) {
         if (!getData.length) {
            this.stickyText(true, 'error', 'Error', 'No data found')
         }
         else {
            for (var i = 0; i < getData.length; i++) {
               tempData.push({
                  key: getData[i].StateMasterID,
                  value: getData[i].StateName,
               });
            }
            this.setState({ BillingStateDropdownData: tempData, sticky_show: false, });
         }
      }
   }
   onSelectBillingState = (data: any) => {
      this.setState({ BStateID: data[0].key, BStateValue: data[0].value, sticky_show: false })
   }
   ShippingCountryAutoComplete = async (prefix: string) => {
      let BillingCountryAutoCompleteEntityObject: BillingCountryAutoCompleteEntityObject = {
         prefix: prefix,
      }
      this.ClearDropdownData();
      let res: any = await this.Service.GetCountry(BillingCountryAutoCompleteEntityObject)
      var getData = res.data.length ? res.data : [];
      
      var tempData = [];
      if (res.status === 200) {
         for (var i = 0; i < getData.length; i++) {
            tempData.push({
               key: getData[i].CountryMasterID,
               value: getData[i].CountryName,
            });
         }
      }
      this.setState({ ShippingCountryDropdownData: tempData, sticky_show: false, });
   }
   onSelectShippingCountry = (data: any) => {
      //console.log(data)
      this.setState({ SCountryID: data[0].key, SCountryValue: data[0].value, sticky_show: false })
   }
   ShippingStateAutoComplete = async (prefix: string) => {
      if (this.state.SCountryID === 0 || this.state.SCountryID === undefined || this.state.SCountryID === null) {
         this.stickyText(true, 'error', 'Error', 'Please select Country')
         return false
      }
      let BillingStateAutoCompleteEntityObject: BillingStateAutoCompleteEntityObject = {
         CountryID: this.state.SCountryID,
         Prefix: prefix,
         Flag: ""
      }
      
      this.ClearDropdownData();
      let res: any = await this.Service.GetState(BillingStateAutoCompleteEntityObject)
      var getData = res.data.length ? res.data : [];
      
      var tempData = [];
      if (res.status === 200) {
         if (!getData.length) {
            this.stickyText(true, 'error', 'Error', 'No data found')
         } else {
            for (var i = 0; i < getData.length; i++) {
               tempData.push({
                  key: getData[i].StateMasterID,
                  value: getData[i].StateName,
               });
            }

            this.setState({ ShippingStateDropdownData: tempData, sticky_show: false, });
         }

      }
   }
   onSelectShippingState = (data: any) => {
      //console.log(data)
      this.setState({ SStateID: data[0].key, SStateValue: data[0].value, sticky_show: false })
   }

   onSelectcurrency = (data: any) => {
      //console.log(data)
      this.setState({ currencyid: data[0].key, currencyvalue: data[0].value, sticky_show: false })
   }
   currencyStateAutoComplete = async (prefix: string) => {
      
      this.ClearDropdownData();
      var getData = [{ "currrency": "INR", "key": 1 },
      { "currrency": "USD", "key": 2 }]
      var tempData = [];
      for (var i = 0; i < getData.length; i++) {
         tempData.push({
            key: getData[i].key,
            value: getData[i].currrency,
         });
      }
      this.setState({ currencyDropdownData: tempData, sticky_show: false, });
   }

   submit = async () => {
      
      if (this.state.DisplayName === "") {
         this.stickyText(true, 'error', 'Error', 'Please enter Display Name ')
      }
      else if (!this.state.Email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      this.stickyText(true, 'error', 'Error', 'Please enter correct Email Address');
      return false;
    }
      else if (this.state.CompanyReg === "") {
         this.stickyText(true, 'error', 'Error', 'Please enter Company Reg. No.')
      }
      else if (this.state.TaxationNo === "") {
         this.stickyText(true, 'error', 'Error', 'Please enter Taxation No.(VAT/GST)')
      }
      else if (this.state.currencyvalue === "") {
         this.stickyText(true, 'error', 'Error', 'Please select Currency')
      }
      else if (this.state.BCountryValue === "") {
         this.stickyText(true, 'error', 'Error', 'Please select Billingcountry')
      }
      else if (this.state.BStateValue === "") {
         this.stickyText(true, 'error', 'Error', 'Please select BillingState')
      }
      else if (this.state.BillingCity === "") {
         this.stickyText(true, 'error', 'Error', 'Please enter BillingCity')
      }
      else if (this.state.billingZipCode === "") {
         this.stickyText(true, 'error', 'Error', 'Please enter billingZipCode')
      }
      else if (this.state.billingAddress === "") {
         this.stickyText(true, 'error', 'Error', 'Please enter billingAddress')
      }
      else if (this.state.SCountryValue === "") {
         this.stickyText(true, 'error', 'Error', 'Please select shippingcountry')
      }
      else if (this.state.SStateValue === "") {
         this.stickyText(true, 'error', 'Error', 'Please select shippingstate')
      }
      else if (this.state.shippingCity === "") {
         this.stickyText(true, 'error', 'Error', 'Please enter shippingCity')
      }
      else if (this.state.shipppingZipCode === "") {
         this.stickyText(true, 'error', 'Error', 'Please enter shipppingZipCode')
      }
      else if (this.state.shippingAddress === "") {
         this.stickyText(true, 'error', 'Error', 'Please enter billingAddress')
      }
      else {
         this.setState({ spin_loading: true })
         // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
         const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
         
         if (localTemp.length) {
            const local: any = localTemp ? JSON.parse(localTemp) : []
            let submitdata: any = {
               displayname: this.state.DisplayName.trim(),
               firstname: this.state.FirstName.trim(),
               lastname: this.state.LastName.trim(),
               email: this.state.Email.trim(),
               billingStreet: this.state.billingAddress.trim(),
               billingCity: this.state.BillingCity.trim(),
               billingState: this.state.BStateValue,
               billingCountry: this.state.BCountryValue,
               billingZipCode: this.state.billingZipCode,
               shippingStreet: this.state.shippingAddress.trim(),
               shippingCity: this.state.shippingCity.trim(),
               shippingState: this.state.SStateValue,
               shippingCountry: this.state.SCountryValue,
               shippingZipCode: this.state.shipppingZipCode,
               companyRegNo: this.state.CompanyReg.trim(),
               taxationNo: this.state.TaxationNo.trim(),
               companyname: this.state.CompanyName.trim(),
               mobileno: this.state.MobileNo,
               currencycode: this.state.currencyvalue,
               ipAddress: this.state.IPAddress.trim(),
               hostName: this.state.HostName.trim(),
               Apps_MST_Account_ID: local.Apps_MST_Account_ID,
               ZohoCustomer_ID: local.ZOHO_Customer_ID.toString()
            }
            
            var res: any = await this.Service.UpdateProfileDataService(submitdata)
            console.log(res)
            if (res.data === 1) {
               this.stickyText(true, 'success', 'Success', 'Account updated successfully')
            }
            else {
               this.stickyText(true, 'error', 'Error', 'Something went wrong')
            }
            this.setState({ spin_loading: false })
            this.componentDidMount()
         }
      }
   }
   componentDidMount = async () => {
      // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
      const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
      
      if (localTemp.length) {
         const local: any = localTemp ? JSON.parse(localTemp) : []
         let GetManageAddOnDataEntityObject: any = {
            apps_MST_Account_ID: local.Apps_MST_Account_ID
         }
         this.setState({ spin_loading: true })
         let res: any = await this.Service.GetProfileDataService(GetManageAddOnDataEntityObject)
         
         const tempdata = res.data ? res.data[0] : [];
         this.setState({
            spin_loading: false,
            // profiledata: tempdata,
            BCountryValue: tempdata.Country,
            SCountryValue: tempdata.ShippingCountry,
            SStateValue: tempdata.ShippingState,
            BStateValue: tempdata.State,
            DisplayName: tempdata.display_name,
            FirstName: tempdata.first_name,
            LastName: tempdata.last_name,
            Email: tempdata.email,
            MobileNo: tempdata.mobile,
            CompanyName: tempdata.company_name,
            CompanyReg: tempdata.CompanyRegNo,
            TaxationNo: tempdata.TaxationNo,
            currencyvalue: tempdata.currency_code,
            BillingCity: tempdata.City,
            billingZipCode: tempdata.Zip,
            shippingCity: tempdata.ShippingCity,
            shipppingZipCode: tempdata.ShippingZipCode,
            billingAddress: tempdata.BillingAddress,
            shippingAddress: tempdata.ShippingAddress
         })
      }
   }
   render() {

      return (
         <div className=''>
            <div className='stikey'>
               <>
                  {
                     this.state.sticky_show ?
                        <StickyText
                           display={this.state.sticky_show}
                           sticky_type={this.state.sticky_type}
                           sticky_title={this.state.sticky_title}
                           sticky_message={this.state.sticky_message}
                           sendData={this.getDataFromSticky}
                           key={this.state.sticky_title}
                        />
                        :
                        <></>
                  }
               </>
            </div>
            <div className="">
               <div className={this.state.spin_loading ? "spin_class2" : "spin_class1"}>
                  <img src={skubiq_loader} alt="loading..." height={80} width={80} />
               </div>
               <div className="EditAccount" style={{ marginBottom: 60 }}>
                  <form >
                     <h3>Edit Profile</h3>
                     <Row gutter={16} className="inputRow">
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="DisplayName"
                                 maxLength={20}
                                 value={this.state.DisplayName}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                                 disabled={true}
                              />
                              <label><span className="required-label">*</span>Company Code</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="FirstName"
                                 maxLength={20}
                                 value={this.state.FirstName}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>First Name</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="LastName"
                                 maxLength={20}
                                 value={this.state.LastName}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>Last Name</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="Email"
                                 maxLength={20}
                                 value={this.state.Email}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>Email</label>
                           </div>
                        </Col>
                     </Row>
                     <Row gutter={16} className="inputRow">
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="MobileNo"
                                 maxLength={20}
                                 value={this.state.MobileNo}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>Mobile No</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="CompanyName"
                                 maxLength={20}
                                 value={this.state.CompanyName}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>Company Name</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="CompanyReg"
                                 maxLength={20}
                                 value={this.state.CompanyReg}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>Company Reg. No.</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="TaxationNo"
                                 maxLength={20}
                                 value={this.state.TaxationNo}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>Taxation No.</label>
                           </div>
                        </Col>
                     </Row>
                     <Row gutter={16} className="inputRow">
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="IPAddress"
                                 maxLength={20}
                                 // value={tempdata.TaxationNo}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label>IP Address</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="HostName"
                                 maxLength={20}
                                 // value={WarehouseDetails.WHName}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label>Host Name</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="">
                              <Autocomplete
                                 options={this.state.currencyDropdownData}
                                 onValueSelect={this.onSelectcurrency}
                                 defaultValue={this.state.currencyvalue}
                                 AutocompleteOnChange={this.currencyStateAutoComplete}
                                 aPICallRequired={true}
                                 PrefixMinLenght={0}
                                 key={this.state.BStateValue}
                                 label="Currency"
                              />
                           </div>
                        </Col>
                     </Row>

                     <Row gutter={16} className="inputRow">
                        <Col span={12}>
                           <span className='billing' >
                              Billing Address :
                           </span>
                        </Col>
                        <Col span={12}>
                           <span className='billing' style={{ paddingRight: "50px" }}>
                              Shipping  Address :
                           </span>
                           <span style={{ paddingRight: "50px" }}>
                              <input type="checkbox" onChange={(e) => this.addresscheck(e)} style={{ marginRight: "10px" }} />
                              same as billing address
                           </span>
                        </Col>
                     </Row>

                     <Row gutter={16} className="inputRow">
                        <Col span={6}>
                           <div className="divFlex">
                              <Autocomplete
                                 options={this.state.BillingCountryDropdownData}
                                 onValueSelect={this.onSelectBillingCountry}
                                 defaultValue={this.state.BCountryValue}
                                 AutocompleteOnChange={this.BillingCountryAutoComplete}
                                 aPICallRequired={true}
                                 PrefixMinLenght={0}
                                 key={this.state.BCountryValue}
                                 label="Country"
                              />
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <Autocomplete
                                 options={this.state.BillingStateDropdownData}
                                 onValueSelect={this.onSelectBillingState}
                                 defaultValue={this.state.BStateValue}
                                 AutocompleteOnChange={this.BillingStateAutoComplete}
                                 aPICallRequired={true}
                                 PrefixMinLenght={0}
                                 key={this.state.BStateValue}
                                 label="State"
                              />
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <Autocomplete
                                 options={this.state.ShippingCountryDropdownData}
                                 onValueSelect={this.onSelectShippingCountry}
                                 defaultValue={this.state.SCountryValue}
                                 AutocompleteOnChange={this.ShippingCountryAutoComplete}
                                 aPICallRequired={true}
                                 PrefixMinLenght={0}
                                 key={this.state.SCountryValue}
                                 label="Country"
                              />
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <Autocomplete
                                 options={this.state.ShippingStateDropdownData}
                                 onValueSelect={this.onSelectShippingState}
                                 defaultValue={this.state.SStateValue}
                                 AutocompleteOnChange={this.ShippingStateAutoComplete}
                                 aPICallRequired={true}
                                 PrefixMinLenght={0}
                                 key={this.state.SStateValue}
                                 label="State"
                              />
                           </div>
                        </Col>
                     </Row>

                     <Row gutter={16} className="inputRow">
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="BillingCity"
                                 maxLength={20}
                                 value={this.state.BillingCity}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>City</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="billingZipCode"
                                 maxLength={20}
                                 value={this.state.billingZipCode}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>Zip Code</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="shippingCity"
                                 maxLength={20}
                                 value={this.state.shippingCity}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>City</label>
                           </div>
                        </Col>
                        <Col span={6}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="shipppingZipCode"
                                 maxLength={20}
                                 value={this.state.shipppingZipCode}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>Zip Code</label>
                           </div>
                        </Col>
                     </Row>
                     <Row gutter={16} className="inputRow">
                        <Col span={12}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="billingAddress"
                                 maxLength={20}
                                 value={this.state.billingAddress}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>Address</label>
                              <span className="required-label"></span>
                           </div>
                        </Col>
                        <Col span={12}>
                           <div className="divFlex">
                              <input
                                 type="text"
                                 required
                                 name="shippingAddress"
                                 maxLength={20}
                                 value={this.state.shippingAddress}
                                 onChange={this.handleChange}
                                 autoComplete="off"
                              />
                              <label><span className="required-label">*</span>Address</label>
                              <span className="required-label"></span>
                           </div>
                        </Col>
                     </Row>
                  </form>
                  <div  className="inputRow">
                     
                     <span  onClick={() => this.submit()} className='btn-left1'>
                        Submit
                     </span>
                     <NavLink to="/MyProfile"><span className='btn-right1' style={{ float: "right" }}>Previous</span></NavLink>



                     {/* <NavLink to="/MyProfile"><span className='btn-left1'>Previous</span></NavLink>
                     <span className='btn-right1' style={{ float: "right" }} onClick={() => this.submit()}>
                        Submit
                     </span> */}
                  </div>
               </div >
            </div >
         </div >
      )
   }
}
