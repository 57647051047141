import React, { Component } from 'react';
import FooterPagesHeader from '../FooterPagesHeader';
import FooterPagesFooter from '../FooterPagesFooter';

export default class AntiSpam extends Component {
    componentDidMount=()=>{
		window.scrollTo(0, 0)
	}
    render() {
        return (
            <>
                <FooterPagesHeader text="Anti-Spam" />

                <section className="content-block">
                    <div className="container">
                        <article className='FooterArticle'>
                            <h3>Anti Spam Policy</h3>
                            <p>This policy is applicable to all Skubiq Services that may be used for transmitting bulk emails or commercial emails (“Covered Services”). You agree to Skubiq’s Anti-Spam Policy as a precondition to use of Covered Services. Covered Services may be used only for sending mails or messages to persons from whom you have an express permission to send mails or messages on the subject of such mail or message, such as permission-based email marketing. We are very serious about our Anti-Spam Policy. If we discover that you are sending emails or messages to people without their permission, we reserve the right to terminate your user account. By “permission” we mean express and provable permission granted to you. A permission to send commercial emails may be through</p>
                            <ul>
                                <li><p>Submission of email address as part of downloading anything from your website or ordering a product or service from you.</p></li>
                                <li><p>Subscription to an email newsletter by filling a form on your website.</p></li>
                                <li><p>Someone has provided you with the email address as part of participation in any contest, event or survey conducted by you and you have informed him/her that you would be sending them marketing emails.</p></li>
                                <li><p>Any business card given by a person who has expressed his/her willingness to receive emails of a commercial nature. Willingness to receive emails of a commercial nature will be presumed where the business card was dropped in your booth at a tradeshow.</p></li>
                            </ul>
                            <p>The rule of thumb is “Do not use Covered Services to send mails or messages to email addresses to which you do not have express permission to send emails on the subject of the email.” We insist on 100% compliance with the above rule. When we say 100%, we do not include those instances where the recipient of an email has marked it as Spam although you have an express provable permission to send emails to that email address. It should be borne in mind that a permission once granted is deemed to have been revoked if the recipient of a mail has opted out of receiving emails from you. In particular, you shall strictly comply with the following rules, which clarify the Rule of Thumb mentioned above</p>
                            <ul>
                                <li>
                                    <p>You should have explicit permission to contact the recipient on the subject of your email. You shall maintain sufficient proof of the fact that you have received permission from all recipients of emails sent by you through Covered Services.</p>
                                </li>
                                <li><p>You shall not import or send emails to email addresses that you have bought, loaned, rented or in any way acquired from a third party, irrespective of any claim about quality or permission, while using Covered Services. You shall also refrain from importing into your Covered Services account or sending mails to email addresses you have collected from other websites.</p></li>
                                <li><p>You shall ensure that the routing and header information including your emails “From” and “To,” the originating domain name and email address are true and accurate.</p></li>
                                <li><p>You shall not use subject lines that mislead the recipient about the contents or subject matter of the message.</p></li>
                                <li><p>You shall provide a one-click unsubscribe option in all emails and refrain from sending emails to persons who have opted out or un-subscribed from your mailing list. The request to opt-out from the mailing list should be honored within 10 days from the date of request.</p></li>
                                <li><p>You shall include your valid physical postal address in all emails sent through Covered Services.</p></li>
                                <li><p>You shall include a conspicuous notice in all marketing emails that the message is an advertisement or solicitation and that the recipient can opt out of receiving more commercial emails from you.</p></li>

                            </ul>
                            <p>We may, at any time, require you to prove that you have express permission to use the software from Skubiq. We may also need you prove that you have not misused the software from Skubiq. Your use of our Services signifies your unconditional acceptance of this Anti-Spam Policy. If you have any questions about our Anti-Spam Policy, or if you want to report spamming activity by one of our customers, Please contact our abuse department at:</p>
                        </article>
                        <article>
                            <h3>Postal Address</h3>
                            <address>
                                <p style={{ fontSize: "14px", fontStyle: "oblique" }}>
                                    Avya Inventrax Private Limited.<br />
                                    Sampath Vinyagur, D.No. 50-94-14/3, <br />
                                    1st & 2nd Floor,Santhipuram, <br />
                                    Visakhapatnam, Andhra Pradesh,<br />
                                    India- 530016.<br />
                                    Tel   : +91 891 252 6060<br />
                                    Fax   : +91 891 253 6060<br />
                                    Email : <a href="mailto:abuse@inventrax.com">abuse@inventrax.com</a>&nbsp;&nbsp;<a href="mailto:abuse@Skubiq.com">abuse@Skubiq.com</a>

                                </p>
                            </address>
                        </article>
                    </div>

                </section>
                {/* <!-- footer-area --> */}
                <FooterPagesFooter />
            </>
        )
    }
}
