import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Service } from '../Common/Services.service';
import StickyText from '../Common/StikeyText';
import "../loginpage/login.css";
import LoginCaptch from '../loginpage/LoginCaptch';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';

interface email {
  email: string
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
  spin_loading: boolean
}
export default class Forgotpassword extends Component<any, email>  {
  public columns: any = []
  child: any;
  private Service: Service;
  constructor(props: any) {
    super(props)
    this.Service = new Service();
    this.child = React.createRef();
    this.state = {
      email: "",
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
      spin_loading: false
    }
  }
  getDataFromSticky(show: boolean) {
    
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  handleChange = (e: any) => {
    
    this.setState({ ...this.state, [e.target.name]: e.target.value })
  };
  Forgotpassword = async () => {
    
    if (this.state.email === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter Registered Email');
      return false;
    }
    let val = this.child.current.capchavalidation()
    if (val === false) {
      this.stickyText(true, 'error', 'Error', 'Please enter valid captcha');
    }
    else {
      let forgotentityobject = {
        Email: this.state.email
      }
      this.setState({ spin_loading: true, sticky_show: false })
      const res: any = await this.Service.ForgetPasswordService(forgotentityobject)
      this.setState({ spin_loading: false })
      if (res.status === 200) {
        var GetData = res.data[0].n;
        if (GetData === 1) {
          this.stickyText(true, 'success', 'sucess', 'Mail successfully sent');
          setTimeout(() => {
            this.setState({ sticky_show: false })
          }, 1000)
          window.location.href = "/";
        }
        else if (GetData === 2) {
          this.stickyText(true, 'error', 'error', 'Enter Valid Email ID');
          setTimeout(() => {
            this.setState({ sticky_show: false })
          }, 3000)
        }
        else if (GetData === 3) {
          this.stickyText(true, 'error', 'error', 'Error while sending email');
          setTimeout(() => {
            this.setState({ sticky_show: false })
          }, 3000)
        }
        else if (GetData === "Invalid Email ID") {
          this.stickyText(true, 'error', 'error', 'Enter Valid Email ID');
          setTimeout(() => {
            this.setState({ sticky_show: false })
          }, 3000)
        }
      }
      else {
        this.stickyText(true, 'error', 'Error', 'Error while sending email');
        return false;
      }
    }
  }

  render() {
    return (
      <div className='loginpagebg'>
        <div className={this.state.spin_loading ? "spin_class3" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />

                :
                <></>
            }
          </>
        </div>
        <div className="container loginpage">
          <div className="login">
            <form action="#">
              <div className="title">Forgot password</div>
              <div className="" style={{ textAlign: "center" }}> We just need your Registered Email Id to sent your password reset instructions.</div><br />
              <div className="input-box">
                <div className="divFlex">
                  <input
                    type="text"
                    required
                    name="email"
                    // maxLength={20}
                    // value={WarehouseDetails.WHName}
                    onChange={this.handleChange}
                    autoComplete="off"
                  />
                  <label>Registered Email</label>
                  <span className="required-label"></span>
                </div>
              </div>
              {/* <div className="input-box underline">
                <input type="text" name='email' placeholder="Registered Email" onChange={this.handleChange} required />
                <div className="underline"></div>
              </div> */}
            </form><br/>
            <LoginCaptch ref={this.child} /><br/>
            <div>
              <div style={{ marginTop: "" }}>
                <NavLink to="/Login"><span className='btn-left'>Back to Login</span></NavLink>
                <span className='btn-right' style={{ float: "right" }} onClick={() => this.Forgotpassword()}>Send</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}