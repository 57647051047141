import React, { Suspense, useState } from 'react';
import './App.css';
import HomePage from "./Modules/HomePage/HomePage"
import Login from './Modules/loginpage/Login';
import CreateAccount from '../src/Modules/CreateAccount/CreateAccount';
import CreateTrailAccount from '../src/Modules/CreateTrialAccount/CreateTrialAccount';
// import Creataccountnext from '../src/Modules/createaccountnext/creataccountnext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ForgotPassword from './Modules/ForgotPassword/ForgotPassword';
import EditAccount from './Modules/EditAccount/EditAccount';
import TermsAndConditions from './Modules/Footer/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './Modules/Footer/PrivacyPolicy/PrivacyPolicy';
import TransactionPolicy from './Modules/Footer/TransactionPolicy/TransactionPolicy';
import AntiSpam from './Modules/Footer/AntiSpam/AntiSpam';
import IPR from './Modules/Footer/IPR/IPR';
import FAQ from './Modules/Footer/FAQ/FAQ';
import Support from './Modules/Footer/Support/Support';
import SelectPlan from './Modules/SelectPlan/SelectPlan';
import { reactLocalStorage } from 'reactjs-localstorage';
import Dashboard from '../src/Modules/Dashboard/Dashboard';
import { AesEncryption } from './Modules/Common/EncryptionDecryption';
import Successfully from './Modules/Successfully/Successfully';
import Resetpassword from './Modules/Resetpassword/Resetpassword';
import AccountDetails from './Modules/AccountDetails/AccountDetails';
import About from './Modules/Seo/About'
import Keyfeatures from './Modules/Seo/Keyfeatures'
import Pricing from './Modules/Seo/Pricing'
import Segments from './Modules/Seo/Segments'
import AdminDashboardRoute from './Modules/AdminDashboardRoute/AdminDashboardRoute';
export const Context = React.createContext<any>([]);

function App() {
  const [context, setContext] = useState(false);
  // var tokenData : any = sessionStorage.getItem("LandingSiteTokenInfo");
  // var LoginData : any = sessionStorage.getItem("LandingSiteLoginData");
  var tokenData: any = reactLocalStorage.get('LandingSiteTokenInfo');
  var LoginData: any = reactLocalStorage.get("LandingSiteLoginData");
  // debugger  
  var Email = LoginData ? AesEncryption.decrypt(JSON.parse(LoginData).username) : "";
  // debugger
  return (
    <Context.Provider value={[context, setContext]}>
      <div className="" style={{ width: "100%",height:'100%' }}>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            {
              tokenData !== "" && tokenData !== undefined && tokenData !== null ?
                Email === "admin@inventrax.com" || Email === "Admin@inventrax.com" ? <AdminDashboardRoute /> : <Dashboard />
                :
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/Keyfeatures" element={<Keyfeatures />} />
                  <Route path="/About" element={<About />} />
                  <Route path="/Pricing" element={<Pricing />} />
                  <Route path="/Segments" element={<Segments />} />
                  <Route path="/signup/:id1/:id2" element={<CreateAccount />} />
                  <Route path="/trial/:id1" element={<CreateTrailAccount />} />
                  <Route path="/Login" element={<Login />} />
                  <Route path="/Editaccount" element={<EditAccount />} />
                  <Route path="/ForgotPassword" element={<ForgotPassword />} />
                  <Route path="/SelectPlan" element={<SelectPlan />} />
                  {/* footer page*/}
                  <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
                  <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="/TransactionPolicy" element={<TransactionPolicy />} />
                  <Route path="/AntiSpam" element={<AntiSpam />} />
                  <Route path="/IPR" element={<IPR />} />
                  <Route path="/FAQ" element={<FAQ />} />                                                
                  <Route path="/Support" element={<Support />} />
                  <Route path="/Success" element={<Successfully />} />
                  <Route path="/Successfully" element={<Successfully />} />
                  <Route path="/Resetpassword" element={<Resetpassword />} />
                  <Route path="/AccountDetails" element={<AccountDetails />} />
                </Routes>
            }
          </Suspense>
        </Router>
      </div>
    </Context.Provider>
  );
}

export default App;
