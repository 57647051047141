import React, { Component } from 'react'
import FooterPagesHeader from '../FooterPagesHeader';
import FooterPagesFooter from '../FooterPagesFooter';

export default class Privacy extends Component {
    componentDidMount=()=>{
		window.scrollTo(0, 0)
	}
    render() {
        return (
            <>
                <FooterPagesHeader text="Privacy Statement" />

                <section className="content-block">
                    <div className="container">
                        <article className='FooterArticle'>
                            <h3>Purpose And Scope</h3>
                            <p>This SKUBIQ Privacy Statement (the "Privacy Statement") governs how www.skubiq.com represented by Avya Inventrax Pvt. Ltd. DBA : Inventrax, a Visakhapatnam based company, and companies that we own or control ("Affiliates") (collectively, "SKUBIQ", "we", or "us") collect, use, store, process, disclose, and delete Personal Data (as defined in this Privacy Statement) you provide directly to us or that we receive from your authorized third parties including, without limitation, that you provide to us via our website, located at the _________ domain, or any website of any one of our Affiliates that post or link to this Privacy Statement (individually and collectively, "Website"), accessed through any medium, including via computer, mobile device, or other device (collectively, "Device").</p>
                            <p>SKUBIQ provides you with the ability to purchase or access a variety of product and service offerings made available by SKUBIQ and various third party providers ("Offerings") both directly from SKUBIQ and through resellers and distributors. EACH OFFERING MAY BE SUBJECT TO OFFERING-SPECIFIC PRIVACY POLICIES, WHICH MAY SUPERCEDE THE TERMS OF THIS PRIVACY STATEMENT. SKUBIQ ENCOURAGES YOU TO CAREFULLY READ THE PRIVACY POLICY OF ANY OFFERING BEFORE ACCESSING OR USING THAT OFFERING.</p>
                            <p>Although we encourage you to read this entire Privacy Statement, this is a summary of some of the more important aspects of the Privacy Statement:</p>
                            <ul>
                                <li><p>Each Offering may include its own specific privacy policy. You should carefully read the privacy policy of any Offering before accessing or using that Offering.</p></li>
                                <li><p>We may collect both Personal Data and other information from you through a variety of sources, including Personal Data we have collected from you, whether on- or offline. We may also combine it with information we receive about you from other sources, such Affiliates, publicly available information sources (including information from your publicly available social media profiles), and other third parties.</p></li>
                                <li><p>We will not use or share your Personal Data except as permitted by this Privacy Statement.</p></li>
                                <li><p>Where applicable, We honor opt out instructions from you for certain uses of your Personal Data under this Privacy Statement, such as, for example, when you opt out of receiving marketing email communications from us.</p></li>
                                <li><p>We use commercially reasonable technical and organizational controls to secure and protect your Personal Data, but we cannot ensure or warrant that your Personal Data will be completely secure from misappropriation by hackers or from other nefarious or criminal activities.</p></li>
                            </ul>
                        </article>
                        <article>
                            <h3>General Principles For The Processing Of Personal Data</h3>
                            <p>Personal Data will be collected, stored, processed and transmitted in accordance with SKUBIQ’s established policies and applicable federal, state, local and foreign laws, rules and regulations.</p>
                            <p>The principles of SKUBIQ with respect to the processing of Personal Data are as follows:</p>
                            <ul>
                                <li><p>Personal Data will be processed fairly and lawfully.</p></li>
                                <li><p>Personal Data will be collected for specified, explicit, and legitimate purposes and not further processed for incompatible purposes</p></li>
                                <li><p>Personal Data collected by SKUBIQ will be adequate, relevant, and not excessive in relation to the purposes for which it is collected.</p></li>
                                <li><p>Personal Data collected by SKUBIQ will be accurate and, where necessary, kept up to date to the best of our ability</p></li>
                                <li><p>Personal Data will be protected against unauthorized access and processing using commercially reasonable technical and organizational security measures and controls. and </p></li>
                                <li><p>Personal Data collected by SKUBIQ will be retained as identifiable data for no longer than necessary to serve the purposes for which the Personal Data was collected.</p></li>
                                <li><p></p></li>
                            </ul>
                            <p>If SKUBIQ engages in the processing of Personal Data for purposes other than those specified in this Privacy Statement, SKUBIQ will provide notice of these changes, the purposes for which the Personal Data will be used, and the recipients of Personal Data.</p>
                        </article>
                        <article>
                            <h3>Information That SKUBIQ Collects</h3>
                            <p>SKUBIQ may collect different types of information from you when you access or use the Website:</p>
                            <p><b>"Personal Data"</b> refers to any information that directly identifies you or information otherwise defined as "personally identifiable" under applicable law. This includes, but is not limited to, your first and last name; billing or shipping address; credit card or payment information; government issued identification number; email address; phone number; purchase information and history; your geo-location information; your account number; resume information; and a combination of your username and password used to access the Website.</p>
                            <p><b>"Additional Usage Information"</b>, refers to any additional information that may not by itself reasonably identify you as the source. This includes, non-user specific information regarding your computer, your IP address, your Media Access Control (MAC) address, the type and version of Internet browser you are using, screen resolution, the location from which you access the Website, the type of computer operating system you are using (Windows or Mac OS), the domain name of the website from which you linked to the Website, or the areas of the Website you visited and actions performed on the Website. We may collect similar information, such as your device type and identifier, if you access the Website through a mobile device.</p>
                        </article>
                        <article>
                            <h3>Collection Of Information</h3>
                            <p>SKUBIQ may collect Personal Data and Additional Usage Information about you from a variety of sources, including, for example:</p>
                            <ul>
                                <li><p><u>Directly from You.</u> We may collect Personal Data directly from you when you voluntarily provide it to us. For example, when you communicate with us over the telephone or through the Website by way of email, online chat, web form, or online account registration to obtain access to Offerings, register for an event or training, purchase an Offering, ask questions, attempt to resolve any issues related to the Website or Offerings, submit a job application, or submit feedback and comments.</p></li>
                                <li><p><u>From Other Users.</u> We may receive Personal Data about you from other users of the Website when they provide information to us. For example, we may receive information when they communicate with you or with us through the Website.</p></li>
                                <li><p><u>From Resellers and Distributors</u>. We may obtain Personal Data about you from the resellers and distributors that purchase Offerings available from us and provide such Offerings to you.</p></li>
                                <li><p><u>From Our Vendors</u> We may obtain Personal Data about you from the third party vendors that make Offerings available through us.</p></li>
                                <li><p><u>From Our Service Providers</u>. We may obtain Personal Data about you from third party service providers that provide services to us. For example, when we rely on third party services to support our Website or facilitate emails or other messages sent through the Website, we may receive Personal Data about you from those third parties.</p></li>
                                <li><p><u>From Third Party Information Providers</u>. SKUBIQ may also obtain Personal Data from third parties that have obtained or collected information about you and that have the right to provide that Personal Data to us. For example, if you register for the Website using a third party service or through a third party website or service, the information you provide to that third party will be provided to us to establish your account on the Website. We may also contract with third parties that are in the business of providing information to businesses such as ours to verify certain information about you or obtain information about you. We may use the Personal Data about you we receive from a third party outside of the Website in accordance with the terms we establish with that third party. We may also obtain Personal Data from publicly-available sources such as open government databases or other data in the public domain.</p></li>
                                <li><p><u>From Our Affiliates and Business Units.</u> We may obtain Personal Data about you from our Affiliates regardless of whether these entities share the "SKUBIQ" brand. We may also obtain information about you from other business units that also offer products or services under the SKUBIQ brand or one of our other brands. We may use any information about you that we obtain from our Affiliates, or business units in accordance with the terms under which that information was collected by that Affiliate or business unit.</p></li>
                                <li><p><u>Through the Operation of the Website</u>. The systems we use to provide the Website may automatically log and record Additional Usage Information about your use of the Website. For example, our systems may record the information you enter on the Website, the areas of the Website you visit, your activities on the Website, your IP address, or information about the computer or software you are using to access the Website.</p></li>
                            </ul>
                        </article>
                        <article>
                            <h3>Cookies & Other Website Usage Information</h3>
                            <p>The Website may also monitor and collect Additional Usage Information about how you access, use, and interact with the Website automatically through "cookies," "flash cookies", "web beacons," and other automated tracking technology.</p>
                            <p>A "cookie" is a small text file that is stored on a user's Device. Cookies allow us to collect information such as browser type, time spent on the Website, pages visited, and language preferences. We and our service providers use the information for security purposes, to facilitate navigation, display information more effectively, and to personalize your experience while using the Website.</p>
                            <ul>
                                <li><p><u>Strictly necessary cookies</u>. These are cookies that are required for the operation of our Website. They include, for example, cookies that enable you to log into secure areas of our Website or use a shopping cart.</p></li>
                                <li><p><u>Analytical/performance cookies</u>. These cookies are used to analyze how the Website is used and to monitor its performance, which allows us to improve your experience in using the Website. These cookies help us to tailor the content of Website to reflect what users find most interesting and to identify when technical issues with the Website arises. We may also use this data to compile reports to help us to analyze how the Website is used, what the most common issues are and how we can improve the Website.</p></li>
                                <li><p><u>Functional/tracking cookies</u>. These cookies enable us to recognize repeat visitors to our Websites. By matching an anonymous, randomly generated identifier, a tracking cookie keeps track of where a user of our Websites came from, what search engine they may have used, what link they clicked on, what keyword they used and where they were in the world when they accessed the Website. By monitoring this data, we can make improvements to our Website.</p></li>
                                <li><p><u>Targeting cookies</u>. These cookies record your visit to our Website, the pages you have visited and the links you have followed. We will use this information to make our Website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</p></li>
                                <li><p><u>Session cookies.</u> These cookies are used 'in-session' each time you visit and then expire when you leave a Website or shortly thereafter: they are not stored on your Device permanently, do not contain any Personal Data and help by minimizing the need to transfer Personal Data across the internet. These cookies can be deleted or you can refuse to allow their use, but this will hamper the performance and your experience in using the Website. These cookies also take time stamps that record when you access the Website and when you leave the Website.</p></li>
                                <li><p><u>Persistent Cookies</u>. This type of cookie is saved on your Device for a fixed period (sometimes for a few hours, sometimes for a year or longer) and is not deleted when the browser is closed. Persistent cookies are used where we need to remember who you are for more than one browsing session. For example, this type of cookie can be used to store your language preferences, so that they are remembered for the next visit to the Website.</p></li>
                            </ul>
                            <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you would prefer not to accept cookies, most browsers will allow you to: (i) change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) to disable existing cookies; or (iii) to set your browser to automatically reject any cookies. However, please be aware that if you disable or reject cookies, some features and services on our Websites may not work properly because we may not be able to recognize and associate you with your account(s). In addition, the Offerings we provide when you visit us may not be as relevant to you or tailored to your interests.</p>
                            <p>A <b>"flash cookie"</b> is a file similar to a cookie, except that they can store more complex data. Our use of Adobe Flash technology (including Flash Local Stored Objects ("Flash LSOs")) allows us to, among other things, serve you with more tailored information, facilitate your ongoing access to and use of the Website, and collect and store information about your use of the Website. If you do not want Flash LSOs stored on your computer, you can adjust the settings of your Flash player to block Flash LSO storage using the tools contained in the Adobe Flash Website Storage Settings Panel available on the Internet. You can also control Flash LSOs by going to the Adobe Flash Global Storage Settings Panel available on the Internet and following the instructions (which may include instructions that explain, for example, how to delete existing Flash LSOs (referred to as "information" on the Adobe site), how to prevent Flash LSOs from being placed on your computer without your being asked, and (for Flash Player 8 and later) how to block Flash LSOs that are not being delivered by the operator of the page you are on at the time). Please note that setting the Flash Player to restrict or limit acceptance of Flash LSOs may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with this Website or our online content.</p>
                            <p>A <b>"web beacon"</b> (also known as a clear pixel or pixel tag) includes an electronic image imbedded in the Website interface that allows us to recognize when you visit that area of the Website. These may also be used in connection with some Website pages and HTML-formatted e-mail messages to, among other things, track the actions of Website users and e-mail recipients, measure the success of our marketing campaigns, and compile statistics about Website usage and response rates.</p>
                            <p>Please note that the use of cookies or these other tracking technologies by SKUBIQ’s third party service providers is not covered by this Privacy Statement. SKUBIQ does not have access or control over third-party cookies. SKUBIQ’s service providers may use persistent cookies for advertising and retargeting purposes. We may tie the information gathered from third party cookies to SKUBIQ’s users' Personal Data, and we will use the combined information in accordance with this Privacy Statement.</p>
                        </article>
                        <article>
                            <h3>Use Of Information</h3>
                            <p><u>Additional Usage Information</u>. SKUBIQ may use Additional Usage Information in any way that it determines appropriate, except where we are required to do otherwise under applicable law (for example, if we are required to treat such information as Personal Data). This may include, by way of example, providing Additional Usage Information to authorized third parties, developing products, services or other Offerings based on such information, or conducting research and analysis using such information. In addition, we may use and disclose information that is not in personally identifiable form (such as anonymized, masked or de-identified information) for any purpose. If we combine information that is not in personally identifiable form with information that is (such as combining your name with your geographical location), we will treat the combined information as Personal Data as long as it is combined.</p>
                            <p>Personal Data. SKUBIQ may use your Personal Data for a variety of purposes (the "Purposes"), including, but not limited to:</p>
                            <ul>
                                <li><p>Provide users with Offerings that have been requested or purchased.</p></li>
                                <li><p>Contact users directly (such as through email) or indirectly (such as through web based advertising) with marketing messages regarding Offerings offered by SKUBIQ and its Affiliates, contractors, and business partners.</p></li>
                                <li><p>Understand user preferences in order to enhance users' experience with SKUBIQ and its Affiliates, contractors, and business partners</p></li>
                                <li><p>Research the effectiveness of the Website and the marketing, advertising and sales efforts of SKUBIQ, its Affiliates, contractors, and business partners</p></li>
                                <li><p>Collect account receivables owed by users to SKUBIQ.</p></li>
                                <li><p>Process orders and payments made through the Website.</p></li>
                                <li><p>Develop additional Offerings</p></li>
                                <li><p>Conduct research and analysis</p></li>
                                <li><p>Process job applications</p></li>
                                <li><p>Comply with legal obligations; and</p></li>
                                <li><p>Manage our everyday business needs.</p></li>
                            </ul>
                            <p>We may combine and enhance the Personal Data we collect about you with other information we receive from third parties. We may also combine and enhance Personal Data using Additional Usage Information. In each case, we will treat the combined information as Personal Data and use it in accordance with this Privacy Statement.</p>
                            <p>We may also use the Personal Data we collect about you through the Website to generate Additional Usage Information. When we do so, we will take reasonable measures to ensure that the Additional Usage Information is no longer personally identifiable and may not later be easily used to identify you except in the case of fraud checks or as otherwise required by applicable law. This will include, by way of example, storing the Additional Usage Information separately from any Personal Data. Once we generate Additional Usage Information from any Personal Data, we will not attempt to again make that information into Personal Data (i.e., to make it personally identifiable). However, we may combine and enhance that Additional Usage Information with other Additional Usage Information (including information we obtain third parties) and use the Additional Usage Information in accordance with this Privacy Statement.</p>
                        </article>
                        <article>
                            <h3>Information Sharing</h3>
                            <p>We may disclose your Personal Data as follows:</p>
                            <p><u>Third Party Vendors.</u> SKUBIQ may share Personal Data about you with the third party vendors that make Offerings available through us. For example, we may share your Personal Data with a third party vendor if you purchase or access an Offering through us, or an SKUBIQ reseller or distributor.</p>
                            <p><u>Resellers and Distributors</u> SKUBIQ may share your Personal Data with the resellers and distributors that purchase Offerings available through the Website. For example, we may share your Personal Data if you purchase or access an Offering through a reseller or distributor.</p>
                            <p><u>Third Party Service Providers</u> SKUBIQ may provide your Personal Data to third parties that provide services to assist us with the Website and with achieving the Purposes. For example, these third parties may include providers of customer service, payment processing, email and messaging support, hosting, management, maintenance, information analysis, Offering fulfilment or delivery, or other services we may receive on an outsourced basis. We will endeavor to require that these third parties use your Personal Data only as necessary for such reasons and protect your Personal Data consistent with this Privacy Statement and applicable law.</p>
                            <p><u>Affiliates and Business Units</u>. SKUBIQ may share your Personal Data with our Affiliates, regardless of whether these entities share the SKUBIQ brand. We may also share your Personal Data with other business units that also offer products or services under the SKUBIQ brand or one of our other brands. Our Affiliates and business units will use your Personal Data we share with them in a manner consistent with this Privacy Statement.</p>
                            <p><u>Other Users.</u> SKUBIQ may share your Personal Data with other users when you elect to interact with those users (or request that we communicate with them on your behalf) through the Website. This may include facilitating communications with other users or enabling posting of Personal Data to areas of the Website accessible by other users. You should be aware that any Personal Data (or other information) you provide in these areas may be read, collected, and used by others who access them.</p>
                            <p><u>Law Enforcement and Safety</u>. SKUBIQ may share your Personal Data with law enforcement or other governmental officials if it relates to a criminal investigation, alleged illegal activity, or regulatory enforcement. SKUBIQ may share your Personal Data as required or permitted by law, whether in response to subpoenas, court orders, other legal processes, or as we believe necessary to exercise or enforce our rights, including to defend against potential or actual legal claims brought against us. SKUBIQ may disclose your Personal Data if we believe that such action is necessary to protect or defend the rights, property, interests, or safety of SKUBIQ, any of our users, resellers or vendors, or any other third parties or to act under urgent circumstances to protect the safety of SKUBIQ or its employees or a member of the public.</p>
                            <p><u>Sale or Acquisition of Assets</u>. If SKUBIQ transfers ownership or control of any portion of SKUBIQ or the Website to a third party, whether in the context of an acquisition, merger, reorganization, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings), we may transfer your Personal Data to that third party, provided that the use of your Personal Data by that third party remains subject to applicable law.</p>
                        </article>
                        <article>
                            <h3>Security Of Personal Data</h3>
                            <p>SKUBIQ uses commercially reasonable technical and organization controls and measures to secure and protect your Personal Data from unauthorized loss, misuse, and disclosure. Unfortunately, no data transmitted over or accessible through the Internet can be guaranteed to be 100% secure. As a result, while SKUBIQ attempts to protect all Personal Data, SKUBIQ cannot ensure or warrant that Personal Data will be completely secure from misappropriation by hackers or from other nefarious or criminal activities, or in the event of a failure of computer hardware, software, or a telecommunications network. SKUBIQ will notify you in the event we become aware of a security breach involving your personally identifiable information (as defined by the applicable foreign, federal, state, and local laws) stored by or for us. By disclosing your email address to us for any reason, you expressly consent to receive electronic notice from us in the event of such a security breach.</p>
                        </article>
                        <article>
                            <h3>Access To Personal Data</h3>
                            <p>If you contact SKUBIQ and request that SKUBIQ provide you with access to the Personal Data held about you or delete your Personal Data from SKUBIQ's system and records, subject to this Privacy Statement and applicable law, SKUBIQ will use commercially reasonable efforts to honor your request. Due to technical constraints and the fact that SKUBIQ backs up its systems, your Personal Data may continue to reside within SKUBIQ's systems after deletion for some period of time and you should not expect that your Personal Data will be completely removed from SKUBIQ's systems in response to any accepted request for deletion.</p>
                            <p>If your Personal Data changes, you may correct, update, or delete it through the Website or by contacting us as indicated in this Privacy Statement.</p>
                            <p>SKUBIQ reserves the right to decline requests to access or delete Personal Data if: (1) disclosure or deletion of the information requested is not required or permitted by applicable law; or (2) the request is, in the sole discretion of SKUBIQ, repetitious or vexatious.</p>
                            <p>If information will not or cannot be disclosed, the individual making the request will be provided with the reasons for non-disclosure.</p>
                            <p>To guard against fraudulent requests for access, SKUBIQ reserves the right to require sufficient information to allow it to confirm the identity of the party making the request before granting access or making corrections.</p>

                        </article>
                        <article>
                            <h3>Accuracy</h3>
                            <p>It is your responsibility to provide SKUBIQ with accurate Personal Data. Except as otherwise set forth in this Privacy Statement, SKUBIQ shall only use Personal Data in ways that are compatible with the purposes for which it was collected or subsequently authorized by you. To the extent necessary for these purposes, SKUBIQ shall take reasonable steps to ensure that Personal Data is accurate, complete, current and relevant to its intended use.</p>
                        </article>
                        <article>
                            <h3>Retention Period</h3>
                            <p>We retain your Personal Data for the period necessary to fulfill the purposes outlined in this Privacy Statement, unless a longer retention period is required or allowed by law or to otherwise fulfill a legal obligation.</p>

                        </article>
                        <article>
                            <h3>Additional Provisions</h3>
                            <p><u>Children</u>. The Website is intended solely for use by individuals 18 years of age and older and thus is not directed to children under 13 years of age. SKUBIQ does not knowingly solicit or collect Personal Data from children under 13 years of </p>
                            <p><u>Do Not Track</u>. Certain web browsers and other devices you may use to access the Website may permit you to indicate your preference that you do not wish to be "tracked" online. At this time, the Website does not respond to "Do Not Track" signals. We do not modify your experience on the Website, or modify the Personal Data we collect from you through the Website, based on whether such a preference is indicated</p>
                            <p><u>Your Indian Privacy Rights</u>. Indian law provides that Indian residents have a right to request that businesses tell them how their personal information has been shared with third parties for their direct marketing purposes. Under Indian law, "personal information" includes your name, signature, Social Security number, physical description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. There is an exception to this requirement for businesses that have adopted and disclosed, in their privacy policy, a policy of not disclosing a person's personal information to third parties for direct marketing purposes if that person has exercised an option to opt-out of the disclosure of their Personal Data to third parties for such purposes. We have adopted a policy of allowing you to opt-out of the disclosure of your personal information to third parties for direct marketing purposes and thus fall within this exception. To opt-out of the sharing of your personal information to third parties for direct marketing purposes, please contact us at: SKUBIQ  <a href="mailto:legaldept@skubiq.com">legaldept@skubiq.com</a></p>
                            <p><u>Your European Union Privacy Rights</u>. The controller for any Personal Data processed by us is the SKUBIQ Affiliate in the European Economic Area country or Switzerland collecting the Personal Data. If you have any questions about the Personal Data you have submitted to the respective Affiliate, please contact the relevant Affiliate. You have no direct access to the Persona Data stored in our servers. If you wish to modify or delete your Personal Data, or want to know what Personal Data is stored on your behalf, please contact us at: <a href="mailto:legaldept@Skubiq.com">legaldept@Skubiq.com</a>. We will make all reasonable efforts to reply within 15 working days. All Personal Data received and stored by SKUBIQ are erased after 5 years, save for any Personal Data in documents and files we have to keep for a longer period under applicable laws. For example, we cannot erase Personal Data appearing on VAT invoices before we can delete the invoices from our systems.</p>
                            <p><u>Compliance with Local Laws</u>. This Privacy Statement is meant to guide SKUBIQ with respect to Personal Data collected from or about you at this Website. While this Privacy Statement applies to Personal Data generally, the local laws, rules and regulations of jurisdictions that are applicable to SKUBIQ ("Local Laws") may require standards which are stricter than this Privacy Statement and, in such event, SKUBIQ will comply with applicable Local Laws. Specific privacy policies may be adopted to address the specific privacy requirements of particular jurisdictions.</p>
                            <p><u>Cross-Border Data Transfer</u>. Your Personal Data may be stored and processed in any country where we have facilities or service providers, and by using our Website or by providing consent to us (where required by law), you agree to the transfer of Personal Data to countries outside of your country of residence, including to the United States, which may provide for different data protection rules than in your country.</p>
                            <p><u>Cross-Border Data Transfers from the EU</u>. For Personal Data collected in the countries of the European Economic Area and Switzerland and transferred or accessed by SKUBIQ in any other jurisdiction, SKUBIQ uses approved Model Contractual Clauses for the international transfer of Personal Data. SKUBIQ has legal entities around the world in different jurisdictions, and each of these entities is responsible for the Personal Data it collects, controls, or processes under the laws of its respective country on behalf of SKUBIQ. By way of example, Personal Data collected from customers may reside on servers in the United States or other countries.</p>
                            <p><u>Sensitive Information</u>. Unless we specifically request or invite it, we ask that you not send us, and you not disclose, any sensitive personal data (e.g., information related to racial or ethnic origin, political opinions, religion or other beliefs, health, criminal background or trade union membership) on or through this Website or otherwise to us. In those cases where we may request or invite you to provide sensitive personal data, we will do so with your express consent.</p>
                            <p><u>Third Party Websites</u>. In addition to providing access to Offerings, the Website may include links to other websites and web services. This Privacy Statement does not apply to those websites and services and the privacy practices of those websites and services may differ from those described in this Privacy Statement. If you submit Personal Data to any of those other websites or services, your Personal Data is governed by the privacy policies applicable to those websites and services. SKUBIQ encourages you to carefully read the privacy policy of any website or web service you visit.</p>
                            <p><u>Compliance</u>. SKUBIQ will use a self-assessment approach to verify compliance with this Privacy Statement and periodically verify that the Privacy Statement is accurate, comprehensive for the information intended to be covered, prominently displayed, implemented and accessible.</p>
                            <p>If you believe that your Personal Data has been processed or disclosed in violation of this Privacy Statement, SKUBIQ encourages you to raise any concerns using the contact information provided in this Privacy Statement. SKUBIQ will investigate and attempt to resolve any complaints and disputes regarding use and disclosure of Personal Data.</p>
                            <p><u>Revisions.</u> SKUBIQ may from time to time revise this Privacy Statement in its sole and absolute discretion to reflect changes in our business practices. If we revise this Privacy Statement, we will notify you by posting the updated Privacy Statement on this Website. Changes to the Privacy Statement will become effective and will apply to the information collected starting on the date SKUBIQ posts the revised Privacy Statement on the Website. Your continued use of the Website after any change has become effective will constitute your acceptance of any change.</p>
                            <p><u>Questions.</u> If you have any questions or concerns about this Privacy Statement, the privacy practices of SKUBIQ, our collection or use of your Personal Data, or you wish to access your Personal Data, please contact us at: legaldept@skubiq.com or https://Skubiq/Contact-Us.aspx. If we need or are required to contact you concerning any issue or event that involves this Privacy Statement, your Personal Data, or the Website, then we may do so using the email address, telephone number, or physical mailing address we have on file for you.</p>
                        </article>
                    </div>
                </section>
                <FooterPagesFooter />
            </>
        )
    }
}