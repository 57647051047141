import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Basic from "../../Assests/img/basic-1.png";
import Standard from "../../Assests/img/standard-1.png";
import Premium from "../../Assests/img/premium-1.png";
import Doubalarrow from "../../Images/double_arrow.svg"
import FooterPagesFooter from '../Footer/FooterPagesFooter';
import Header from './header';
interface Price {
    basic: number;
    standard: number;
    platinum: number;
    Lite: number;
    currency: String;
    visible: boolean;
    title: string;
    CurrencyId:number;
}
export default class Pricing extends Component<any, Price> {
    constructor(props: any) {
        super(props);

        this.state = {
            visible: false,
            basic: 89,
            standard: 99,
            platinum: 129,
            Lite: 24,
            currency: "$",
            title: "",
            CurrencyId:2
        };
    }

    usd() {
        var element: any = document.getElementById("usd");
        var element1: any = document.getElementById("inr");
        var element2: any = document.getElementById("eur");
        element1.classList.remove("active-usd");
        element2.classList.remove("active-usd");
        element.classList.add("active-usd");
        this.setState({ standard: 109, basic: 99, platinum: 139, Lite: 49, currency: "$",CurrencyId:2 });
    }
    inr() {
        var element: any = document.getElementById("usd");
        var element1: any = document.getElementById("inr");
        var element2: any = document.getElementById("eur");
        element.classList.remove("active-usd");
        element1.classList.add("active-usd");
        element2.classList.remove("active-usd");
        this.setState({
            standard: 8499,
            basic: 7499,
            platinum: 10499,
            Lite: 2499,
            currency: "₹",CurrencyId:1
        });
    }
    eur() {
        var element: any = document.getElementById("usd");
        var element1: any = document.getElementById("inr");
        var element2: any = document.getElementById("eur");
        element.classList.remove("active-usd");
        element1.classList.remove("active-usd");
        element2.classList.add("active-usd");
        this.setState({ standard: 109, basic: 99, platinum: 139, Lite:49,currency: "€",CurrencyId:3 });
    }
    render() {
        return (
            <>
                <Header />
                <div className="container postionr">

                    <p className='bedc'>
                        <Link to="/">
                            <span className='home_bedcle'>Home  </span>

                        </Link> <img src={Doubalarrow} className="bed_arrow" alt="arrow" /> Pricing
                    </p>
                </div>

                <section
                    id="Pricing"
                    className="pricing-area gray-bg position-relative pt-100 pb-70" style={{ backgroundImage: "none" }}   >

                    <div className="container">
                        <div className="tab-content" id="myTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="web-hosting"
                                role="tabpanel"
                                aria-labelledby="web-hosting-tab"
                            >
                                <div className="row justify-content-center">
                                    <div className="col-xl-7 col-lg-9">
                                        <div className="section-title text-center mb-30">

                                            <h2 className="title Tital_pricing">Pricing</h2>
                                            <p>
                                                Start building today with our 14 day trial. No
                                                commitment. No credit card required.
                                            </p>
                                            <div>
                                            <span
                                                    className="inr"
                                                    id="inr"
                                                    onClick={() => this.inr()}
                                                >
                                                    INR
                                                </span>
                                                <span
                                                    className="usd active-usd"
                                                    onClick={() => this.usd()}
                                                    id="usd"
                                                >
                                                    USD
                                                </span>
                                               
                                                <span
                                                        className="eur"
                                                        id="eur"
                                                        onClick={() => this.eur()}
                                                    >
                                                        EUR
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pricing-box-wrap justify-content-center">
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="pricing-box pricing-responding mb-30">
                                            <div className="pricing-head">
                                                <h6 style={{ color: "#7CB9E8" }}>Lite</h6>
                                                <div
                                                    className="pricing-icon services-icon"
                                                    style={{ backgroundColor: "white" }}
                                                >
                                                    {/* <i className="flaticon-hosting"></i> */}
                                                    <img
                                                        src={Basic}
                                                        alt=""
                                                        style={{ width: "40px", height: "40px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="pricing-list price-basic mb-30">
                                                <h5>features</h5>
                                                <ul style={{ fontSize: "12px" }}>
                                                    <li style={{ fontSize: 12 }}>Starts with 2 Users</li>
                                                    <li style={{ fontSize: 12 }}>1 Warehouse</li>
                                                    <li style={{ fontSize: 12 }}>3 Hours On call Training - One time</li>
                                                    <li style={{ fontSize: 12 }}>15 Hours Help Desk Support - Per Annum</li>
                                                    <li style={{ fontSize: 12 }}>Shared Database</li>
                                                    <li style={{ fontSize: 12 }}>12,000 transactions - Per Annum </li>
                                                    <li style={{ fontSize: 12 }}>Handheld App. 2D/1D Barcode Scanning</li>
                                                </ul>
                                            </div>

                                            <div className="price price-basic mb-20">
                                                <h2>
                                                    {this.state.currency}
                                                    {this.state.Lite}
                                                    <span>per user/per month</span>
                                                </h2>
                                            </div>
                                            <div className="pricing-btn">
                                                <Link to={`/signup/SKU23-1W2U/${this.state.CurrencyId}`}>
                                                    <span className="btn Getstart">
                                                        Get start
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className='Trail_or'>Or</div>
                                            <Link to={`/trial/TP-1U1W`}>
                                                <span id="Trial_link">
                                                    Start Your Free Trial
                                                </span>
                                            </Link>

                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="pricing-box pricing-responding mb-30">
                                            <div className="pricing-head">
                                                <h6 style={{ color: "#3ab2bd" }}>Silver</h6>
                                                <div
                                                    className="pricing-icon services-icon"
                                                    style={{ backgroundColor: "white" }}
                                                >
                                                    {/* <i className="flaticon-hosting"></i> */}
                                                    <img
                                                        src={Basic}
                                                        alt=""
                                                        style={{ width: "40px", height: "40px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="pricing-list price-basic mb-30">
                                                <h5>features</h5>
                                                <ul  >
                                                    <li style={{ fontSize: 12 }}>Starts with 5 Users</li>
                                                    <li style={{ fontSize: 12 }}>1 Warehouse</li>
                                                    <li style={{ fontSize: 12 }}>3 Hours On call Training - One time</li>
                                                    <li style={{ fontSize: 12 }}>30 Hours Help Desk Support - Per Annum</li>
                                                    <li style={{ fontSize: 12 }}>Shared Database</li>
                                                    <li style={{ fontSize: 12 }}>24,000 transactions - Per Annum </li>
                                                    <li style={{ fontSize: 12 }}>Handheld App. 2D/1D Barcode Scanning</li>
                                                </ul>
                                            </div>
                                            <div className="price price-basic mb-20">
                                                <h2>
                                                    {this.state.currency}
                                                    {this.state.basic}
                                                    <span>per user/per month</span>
                                                </h2>
                                            </div>
                                            <div className="pricing-btn">
                                                <Link to={`/signup/SKU22-1W5U/${this.state.CurrencyId}`}>
                                                    <span className="btn Getstart">
                                                        Get start
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className='Trail_or'>Or</div>
                                            <Link to={`/trial/TP-1U1W`}>
                                                <span id="Trial_link">
                                                    Start Your Free Trial
                                                </span>
                                            </Link>

                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="pricing-box pricing-responding mb-30">
                                            <div className="pricing-head">
                                                <h6 style={{ color: "#d44fa4" }}>Gold</h6>
                                                <div
                                                    className="pricing-icon services-icon"
                                                    style={{ backgroundColor: "white" }}
                                                >
                                                    {/* <i className="flaticon-hosting"></i> */}
                                                    <img
                                                        src={Standard}
                                                        alt=""
                                                        style={{ width: "40px", height: "40px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="pricing-list price-standard mb-30">
                                                <h5>features</h5>
                                                <ul  >
                                                    <li style={{ fontSize: 12 }}>Starts with 10 Users</li>
                                                    <li style={{ fontSize: 12 }}>2 Warehouse</li>
                                                    <li style={{ fontSize: 12 }}>5 Hours On call Training - One time</li>
                                                    <li style={{ fontSize: 12 }}>45 Hours Help Desk Support - Per Annum</li>
                                                    <li style={{ fontSize: 12 }}>Shared Database</li>
                                                    <li style={{ fontSize: 12 }}>52,000 transactions - Per Annum </li>
                                                    <li style={{ fontSize: 12 }}>Handheld App. 2D/1D Barcode Scanning</li>
                                                    <li style={{ fontSize: 12 }}>3PL Billing Support</li>
                                                    <li style={{ fontSize: 12 }}>API Integration with ERP</li>
                                                </ul>
                                            </div>
                                            <div className="price price-standard mb-20">
                                                <h2>
                                                    {this.state.currency}
                                                    {this.state.standard}
                                                    <span>per user/per month</span>
                                                </h2>
                                            </div>
                                            <div className="pricing-btn">
                                                <Link to={`/signup/SKU22-2W10U/${this.state.CurrencyId}`}>
                                                    <span className="btn Getstart">
                                                        Get start
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className='Trail_or'>Or</div>
                                            <Link to={`/trial/TP-1U1W`}>
                                                <span id="Trial_link">
                                                    Start Your Free Trial
                                                </span>
                                            </Link>

                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="pricing-box pricing-responding mb-30">
                                            <div className="pricing-head">
                                                <h6>Platinum</h6>
                                                <div
                                                    className="pricing-icon services-icon"
                                                    style={{ backgroundColor: "white" }}
                                                >
                                                    {/* <i className="flaticon-hosting"></i> */}
                                                    <img
                                                        src={Premium}
                                                        alt=""
                                                        style={{ width: "40px", height: "40px" }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="pricing-list mb-30">
                                                <h5>features</h5>
                                                <ul  >
                                                    <li style={{ fontSize: 12 }}>Starts with 15 Users</li>
                                                    <li style={{ fontSize: 12 }}>3 Warehouse</li>
                                                    <li style={{ fontSize: 12 }}>10 Hours On call Training - One time</li>
                                                    <li style={{ fontSize: 12 }}>60 Hours Help Desk Support - Per Annum</li>
                                                    <li style={{ fontSize: 12 }}>Shared Database</li>
                                                    <li style={{ fontSize: 12 }}>1,20,000 transactions - Per Annum </li>
                                                    <li style={{ fontSize: 12 }}>Handheld App. 2D/1D Barcode Scanning</li>
                                                    <li style={{ fontSize: 12 }}>3PL Billing Support</li>
                                                    <li style={{ fontSize: 12 }}>API Integration with ERP</li>
                                                    <li style={{ fontSize: 12 }}>API Integration with MarketPlace*</li>
                                                </ul>
                                            </div>
                                            <div className="price mb-20">
                                                <h2>
                                                    {this.state.currency}
                                                    {this.state.platinum}
                                                    <span>per user/per month</span>
                                                </h2>
                                            </div>
                                            <div className="pricing-btn">
                                                <Link to={`/signup/SKU22-3W20U/${this.state.CurrencyId}`}>
                                                    <span className="btn Getstart">
                                                        Get start
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className='Trail_or'>Or</div>
                                            <Link to={`/trial/TP-1U1W`}>
                                                <span id="Trial_link">
                                                    Start Your Free Trial
                                                </span>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterPagesFooter />
            </>
        )
    }
}

