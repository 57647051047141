import React, { Component } from 'react'
import Faq from "../../Assests/img/faq_img.webp";
import DownArrrow from "../../Assests/img/arrow.svg";
import "../HomePage/HomePage.css";
import "../HomePage/responsivelandingsite.css";
import "../HomePage/stylelandingsite.css";
import "../HomePage/mybootstrap.css";
import FooterPagesFooter from '../Footer/FooterPagesFooter';
import Header from './header';
import Automobile from "../../Assests/img/automobile.png";
import Pharma from "../../Assests/img/pharma.png";
import Consumer from "../../Assests/img/Consumer.png";
import Retail from "../../Assests/img/retail.png";
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import Doubalarrow from "../../Images/double_arrow.svg"
interface Price {
    basic: number;
    standard: number;
    platinum: number;
    currency: String;
    visible: boolean;
    title: string;
    props: string
}
export default class About extends Component<any, Price> {
    constructor(props: any) {
        super(props);

        this.state = {
            visible: false,
            basic: 89,
            standard: 99,
            platinum: 129,
            currency: "$",
            title: "",
            props: "About"
        };
    }
    setVisible = () => {
        this.setState({ visible: false });
    };
    modelvisible = (title: any) => {
        
        if (title === "Distribution") {
            this.setState({ visible: true, title: "Distribution Industry" });
        } else if (title === "Manufacturing") {
            this.setState({ visible: true, title: "Manufacturing" });
        } else if (title === "3pl") {
            this.setState({ visible: true, title: "3PL" });
        } else if (title === "Surgical") {
            this.setState({ visible: true, title: "Pharma & Surgical" });
        } else if (title === "Automobile") {
            this.setState({ visible: true, title: "Automobile" });
        } else if (title === "Fast-moving") {
            this.setState({ visible: true, title: "Fast-moving Consumer Goods" });
        } else if (title === "Fashion") {
            this.setState({ visible: true, title: "Fashion and Retail" });
        }
    };

    render() {


        return (
            <>
                <Header />
                <div className="container">
                    <p className='bedc'>
                        <Link to="/">
                            <span className='home_bedcle'>Home</span>

                        </Link><img src={Doubalarrow} className="bed_arrow" alt="arrow" />  About
                    </p>
                </div>
                <section
                    id="About"
                    className="cloud-hosting-area  pb-100"
                >
                    <div className="container container-about">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-title text-center mb-70">

                                    <h2 className="title">About</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="faq-img text-center">
                                    <img src={Faq} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="cloud-hosting-list">
                                    <ul>
                                        <li>
                                            <div className="cloud-hosting-content">
                                                <p>
                                                    SKUBIQ is an endeavour to provide a single point
                                                    operating platform for all the warehouse management
                                                    needs, earned through months of research &
                                                    development, and years of listening to our customer
                                                    feedback and prospect requirements.
                                                </p>
                                                <br />
                                                <p>
                                                    SKUBIQ caters SMB’s and eCommerce business through a
                                                    wide array of integrations with sales and delivery
                                                    channel applications to seamlessly serve their
                                                    customers. And all this at a low total cost of
                                                    ownership.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="cloud-hosting-content">
                                                <h4>Value Proposition:</h4>
                                                <ol>
                                                    <li className="ab-li">
                                                        <img
                                                            src={DownArrrow}
                                                            className="rightarrow-o"
                                                            style={{}}
                                                            alt=""
                                                        />
                                                        Using this platform, you can consistently lower the
                                                        operations cost with high visibility of operational
                                                        data for reactive/proactive decision making.
                                                    </li>
                                                    <li className="ab-li">
                                                        <img
                                                            src={DownArrrow}
                                                            className="rightarrow-o"
                                                            style={{}}
                                                            alt=""
                                                        />
                                                        You can improve your end customer experience with
                                                        high inventory availability, faster fulfillment time
                                                        and right product delivery at right time.
                                                    </li>
                                                </ol>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="exclusive-service es-bg">
                    <div className="container">
                        {/* <!-- <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
                <div className="section-title white-title text-center mb-70">
                    <span className="sub-title">ALWAYS IN THE RIGHT</span>
                    <h2 className="title">Marketing For Everyone</h2>
                </div>
            </div>
        </div> --> */}
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-md-6 col-sm-9">
                                <div className="services-item exclusive-services-item services-responsive mb-30">
                                    <div className="services-icon">
                                        <img src={Pharma} alt="" />
                                    </div>
                                    <div className="services-content mb_30">
                                        <h4>
                                            <a href="# ">Pharma & Surgical</a>
                                        </h4>
                                        <p>
                                            The Pharma industry faces some very unique challenges
                                            covers a lot of territory...{" "}
                                            <span style={{ display: "none" }}>
                                                {" "}
                                                No matter what distribution model you follow,
                                                customers, and product catalog pose a unique set of
                                                problems for your supply chain and hence we have
                                                teamed up with a lot of Pharma companies in developing
                                                a unique solution called SKUBIQ to specifically
                                                address the issues of this industry.:
                                            </span>
                                        </p>
                                        <span
                                            className="know-more"
                                            onClick={() => this.modelvisible("Surgical")}
                                        >
                                            Read More...
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-9">
                                <div className="services-item exclusive-services-item services-responsive mb-30">
                                    <div className="services-icon">
                                        <img src={Automobile} alt="" />
                                    </div>
                                    <div className="services-content mb_30">
                                        <h4>
                                            <a href="# ">Automobile</a>
                                        </h4>
                                        <p>
                                            In the highly regulated industry of Automative
                                            manufacturing, attention...{" "}
                                            <span style={{ display: "none" }}>
                                                {" "}
                                                to detail is critical to help ensure stringent
                                                requirements for product quality and deadlines to be
                                                met. Ones ability to achieve a lean environment in
                                                which one would have visibility to and control over
                                                these details is where competitive advantage often
                                                resides. SKUBIQ has been designed in partnership with
                                                many automative
                                            </span>
                                        </p>
                                        <span
                                            className="know-more"
                                            onClick={() => this.modelvisible("Automobile")}
                                        >
                                            Read More...
                                        </span>
                                        {/* <a href="# " className="read-more">Read More...</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-9">
                                <div className="services-item exclusive-services-item services-responsive mb-30">
                                    <div className="services-icon">
                                        <img src={Consumer} alt="" />
                                    </div>
                                    <div className="services-content">
                                        <h4>
                                            <a href="# ">Fast-moving Consumer Goods</a>
                                        </h4>
                                        <p>
                                            Omni-Channel and internet retail growth is putting the
                                            spotlight on warehouse...{" "}
                                            <span style={{ display: "none" }}>
                                                {" "}performance in the consumer packaged goods industry as
                                                well. Large distribution center replenishment orders,
                                                direct to store and direct to consumer shipment
                                                coordination from charge-back happy retailers
                                                consumers are creating new pressures.
                                            </span>
                                        </p>
                                        <span
                                            className="know-more"
                                            onClick={() => this.modelvisible("Fast-moving")}
                                        >
                                            Read More...
                                        </span>
                                        {/* <a href="# " className="read-more">Read More...</a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-9">
                                <div className="services-item exclusive-services-item services-responsive mb-30">
                                    <div className="services-icon">
                                        <img src={Retail} alt="" />
                                    </div>
                                    <div className="services-content mb_30">
                                        <h4>
                                            <a href="# ">Fashion and Retail</a>
                                        </h4>
                                        <p>
                                            The era when the majors effectively ruled this industry
                                            is the bygone. The fashion...{" "}
                                            <span style={{ display: "none" }}>
                                                and retail industry is no longer dominated by a select
                                                group of large retailers with hundreds of
                                                brick-and-mortar stores. People have taken to online
                                                shopping as duck takes to water and has become
                                                extremely popular even as a number
                                            </span>
                                        </p>
                                        <span
                                            className="know-more"
                                            onClick={() => this.modelvisible("Fashion")}
                                        >
                                            Read More...
                                        </span>
                                        {/* <a href="# " className="read-more">Read More...</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="exclusive-services-shape">
                        <img src="img/images/services_circle_shape.png" alt="" />
                    </div>
                </section>
                <>
                    <Modal
                        className="footerhomepage"
                        title={this.state.title}
                        centered
                        visible={this.state.visible}
                        onCancel={() => this.setVisible()}
                        footer={[]}
                        width={800}
                    >
                        {this.state.title === "Distribution Industry" ? (
                            <div>
                                <p>
                                    Distributors normally lack precise and seamless traceability,
                                    lot control, and recall management capabilities jeopardizing
                                    the inventory thereby putting their businesses at risk of
                                    compliance failures and legal liabilities. In addition, they
                                    are constantly challenged by the emerging consumer demands for
                                    omni-channel commerce, specialized products, and more
                                    convenient delivery options
                                </p>
                                <p>
                                    SKUBIQ is trusted worldwide for supply chain management and
                                    visibility. But Why? Simply because our uniquely adaptable
                                    software solutions help companies like you stay on top of this
                                    fast-changing market.
                                </p>
                                <p>
                                    SKUBIQ helps address the complete process of fulfilling
                                    complex, multi-temperature home delivery orders. SKUBIQ has
                                    the inbuilt flexibility in helping emerging online retailers
                                    and distributors a wide range of specialty products through
                                    traditional eCommerce fulfillment models.
                                </p>
                                <p>
                                    As one of the market leaders in warehouse management (WMS) for
                                    cold-storage, third-party-logistics companies that play a
                                    critical role distribution, we bridge inventory and
                                    distribution between some of the world’s largest producers and
                                    their customers.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Manufacturing" ? (
                            <div>
                                <p className="text-justify text-muted1 mb-2">
                                    The manufacturing is a highly regulated industry globally
                                    where attention to detail is critical to help ensure stringent
                                    requirements for product quality and deadlines are met. Its
                                    become imperative for organizations to achieve a lean
                                    environment in which they have visibility to and control over
                                    these details is where competitive advantage often resides.
                                    SKUBIQ partners with major manufacturing companies globally to
                                    improve efficiency and reduce costs, such as:
                                </p>
                                <ol className="pllist">
                                    <li>
                                        Support of lean manufacturing initiatives by including
                                        inventory management capabilities within manufacturing
                                    </li>
                                    <li>Initiating supplier re-orders based on demand signals</li>
                                    <li>
                                        Compliance with industry requirements for quality, product
                                        tracking, safety, and recall management
                                    </li>
                                    <li>Integrated RFID, including asset tracking</li>
                                    <li>Quality assurance and inspection</li>
                                    <li>Managing a large product catalog / SKU proliferation</li>
                                    <li>Addressing Customer OEM parts packaging</li>
                                    <li>Core stratification and remanufacturing</li>
                                    <li>
                                        Wave planning and small order pick optimization to reduce
                                        picker travel
                                    </li>
                                    <li>Serialized inventory tracking</li>
                                    <li>EDI / ASN integration</li>
                                </ol>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "3PL" ? (
                            <div>
                                <p>
                                    The SKUBIQ is a cloud based Warehouse management system and is
                                    designed for third-party logistics companies to manage
                                    multiple customers, processes and billing schedules. The
                                    system enables access to real-time information and provides
                                    integrations with warehouse management technologies, including
                                    EDI, barcode scanning, and e-commerce shopping carts. The
                                    software scalability helps companies to manage different stock
                                    levels in warehouses, streamline business, and satisfy
                                    customers.
                                </p>
                                <p>
                                    SKUBIQ is designed to help logistics companies automate
                                    processes and bill items accurately. The software provides
                                    features which allow the user to easily add and remove
                                    customers and products. The software is designed to help
                                    logistics providers satisfy customers’ need for updated
                                    information and increase profits through process automation.
                                </p>
                                <p>
                                    The SKUBIQ can be integrated with any line of business
                                    application or ERP thereby allowing users to synchronize
                                    items, inventory, purchase orders, and receipts.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Pharma & Surgical" ? (
                            <div>
                                <p>
                                    The SKUBIQ is a cloud based Warehouse management system and is
                                    designed for third-party logistics companies to manage
                                    multiple customers, processes and billing schedules. The
                                    system enables access to real-time information and provides
                                    integrations with warehouse management technologies, including
                                    EDI, barcode scanning, and e-commerce shopping carts. The
                                    software scalability helps companies to manage different stock
                                    levels in warehouses, streamline business, and satisfy
                                    customers.
                                </p>
                                <p>
                                    SKUBIQ is designed to help logistics companies automate
                                    processes and bill items accurately. The software provides
                                    features which allow the user to easily add and remove
                                    customers and products. The software is designed to help
                                    logistics providers satisfy customers’ need for updated
                                    information and increase profits through process automation.
                                </p>
                                <p>
                                    The SKUBIQ can be integrated with any line of business
                                    application or ERP thereby allowing users to synchronize
                                    items, inventory, purchase orders, and receipts.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Automobile" ? (
                            <div>
                                <p>
                                    Distributors normally lack precise and seamless traceability,
                                    lot control, and recall management capabilities jeopardizing
                                    the inventory thereby putting their businesses at risk of
                                    compliance failures and legal liabilities. In addition, they
                                    are constantly challenged by the emerging consumer demands for
                                    omni-channel commerce, specialized products, and more
                                    convenient delivery options
                                </p>
                                <p>
                                    SKUBIQ is trusted worldwide for supply chain management and
                                    visibility. But Why? Simply because our uniquely adaptable
                                    software solutions help companies like you stay on top of this
                                    fast-changing market.
                                </p>
                                <p>
                                    SKUBIQ helps address the complete process of fulfilling
                                    complex, multi-temperature home delivery orders. SKUBIQ has
                                    the inbuilt flexibility in helping emerging online retailers
                                    and distributors a wide range of specialty products through
                                    traditional eCommerce fulfillment models.
                                </p>
                                <p>
                                    As one of the market leaders in warehouse management (WMS) for
                                    cold-storage, third-party-logistics companies that play a
                                    critical role distribution, we bridge inventory and
                                    distribution between some of the world’s largest producers and
                                    their customers.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Fast-moving Consumer Goods" ? (
                            <div>
                                <p>
                                    The manufacturing is a highly regulated industry globally
                                    where attention to detail is critical to help ensure stringent
                                    requirements for product quality and deadlines are met. Its
                                    become imperative for organizations to achieve a lean
                                    environment in which they have visibility to and control over
                                    these details is where competitive advantage often resides.
                                    SKUBIQ partners with major manufacturing companies globally to
                                    improve efficiency and reduce costs, such as:
                                </p>
                                <ol className="pllist">
                                    <li>
                                        Support of lean manufacturing initiatives by including
                                        inventory management capabilities within manufacturing
                                    </li>
                                    <li>Initiating supplier re-orders based on demand signals</li>
                                    <li>
                                        Compliance with industry requirements for quality, product
                                        tracking, safety, and recall management
                                    </li>
                                    <li>Integrated RFID, including asset tracking</li>
                                    <li>Quality assurance and inspection</li>
                                    <li>Managing a large product catalog / SKU proliferation</li>
                                    <li>Addressing Customer OEM parts packaging</li>
                                    <li>Core stratification and remanufacturing</li>
                                    <li>
                                        Wave planning and small order pick optimization to reduce
                                        picker travel
                                    </li>
                                    <li>Serialized inventory tracking</li>
                                    <li>EDI / ASN integration</li>
                                </ol>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Fashion and Retail" ? (
                            <div>
                                <p>
                                    The SKUBIQ is a cloud based Warehouse management system and is
                                    designed for third-party logistics companies to manage
                                    multiple customers, processes and billing schedules. The
                                    system enables access to real-time information and provides
                                    integrations with warehouse management technologies, including
                                    EDI, barcode scanning, and e-commerce shopping carts. The
                                    software scalability helps companies to manage different stock
                                    levels in warehouses, streamline business, and satisfy
                                    customers.
                                </p>
                                <p>
                                    SKUBIQ is designed to help logistics companies automate
                                    processes and bill items accurately. The software provides
                                    features which allow the user to easily add and remove
                                    customers and products. The software is designed to help
                                    logistics providers satisfy customers’ need for updated
                                    information and increase profits through process automation.
                                </p>
                                <p>
                                    The SKUBIQ can be integrated with any line of business
                                    application or ERP thereby allowing users to synchronize
                                    items, inventory, purchase orders, and receipts.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                    </Modal>
                </>
                <FooterPagesFooter />
            </>
        )
    }
}
