import React, { Component } from "react";
import FooterPagesHeader from "../FooterPagesHeader";
import FooterPagesFooter from "../FooterPagesFooter";

export default class TransactionPolicy extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <>
        <FooterPagesHeader text="Transaction" />
        <section className="content-block">
          <div className="container">
            <article className="FooterArticle">
              <h3>Handling Returns And Refunds </h3>
              <p>
                On cancellation of an order SKUBIQ does allow you to claim the
                payment you have successfully made to SKUBIQ. If you would like
                a refund of a payment that you made, we ask that you first write
                to us on  <a href="mailto:refund@skubiq.com"> refund@skubiq.com </a>, or
                fill in the form at the contact us page on the site to explain
                the situation and to make refunds for digital purchase, in
                accordance with our return policies.
              </p>
              <p>
                To contact the us you may either contact through the site, mail
                or call us on the toll free number provided on the site.
              </p>
              <ul>
                <li>
                  <p>Go to the pay link on the web site.</p>
                </li>
                <li>
                  <p>
                    Click the My Account link at the top of the website, and
                    sign in when prompted.
                  </p>
                </li>
                <li>
                  <p>
                    In the Overview page, click on the details link of a
                    transaction for which you wish to contact us.
                  </p>
                </li>
                <li>
                  <p>
                    On the transaction detail page, select merchant e-mail
                    mentioned and send an e-mail.
                  </p>
                </li>
              </ul>
              <p>
                We want to make sure that your order works out. In case someone
                does not follow up with you and you do not receive a reply from
                the within 3 business days, or if you are not able to come to an
                agreement with the customer care personnel regarding a return or
                refund, please contact our Manager Customer care.
              </p>
              <p>
                When we receive a report from you, we'll investigate the status
                of your transaction and take any actions available to us to
                resolve the problem.
              </p>
            </article>
            <article>
              <h3>Troubleshooting Failed Payments</h3>
              <ul>
                <li>
                  <p>
                    To protect your security and privacy, your bank does not
                    provide us with information about why your payment was
                    declined. For this, you will need to contact your bank
                    directly to solve your payment issues.
                  </p>
                </li>
                <li>
                  <p>
                    Skubiq Customer Service can help you verify that you entered
                    your payment information correctly on your order or not.
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>
                Contact Your Bank To Resolve All Other Payment Issues Even If:
              </h3>
              <ul>
                <li>
                  <p>
                    You have successfully used the payment method on a previous
                    order.
                  </p>
                </li>
                <li>
                  <p>
                    Part of your order has already been charged and shipped
                    successfully.
                  </p>
                </li>
                <li>
                  <p>
                    You have funds available in your bank account or on your
                    credit card to cover the order cost.
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>Steps That You Can Take To Resolve Payment Issues</h3>
              <ul>
                <li>
                  <p>
                    <b>Contact your bank about payment security policies</b>
                    . Your bank may flag any unexpected activity on your
                    account. This includes first-time orders and high-value
                    purchases, regardless of the amount of funds available or
                    your credit limit. Your bank may require your authorization
                    to proceed with a transaction.
                  </p>
                </li>
                <li>
                  <p>
                    <b>
                      Contact your bank about daily withdrawal or purchase
                      limits
                    </b>
                    . Most banks have limits on how much money can be charged or
                    accessed in a single day. If you exceed this daily amount,
                    your bank may block your account from any further activity
                    regardless of available funds in the account. Please check
                    with your bank and request a higher purchase limit to
                    complete the transaction.
                  </p>
                </li>
                <li>
                  <p>
                    <b>
                      Verify the payment information that is entered on your
                      transaction.
                    </b>
                    Make sure you have entered the correct CVV or 3D secure
                    password while completing the payment.
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>Transaction Disputes</h3>
              <p>
                SKUBIQ wants every transaction to be as smooth as possible, but
                in rare cases, disputes might occur. Because an SKUBIQ pay
                transaction is a separate agreement between the buyer and us, we
                suggest that buyers contact us directly to resolve the issue.
              </p>
            </article>
            <article>
              <h3>Contacting A SKUBIQ To Resolve An Issue</h3>
              <p>Refer Handling Returns and Refunds.</p>
              <p>
                You can help resolve your problem quickly by providing the
                following information in your initial communication:
              </p>
              <ul>
                <li>
                  <p>
                    Order details, such as order reference provided by the
                    merchant and the list of items in the order.
                  </p>
                </li>
                <li>
                  <p>The cost of the order</p>
                </li>
                <li>
                  <p>Transaction details</p>
                </li>
                <li>
                  <p>Nature of the problem(s) with the order</p>
                </li>
                <li>
                  <p>Your preferred resolution for the problem</p>
                </li>
              </ul>
              <p>
                You will be prompted to enter information about the transaction
                on the page that opens. If the issue cannot be resolved, you can
                dispute the transaction by contacting our Customer Service .
              </p>
              <p>
                There are several reasons that a transaction might be disputed:
              </p>
              <ul>
                <li>
                  <p>An error in the SKUBIQ Pay transaction</p>
                </li>
                <li>
                  <p>
                    The amount charged to credit card or bank account is
                    different from the amount displayed on the SKUBIQ Pay screen
                    or confirmation e-mail
                  </p>
                </li>
                <li>
                  <p>The product/ service paid for was not received</p>
                </li>
                <li>
                  <p>The product has not matched your requirements.</p>
                </li>
              </ul>
              <p>
                If a transaction in your account meets any of the above
                criteria, you may be able to submit a dispute for the
                transaction. See A to Z Guarantee for additional details.
              </p>
            </article>
            <article>
              <h3>Disputing A Transaction</h3>
              <p>
                To dispute a transaction (when the issue does not relate to the
                SKUBIQ Pay service directly, but relates to the services
                provided), please contact us with following details:
              </p>
              <ul>
                <li>
                  <p>Date of transaction</p>
                </li>
                <li>
                  <p>Amount of transaction</p>
                </li>
                <li>
                  <p>Transaction ID</p>
                </li>
                <li>
                  <p>Description of the problem</p>
                </li>
              </ul>
              <p>
                Once you contact us, your dispute will be sent to our
                investigations team for review, under the A to z Guarantee
                claim. Although we will try to complete our investigation as
                soon as possible, it can take up to 10 business days before we
                reach a conclusion. During this time, we may contact you to
                obtain additional information about the situation.
              </p>
            </article>
            <article>
              <h3>Unauthorised Charges</h3>
              <p>
                If you see an SKUBIQ pay charge on your bank or credit card
                statement and you are not sure where the charge came from, we
                suggest you log on to your SKUBIQ Pay account to search for a
                transaction that matches the charge. Details about the
                application, merchant, or the specific good you purchased will
                appear on the transaction details screen.
              </p>
              <ul>
                <li>
                  <p>
                    If you still cannot figure out where the charge originated,
                    contact your bank for further investigation. If you require
                    any help, you can contact us
                  </p>
                </li>
                <li>
                  <p>
                    If there are any unauthorized or erroneous transactions or
                    activities involving your SKUBIQ Pay account and any
                    balances that you hold or bank accounts that are registered
                    with SKUBIQ Pay, please see Acceptable Use Policy for more
                    information. The Policy details additional requirements and
                    certain limits on our liability for unauthorized
                    transactions. As part of the dispute resolution process, we
                    will contact both parties in the transaction. If we
                    determine you are not responsible for the charges, you will
                    be reimbursed. Also, under certain circumstances we may
                    grant you a provisional credit for the disputed amount.
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>SKUBIQ Pay A-To-Z Guarantee For Customers</h3>
              <p>
                The following policy applies to the purchases made on
                third-party sites using SKUBIQ Pay.
              </p>
              <p>
                Over the years, SKUBIQ has built a base of satisfied buyers by
                being responsive to their concerns and acting quickly to resolve
                these concerns. We extend this valuable buyer trust to
                organizations like you who are using SKUBIQ Pay services,
                through the SKUBIQ A-to-z Guarantee Program. The SKUBIQ A-to-z
                Guarantee provides buyers with an opportunity to obtain a full
                reimbursement for their purchase using SKUBIQ Pay.
              </p>
              <p>
                We encourage you to answer all buyer inquiries within 24 hours
                of receipt. Good communication with buyers promotes good
                feedback.
              </p>
              <p>
                The vast majority of buyers will never need this program, but
                for those who do, the guarantee gives us a chance to provide
                confidence and trust when they shop from the universe of
                merchants who use our service.
              </p>
            </article>
            <article>
              <h3>
                When Can A Buyer File An A-To-Z Guarantee Claim For An SKUBIQ
                Pay Transaction?
              </h3>
              <p>
                Buyers can file an A-to-z Guarantee claim when one of the
                following conditions applies:
              </p>
              <ul>
                <li>
                  <p>They did not receive the Product/service.</p>
                </li>
                <li>
                  <p>
                    They received the Product/service later than the maximum
                    estimated delivery date provided by you.
                  </p>
                </li>
                <li>
                  <p>
                    They received the Product/service but was not the item
                    depicted in your description.
                  </p>
                </li>
                <li>
                  <p>
                    They returned the item as per their agreement with you but
                    you did not provide the agreed-upon reimbursement after
                    receiving the item.
                  </p>
                </li>
                <li>
                  <p>
                    You are not accepting the return of the item in accordance
                    with the return policy posted on your website.
                  </p>
                </li>
                <li>
                  <p>
                    They believe that you charged an amount greater than the
                    amount authorized for the purchase
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>What Should I Do To Avoid A Claim?</h3>
              <p>
                The SKUBIQ Customer care policy  describes the practices that
                SKUBIQ expects you to follow in order to offer a high quality
                customer experience.
              </p>
              <p>
                Finally, these 4 tips are most useful in mitigating the risk of
                an A-to-z Guarantee claim
              </p>
              <ul>
                <li>
                  <p>
                    Deliver a service well in time based on your promise to the
                    customer.
                  </p>
                </li>
                <li>
                  <p>
                    Inform customer in case of any issue with respect to
                    delivery of a service/an item.
                  </p>
                </li>
                <li>
                  <p>
                    Ship promptly and use a shipping method that ensures
                    reliable delivery.
                  </p>
                </li>
                <li>
                  <p>
                    Ship with tracking information and provide this information
                    to the buyer.
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>How Do I View And Manage My Claims?</h3>
              <p>
                For every claim, SKUBIQ sends you an email with details, and
                requests a response, giving you an opportunity to present your
                side of the issue. You can respond to a claim by replying to the
                email claim notification with the requested information.
              </p>
            </article>
            <article>
              <h3>What Are My Obligations When A Claim Is Filed?</h3>
              <ul>
                <li>
                  <p>
                    You have 3 business days in case of a digital service and 7
                    business days for physical item to respond to SKUBIQ
                    notification, but it is a best practice to reply
                    immediately. Within this period you can issue a refund to
                    the buyer or represent your case to SKUBIQ. If the item has
                    not yet shipped, you can cancel that portion of the order.
                    If you do not respond within this time frame, we will
                    automatically assign the claim to you. For assigned claims,
                    we will reimburse the buyer and debit your account or cancel
                    any future payment, as applicable.
                  </p>
                </li>
                <li>
                  <p>
                    For an " Product/service Not Received " or " Product/service
                    Received Late" claim, explain your side of the issue by
                    responding to SKUBIQ's email. You should also provide the
                    following information:
                  </p>
                </li>
                <ol>
                  <li>
                    <p>
                      A verifiable shipment tracking number, or proof of
                      shipment date, carrier, method, and className if shipped
                      via a non-traceable method in case of physical item.
                    </p>
                  </li>
                  <li>
                    <p>
                      A copy of the email provided to the buyer that indicated
                      the delivery.
                    </p>
                  </li>
                  <li>
                    <p>
                      A copy of the receipt or order confirmation email you
                      sent to the buyer that displays the date of purchase,
                      shipping address, total purchase price including
                      shipping and handling, taxes, and any other charges, and
                      for each item, its description, price, quantity, and, if
                      it was not new, its condition
                    </p>
                  </li>
                  <li>
                    <p>
                      Details of any communication you had with the buyer
                      outside of the SKUBIQ communication manager.
                    </p>
                  </li>
                </ol>
                <li>
                  <p>
                    For "Materially Different" claims, explain your side of the
                    issue by responding to SKUBIQ's email. For claims on SKUBIQ
                    Pay purchases, you should also provide the following
                    information:
                  </p>
                </li>
                <ol>
                  <li>
                    <p>
                      A copy of the receipt or order confirmation emails you
                      sent to the buyer that displays the date of purchase,
                      shipping address, total purchase price including shipping
                      and handling, taxes, and any other charges, and for each
                      item, its description, price, quantity, and, if it was not
                      new, its condition;
                    </p>
                  </li>
                  <li>
                    <p>
                      A description of the disputed item and URL of the detail
                      page where the disputed item is listed; and,
                    </p>
                  </li>
                  <li>
                    <p>
                      Details of any communication you had with the buyer
                      outside of the SKUBIQ communication manager.
                    </p>
                  </li>
                </ol>
                <li>
                  <p>
                    For "Returns" or "Refund" related claims, explain your side
                    of the issue by responding to SKUBIQ's email. You should
                    also provide the following information:
                  </p>
                </li>
                <ol>
                  <li>
                    <p>
                      A copy of the receipt or order confirmation emails you
                      sent to the buyer that displays the date of purchase,
                      shipping address, total purchase price including shipping
                      and handling, taxes, and any other charges, and for each
                      item/service, its description, price, quantity, and, if it
                      was not new, its condition;
                    </p>
                  </li>
                  <li>
                    <p>
                      The URL of the page where you describe your returns policy
                      and return address
                    </p>
                  </li>
                  <li>
                    <p>
                      A copy of the return label provided to the buyer, if any;
                      and,
                    </p>
                  </li>
                  <li>
                    <p>
                      Details of any communication you had with the buyer
                      outside of the SKUBIQ communication manager
                    </p>
                  </li>
                </ol>
                <li>
                  <p>
                    For "Charged more than expected" claims, explain your side
                    of the issue by responding to SKUBIQ's email. You should
                    also provide the following information:
                  </p>
                </li>
                <ol>
                  <li>
                    <p>
                      A copy of the receipt or order confirmation emails you
                      sent to the buyer that displays the date of purchase,
                      shipping address, total purchase price including shipping
                      and handling, taxes, and any other charges, and for each
                      item, its description, price, quantity, and, if it was not
                      new, its condition; and,
                    </p>
                  </li>
                  <li>
                    <p>
                      Details of any communication you had with the buyer
                      outside of SKUBIQ communication manager.
                    </p>
                  </li>
                </ol>
                <li>
                  <p>
                    If you fail to provide a sufficient response to the claim,
                    violate the SKUBIQ customer service agreement or if we
                    determine you were at fault, we will reimburse the buyer and
                    debit your account for the amount of that refund, including
                    item price, tax, and shipping. We may also cancel any future
                    payment for the disputed order, if applicable.
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>
                How Does SKUBIQ Determine Whether An Item/Service Was Not
                Received By A Buyer?
              </h3>
              <p>
                Merchants are accountable for resolving A-to-z Guarantee claims
                for an item/service not received. The claims will be processed
                according to the following policies:
              </p>

              <ul>
                <li>
                  <p>
                    <b>Products shipped without tracking</b>
                    <br />A merchant will be liable for a not-received claim if
                    the estimated delivery date (“EDD”) has passed and the order
                    was shipped without tracking information. This policy
                    applies to all orders, including those shipped by media
                    mail.
                  </p>
                </li>

                <li>
                  <p>
                    <b>Products shipped with tracking — in transit</b>
                    <br />
                    If a product is not delivered by the end of the EDD range,
                    and tracking information shows an expected delivery in the
                    near future, the claim will not be granted and the buyer
                    will be asked to wait for the product. The merchant will not
                    be liable. However, if tracking information does not show
                    expected delivery in a reasonable time frame, the claim will
                    be granted and the merchant will be held liable. This will
                    apply to packages lost in transit as well.
                  </p>
                </li>
                <li>
                  <p>
                    <b>
                      Products shipped with signature confirmation tracking —
                      where tracking shows delivery but the buyer claims item
                      not received
                    </b>
                    <br />
                    If the name on the signature confirmation matches the buyer,
                    the A-to-z Guarantee team will deny the claim. If signature
                    confirmation does not match the buyer, the A-to-z Guarantee
                    team will deny the claim and ask the buyer to follow up with
                    the individual that signed for the package. Guarantee claims
                    for packages that are signed for by a freight forwarder or
                    an agent of the buyer (e.g. receptionist, family member)
                    will be denied. However, if an investigation determines that
                    the customer did not receive the order due to a shipping
                    error beyond his or her control, the merchant may be held
                    liable. We anticipate that this would be a very rare
                    occurrence.
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>
                Am I Required To Allow Returns For Items That The Buyer Believes
                Are Materially Different, Defective, Or Damaged?
              </h3>
              <p>
                As a company, you are responsible for managing the returns
                process as per your policies mentioned on your website for
                materially different, defective, or damaged items, including
                providing buyers with a way to make the return and promptly
                processing the refund for the return. Failure to not abide by
                your policy may result in a debit to your Pay account if the
                buyer is refunded pursuant to an A-to-z Guarantee claim, even if
                the item is not returned to you. You may also be held
                responsible if no response is received from you on an A-to-z
                Guarantee claim, or for declining to handle a return request
                that falls within your return policies.
              </p>
            </article>
            <article>
              <h3>
                Do Buyers See The Information I Provide To SKUBIQ To Investigate
                A Claim?
              </h3>
              <p>
                Buyers do not see comments provided by you in response to our
                email; they are sent only to SKUBIQ investigators.
              </p>
            </article>
            <article>
              <h3>How Do A-To-Z Guarantee Claims Affect My Account?</h3>
              <p>
                A-to-z Guarantee claims affect your Defect Rate, which is used
                to calculate your reserve. Please see our Reserve Policy page
                for more details. Excessive A-to-z Guarantee claims can lead to
                warnings, suspensions, or account termination. You should take
                care to minimize the number of claims granted against you on
                your account. Issuing a refund is the easiest way to resolve the
                issue.
              </p>
            </article>
            <article>
              <h3>
                How Does SKUBIQ Prevent Buyers From Filing Both An A-To-Z
                Guarantee Claim And A Chargeback?
              </h3>
              <p>
                SKUBIQ denies an A-to-z Guarantee claim under review when we
                receive a chargeback from the credit card issuing bank. If the
                A-to-z Guarantee claim is already granted to the buyer for the
                full amount of the transaction before SKUBIQ receives a
                chargeback, SKUBIQ will represent the chargeback to the issuing
                bank
              </p>
            </article>
            <article>
              <h3>
                How Does SKUBIQ Handle An A-To-Z Guarantee Claim When I Have
                Already Issued A Refund
              </h3>
              <p>
                SKUBIQ denies an A-to-z Guarantee claim if you have already
                issued a full refund for the items in question. In order for
                SKUBIQ to track the refund, you must issue the refund through
                SKUBIQ Pay and provide the necessary information to SKUBIQ. If
                you have resolved the issue with the buyer outside of SKUBIQ's
                knowledge and SKUBIQ has granted the claim to the buyer, SKUBIQ
                cannot guarantee recovery of funds. In such a case, you should
                summarize the resolution and send it to the buyer. If you have a
                Seller Central account then you must send this communication
                from the SKUBIQ Communications Manager in Seller Central. You
                can also request the buyer to reverse the A-to-z Guarantee claim
                by informing them to simply reply to initial claim confirmation
                e-mail received from SKUBIQ Pay.
              </p>
            </article>
            <article>
              <h3>How Can I Appeal A Claim Granted Against Me?</h3>
              <p>
                If the claim is decided against you, you have up to 7 calendar
                days to appeal that decision by simply replying to confirmation
                e-mail received from SKUBIQ Pay. During the appeals process,
                SKUBIQ may request additional details from you about the
                transactions. If the decision is reversed by SKUBIQ, your
                account will be credited.
              </p>
            </article>
            <article>
              <h3>What If I Have Additional Questions?</h3>
              <p>
                If you have additional questions about the SKUBIQ A-to-z
                Guarantee, please contact us.
              </p>
            </article>
          </div>
        </section>
        <FooterPagesFooter />
      </>
    );
  }
}
