import Axios from "axios";
import { reactLocalStorage } from 'reactjs-localstorage';
import { EnvironmentValiables } from './Env';




export class AxiosService {
    post(BaseUrl: any, url?: string, EntityObject?: any) {
        const TokenInfo:any   = reactLocalStorage.get('LandingSiteTokenInfo');
        // const TokenInfo:any = sessionStorage.getItem("LandingSiteTokenInfo");
        return new Promise((resolve, reject) => {
            try {
                var basicAuth = 'Bearer '+JSON.parse(TokenInfo).access_token;
                Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    headers: {
                        'Authorization':  basicAuth,
                        "Content-Type": "application/json; charset=utf8"
                    },
                    data: EntityObject
                }).then((res: any) => {
                    
                    resolve(res);
                }).catch((error: any) => {
                    
                    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                        window.localStorage.clear();
                        // sessionStorage.clear()
                        window.location.href = "/";
                    }
                    else if (error.response.status === 500) {
                        
                        //this.stickyText(error.response.data);
                        // this.stickyText();
                    }
                    resolve("error");
                });
            } catch (error) {
                reject(error);
            }
        });
    }
    
    common = (BaseUrl: string, url: string, EntityObject?: any) => {
        
        return new Promise(async(resolve, reject) => {
            
            try {
                await Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    data: EntityObject
                }).then(res => {
                    resolve(res);
                }).catch((error) => {
                    resolve(error);
                });
            } catch (error) {
                resolve("error");
            }
        });
    }

    login(BaseUrl: string, url: string, EntityObject?: any) {
        
        return new Promise((resolve, reject) => {
            
            try {
                var ClientID = EnvironmentValiables.ClientID;
                var Client_Secret = EnvironmentValiables.Client_Secret;
                var basicAuth = 'Basic ' + btoa(ClientID + ':' + Client_Secret);
                Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", 'Authorization': basicAuth },
                    data: EntityObject
                }).then(res => {
                    resolve(res);
                }).catch((error) => {
                    resolve(error);
                });
            } catch (error) {
                resolve("error");
            }
        });
    }
    forget_Password(BaseUrl: any, url?: string, EntityObject?: any) {
        return new Promise((resolve, reject) => {
            
            try {
                Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    data: EntityObject
                }).then(res => {
                     
                    resolve(res);
                }).catch((error) => {
                     
                     resolve('error');
                });
            } catch (error) {
                reject(error);
            }
        });
    }
    reset_Password(BaseUrl: any, url?: string, EntityObject?: any) {
        return new Promise((resolve, reject) => {
            
            try {
                Axios({
                    method: 'post',
                    url: `${BaseUrl}${url}`,
                    data: 
                    {
                        "auth": {
                            "loggedInUserID": "string",
                            "transactionUserID": "string",
                            "authToken": "string"
                        },
                        EntityObject,
                        "messages": [
                            {
                                "merlinWMSMessage": "string",
                                "merlinWMSExceptionCode": "string",
                                "uniqueErrorCode": "string",
                                "userMessage": true
                            }
                        ],
                        "isError": true
                    }
                }).then(res => {
                     
                    resolve(res);
                }).catch((error) => {
                     
                     resolve('error');
                });
            } catch (error) {
                reject(error);
            }
        });
    }
}