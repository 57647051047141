import React, { Component } from 'react'
import FooterPagesHeader from '../FooterPagesHeader';
import FooterPagesFooter from '../FooterPagesFooter';

export default class Ipr extends Component {
    componentDidMount=()=>{
		window.scrollTo(0, 0)
	}
    render() {
        return (
            <>
                <FooterPagesHeader text="IPR Complaints" />
                <section className="content-block">
                    <div className="container">
                        <article className='FooterArticle'>
                            <h3>Complaint Procedure</h3>
                            <p>If you for any reason stand to believe that the content on any of the SKUBIQ services has violated your copyrights or other intellectual property rights, you are requested to kindly write to our Designated Agent providing the following information :</p>
                            <ul>
                                <li><p>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property right alleged to be infringed</p></li>
                                <li><p>A description of the copyrighted work or other intellectual property that you claim to have been infringed</p></li>
                                <li><p>Identification of the material that you claim to be infringing including information regarding its location that would enable it to be located and if possible the URL of such infringing material</p></li>
                                <li><p>Information that would enable us to contact you, such as your address, telephone number, and email address</p></li>
                                <li><p>A statement by you to the effect that you believe in good faith that the use of the infringing material in the manner complained of is not authorized by the copyright or intellectual property owner, its agent, or the law</p> </li>
                                <li><p>A statement by you, under penalty of perjury, that the above information contained in your communication is accurate and that you are authorized to act on behalf of the owner of copyright or other intellectual property right</p></li>
                            </ul>
                            <p>The above information may sent to our designated agent who would deal effectively with any notice of claims, of infringement of copyright and intellectual property rights at the following address:</p>
                            <address>
                                <p style={{ fontSize: "14px", fontStyle: "oblique" }}>
                                    IPR complaints cell<br />
                                    C/o Avya Inventrax Private Limited.<br />
                                    Sampath Vinyagur, D.No. 50-94-14/3,<br />
                                    1st & 2nd Floor,Santhipuram,<br />
                                    Visakhapatnam, Andhra Pradesh,<br />
                                    India- 530016.<br />
                                    Tel  : +91 891 252 6060<br />
                                    Fax : +91 891 253 6060 <br />
                                    Email: <a href="mailto:legal@skubiq.com">legal@skubiq.com </a>
                                </p>
                            </address>
                            <p>Please take note that if you knowingly misrepresent that any material or activity is infringing, you may be subject to liability under Sections 18, 19, 30, 30A, 33, 33A, 34, 35, 36, 53, 55, 58, 63, 64, 65, 65A, 65B and 66 of the Copyright Act 1957</p>

                        </article>
                        <article>
                            <h3>Counter-Notice Procedure</h3>
                            <p>If you are a subscriber and you feel that material posted by you on any of the SKUBIQ services has been wrongly removed, please write to our Designated Agent providing the following information:</p>
                            <ul>
                                <li><p>your physical or electronic signature</p></li>
                                <li><p>Identification of material posted by you that has been removed, or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled</p></li>
                                <li><p>. A statement by you, under penalty of perjury that you believe in good faith that the material was removed or disabled as a result of mistake or wrong identification of the material to be removed or disabled</p></li>
                                <li><p>Your name, address, email and telephone number, and a statement to the effect that you consent to the jurisdiction of Federal District Court, within the jurisdiction of which your address is located, or if your address is outside the Indian Union , having jurisdiction over the place of business and that you will accept service of process from the person who provided notification of infringement or his agent.</p></li>

                            </ul>
                            <p>Your counter-notice with the above information to be effective should be sent to our Designated Agent for notice of claims of infringement of copyright and intellectual property rights at the following </p>
                            <p>Designated Agent (IPR complaints)</p>
                        </article>
                        <article>

                            <address>
                                <p style={{ fontSize: "14px", fontStyle: "oblique" }}>


                                    C/o Avya Inventrax Private Limited.<br />
                                    Sampath Vinyagur, D.No. 50-94-14/3,<br />
                                    1st & 2nd Floor,Santhipuram,<br />
                                    Visakhapatnam, Andhra Pradesh,<br />
                                    India- 530016.<br />
                                    Tel  : +91 891 252 6060<br />
                                    Fax : +91 891 253 6060 <br />
                                    Email: <a href="mailto:legal@Skubiq.com">legal@Skubiq.com </a>
                                </p>
                            </address>
                            <p>Please take note that if you knowingly misrepresent that material was removed or activity was disabled as a result of mistake or wrong identification, you may be subject to liability under Section 512 of the Copyright Acts. Also, kindly note that we have a policy of terminating the accounts of subscribers who repeatedly infringe copyright or other third party rights.</p>
                        </article>
                    </div>

                </section>

                <FooterPagesFooter />

            </>
        )
    }
}
