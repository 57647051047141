import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import "./Dashboard.css"
import { Route, NavLink, Routes } from 'react-router-dom';
import Subscription from '../Subscription/Subscription';
import CancelSubscription from '../CancelSubscription/CancelSubscription';
import TransactionHistory from "../TransactionHistory/TransactionHistory"
import InvoiceHistory from '../InvoiceHistory/InvoiceHistory';
import WMS from '../WMS/WMS';
// import Logo from "../../Assests/img/SKUBIQ_Logo.png"
import Logo from "../../Assests/img/logo_skubiq.svg"
import ManagePlans from '../ManagePlans/ManagePlans';
import {
  UserOutlined,
  UserAddOutlined,
  HistoryOutlined,
  HddOutlined,
  FireOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import ManageAddons from '../ManageAddons/ManageAddons';
import WelcomeToSkubiq from '../WelcomeToSkubiq/WelcomeToSkubiq';
import MyProfile from '../MyProfile/MyProfile';
import EditAccount from '../EditAccount/EditAccount';
import { reactLocalStorage } from 'reactjs-localstorage';
import { END_POINT_SKUBIQWMSURL } from '../Common/EndPoints';
import ChangePassword from '../ChangePassword/ChangePassword';
import StickyText from '../Common/StikeyText';
// import { LoginStoreObj } from '../Common/Store.store';

const { Header, Sider } = Layout;

interface IProps {
  collapsed: boolean,
  tokenData: string,
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
  menuActiveKey: string
}
export default class dashbord extends Component<any, IProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      collapsed: false,
      tokenData: "",
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
      menuActiveKey: ''
    };
  }
  logout = () => {
    setTimeout(() => {
      window.localStorage.clear();
      // sessionStorage.clear()
      window.location.href = "https://www.skubiq.com/";
    }, 1000);
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };
  LoginWMS = () => {
    var UserDataTemp: any = reactLocalStorage.get('LandingSiteLoginUserInfo');
    // const UserDataTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    var UserData: any = UserDataTemp !== 'undefined' ? JSON.parse(UserDataTemp) : "";
    if (UserData.SubscriptionStatus === 0  ) {
      if  (UserData.ZOHO_Status === 'cancelled' ) {
        this.stickyText(true, 'error', 'Error', 'Subscription Cancelled');
      }
      else
      this.stickyText(true, 'error', 'Error', 'Subscription Expired');
    }    
    else {
      const LoginDecData: any = reactLocalStorage.get("LandingSiteTokenInfo");
      // const LoginDecData:any = sessionStorage.getItem("LandingSiteTokenInfo");
      window.open(`${END_POINT_SKUBIQWMSURL}login?p1=${LoginDecData}`);
    }
  }
  getDataFromSticky(show: boolean) {
    
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  Callback = (key: any) => {
    
    this.setState({ menuActiveKey: key });
  };
  componentDidMount = async () => {
    // const tokenData:any = sessionStorage.getItem("LandingSiteTokenInfo");
    // const UserDataTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    var tokenData: any = reactLocalStorage.get('LandingSiteTokenInfo');
    var UserDataTemp: any = reactLocalStorage.get('LandingSiteLoginUserInfo');
    var UserData: any = UserDataTemp !== 'undefined' ? JSON.parse(UserDataTemp) : "";
    if (tokenData === "" || tokenData === 'undefined' || tokenData === null || UserData === "" || UserData === 'undefined' || UserData === null) {
      window.localStorage.clear();
      // sessionStorage.clear()
      window.location.href = '/';
    }
  }
  render() {
    // const UserDataTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    var UserDataTemp: any = reactLocalStorage.get('LandingSiteLoginUserInfo');
    var UserData: any = UserDataTemp !== 'undefined' ? JSON.parse(UserDataTemp) : "";
    return (
      <Layout>

        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>

          <div className='logo_div'>
            <NavLink to='/DashBoard'><img src={Logo} alt="skubiq" /></NavLink>
          </div>
          <div style={{ position: "absolute", bottom: "0px", width: '100%' }}>
            {/* <p style={{ color: "white", textAlign: "center", margin: "0px", fontSize: '12px' }}>©2022</p>
            <p style={{ color: "white", textAlign: "center", fontSize: '12px' }}>SKUBIQ all rights Reserved</p> */}
          </div>
          <div className="logo" />
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[this.state.menuActiveKey]}
            items={[
              {
                key: '1',
                icon: <UserAddOutlined />,
                label: <NavLink to='/Subscription'>Subscription</NavLink>
              },
              {
                key: '2',
                icon: <HistoryOutlined />,
                label: <NavLink to='/TransactionHistory'>Transaction History</NavLink>
              },
              {
                key: '3',
                icon: <HddOutlined />,
                label: <NavLink to='/InvoiceHistory'>Invoice History</NavLink>
              },
              {
                key: '4',
                icon: <FireOutlined />,
                label: <span onClick={() => this.LoginWMS()}>WMS</span>
              },
            ]}
          />
        </Sider>
        <Layout className="site-layout">
        {
          window.location.pathname.includes("/DashBoard") ? "":
          <>
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <div>
              {/* <MenuUnfoldOutlined className='trigger' style={{ paddingLeft: "10px" }} onClick={this.toggle} /> */}
              <span className='profilesettings cursor-pointer' onClick={() => this.logout()}>
                <span className='profileicons'>
                  <LogoutOutlined />
                </span>
                Logout
              </span>
              <NavLink to='/MyProfile'>
                <span className='profilesettings cursor-pointer'>
                  <span className='profileicons'>
                    <SettingOutlined />
                  </span>

                  My Profile
                </span>
              </NavLink>
              <span className='profilesettings cursor-pointer'>
                <span className='profileicons'>
                  <UserOutlined />
                </span>
                {UserData ? UserData.company_name : ""}
              </span>
            </div>
          </Header>
          </>}
          <Content
            className="site-layout-background"
            style={{
              margin: "2px",
              // padding: 12,
              minHeight: 280,
              overflowY: 'scroll',
            }}
          >
             <div className='stikey'>
                    <>
                        {
                            this.state.sticky_show ?
                                <StickyText
                                    display={this.state.sticky_show}
                                    sticky_type={this.state.sticky_type}
                                    sticky_title={this.state.sticky_title}
                                    sticky_message={this.state.sticky_message}
                                    sendData={this.getDataFromSticky}
                                    key={this.state.sticky_title}
                                />

                                :
                                <></>
                        }
                    </>
                </div>
            <Routes>
              <Route path="/Subscription" element={<Subscription parentCallback={this.Callback} />} />
              <Route path="/TransactionHistory" element={<TransactionHistory parentCallback={this.Callback} />} />
              <Route path="/ManagePlans" element={<ManagePlans />} />
              <Route path="/CancelSubscription" element={<CancelSubscription />} />
              <Route path="/InvoiceHistory" element={<InvoiceHistory parentCallback={this.Callback} />} />
              <Route path="/WMS" element={<WMS />} />
              <Route path="/ManageAddons" element={<ManageAddons />} />
              <Route path="/DashBoard" element={<WelcomeToSkubiq parentCallback={this.Callback} />} />
              <Route path="/MyProfile" element={<MyProfile />} />
              <Route path="/EditAccount" element={<EditAccount />} />
              <Route path="/ChangePassword" element={<ChangePassword />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
      // </Router>
    );
  }
}