import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { END_POINT_LandingSite } from "../Common/EndPoints";
import LOgo from "./../../Assests/img/logo_skubiq.svg";
import UnlocK from "./../../Assests/img/unlocK.svg";
import "./../Footer/Footer.css";
import "./../HomePage/HomePage.css";
import "./../HomePage/responsivelandingsite.css";
import "./../HomePage/stylelandingsite.css";

interface IProps {
  text: string;
}
export default class FooterPagesHeader extends Component<any, IProps> {
  isSticky = () => {
    const header: any = document.getElementById("sticky-header");
    //const navigation: any = document.getElementById("navigation");
    // const scrollTop = window.scrollY;
    // scrollTop >= 250
    //   ? header.classList.add("sticky-header")
    //   : header.classList.remove("sticky-header");
    // scrollTop >= 250
    //   ? header.classList.add("navigationclr")
    //   : header.classList.remove("navigationclr");
    var sticky: any = header.offsetTop
    if (window.scrollY > sticky) {
      
      header.classList.add("sticky-header")
      header.classList.add("navigationclr")
    }
    else {
      header.classList.remove("sticky-header")
      header.classList.remove("navigationclr")
    }
  };

  closemenu() {
    
    var element: any = document.getElementById("mblmenu");
    element.classList.remove("closemenu");
  }
  openmenu() {
    var element: any = document.getElementById("mblmenu");
    element.classList.add("closemenu");
  }

  componentDidMount() {
    window.addEventListener("scroll", this.isSticky);
    window.onscroll = () => { this.isSticky(); }
  }

  render() {
    return (
      <>
        <section className="headerblock">
          <div className="middle">
            <header>
              <div id="mblmenu" className="mennnu ">
                <div className="menu-backdrop"></div>
                <div className="close-btn">
                  <i className="fas fa-times"></i>
                </div>
                <nav className="menu-box">
                  <div className="nav-logom">
                    <img src={LOgo} alt="skubiq" />
                  </div>
                  <div className="menu-outer">
                    <CloseOutlined onClick={() => this.closemenu()} />
                    {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
                  </div>
                  <div className="social-links">
                    <ul className="navigationmbl">
                      <li className="">
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/Support">Support</Link>
                      </li>
                      <li>
                        <a href="tel:+91 7995079925">
                          Need help? &nbsp;tel:+91 7995079925
                        </a>
                      </li>
                    </ul>
                    <div className=" header-actionmbl">
                      <ul>
                        {/* <!-- <li className="header-search"><a href="# " >Need Help? &nbsp;  +91 7674978928</a></li> --> */}
                        <li className="header-btn">
                          <Link to={"/Login"}>
                            <span className="btn">
                              <img src={UnlocK} alt="" />
                              <span style={{ color: "white" }}>Login</span>
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div id="sticky-header" className="main-header menu-area">
                <div className="container custom-container ">
                  <div className="row">
                    <div className="col-12">
                      <div className="mobile-nav-toggler">
                        <i className="fas fa-bars"></i>
                      </div>
                      <div className="menu-wrap">
                        <nav className="menu-nav ">
                          <div className="logo">
                            <Link to="/">
                              <img src={LOgo} alt="skubiq" />
                            </Link>
                          </div>
                          <div className="navbar-wrap ">
                            <ul className="">
                              <li className="">
                              <a href="https://www.skubiq.com">Home</a>
                              </li>
                              <li>
                                <Link to="/Support">Support</Link>
                              </li>
                              <li>
                                <a href="tel:+91 08912526060">
                                  Need help? &nbsp;+91 0891 2526060
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="header-action d-none d-md-block">
                            <ul>
                              <li className="header-btn">
                                <Link to={"/Login"}>
                                  <span className="btn">
                                    <img src={UnlocK} alt="" />
                                    <span style={{ color: "white" }}>
                                      Login
                                    </span>
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="mobilmenuopen">
                            <MenuOutlined onClick={() => this.openmenu()} />
                          </div>
                        </nav>
                      </div>
                      {/* <!-- Mobile Menu  --> */}
                      <div className="mobile-menu">
                        <div className="menu-backdrop"></div>
                        <div className="close-btn">
                          <i className="fas fa-times"></i>
                        </div>
                        <nav className="menu-box">
                          <div className="nav-logo">
                            <a href="index.html">
                              <img
                                src="assets/img/logo_skubiq.svg"
                                alt=""
                                title=""
                              />
                            </a>
                          </div>
                          <div className="menu-outer">
                            {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
                          </div>
                          <div className="social-links">
                            <ul className="clearfix">
                              <li>
                                <a href="# ">
                                  <span className="fab fa-twitter"></span>
                                </a>
                              </li>
                              <li>
                                <a href="# ">
                                  <span className="fab fa-facebook-square"></span>
                                </a>
                              </li>
                              <li>
                                <a href="# ">
                                  <span className="fab fa-pinterest-p"></span>
                                </a>
                              </li>
                              <li>
                                <a href="# ">
                                  <span className="fab fa-instagram"></span>
                                </a>
                              </li>
                              <li>
                                <a href="# ">
                                  <span className="fab fa-youtube"></span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </nav>
                      </div>
                      {/* <!-- End Mobile Menu -->
                                <!-- Modal Search --> */}
                      <div
                        className="modal fade"
                        id="search-modal"
                        role="dialog"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <form>
                              <input type="text" placeholder="Search here..." />
                              <button>
                                <i className="fa fa-search"></i>
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="FooterHeadBG">
                  <h2 className="h2-heading">{this.props.text}</h2>
                </div>
              </div>
            </header>
          </div>
        </section>
      </>
    );
  }
}
