import { CloseOutlined, DownOutlined, MenuOutlined } from '@ant-design/icons'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import UnlocK from "../../Assests/img/unlocK.svg";
import LOgo from "../../Assests/img/logo_skubiq.svg";
// import { END_POINT_LandingSite } from '../Common/EndPoints';
interface IProps {
    text: string;
}
export default class headder extends Component<any, IProps> {
    isSticky = () => {
        // const header: any = document.getElementById("sticky-header");
        // //const navigation: any = document.getElementById("navigation");
        // 
        // var sticky: any = header.offsetTop
        // if (window.scrollY > sticky) {
        //     
        //     header.classList.add("sticky-header")
        //     header.classList.add("navigationclr")
        // }
        // else {
        //     header.classList.remove("sticky-header")
        //     header.classList.remove("navigationclr")
        // }
    };
    menu = (id: any) => {
        var id1 = id.currentTarget.id;
        var element: any = document.getElementById(id1);
        // var home: any = document.getElementById("home");
        // var Features: any = document.getElementById("Features");
        // var about: any = document.getElementById("about");
        // var Segments: any = document.getElementById("Segments");
        // var Pricing: any = document.getElementById("Pricing");
        // var contacts: any = document.getElementById("contacts");
        if (id1 === "home") {
            // element.classList.add("active");
            // Features.classList.remove("active");
            // about.classList.remove("active");
            // Segments.classList.remove("active");
            // Pricing.classList.remove("active");
            // contacts.classList.remove("active");
            element.classList.toggle('active')
        }
        else if (id1 === "Features") {
            // element.classList.add("active");
            // home.classList.remove("active");
            // about.classList.remove("active");
            // Segments.classList.remove("active");
            // Pricing.classList.remove("active");
            // contacts.classList.remove("active");
            element.classList.toggle('active')
        }
        else if (id1 === "about") {
            // element.classList.add("active");
            // Features.classList.remove("active");
            // home.classList.remove("active");
            // Segments.classList.remove("active");
            // Pricing.classList.remove("active");
            // contacts.classList.remove("active");
            element.classList.toggle('active')
        }
        else if (id1 === "Segments") {
            // element.classList.add("active");
            // Features.classList.remove("active");
            // home.classList.remove("active");
            // about.classList.remove("active");
            // Pricing.classList.remove("active");
            // contacts.classList.remove("active");
            element.classList.toggle('active')
        }
        else if (id1 === "Pricing") {
            // element.classList.add("active");
            // Features.classList.remove("active");
            // home.classList.remove("active");
            // about.classList.remove("active");
            // Segments.classList.remove("active");
            // contacts.classList.remove("active");
            element.classList.toggle('active')
        }
        else if (id1 === "contacts") {
            // element.classList.add("active");
            // Features.classList.remove("active");
            // home.classList.remove("active");
            // about.classList.remove("active");
            // Segments.classList.remove("active");
            // Pricing.classList.remove("active");
            element.classList.toggle('active')
        }
        this.closemenu();
    };
    openmenu() {
        var element: any = document.getElementById("mblmenu");
        element.classList.add("closemenu");
    }
    closemenu() {
        
        var element: any = document.getElementById("mblmenu");
        element.classList.remove("closemenu");
    }
    ResourcesMenu = () => {
        
        var element: any = document.getElementById("submenu");
        element.classList.toggle("showResources");
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.isSticky);
    }
    render() {
        return (
            <>
                <header>
                    <div id="mblmenu" className="mennnu">
                        <div className="menu-backdrop"></div>
                        <div className="close-btn">
                            <i className="fas fa-times"></i>
                        </div>
                        <nav className="menu-box">
                            <div className="nav-logom">
                                <img src={LOgo} alt="skubiq" />
                            </div>
                            <div className="menu-outer">
                                <CloseOutlined onClick={() => this.closemenu()} />
                                {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
                            </div>
                            <div className="social-links">
                                <ul className="navigationmbl">
                                    <li id="home" onClick={(id) => this.menu(id)}><a href="#Home">Home</a></li>
                                    <li id="features" onClick={(id) => this.menu(id)}><a href="#Features">Features</a></li>
                                    <li id="segments" onClick={(id) => this.menu(id)}><a href="#Segments">Segments</a></li>
                                    <li id="pricing" onClick={(id) => this.menu(id)}><a href="#Pricing">Pricing</a></li>
                                    <li className="dropdown" onClick={this.ResourcesMenu}>Resources <span className="DownOutlined"> &nbsp;
                                        <DownOutlined />
                                    </span>
                                        <ol id="submenu">
                                        <li><a href="https://www.skubiq.com/blog/">Blog</a></li>
                                                    {/* <li><a href="# ">Videos</a></li> */}
                                                    <li id="about" onClick={(id) => this.menu(id)}><a href="#About">About</a></li>
                                                    <li><a href="https://www.skubiq.com/saas-wms-india/">SaaS WMS India</a></li>
                                                </ol>
                                                <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div></li>
                                            <li id="contacts" onClick={(id) => this.menu(id)}><a href="https://www.skubiq.com/contact-us/">Contact Us</a></li>
                                </ul>
                                <div className=" header-actionmbl">
                                    <ul>
                                        {/* <!-- <li className="header-search"><a href="# " >Need Help? &nbsp;  +91 7674978928</a></li> --> */}
                                        <li className="header-btn">
                                            <Link to={"/Login"}>
                                                <span className="btn">
                                                    {/* <img src={UnlocK} alt="" /> */}
                                                    <span style={{ color: "#d41324" }}>Login</span>
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div id="sticky-header" className="main-header menu-area ">
                        <div className="container custom-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="mobile-nav-toggler">
                                        <i className="fas fa-bars"></i>
                                    </div>
                                    <div className="menu-wrap">
                                        <nav className="menu-nav show">
                                            <div className="logo">
                                                <Link to="/">
                                                    <img src={LOgo} alt="skubiq" />
                                                </Link>
                                            </div>
                                            <div className="navbar-wrap main-menu d-none d-lg-flex">
                                                <ul id="navigation" className="navigation">
                                                    <li
                                                        className=""
                                                        id="home"
                                                        onClick={(id) => this.menu(id)}
                                                    >
                                                        <a href="https://www.skubiq.com">
                                                            <span>Home</span>
                                                        </a>
                                                    </li>
                                                    <li
                                                        id="features"
                                                        onClick={(id) => this.menu(id)}
                                                        className=""
                                                    >
                                                        <Link to="/Keyfeatures">
                                                            <span>Features</span>
                                                        </Link>

                                                    </li>
                                                    <li
                                                        id="segments"
                                                        onClick={(id) => this.menu(id)}
                                                        className=""
                                                    >
                                                        <Link to="/Segments">
                                                            <span>
                                                                Segments
                                                            </span>

                                                        </Link>

                                                    </li>
                                                    <li id="pricing" onClick={(id) => this.menu(id)}>
                                                        <Link to="/Pricing">
                                                            <span>Pricing</span>

                                                        </Link>

                                                    </li>
                                                    <li className="dropdown">
                                                        <a href="# ">
                                                            Resources{" "}
                                                            <div className="DownOutlined">
                                                                {" "}
                                                                &nbsp;
                                                                <DownOutlined />
                                                            </div>
                                                        </a>
                                                        <ol className="submenu">
                                                        <li>
                                                                        <a href="https://www.skubiq.com/blog/">BLOG</a>
                                                                    </li>
                                                                    {/* <li>
                                                                        <a href="# ">VIDEOS</a>
                                                                    </li> */}
                                                                   
                                                                    <li
                                                                        id="about"
                                                                        onClick={(id) => this.menu(id)}
                                                                    >
                                                                        <Link to="/About">
                                                                            <span>ABOUT</span>
                                                                        </Link>

                                                                    </li>
                                                                    <li><a href="https://www.skubiq.com/saas-wms-india/">SaaS WMS India</a></li>
                                                        </ol>
                                                        <div className="dropdown-btn">
                                                            <span className="fas fa-angle-down"></span>
                                                        </div>
                                                    </li>
                                                    <li id="contacts" onClick={(id) => this.menu(id)}>
                                                        <a href="https://www.skubiq.com/contact-us/">Contact Us</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="header-action ">
                                                <ul>
                                                    {/* <!-- <li className="header-search"><a href="# " >Need Help? &nbsp;  +91 7674978928</a></li> --> */}
                                                    <li className="header-btn">
                                                        <Link to={"/Login"}>
                                                            <span className="btn">
                                                                {/* <img src={UnlocK} alt="" /> */}
                                                                <span style={{ color: "#d41324" }}>
                                                                    Login
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="mobilmenuopen">
                                                <MenuOutlined onClick={() => this.openmenu()} />
                                            </div>
                                        </nav>
                                    </div>
                                    {/* <!-- Mobile Menu  --> */}
                                    <div className="mobile-menu">
                                        <div className="menu-backdrop"></div>
                                        <div className="close-btn">
                                            <i className="fas fa-times"></i>
                                        </div>

                                        <nav className="menu-box">
                                            <div className="nav-logo">
                                                <a href="index.html">
                                                    <img src="img/logo/w_logo.png" alt="" title="" />
                                                </a>
                                            </div>
                                            <div className="menu-outer">
                                                {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
                                            </div>
                                            <div className="social-links">
                                                <ul className="clearfix">
                                                    <li>
                                                        <a href="# ">
                                                            <span className="fab fa-twitter"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="# ">
                                                            <span className="fab fa-facebook-square"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="# ">
                                                            <span className="fab fa-pinterest-p"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="# ">
                                                            <span className="fab fa-instagram"></span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="# ">
                                                            <span className="fab fa-youtube"></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                    {/* <!-- End Mobile Menu --> */}
                                    {/* <!-- Modal Search --> */}
                                    <div
                                        className="modal fade"
                                        id="search-modal"
                                        role="dialog"
                                        aria-hidden="true"
                                    >
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <form>
                                                    <input type="text" placeholder="Search here..." />
                                                    <button>
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="FooterHeadBGSeo">
                            {/* <h2 className="h2-heading"><BreadCrumb/></h2> */}
                        </div>
                    </div>
                </header>
                <>

                </>

            </>
        )
    }
}
