import React, { Component } from 'react'
import { Service } from '../Common/Services.service';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import { NavLink } from 'react-router-dom';
import { Button, Card, Col, Row } from 'antd';
import { CancelSubscriptionEntityObject } from '../Common/Interface';
import { reactLocalStorage } from 'reactjs-localstorage';
import StickyText from '../Common/StikeyText';
interface AddOnData {
  AddOnData: any
  spin_loading: boolean
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
}
export default class CancelSubscription extends Component<any, AddOnData> {

  private Service: Service;

  constructor(props: any) {
    super(props)
    this.Service = new Service();
    this.state = {
      AddOnData: [],
      spin_loading: false,
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: ''
    }

  }
  CancelSubscription = async (bool: string) => {
    this.setState({ spin_loading: true })
    // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
    
    if (localTemp.length) {
      const local: any = localTemp ? JSON.parse(localTemp) : []
      let CancelSubscriptionEntityObject: CancelSubscriptionEntityObject = {
        SubscriptionID: local.SubscriptionValue,
        Flag: bool
      }
      if (local.SubscriptionValue === undefined) {
        this.stickyText(true, 'error', 'Error', 'Something went wrong');
      }
      else {
        let res: any = await this.Service.CancelSubscriptionService(CancelSubscriptionEntityObject)
        
        this.setState({ spin_loading: false })
        const tempdata = res.data ? JSON.parse(res.data) : [];
        if (!tempdata) {
          this.stickyText(true, 'error', 'Error', 'Something went wrong');
          return false;
        }
        else {
          if (tempdata.message === ".") {
            this.stickyText(true, 'error', 'Error', 'Something went wrong');
            return false;
          }
          else if (tempdata.message === "Subscription is in inactive state.") {
            this.stickyText(true, 'error', 'Error', 'Subscription is in inactive state.');
            return false;
          }
          else {
            this.stickyText(true, 'success', 'Success', tempdata.message);
            setTimeout(() => {
              // window.localStorage.clear();
              window.location.href = "/";
            }, 2000)
            return false;
          }
        }
      }
    }
  }
  getDataFromSticky(show: boolean) {
    
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  render() {
    return (
      <>
        <div className={this.state.spin_loading ? "spin_class2" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />

                :
                <></>
            }
          </>
        </div>
        <Card className='Subscription_card'>
          <Row gutter={16}>
            <Col className='item' span={6}><NavLink to={'/Subscription'}>Current Plan</NavLink></Col>
            <Col className='item' span={6}><NavLink to={'/ManagePlans'}>Manage Plans</NavLink></Col>
            <Col className='item' span={6}><NavLink to={'/ManageAddons'}>Manage Addons</NavLink></Col>
            <Col className='item active' span={6}><NavLink to={'/CancelSubscription'}>Cancel Subscription</NavLink></Col>
          </Row>
        </Card>
        <div className='ManageAddons'>
          <div className='ManageAddons-sub'>
          <Row gutter={16}>
            <Col span={7}>
              <Button className='Cancel_Immediately_Btn2' onClick={() => this.CancelSubscription('true')}>Cancel End of The Subscription</Button>
            </Col>
            <Col span={6}>
              <Button className='Cancel_Immediately_Btn1' onClick={() => this.CancelSubscription('false')}>Cancel Immediately</Button>
            </Col>
          </Row>
          </div>
        </div>
      </>
    )
  }
}
