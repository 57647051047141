import { Component } from "react";
import { NavLink } from "react-router-dom";
import { Service } from "../Common/Services.service";
import { LoginStoreObj } from "../Common/Store.store";
import "../loginpage/login.css";
import { reactLocalStorage } from 'reactjs-localstorage';
import { AesEncryption } from "../Common/EncryptionDecryption";
import Home_Icon_WH from './../../Assests/img/Home_Icon_WH.svg';
// import logo from "../../Assests/img/logo_skubiq.svg";
import logo from "../../Assests/img/logo_skubiq.svg"
import { EyeFilled, EyeInvisibleFilled, QuestionCircleOutlined } from "@ant-design/icons";
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import StickyText from "../Common/StikeyText";
import LoginCaptch from "./LoginCaptch";
import React from "react";
import Header from "../Seo/header";
// import { END_POINT_LandingSite } from "../Common/EndPoints";

interface LoginEntity {
  username: any;
  password: string;
  passwordShown: boolean;
  spin_loading: boolean
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
}
export default class Login extends Component<any, LoginEntity> {
  private Service: Service;
  child: any;
  constructor(props: any) {
    super(props);
    this.Service = new Service();
    this.child = React.createRef();
    this.state = {
      username: "",
      password: "",
      passwordShown: false,
      spin_loading: false,
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
    };
  }
  getDataFromSticky(show: boolean) {
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  Login = async (e: any) => {
    ;
    
    let val = this.child.current.capchavalidation()
    if (this.state.username === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter User Name');
      return false
    }
   else if (this.state.password === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter Password');
      return false
    }
   else if (val === false) {
      e.preventDefault();
      this.stickyText(true, 'error', 'Error', 'Please enter valid captcha');
    }
    else{
      e.preventDefault();
      this.setState({ spin_loading: true, sticky_show: false })
    var LoginDataObj = {
      username: AesEncryption.encrypt(this.state.username),
      password: AesEncryption.encrypt(this.state.password)
    };
    // sessionStorage.setItem("LandingSiteLoginData", JSON.stringify(LoginDataObj));
    reactLocalStorage.set("LandingSiteLoginData", JSON.stringify(LoginDataObj));

    var bodyFormData = new FormData();
    bodyFormData.append("username", this.state.username);
    bodyFormData.append("password", this.state.password);
    bodyFormData.append("grant_type", "password");
    bodyFormData.append("scope", "openid offline_access skubiq");
    bodyFormData.append("islandingsite", "true");
    await LoginStoreObj.LoginStoreMethod(bodyFormData);
    this.setState({ spin_loading: false, sticky_show: false })
    }
    
  };
  public handleChange = (e: any) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false });
  };
  togglePassword = () => {
    setTimeout(() => {
      this.setState({
        passwordShown: !this.state.passwordShown, sticky_show: false
      })
    }, 2000);
    this.setState({
      passwordShown: !this.state.passwordShown, sticky_show: false
    })
  };
   handleLinkClick = () => {
    window.localStorage.clear();
  };
  render() {
    const{jwtToken} = LoginStoreObj;
    return (
      <div className="loginpagebg">
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />

                :
                <></>
            }
          </>
        </div>
        <div style={{ display: "none" }}>
          <Header />
        </div>
        <div className={this.state.spin_loading ? "spin_class_login" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>
        <div className="container loginpage">
          <div className="Main_login">
            <div className="coursel">
              <div className="text" >
                <div >
                  {/* <h3 className="Gap">Welcome back !</h3> */}
                </div>
              </div>
            </div>
            
            <div className="login">
            <a href="https://www.skubiq.com">
              <span className="homestyle">
                <img src={Home_Icon_WH} alt="Home_Icon_WH" />
              </span>
            </a>
              <div className="text-center">
                <img src={logo} alt="" className="Imglogo" />
              </div>

              <form onSubmit={this.Login}>
                <div className="title">Sign In</div>

                <div className="input-box">
                  <div className="divFlex">
                    <input
                      type="text"
                      required
                      name="username"
                      // maxLength={20}
                      // value={WarehouseDetails.WHName}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <label>Email ID </label>
                    <span className="required-label"></span>
                  </div>
                </div>

                <div className="input-box">
                  <div className="divFlex">
                    <input
                      type={this.state.passwordShown ? "text" : "password"}
                      required
                      name="password"
                      // maxLength={20}
                      // value={WarehouseDetails.WHName}
                      onChange={this.handleChange}
                      autoComplete="off"
                    // iconRender={(visible: any) =>
                    //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                    />{this.state.passwordShown ? <EyeInvisibleFilled onClick={this.togglePassword} /> : <EyeFilled onClick={this.togglePassword} />}
                    
                    <label className="active">Password </label>

                    <span className="required-label"></span>
                  </div>
                </div>
                <br />
                <LoginCaptch ref={this.child} />
                <div className="signin-button">
                  <button className="signin-btn">
                    Sign In
                  </button>
                </div>
                  <p className="Invalid">{jwtToken === 'Invalid username' || jwtToken === 'Invalid username and password' ? jwtToken : " "}</p>
                <div style={{ marginTop: "10px" }}>
                  <NavLink to="/forgotpassword">
                    <div className="login_options1">
                      <QuestionCircleOutlined className="usrAdd" />&nbsp;  Forgot password
                    </div>
                  </NavLink>
                  {/* <NavLink to="/SelectPlan">
                    <div className="login_options2"><UserAddOutlined className="usrAdd" />&nbsp; Create Your Account </div>
                  </NavLink> */}
                  <a href="https://www.skubiq.com/start-your-free-trial/" onClick={this.handleLinkClick}>
                    <div className="login_options2">
                      {/* <UserAddOutlined className="usrAdd" />&nbsp; */}
                      Start Your Free Trail </div>
                  </a>
                  {/* This is for redirect to trail account */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
