import moment from "moment";
export class DateFormatStore {
    static DateStringToNum(date: any) {
        
        if (date !== null || date === undefined || date === "") {
            return moment(date).format('DD-MMM-YYYY');
        }
        else {
            return "";
        }
    }
    static DateStringToNum1(date: any) {
        
        if (date !== null || date === undefined || date === "") {
            return moment(date).format("DD-MMM-YYYY HH:mm:ss");
        }
        else {
            return "";
        }
    }


    
}