import React from 'react'
// import "../homepage/responsive.css"
import "./../HomePage/HomePage.css";
import "./../HomePage/responsivelandingsite.css";
import "./../HomePage/stylelandingsite.css";
import "./../HomePage/mybootstrap.css";
import { FacebookFilled, LinkedinFilled, TwitterSquareFilled, YoutubeFilled } from '@ant-design/icons';
import DownArrow from "../../Assests/img/downArrow.svg";
export default class Homepage extends React.Component<any> {

    render() {
        return (
            <>
                <footer className="black-bg">
                    <div className="container">
                        <div className='row'>
                        <div className="col-md-4 col-sm-12">
                            <div>
                                <span className='about_t'>About SKUBIQ</span>
                                <p>SKUBIQ is an endeavor to provide a unified SaaS omnichannel fulfilment and warehouse operating platform for all the inventory & warehouse operation needs, earned through months of research & development, and years of listening to our customer feedback.</p>
                                <p>SKUBIQ caters to Distribution / eCommerce fulfillment centers, manufacturing facilities, and Contract Logistics through a wide array of integrations to seamlessly serve their customers. </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="container">
                                <div className='row'>
                                    <div className="col-md-6 col-sm-6">
                                        <div className='quick_links_a'>
                                            <span className='quick_links'>Quick Links</span><br></br>
                                            <a href="https://www.skubiq.com/"><img src={DownArrow} className="rightarrow-f" alt=""/>Home</a><br></br>
                                            <a href="https://www.skubiq.com/about/"><img src={DownArrow} className="rightarrow-f" alt=""/>About</a><br></br>
                                            <a href="https://www.skubiq.com/blog/"><img src={DownArrow} className="rightarrow-f" alt=""/>Blog</a><br></br>
                                            <a href="https://www.skubiq.com/contact-us/"><img src={DownArrow} className="rightarrow-f" alt=""/>Contact Us</a><br></br>
                                            <a href="https://www.skubiq.com/#Features"><img src={DownArrow} className="rightarrow-f" alt=""/>Features</a><br></br>
                                            <a href="https://www.skubiq.com/#Segments"><img src={DownArrow} className="rightarrow-f" alt=""/>Segments</a><br></br>
                                            <a href="https://www.skubiq.com/#Pricing"><img src={DownArrow} className="rightarrow-f" alt=""/>Pricing</a>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className='quick_links_a'>
                                            <span className='quick_links'></span><br></br>
                                            <a href="https://www.skubiq.com/terms-of-service/"><img src={DownArrow} className="rightarrow-f" alt=""/>Terms of Service</a><br></br>
                                            <a href="https://www.skubiq.com/privacy/"><img src={DownArrow} className="rightarrow-f" alt=""/>Privacy Policy</a><br></br>
                                            <a href="https://www.skubiq.com/legal/"><img src={DownArrow} className="rightarrow-f" alt=""/>Legal</a><br></br>
                                            <a href="https://www.skubiq.com/faq/"><img src={DownArrow} className="rightarrow-f" alt=""/>FAQs</a><br></br>
                                            <a href="https://www.skubiq.com/support/"><img src={DownArrow} className="rightarrow-f" alt=""/>Support</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className="col-md-4 col-sm-12">
                                <div>
                                    <div>
                                        <span className='about_t'>Subscribe Now</span>
                                        <p>Don’t miss our future updates! Get Subscribed Today!</p>
                                        <div className="divFlex">
                                            <input
                                                type="text"
                                                required
                                                name="Email"
                                                //   value={this.state.Email}
                                                //   onChange={this.handleChange}
                                                autoComplete="off"
                                                className="full-border-input"
                                                placeholder='Email'
                                            />
                                            <div className='subscribe-b'><button className='btn-subscribe'>Subscribe Now</button></div>
                                        </div>
                                    </div>
                                    <div className="mb-3 ft-a followUs">
                                        <span className='follow mobile follow_us'>Follow us on:</span><br />
                                        <a href='https://www.facebook.com/skubiq/' target='_blank' rel="noreferrer">
                                            <span className='follow'><FacebookFilled /></span></a>
                                        <a href='https://twitter.com/SKUBIQ_CMO' target='_blank' rel="noreferrer">
                                            <span className='follow'><TwitterSquareFilled /></span></a>
                                        <a href='https://www.linkedin.com/company/skubiq/' target='_blank' rel="noreferrer">
                                            <span className='follow'><LinkedinFilled /></span></a>
                                            <a href='https://www.youtube.com/@skubiq' target='_blank' rel="noreferrer">
                                            <span className='follow' ><YoutubeFilled /></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="footer-overlay-icon"><img src="img/icon/footer_icon.png" alt="" /></div>
                    <div className="copyright-wrap">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="copyright-text">
                                        <p>Copyright© 2024 by Skubiq. All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}