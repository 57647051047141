import React, { Component } from 'react';
import { Service } from '../Common/Services.service';
import "./AdminCreateAccount.css";
import StickyText from '../Common/StikeyText';
// import  '../HomePage/responsive.css';
import {
  IKeyValueData, IKeyValueData1,
  BillingCountryAutoCompleteEntityObject,
  BillingStateAutoCompleteEntityObject
} from '../Common/Interface';
import Autocomplete from '../Common/AutoComplete/MerlinAutoComplete';
// import { Col, Row } from 'antd';
import { Col, Row, Select } from 'antd';
import SearchOutlined from '@ant-design/icons/lib/icons/SearchOutlined';
import { CalendarOutlined, EyeFilled } from '@ant-design/icons';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import moment from 'moment';
const { Option } = Select;
interface IState {
  CompanyCode: string
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
  FirstName: string
  Email: string
  LastName: string
  ContactNumber: string
  ConfirmPassword: string
  Password: string

  BillingCountryDropdownData: IKeyValueData[],
  BillingStateDropdownData: IKeyValueData[],
  BillingStateDropdownDataTemp: IKeyValueData1[],

  ShippingCountryDropdownData: IKeyValueData[],
  ShippingStateDropdownData: IKeyValueData[],

  CurrencyDropdownData: IKeyValueData[],

  CompanyName: string
  CompanyReg: string
  TaxationNo: string,
  ZohoCusomerID: number,
  ZohoSubscriptionID: number,
  BillingCity: string
  BillingZipCode: string
  shippingCity: string
  shipppingZipCode: string
  billingAddress: string
  shippingAddress: string

  BCountryID: number,
  BCountryValue: string,

  BStateID: number,
  BStateValue: string,
  StateCode: string,

  SCountryID: number,
  SCountryValue: string,

  SStateID: number,
  SStateValue: string

  CurrencyId: number
  CurrencyValue: string

  IsSubmit: number
  PlanCode: string,
  IsPaid: string,
  passwordShown1: boolean,
  passwordShown2: boolean,

  spin_loading: boolean
  GstTreatment: string,
  PlanIDLevel: string,
  PlanID: string,
  PlanType: string,
  ValidFrom:string,
  ValidTo:string
}
export default class AdminCreateAccount extends Component<any, IState> {
  Service: Service;
  constructor(props: any) {
    super(props)
    this.Service = new Service();
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      Password: "",
      ConfirmPassword: "",
      LastName: "",
      ContactNumber: "",
      CompanyCode: "",
      FirstName: "",
      Email: "",
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
      ZohoCusomerID: 0,
      ZohoSubscriptionID: 0,
      shippingAddress: "",
      CompanyName: "",
      BillingCity: "",
      CompanyReg: "",
      TaxationNo: "",
      BillingZipCode: "",
      shippingCity: "",
      shipppingZipCode: "",
      billingAddress: "",
      BillingCountryDropdownData: [],
      BillingStateDropdownData: [],
      BillingStateDropdownDataTemp: [],
      ShippingCountryDropdownData: [],
      ShippingStateDropdownData: [],
      CurrencyDropdownData: [],
      CurrencyId: 0,
      CurrencyValue: "",
      BCountryID: 0,
      BCountryValue: "",

      BStateValue: '',
      BStateID: 0,
      StateCode: "",

      SCountryID: 0,
      SCountryValue: "",

      SStateValue: '',
      SStateID: 0,

      IsSubmit: 0,
      PlanCode: "",
      IsPaid: 'false',
      passwordShown1: false,
      passwordShown2: false,
      spin_loading: false,
      GstTreatment: 'GST Treatment',
      PlanIDLevel: 'Select Plans',
      PlanID: "",
      PlanType: "",
      ValidFrom:"",
      ValidTo:""
    }
  }
  getDataFromSticky(show: boolean) {
    
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  handleChange1 = (e: any) => {
    
    this.setState({ ...this.state, GstTreatment: e, sticky_show: false })
  }
  handleChange = (e: any) => {
    
    if (e.target.name === 'ContactNumber') {
      if (e.target.value === "") {
        this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
      }
      else {
        const re = /^([1-9+][-0-9]*)$/;
        if (re.test(e.target.value)) {
          this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
        }
      }
    }
    else if (e.target.name === 'FirstName' || e.target.name === 'LastName' || e.target.name === 'CompanyCode') {
      const re = /^[a-zA-Z0-9\s]*$/;
      if (re.test(e.target.value)) {
        this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
      }
    }
    else if (e.target.name === 'CompanyCode') {
      const re = /^[a-zA-Z0-9]*$/;
      if (re.test(e.target.value)) {
        this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
      }
    }
    else if (e.target.name === 'ZohoSubscriptionID' || e.target.name === 'ZohoCusomerID') {
      const re = /^([1-9][0-9]*)$/;
      if (re.test(e.target.value)) {
        this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
      }
    }
    else {
      this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
    }
  };
  ClearDropdownData = () => {
    this.setState({
      sticky_show: false,
      BillingCountryDropdownData: [],
      BillingStateDropdownData: [],
      ShippingCountryDropdownData: [],
      ShippingStateDropdownData: [],
      CurrencyDropdownData: []
    })
  }
  
  BillingCountryAutoComplete = async (prefix: string) => {
    let BillingCountryAutoCompleteEntityObject: BillingCountryAutoCompleteEntityObject = {
      prefix: prefix,
    }
    if (prefix === '') {
      this.setState({ BCountryID: 0, BCountryValue: '', sticky_show: false })
    }
    this.ClearDropdownData();
    let res: any = await this.Service.GetCountry(BillingCountryAutoCompleteEntityObject)
    var getData = res.data.length ? res.data : [];
    //
    var tempData = [];
    if (res.status === 200) {

      for (var i = 0; i < getData.length; i++) {
        tempData.push({
          key: getData[i].CountryMasterID,
          value: getData[i].CountryName,
        });
      }
    }
    this.setState({ BillingCountryDropdownData: tempData, sticky_show: false, });
  }

  onSelectBillingCountry = (data: any) => {
    //console.log(data)
    var ID: number = data[0].key;
    this.setState({ BCountryID: data[0].key, BCountryValue: data[0].value, sticky_show: false })
    if (ID !== 5 && this.state.GstTreatment !== 'overseas' && this.state.GstTreatment !== 'GST Treatment')
      this.setState({ GstTreatment: 'overseas' })
  }
  BillingStateAutoComplete = async (prefix: string) => {
    //
    if (this.state.BCountryID === 0 || this.state.BCountryID === undefined || this.state.BCountryID === null) {
      this.stickyText(true, 'error', 'Error', 'Please select Country')
      return false
    }
    // this.stickyText(true, 'error', 'Error', 'Please contact skubiq team')
    let BillingStateAutoCompleteEntityObject: BillingStateAutoCompleteEntityObject = {
      CountryID: this.state.BCountryID,
      Prefix: prefix,
      Flag: ""
    }
    //
    this.ClearDropdownData();
    let res: any = await this.Service.GetState(BillingStateAutoCompleteEntityObject)
    var getData = res.data.length ? res.data : [];
    
    var tempData = [];
    var tempData1 = [];
    if (res.status === 200) {
      if (!getData.length) {
        this.stickyText(true, 'error', 'Error', 'No data found')
      }
      else {
        for (var i = 0; i < getData.length; i++) {
          tempData.push({
            key: getData[i].StateMasterID,
            value: getData[i].StateName,
          });
          tempData1.push({
            StateMasterID: getData[i].StateMasterID,
            StateName: getData[i].StateName,
            StateCode: getData[i].StateCode
          });
        }
        this.setState({ BillingStateDropdownData: tempData, sticky_show: false, BillingStateDropdownDataTemp: tempData1 });
      }
    }
  }
  onSelectBillingState = (data: any) => {
    var newArray = this.state.BillingStateDropdownDataTemp.filter(function (el: any) {
      return el.StateMasterID === data[0].key
    });
    
    this.setState({ BStateID: data[0].key, BStateValue: data[0].value, sticky_show: false, StateCode: newArray[0].StateCode })
  }
  ShippingCountryAutoComplete = async (prefix: string) => {
    let BillingCountryAutoCompleteEntityObject: BillingCountryAutoCompleteEntityObject = {
      prefix: prefix,
    }
    this.ClearDropdownData();
    let res: any = await this.Service.GetCountry(BillingCountryAutoCompleteEntityObject)
    var getData = res.data.length ? res.data : [];
    //
    var tempData = [];
    if (res.status === 200) {
      for (var i = 0; i < getData.length; i++) {
        tempData.push({
          key: getData[i].CountryMasterID,
          value: getData[i].CountryName,
        });
      }
    }
    this.setState({ ShippingCountryDropdownData: tempData, sticky_show: false, });
  }
  onSelectShippingCountry = (data: any) => {
    //console.log(data)
    this.setState({ SCountryID: data[0].key, SCountryValue: data[0].value, sticky_show: false })
  }
  ShippingStateAutoComplete = async (prefix: string) => {
    if (this.state.SCountryID === 0 || this.state.SCountryID === undefined || this.state.SCountryID === null) {
      this.stickyText(true, 'error', 'Error', 'Please select Country')
      return false
    }
    let BillingStateAutoCompleteEntityObject: BillingStateAutoCompleteEntityObject = {
      CountryID: this.state.SCountryID,
      Prefix: prefix,
      Flag: ""
    }
    //
    this.ClearDropdownData();
    let res: any = await this.Service.GetState(BillingStateAutoCompleteEntityObject)
    var getData = res.data.length ? res.data : [];
    // 
    var tempData = [];
    if (res.status === 200) {
      if (!getData.length) {
        this.stickyText(true, 'error', 'Error', 'No data found')
      } else {
        for (var i = 0; i < getData.length; i++) {
          tempData.push({
            key: getData[i].StateMasterID,
            value: getData[i].StateName,
          });
        }

        this.setState({ ShippingStateDropdownData: tempData, sticky_show: false });
      }

    }
  }
  onSelectShippingState = (data: any) => {
    //console.log(data)
    this.setState({ SStateID: data[0].key, SStateValue: data[0].value, sticky_show: false })
  }


  CurrencyAutoComplete = async (prefix: string) => {
    
    this.ClearDropdownData();
    var getData = [{ "currrency": "INR", "key": 1 },
    { "currrency": "USD", "key": 1 }]
    var tempData = [];
    for (var i = 0; i < getData.length; i++) {
      tempData.push({
        key: getData[i].key,
        value: getData[i].currrency,
      });
    }
    this.setState({ CurrencyDropdownData: tempData, sticky_show: false, });
  }
  onSelectCurrency = (data: any) => {
    //console.log(data)
    this.setState({ CurrencyId: data[0].key, CurrencyValue: data[0].value, sticky_show: false })
  }
  addresscheck(e: any) {
    
    var check = e.currentTarget.checked
    if (check === true) {
      this.setState({ shippingAddress: this.state.billingAddress, SCountryValue: this.state.BCountryValue, SStateValue: this.state.BStateValue, shippingCity: this.state.BillingCity, shipppingZipCode: this.state.BillingZipCode, sticky_show: false, SCountryID: this.state.BCountryID });
    }
    else if (check === false) {
      this.setState({ shippingAddress: "", SCountryValue: "", SStateValue: "", shippingCity: "", shipppingZipCode: "", sticky_show: false, SCountryID: 0 });
    }
    //   var shippingAddress=this.state.shippingAddress
  }

  submit = async () => {
    // debugger
    if (this.state.CompanyName === "") {
      this.stickyText(true, 'error', 'Error', 'Please enter Company Name');
      return false;
    }
    if (this.state.CompanyReg === "") {
      this.stickyText(true, 'error', 'Error', 'Please enter Company Reg. No.');
      return false;
    }
    if (this.state.IsPaid !== 'false') {
      if (this.state.GstTreatment === '' || this.state.GstTreatment === 'GST Treatment') {
        this.stickyText(true, 'error', 'Error', 'Please select Business GST');
        return false;
      }
      if (this.state.GstTreatment === 'business_gst' && this.state.TaxationNo === "") {
        this.stickyText(true, 'error', 'Error', 'Please enter Taxation No.(VAT/GST)');
        return false;
      }
      if (this.state.BCountryID === 5 && this.state.GstTreatment === 'overseas') {
        this.stickyText(true, 'error', 'Error', 'Please change Business GST type');
        return false;
      }
    }
    if (this.state.CurrencyValue === "") {
      this.stickyText(true, 'error', 'Error', 'Please select Currency');
      return false;
    }
    if (this.state.PlanID === "") {
      this.stickyText(true, 'error', 'Error', 'Please select Plan');
      return false;
    }
    if (this.state.ZohoCusomerID === 0) {
      this.stickyText(true, 'error', 'Error', 'Please select Zoho Cusomer ID');
      return false;
    }
    if (this.state.ZohoSubscriptionID === 0) {
      this.stickyText(true, 'error', 'Error', 'Please select Zoho Subscription ID');
      return false;
    }
    if (this.state.ValidFrom === "") {
      this.stickyText(true, 'error', 'Error', 'Please select Valid From');
      return false;
    }
    if (this.state.ValidTo === "") {
      this.stickyText(true, 'error', 'Error', 'Please select Valid To');
      return false;
    }
    if (this.state.BCountryValue === "") {
      this.stickyText(true, 'error', 'Error', 'Please Select Billing Country');
      return false;
    }
    if (this.state.BStateValue === "") {
      this.stickyText(true, 'error', 'Error', 'Please Select Billing State');
      return false;
    }
    if (this.state.BillingCity === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter Billing City');
      return false;
    }
    if (this.state.BillingZipCode === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter Billing Zip Code');
      return false;
    }
    if (this.state.billingAddress === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter Billing Address');
      return false;
    }
    if (this.state.SCountryValue === "") {
      this.stickyText(true, 'error', 'Error', 'Please Select Shipping Country');
      return false;
    }
    if (this.state.SStateValue === "") {
      this.stickyText(true, 'error', 'Error', 'Please Select Shipping State');
      return false;
    }
    if (this.state.shippingCity === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter Shipping City');
      return false;
    }
    if (this.state.shipppingZipCode === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter Shippping Zip Code');
      return false;
    }
    //return false;
    if (this.state.shippingAddress === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter Billing Address');
      return false;
    }

    else {
      if (this.state.PlanID) {
        if (this.state.PlanID === "SKU23-1W2U") {
          this.setState({ PlanType: "Lite" })
        }
        if (this.state.PlanID === "SKU22-1W5U") {
          this.setState({ PlanType: "Silver" })
        }
        if (this.state.PlanID === "SKU22-2W10U") {
          this.setState({ PlanType: "Gold" })
        }
        else {
          this.setState({ PlanType: "Platinum" })
        }
      }
      // var companydetails = JSON.parse(localStorage.getItem("companydetails")!)
      let submitdata: any = {
        displayname: '',
        firstname: this.state.FirstName,
        lastname: this.state.LastName,
        email: this.state.Email,
        password: this.state.Password,
        PlanCode: this.state.PlanID,
        billingStreet: this.state.billingAddress,
        billingCity: this.state.BillingCity,
        billingState: this.state.BStateValue,
        billingCountry: this.state.BCountryValue,
        billingZipCode: this.state.BillingZipCode,
        shippingStreet: this.state.shippingAddress,
        shippingCity: this.state.shippingCity,
        shippingState: this.state.SStateValue,
        shippingCountry: this.state.SCountryValue,
        shippingZipCode: this.state.shipppingZipCode,
        companyRegNo: this.state.CompanyReg,
        taxationNo: this.state.TaxationNo,
        companyname: this.state.CompanyName,
        mobileno: this.state.ContactNumber,
        currencycode: this.state.CurrencyValue,
        ipAddress: "",
        hostName: "",
        ZohoAccountId: this.state.ZohoSubscriptionID,
        ZohoCustomer_ID: this.state.ZohoCusomerID,
        ZOHOPlan: this.state.PlanType,
        status: "1",
        subscriptionid: this.state.ZohoSubscriptionID,
        billing_address: "",
        ActivatedOn: this.state.ValidFrom,
        ExpiryDate: this.state.ValidTo,
      }
      this.setState({ spin_loading: true, sticky_show: false })
      const res: any = await this.Service.AdminCreateAccountService(submitdata)
      // debugger
      this.setState({ spin_loading: false, sticky_show: false })
      
      if (res.status === 200) {
        if (res.data === 1) {
          this.stickyText(true, 'success', 'success', 'Account Created Successfully');
          setTimeout(() => {
            window.location.href = '/'
          }, 3000)
        }
        else {
          this.stickyText(true, 'error', 'error', res.data);
        }
      }
      else {
        this.stickyText(true, 'error', 'error', 'Error while Account Creation.');
      }
    }
  }
  previous = () => {
    this.setState({ IsSubmit: 0, sticky_show: false });
  }

  componentDidMount() {
    // debugger
    var URL = window.location.pathname;
    var myArray = URL.split('/');
    var PlanCode = myArray.length ? myArray[2] : "";
    var IsPaid = myArray.length ? myArray[3] : 'false';
    IsPaid = IsPaid === 'true' ? 'true' : 'false';
    this.setState({ PlanCode: PlanCode, IsPaid: IsPaid, sticky_show: false })

  }
  togglePassword1 = () => {
    setTimeout(() => {
      this.setState({
        passwordShown1: !this.state.passwordShown1, sticky_show: false
      })
    }, 2000);
    this.setState({
      passwordShown1: !this.state.passwordShown1, sticky_show: false
    })
  }
  togglePassword2 = () => {
    setTimeout(() => {
      this.setState({
        passwordShown2: !this.state.passwordShown2, sticky_show: false
      })
    }, 2000);
    this.setState({
      passwordShown2: !this.state.passwordShown2, sticky_show: false
    })
  }
  PlanID = (e: any) => {
    
    var PlanIDLevel = ""
    var val = e;
    if (val === 'SKU23-1W2U') {
      PlanIDLevel = 'SKU23-1W2U'
    }
    else if (val === 'SKU22-1W5U') {
      PlanIDLevel = 'SKU22-1W5U'
    }
    else if (val === 'SKU22-2W10U') {
      PlanIDLevel = 'SKU22-2W10U'
    }
    else if (val === 'SKU22-3W20U') {
      PlanIDLevel = 'SKU22-3W20U'
    }
    this.setState({ PlanIDLevel: PlanIDLevel, PlanID: val })
  }
  render() {
    return (
      <div className='Adminloginpagebg'>
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />
                :
                <></>
            }
          </>
        </div>
        <div className={this.state.spin_loading ? "spin_class_login" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>
        <div className="container container2">
          <div className="login-f3 creactaccount">
            <form action="#">
              <div className="title">Create Account</div>
              <div className='letsget'>
                <span>Let's get started with your Plan</span>
              </div>
              <Row gutter={16} className="inputRow">
                <Col span={8}>
                  <div className="divFlex">
                    <input
                      type="text"
                      required
                      name="FirstName"
                      maxLength={20}
                      value={this.state.FirstName}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <label><span className="required-label">*</span>First Name</label>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="divFlex">
                    <input
                      type="text"
                      required
                      name="LastName"
                      maxLength={20}
                      value={this.state.LastName}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <label><span className="required-label">*</span>Last Name</label>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="divFlex">
                    <input
                      type="text"
                      required
                      name="Email"
                      // maxLength={30}
                      value={this.state.Email}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <label><span className="required-label">*</span>Email</label>
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className="inputRow">
                <Col span={8}>
                  <div className="divFlex">
                    <input
                      type="text"
                      required
                      name="ContactNumber"
                      maxLength={16}
                      value={this.state.ContactNumber}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <label><span className="required-label">*</span>Contact Number</label>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="divFlex">
                    <input
                      type={this.state.passwordShown1 ? "text" : "password"}
                      required
                      name="Password"
                      maxLength={20}
                      value={this.state.Password}
                      onChange={this.handleChange}
                      autoComplete="off"
                    /><EyeFilled onClick={() => this.togglePassword1()} />
                    <label><span className="required-label">*</span>Password</label>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="divFlex">
                    <input
                      type={this.state.passwordShown2 ? "text" : "password"}
                      required
                      name="ConfirmPassword"
                      maxLength={20}
                      value={this.state.ConfirmPassword}
                      onChange={this.handleChange}
                      autoComplete="off"
                    /><EyeFilled onClick={() => this.togglePassword2()} />
                    <label><span className="required-label">*</span>Confirm Password</label>
                  </div>
                </Col>
              </Row>
              <div className="title">Company Information</div>
              <div>
              </div>
              <Row gutter={16} className="inputRow">
                <Col span={8}>
                  <div className="divFlex">
                    <input
                      type="text"
                      required
                      name="CompanyName"
                      maxLength={99}
                      value={this.state.CompanyName}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <label><span className="required-label">*</span>Company Name</label>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="divFlex">
                    <input
                      type="text"
                      required
                      name="CompanyReg"
                      maxLength={20}
                      value={this.state.CompanyReg}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <label><span className="required-label">*</span>Company Reg. No.</label>
                  </div>
                </Col>
                {
                  this.state.IsPaid !== 'false' ?
                    <Col span={8}>
                      <Select style={{ width: '100%' }}
                        placeholder="GST Treatment"
                        onChange={this.handleChange1}
                        value={this.state.GstTreatment}
                      >
                        <Option value="business_gst">Business GST</Option>
                        <Option value="business_none">Business None</Option>
                        <Option value="overseas">Overseas</Option>
                      </Select>
                    </Col> : <></>
                }
                {
                  this.state.GstTreatment === 'business_gst' ?
                    <Col span={8}>
                      <div className="divFlex">
                        <input
                          type="text"
                          required
                          name="TaxationNo"
                          maxLength={20}
                          value={this.state.TaxationNo}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>Taxation No.(VAT/GST)</label>
                      </div>
                    </Col> : <></>
                }
                <Col span={8}>
                  <div className="">
                    <Autocomplete
                      options={this.state.CurrencyDropdownData}
                      onValueSelect={this.onSelectCurrency}
                      defaultValue={this.state.CurrencyValue}
                      AutocompleteOnChange={this.CurrencyAutoComplete}
                      aPICallRequired={true}
                      PrefixMinLenght={0}
                      key={this.state.CurrencyValue}
                      label="Currency"
                    />
                    <SearchOutlined className="Icon_Align" />
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className="inputRow">
                <Col span={8}>
                  <Select style={{ width: '100%' }}
                    placeholder="Plans"
                    value={this.state.PlanIDLevel === "SKU23-1W2U" ? "SKU23-1W2U" :
                      this.state.PlanIDLevel === "SKU22-1W5U" ? "SKU22-1W5U" :
                      this.state.PlanIDLevel === "SKU22-2W10U" ? 'SKU22-2W10U' :
                        this.state.PlanIDLevel === "SKU22-3W20U" ? 'SKU22-3W20U' : this.state.PlanIDLevel
                    }
                    onChange={(event: any) => this.PlanID(event)}>
                      <Option value="SKU23-1W2U" >Lite (SKU23-1W2U)</Option>
                    <Option value="SKU22-1W5U" >Sliver (SKU22-1W5U)</Option>
                    <Option value="SKU22-2W10U" >Gold (SKU22-2W10U)</Option>
                    <Option value="SKU22-3W20U" >Platinum (SKU22-3W20U)</Option>
                  </Select>
                </Col>
                <Col span={8}>
                  <div className="divFlex">
                    <input
                      type="text"
                      required
                      name="ZohoCusomerID"
                      maxLength={20}
                      value={this.state.ZohoCusomerID === 0 ? "" : this.state.ZohoCusomerID}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <label><span className="required-label">*</span>Zoho Customer ID</label>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="divFlex">
                    <input
                      type="text"
                      required
                      name="ZohoSubscriptionID"
                      maxLength={20}
                      value={this.state.ZohoSubscriptionID === 0 ? "" : this.state.ZohoSubscriptionID}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <label><span className="required-label">*</span>Zoho Subscription ID</label>
                  </div>
                </Col>
              </Row>
              <Row gutter={16} >
              <Col span={8}>
              <div className="divFlex divFlexDate">
                <input
                  type="date"
                  required
                  autoComplete="off"
                  name="ValidFrom"
                  value={
                    this.state.ValidFrom !== "Invalid date" ||
                      this.state.ValidFrom !== null
                      ? moment(this.state.ValidFrom).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <label><span className="required-label">*</span>Valid From</label>
                <CalendarOutlined className="Icon_Align" />
                <span className="required-label"></span>
              </div>
              </Col>
              <Col span={8}>
              <div className="divFlex divFlexDate">
                <input
                  type="date"
                  required
                  autoComplete="off"
                  min={this.state.ValidFrom}
                  name="ValidTo"
                  value={
                    this.state.ValidTo !== "Invalid date" ||
                      this.state.ValidTo !== null
                      ? moment(this.state.ValidTo).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <label><span className="required-label">*</span>Valid To</label>
                <CalendarOutlined className="Icon_Align" />
                <span className="required-label"></span>
              </div>
              </Col>
              </Row>
              <Row gutter={16} className="inputRow">
                <Col span={12}>
                  <span className='billing' >
                    Billing Address
                  </span>
                  <Row gutter={16} className="billingbottomrow">
                    <Col span={12}>
                      <Autocomplete
                        options={this.state.BillingCountryDropdownData}
                        onValueSelect={this.onSelectBillingCountry}
                        defaultValue={this.state.BCountryValue}
                        AutocompleteOnChange={this.BillingCountryAutoComplete}
                        aPICallRequired={true}
                        PrefixMinLenght={0}
                        key={this.state.BCountryValue}
                        label="Country"
                      />
                      <SearchOutlined className="Icon_Align" />
                    </Col>
                    <Col span={12}>
                      <Autocomplete
                        options={this.state.BillingStateDropdownData}
                        onValueSelect={this.onSelectBillingState}
                        defaultValue={this.state.BStateValue}
                        AutocompleteOnChange={this.BillingStateAutoComplete}
                        aPICallRequired={true}
                        PrefixMinLenght={0}
                        key={this.state.BStateValue}
                        label="State"
                      />
                      <SearchOutlined className="Icon_Align" />
                    </Col>
                    <Col span={12}>
                      <div className="divFlex inputRow">
                        <input
                          type="text"
                          required
                          name="BillingCity"
                          maxLength={20}
                          value={this.state.BillingCity}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>City</label>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="divFlex inputRow">
                        <input
                          type="text"
                          required
                          name="BillingZipCode"
                          maxLength={20}
                          value={this.state.BillingZipCode}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>Zip Code</label>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="divFlex inputRow">
                        <input
                          type="text"
                          required
                          name="billingAddress"
                          // maxLength={20}
                          value={this.state.billingAddress}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>Address</label>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <span className='billing' style={{ paddingRight: "50px" }}>
                    Shipping Address
                  </span>
                  <span>
                    <input type="checkbox" onChange={(e) => this.addresscheck(e)} style={{ marginRight: "10px" }} />
                    Same as Billing Address
                  </span>
                  <br />
                  <Row gutter={16} className="billingbottomrow">
                    <Col span={12}>
                      <Autocomplete
                        options={this.state.ShippingCountryDropdownData}
                        onValueSelect={this.onSelectShippingCountry}
                        defaultValue={this.state.SCountryValue}
                        AutocompleteOnChange={this.ShippingCountryAutoComplete}
                        aPICallRequired={true}
                        PrefixMinLenght={0}
                        key={this.state.SCountryValue}
                        label="Country"
                      />
                      <SearchOutlined className="Icon_Align" />
                    </Col>
                    <Col span={12}>
                      <Autocomplete
                        options={this.state.ShippingStateDropdownData}
                        onValueSelect={this.onSelectShippingState}
                        defaultValue={this.state.SStateValue}
                        AutocompleteOnChange={this.ShippingStateAutoComplete}
                        aPICallRequired={true}
                        PrefixMinLenght={0}
                        key={this.state.SStateValue}
                        label="State"
                      />
                      <SearchOutlined className="Icon_Align" />
                    </Col>
                    <Col span={12}>
                      <div className="divFlex inputRow">
                        <input
                          type="text"
                          required
                          name="shippingCity"
                          maxLength={20}
                          value={this.state.shippingCity}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>City</label>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="divFlex inputRow">
                        <input
                          type="text"
                          required
                          name="shipppingZipCode"
                          maxLength={20}
                          value={this.state.shipppingZipCode}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>Zip Code</label>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="divFlex inputRow">
                        <input
                          type="text"
                          required
                          name="shippingAddress"
                          // maxLength={20}
                          value={this.state.shippingAddress}
                          onChange={this.handleChange}
                          autoComplete="off"
                        />
                        <label><span className="required-label">*</span>Address</label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
            <div style={{ marginTop: "20px" }} >
              <span className='btn-right' style={{ float: "right" }} onClick={() => this.submit()}>
                Submit
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
