import { END_POINT_MasterData, END_POINT_SSO } from './EndPoints';
import { AxiosService } from './AxiosSetup';
import { CheckEmail, CheckOTP,
    CreateEntitobject,
    GetSubscriptionDataEntityObject,
    GetManageAddOnDataEntityObject,
    BillingCountryAutoCompleteEntityObject,
    BillingStateAutoCompleteEntityObject,
    TransationEntityObject,
    CancelSubscriptionEntityObject,
    UpdateSubscriptionPalnEntityObject,
    GetInvoiceURLEntityObject
} from './Interface';

export class Service {
    AxiosService: AxiosService;
    constructor() {
        this.AxiosService = new AxiosService();
    }
    async CheckEmailService(CheckEmail: CheckEmail) {
        // debugger
        return await this.AxiosService.common(END_POINT_SSO, 'api/Account/CheckEmailCode', CheckEmail);
    }
    async CheckOTPService(CheckOTP:CheckOTP) {
        return await this.AxiosService.common(END_POINT_SSO,'api/Account/ValidateTrailOTP',CheckOTP);
    }
    async GetCountry(BillingCountryAutoCompleteEntityObject:BillingCountryAutoCompleteEntityObject) {
        return await this.AxiosService.common(END_POINT_SSO,'api/masterdata/GetCountry',BillingCountryAutoCompleteEntityObject);
    }
    async GetMasterPlansList(MasterPlansAutoCompleteEntityObject:BillingCountryAutoCompleteEntityObject) {
        //  debugger
        return await this.AxiosService.common(END_POINT_SSO,'api/masterdata/GetMasterPlansList',MasterPlansAutoCompleteEntityObject);
    }

    async GetState(BillingStateAutoCompleteEntityObject: BillingStateAutoCompleteEntityObject) {
        return await this.AxiosService.common(END_POINT_SSO, 'api/masterdata/GetState', BillingStateAutoCompleteEntityObject);
    }
    async CreateAccountService(CreateAccountEntityObject: CreateEntitobject) {
        // debugger
        return await this.AxiosService.common(END_POINT_SSO, 'api/Account/CreateAccount', CreateAccountEntityObject);
    }
    async AdminCreateAccountService(AdminCreateAccountEntityObject:CreateEntitobject) {
        // debugger
        return await this.AxiosService.common(END_POINT_SSO,'api/Account/AdminCreateAccount',AdminCreateAccountEntityObject);
    }
    async CheckLoginService(LoginEntityObject:any) {
        return await this.AxiosService.login(END_POINT_SSO,'connect/token',LoginEntityObject);
    }
    //after login
    async GetLoginService() {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/GetLoginData', "");
    }
    async GetInvoiceDataService(InvoiceEntity: TransationEntityObject) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/GetInvoiceData', InvoiceEntity);
    }
    async GetTransactionDataService(transationentity: TransationEntityObject) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/GetTransactionData', transationentity);
    }
    async GetSubscriptionDataService(transationentity: GetSubscriptionDataEntityObject) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/GetSubscriptionData', transationentity);
    }
    async GetManageAddOnDataService(transationentity: GetManageAddOnDataEntityObject) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/GetManageAddOnData', transationentity);
    }
    async GetManagePlanDataService(TransationEntityObject: any) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/GetManagePlanData', TransationEntityObject);
    }
    async GetProfileDataService(TransationEntityObject: any) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/GetProfileData', TransationEntityObject);
    }
    async GetAdminDashboardDataService() {
        return await this.AxiosService.post(END_POINT_SSO,'api/masterdata/GetAccounts',"");
    }
    async GetDatabaseDeatilsService() {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/GetDatabaseDeatils', "");
    }
    async SaveAWSAccountCreationService(AWSAccountEntityObject: any) {
        return await this.AxiosService.post(END_POINT_MasterData, 'Account/CreateWMSAccountCreation', AWSAccountEntityObject)
    }
    async CancelSubscriptionService(CancelSubscriptionEntityObject: CancelSubscriptionEntityObject) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/CancelSubscriptionPaln', CancelSubscriptionEntityObject)
    }
    async GetDBAccountsService(DBAccountsEntityObject: any) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/GetListofDBAccounts', DBAccountsEntityObject);
    }

    async UpdateSubscriptionPalnService(UpdateSubscriptionPalnEntityObject: UpdateSubscriptionPalnEntityObject) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/UpdateSubscriptionPaln', UpdateSubscriptionPalnEntityObject)
    }
    async UpdateProfileDataService(UpdateProfileDataService: any) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/UpdateProfileData', UpdateProfileDataService)
    }
    async GetInvoiceURLService(GetInvoiceURLEntity: GetInvoiceURLEntityObject) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/GetInvoiceURL', GetInvoiceURLEntity)
    }
    // after login Change Password
    async ChangePasswordService(ChangePasswordEntityObject: any) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/ChangePassword', ChangePasswordEntityObject)
    }
    async ForgetPasswordService(TransationEntityObject: any) {
        return await this.AxiosService.forget_Password(END_POINT_SSO, 'api/Account/ForgetPassword', TransationEntityObject);
    }
    async ResetPasswordService(ResetPasswordEntityObject: any) {
        return await this.AxiosService.reset_Password(END_POINT_MasterData, 'Common/ChangePassword', ResetPasswordEntityObject)
    }
    async UpdateAddonService(ManageAddOnDataEntityObject: any) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/UpdateAddOnData', ManageAddOnDataEntityObject)
    }
    async GetExpiredSubscriptionDetailsService(SubscriptionEntityObject: any) {
        return await this.AxiosService.reset_Password(END_POINT_MasterData, 'Account/GetExpairedSubscriptionDetails', SubscriptionEntityObject);
    }
    async AddonsPalnService(UpdateSubscriptionPalnEntityObject: UpdateSubscriptionPalnEntityObject) {
        return await this.AxiosService.post(END_POINT_SSO, 'api/Account/AddonsPalnService', UpdateSubscriptionPalnEntityObject)
    }
}