import React, { Component } from "react";
import "../loginpage/login.css";
import Home_Icon_WH from './../../Assests/img/Home_Icon_WH.svg';
import "../../Modules/Common/AutoComplete/MerlinAutoComplete.css"
import  "../AccountDetails/AccountDetails.css"

interface LoginEntity {
  username: any;
  password: string;
}
export default class AccountDetails extends Component<any, LoginEntity> {
//   private Service: Service;
  constructor(props: any) {
    super(props);
    // this.Service = new Service();
    this.state = {
      username: "",
      password: "",
    };
  }
  
 
  render() {
    return (
      <div className="loginpagebg">
        <div className="container loginpage">
          {/* <Link to='/'> */}
            <span className="homestyle">
              <img src={Home_Icon_WH} alt="Home_Icon_WH" />
            </span>
          {/* </Link> */}
          <div className="login">
            <div className="title">
              {/* <img src={logo} style={{ marginBottom: "0px" }} alt=""/> */}
            </div>

            <form action="#">
              <div className="title">Account Details</div>

              <div className="input-box">
                <div className="divFlex">
                  <input
                    type="text"
                    required
                    name="username"
                    // maxLength={20}
                    // value={WarehouseDetails.WHName}
                    // onChange={this.handleChange}
                    autoComplete="off"
                  />
                  <label>First Name </label>
                  <span className="required-label"></span>
                </div>
              </div>

              <div className="input-box">
                <div className="divFlex">
                  <input
                    type="text"
                    required
                    name="password"
                    // maxLength={20}
                    // value={WarehouseDetails.WHName}
                    // onChange={this.handleChange}
                    autoComplete="off"
                  // iconRender={(visible: any) =>
                  //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                  />
                  <label className="active">Last Name </label>

                  <span className="required-label"></span>
                </div>
              </div>
              <div className="input-box">
                <div className="divFlex">
                  <input
                    type="password"
                    required
                    name="password"
                    // maxLength={20}
                    // value={WarehouseDetails.WHName}
                    // onChange={this.handleChange}
                    autoComplete="off"
                  // iconRender={(visible: any) =>
                  //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                  />
                  <label className="active">Company Name </label>

                  <span className="required-label"></span>
                </div>
              </div>
              <div className="input-box">
                <div className="divFlex">
                  <input
                    type="password"
                    required
                    name="password"
                    // maxLength={20}
                    // value={WarehouseDetails.WHName}
                    // onChange={this.handleChange}
                    autoComplete="off"
                  // iconRender={(visible: any) =>
                  //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                  />
                  <label className="active">Mobile NO(Primary) </label>

                  <span className="required-label"></span>
                </div>
              </div>
              <div className="input-box">
                <div className="divFlex">
                  <input
                    type="password"
                    required
                    name="password"
                    // maxLength={20}
                    // value={WarehouseDetails.WHName}
                    // onChange={this.handleChange}
                    autoComplete="off"
                  // iconRender={(visible: any) =>
                  //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                  />
                  <label className="active">Currency </label>

                  <span className="required-label"></span>
                </div>
              </div>
              <br />

              {/* <div className="input-box button">
                <input type="button" name="" value="Sign In" onClick={() =>this.Login()}/>
              </div> */}
            </form>
            {/* <div className="option">or Connect With Social Media</div> */}
            <div style={{ display: "flow-root", marginTop: "10px" }}>
              <span className="btn-left">
               Back to Login
              </span>
              <span
                className="btn-right"
                // onClick={() => this.Login()}
                style={{ float: "right" }}
              >
                save <i className="material-icons right"></i>
              </span>
            </div>
        
          </div>
        </div>
      </div>
    );
  }
}
