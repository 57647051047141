import React, { Component } from "react";
import FooterPagesHeader from "../FooterPagesHeader";
import FooterPagesFooter from "../FooterPagesFooter";
import "./../Footer.css";
export default class Support extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <>
        <FooterPagesHeader text="SUPPORT " />

        <section className="content-block">
        <div className="container">
            <article className="FooterArticle">
              <h3>SUPPORT</h3>
              <p>
                SKUBIQ Tech Support team provides extensive WMS support for all
                clients.
              </p>
              <p>
                Whilst we provide the training you need at the implementation
                stage, we know there will be occasions when you might require
                additional technical WMS help and advice later on.
              </p>
              <p>
                We’re With You Every Step of the Way.This means we provide you
                with a dedicated, experienced and friendly WMS support team, who
                are available via phone, email, or through our online portal.
              </p>
              <p>
                All queries are dealt with quickly, efficiently and courteously.
              </p>
            </article>
            <article>
              <h3>Standard Support</h3>
              <ul>
                <li>
                  <p>
                    Inventrax will provide Standard technical support as long as
                    Customer is current in payment of applicable fees pursuant
                    to the Purchase Order to the Agreement and is otherwise
                    materially compliant with the terms and conditions of the
                    Agreement for the applicable Subscription Term.
                  </p>
                </li>
                <li>
                  <p>
                    Technical Support for the initial 3 tickets is complimentary
                    during the subscription. Any further requests (Tickets) from
                    the customer will be charged as per the severities of the
                    issues complying to the Technical Support policy. Standard
                    Support Services ("Standard Support") entitles the Customer
                    to the following:
                  </p>
                </li>
                <li>
                  <p>
                    Telephone or electronic support in order to help the
                    Customer locate and correct problems with the Software.
                  </p>
                </li>
                <li>
                  <p>
                    Bug fixes and code corrections to correct Software
                    malfunctions in order to bring the Service into substantial
                    conformity with the operating specifications.
                  </p>
                </li>
                <li>
                  <p>
                    All extensions, enhancements and other changes that the
                    Company makes or adds to the Service and which the Company
                    offers, without charge, to all other Subscribers of the
                    Service.
                  </p>
                </li>
                <li>
                  <p>
                    Up to [NUMBER OF DEDICATED CONTACTS] dedicated contacts
                    designated by the Customer in writing that will have access
                    to support services.
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>Response and Resolution Goals</h3>
              <div className="tbl">
                <table className="table supportTable" style={{overflowX:'scroll'}}>
                  <tbody>
                    <tr>
                      <td className="fixedCol">
                        <p>Incident Severity</p>
                      </td>
                      <td>
                        <p>1</p>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="fixedCol">
                        <p>Initial Response Time</p>
                      </td>
                      <td>
                        <p>1 hour*</p>
                      </td>
                      <td>
                        <p>2 business hours*</p>
                      </td>
                      <td>
                        <p>4 business hours*</p>
                      </td>
                      <td>
                        <p>8 business hours*</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  During normal business hours as stated on SKUBIQ Support
                  online.*
                </p>
                <p>
                  Applies only to issues submitted through the{" "}
                  <a style={{ color: "#000", fontWeight: "600" }} href="# ">
                    Support Portal
                  </a>
                  ,{" "}
                  <a
                    style={{ color: "#000", fontWeight: "600" }}
                    href="mailto:"
                  >
                    Mail
                  </a>
                  .**
                </p>

                <table className="table supportTable"style={{overflowX:'scroll'}}>
                  <tbody>
                    <tr>
                      <td className="fixedCol">
                        <p>Severity 1</p>
                      </td>
                      <td>
                        <p>Mission critical function not available.</p>
                        <p>System crashes.</p>
                        <p>
                          System hangs indefinitely causing unacceptable or
                          indefinite delays for resources or response.
                        </p>
                        <p>Data corruption.</p>
                      </td>
                    </tr>
                    <tr>
                      <td className="fixedCol">
                        <p>Severity 2</p>
                      </td>
                      <td>
                        <p>
                          High-impact business condition possibly endangering a
                          production environment. The software may operate but
                          is severely restricted.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="fixedCol">
                        <p>Severity 3</p>
                      </td>
                      <td>
                        <p>
                          Low-impact business condition with a majority of
                          software functions still usable however, some
                          circumvention may be required to provide service.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="fixedCol">
                        <p>Severity 4</p>
                      </td>
                      <td>
                        <p>
                          Minor problem or question that does not affect the
                          software function.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </article>
          </div>
        </section>
        <FooterPagesFooter />
      </>
    );
  }
}
