// const crypto = require('crypto');
// const crypto =  require.resolve("crypto-browserify")
// const key = 'abcdefghijklmnop';
// const alg = 'des-ede-cbc';
// const cskey = 'QUJDREVGR0g=';

// export class AesEncryption {
//   AesKey: string = "";
//   AesIV: string = "";
//   init() {
//     this.AesKey = 'ABCDEFGHJKLMNOPQRSTUVWXYZABCDEF';
//     this.AesIV = 'ABCDEFGHIJKLMNOP';
//   }
//   static encrypt(text: any) {
//     
//     try {
//     //   var text = JSON.stringify(text1);
//       var crypto = require('crypto');
//       var alg = 'des-ede-cbc';
//       var key1 = new Buffer(key, 'utf-8');
//       var iv = new Buffer('QUJDREVGR0g=', 'base64');    //This is from c# cipher iv
//       var cipher = crypto.createCipheriv(alg, key1, iv);
//       var encoded = cipher.update(text, 'ascii', 'base64');
//       encoded += cipher.final('base64');
//       return encoded;
//     }
//     catch {
//       return 0;
//     }
//   }
// }

const CryptoJS = require("crypto-js");

export class AesEncryption { 
    static encrypt(text: any) {
        return CryptoJS.AES.encrypt(JSON.stringify(text), 'my-secret-key@123').toString();
    }
    static decrypt(text: any) {
        var bytes = CryptoJS.AES.decrypt(text, 'my-secret-key@123');
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    static encrypt1(text: any) {
        
        return CryptoJS.AES.encrypt(JSON.stringify(text), 'abcdefgh').toString();
    }
}