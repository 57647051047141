import React, { Component } from "react";
import "../loginpage/login.css";
import Home_Icon_WH from './../../Assests/img/Home_Icon_WH.svg';
import "../../Modules/Common/AutoComplete/MerlinAutoComplete.css"
import "../AccountDetails/AccountDetails.css"
import { NavLink } from "react-router-dom";
import { Col, Row } from "antd";
import { EyeFilled } from "@ant-design/icons";
import StickyText from "../Common/StikeyText";
import { Service } from "../Common/Services.service";

interface LoginEntity {
  ConfirmPassword: string;
  Password: string;

  passwordShown1: boolean
  passwordShown2: boolean

  Email: string

  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
}
export default class Resetpassword extends Component<any, LoginEntity> {
  private Service: Service;
  constructor(props: any) {
    super(props);
    this.Service = new Service();
    this.state = {
      ConfirmPassword: "",
      Password: "",
      passwordShown1: false,
      passwordShown2: false,
      Email:'',
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: ''
    };
  }
  getDataFromSticky(show: boolean) {
    
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  ResetPasswod = async () => {
    
    if (this.state.Password === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter Password');
      return false;
    }
    var regularExpretion = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/;
    if (this.state.Password.length < 8 || this.state.Password.length > 16) {
      this.stickyText(true, 'error', 'Error', 'Weak password! Password must be between 8 Char to 16 Char');
      return false;
    }
    if (!this.state.Password.match(regularExpretion)) {
      this.stickyText(true, 'error', 'Error', 'Password must contain at least 8 characters, including 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character(!@#$%^&*)');
      return false;
    }
    if (this.state.ConfirmPassword === "") {
      this.stickyText(true, 'error', 'Error', 'Please Enter Confirm Password');
      return false;
    }
    if (this.state.ConfirmPassword.length < 8 || this.state.ConfirmPassword.length > 16) {
      this.stickyText(true, 'error', 'Error', 'Weak password! Password must be between 8 Char to 16 Char');
      return false;
    }
    if (!this.state.ConfirmPassword.match(regularExpretion)) {
      this.stickyText(true, 'error', 'Error', 'Confirm Password must contain at least 8 characters, including 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character(!@#$%^&*)');
      return false;
    }
    if (this.state.Password !== this.state.ConfirmPassword) {
      this.stickyText(true, 'error', 'Error', 'Please Check Password');
      return false;
    }
    var ResetPasswordEntityObject = {
      Email : this.state.Email,
      Password : this.state.Password
    }
    const res : any = await this.Service.ResetPasswordService(ResetPasswordEntityObject);
    if(res.status === 200) {
      var GetData = res.data.entityObject.result;
      GetData = GetData ? JSON.parse(res.data.entityObject.result) : 0;
      var ID = GetData ? GetData[0].N : 0;
      // var ID = GetData ? Number(GetData[0]) : 0 ;
      if(ID === 1) {
        this.stickyText(true, 'success', 'Success', 'Password Changed Successfully');
        setTimeout(() => {
          window.location.href = "/Login";
        },3000)
        return false;
      }
      if(ID === 2) {
        this.stickyText(true, 'error', 'Error', 'Invlid Email');
        setTimeout(() => {
          window.location.href = "/Login";
        },3000)
        return false;
      }
    }
  }
  handleChange = (e: any) => {
    
    this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
  };
  togglePassword1 = () => {
    setTimeout(() => {
      this.setState({
        passwordShown1: !this.state.passwordShown1,sticky_show:false
      })
    }, 2000);
    this.setState({
      passwordShown1: !this.state.passwordShown1,sticky_show:false
    })
  }
  togglePassword2 = () => {
    setTimeout(() => {
      this.setState({
        passwordShown2: !this.state.passwordShown2,sticky_show:false
      })
    }, 2000);
    this.setState({
      passwordShown2: !this.state.passwordShown2,sticky_show:false
    })
  }

  componentDidMount() {
    
    document.title = "SKUBIQ® - Reset Password";
    var URL = window.location.pathname;
    try {
      if (URL === "/Resetpassword") {
        
        window.localStorage.clear();
        // sessionStorage.clear()
        var URL1 = window.location.href;
        var myArray = URL1.split('e1yes2=');
        var params = myArray.length ? myArray[1] : ""
        if (params !== undefined && params.length) {
          this.setState({Email : myArray[1]});
        }
      }
    }
    catch {
      window.location.href = "/"
    }
  }

  render() {
    return (
      <div className="loginpagebg">
        <div className="container loginpage">
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />
                :
                <></>
            }
          </>
        </div>
          <span className="homestyle">
            <img src={Home_Icon_WH} alt="Home_Icon_WH" />
          </span>
          <div className="login">
            <div className="title">
              {/* <img src={logo} style={{ marginBottom: "0px" }} alt=""/> */}
            </div>

            <form action="#">
              <div className="title">Reset Password</div>

              <Row gutter={16} className="inputRow">
                <Col span={24}>
                  <div className="divFlex">
                    <input
                      type={this.state.passwordShown1 ? "text" : "password"}
                      required
                      name="Password"
                      maxLength={20}
                      value={this.state.Password}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <EyeFilled onClick={() => this.togglePassword1()} />
                    <label>Password</label>
                    <span className="required-label"></span>
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className="inputRow">
                <Col span={24}>
                  <div className="divFlex">
                    <input
                      type={this.state.passwordShown2 ? "text" : "password"}
                      required
                      name="ConfirmPassword"
                      maxLength={20}
                      value={this.state.ConfirmPassword}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    <EyeFilled onClick={() => this.togglePassword2()} />
                    <label>Confirm Password</label>
                    <span className="required-label"></span>
                  </div>
                </Col>
              </Row>
              <br />
            </form>
            <div>
              <div style={{ marginTop: "10px" }}>
                <NavLink to="/Login"><span className='btn-left'>Back to Login</span></NavLink>
                <span className='btn-right' style={{ float: "right" }} onClick={() => this.ResetPasswod()}>Change</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
