import { Result } from 'antd'
import React, { Component } from 'react'
import { Service } from '../Common/Services.service';


export default class Successfully extends Component {
  private Service: Service;
  constructor(props: any) {
    super(props);
    this.Service = new Service();
  }
  // Backtologin(){
  //   setTimeout(() => { window.location.href = '/Login';},2000)
  // }
  // Backtohome(){
  //   setTimeout(() => { window.location.href = '/Home';},2000)
  // }
  componentDidMount = async () => {
    setTimeout(() => { window.location.href = '/Login';},3000)

  }
  render() {
    return (
      <div className="loginpagebg">
        <div className="container loginpage">
          <div className="login">
            <Result
              status="success"
              title="Congratulations !"
            />
          </div>
        </div>
      </div>
    )
  }
}
