import React, { Component } from "react";
import FooterPagesHeader from "../FooterPagesHeader";
import FooterPagesFooter from "../FooterPagesFooter";
import { Collapse } from "antd";

const { Panel } = Collapse;

export default class faq extends Component {

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <>
        <FooterPagesHeader text="Frequently Asked Questions" />

        {/* <!-- key features --> */}

        <section className="faq-area pt-50 pb-50 FooterArticle FAQ" id="Features">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="faq-wrap">
                  <Collapse bordered={false} defaultActiveKey={['1']} accordion>
                    <Panel header="What is SKUBIQ Warehouse Management System?" key="1">
                      <p>SKUBIQ is a robust cloud based Warehouse Management
                        System with quality & traceability engines built
                        into it. It is extendable, scalable and adaptable to
                        the size and needs of any business. SKUBIQ SaaS
                        Platform lets you optimize your business processes,
                        make more informed decisions, accelerate order
                        fulfillments, Increase efficiency and delight your
                        customers.</p>
                    </Panel>
                    <Panel header="Why SKUBIQ over other WMS systems?" key="2">
                      <p>Extremely robust, specially designed to make it
                        customer/user friendly. With its unique SKUBIQ
                        Intelli-Suggest, Smart Pick and Accu-Bill
                        functionalities, Algorithms to identify effecient
                        Shortest and Optimal traversal path we significantly
                        improve your warehouse performance and reduce costs.</p>
                    </Panel>
                    <Panel header="Is it cost Beneficiary?" key="3">
                      <p>Yes, very much. Use of SKUBIQ in the warehouse
                        operations cuts down the operational time and cost
                        drastically, optimizes the user space there by
                        bringing in productivity and saving costs.</p>
                    </Panel>

                    <Panel header="What are the market sectors that SKUBIQ has experience in providing warehouse systems for?" key="4">
                      <p>The SKUBIQ core engine has been designed with
                        enormous flexibility and a dynamic platform hence
                        supporting multiple industries, multiple SKU
                        Attributes and configurations with customizations if
                        necessary.</p>
                    </Panel>
                    <Panel header="Typically, what size of warehouse is the best fit for the SKUBIQ Warehouse Management System?" key="5">
                      <p>The SKUBIQ Warehouse Management System (WMS) is
                        fully scalable and can fit any size of warehouse
                        from a business startup operation to mid-level
                        warehouses.</p>
                    </Panel>
                    <Panel header="Are your systems available On-Premise or are Browser Based served over the Internet?" key="6">
                      <p>SKUBIQ is a SaaS product alone. For On-Premise
                        implementations, we have other Dedicated cloud
                        deployment products specifically build for
                        On-Premises.</p>
                    </Panel>

                    <Panel header="Why buy a WMS when my system already works and gets the job done." key="7">
                      <p>SKUBIQ minimizes the maintenance cost and customizes
                        the application to your business requirements. It
                        makes it flexible for new employees to learn. Any
                        challenging modifications and additions can be
                        easily adaptable.</p>
                    </Panel>
                    <Panel header="What are the additional add on’s. How much do they cost?" key="8">
                      <p>Addons can be procured per user, per warehouse or
                        based on certain other features. Additional
                        log-in’s, hardware etc. could be provided at a cost.
                        For further details please refer the add-on’s
                        section once you‘re done with the registration.</p>
                    </Panel>
                    <Panel header="Can SKUBIQ be customized according to my requirements?" key="9">
                      <p>Yes, SKUBIQ is designed in such a way to address
                        unique requirements in every business organization.
                        SKUBIQ (1.0) is a SaaS product with limited scope
                        for customization. Exclusive licenses of FalconWMS
                        needs to be rolled out for Implementations that
                        require Customization. However, future versions of
                        the product running on the Enterprise Core will have
                        a 100% flexibility to configure workflows as well!
                        We understand that the work process in each
                        organization is different so, we designed SKUBIQ to
                        suit your flexible needs.</p>
                    </Panel>

                    <Panel header="What sort of post-implementation support is provided?" key="10">
                      <p>SKUBIQ implementations come with one month of free
                        support after go-live. Post this period, you may
                        choose to opt for our Annual Maintenance Contract
                        (AMC) that will ensure you get access to trained
                        personnel for resolving Support Requests. With an
                        AMC, you will also receive latest fixes and service
                        packs as and when they are available.</p>
                    </Panel>
                    <Panel header="Can I run my warehouse operations in SKUBIQ without using handheld tools" key="11">
                      <p>Yes, SKUBIQ has web interfaces for all major
                        functionalities. However it is not a recommended
                        choice as it would not eradicate the possiblities of
                        errors.</p>
                    </Panel>
                    <Panel header="Can SKUBIQ be used by my partners and vendors with limited access rights" key="12">
                      <p>Yes, SKUBIQ provides various role based services
                        with limited access at a profitable cost.</p>
                    </Panel>

                    <Panel header="Is it possible to integrate the SKUBIQ system with my Supplier’s system?" key="13">
                      <p>Yes, it is treated as a customization.</p>
                    </Panel>
                    <Panel header="How do I Start/Register?" key="14">
                      <p>Go to the Registration page, fill the required
                        details, make payment if necessary and get started
                        with the SKUBIQ .</p>
                    </Panel>
                    <Panel header="How Secure is the SKUBIQ?" key="15">
                      <p>SKUBIQ is designed with best possible measures like
                        secure socket layer (SSL) and Web application
                        Firewalls (WAF) modules to address the security
                        challenges.</p>
                    </Panel>

                    <Panel header="Can SKUBIQ be used by my partners and vendors with limited access rights" key="16">
                      <p>At SKUBIQ/Inventrax, we take special care to ensure
                        your account details. Payment solutions are run on
                        secure SSL, PCI-DSS award-winning gateways with end
                        to end encryption layers.</p>
                    </Panel>
                    <Panel header="Is my payment gateway secure?" key="17">
                      <p>At SKUBIQ/Inventrax, we
                        take special care to ensure your account details.
                        Payment solutions are run on secure SSL, PCI-DSS
                        award-winning gateways with end to end
                        encryption layers.</p>
                    </Panel>
                    <Panel header="How do I cancel my registration after payment?" key="18">
                      <p>Once the payment is done, Registration cannot be
                        cancelled or the amount is not refunded. But, the
                        plans can be changed from one to another during the
                        usage of the SKUBIQ application.</p>
                    </Panel>

                    <Panel header="How do I know if my payment is done" key="19">
                      <p>Once your payment is successfully done you will
                        receive a confirmation mail to the registered mail
                        ID stating your subscription details along with the
                        account credentials.</p>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterPagesFooter />
      </>
    );
  }
}
