import { Table } from 'antd'
import React, { Component } from 'react'
import { Service } from '../Common/Services.service';
import { reactLocalStorage } from 'reactjs-localstorage';
import StickyText from '../Common/StikeyText';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';

interface GetAccountsData {
  GetDBAccounts: any
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
  spin_loading: boolean
}
export default class DBAccounts extends Component<any, GetAccountsData> {
  public columns: any = []
  private Service: Service;
  constructor(props: any) {
    super(props)
    this.Service = new Service();
    this.state = {
      GetDBAccounts: [],
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
      spin_loading: false
    }
    this.columns = [
      {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'First Name',
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        key: 'Last Name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'Email',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
      },
      {
        title: 'Company Name',
        dataIndex: 'company_name',
        key: 'Company Name',
      },
      {
        title: 'PlanCode',
        dataIndex: 'plancode',
        key: 'plancode',
      },
      {
        title: 'DB',
        dataIndex: 'DatabaseName',
        key: 'db',
      }
    ];
  }
  getDataFromSticky(show: boolean) {

    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  componentDidMount = async () => {
    //this.setState({ spin_loading: true, sticky_show: false })
    const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
    if (localTemp.length) {

      let DBAccountsEntityObject: any = {
      }

      let res: any = await this.Service.GetDBAccountsService(DBAccountsEntityObject)

      this.setState({ spin_loading: false, sticky_show: false })
      const tempdata = res.data ? res.data : [];
      if (!tempdata.length) {
        this.stickyText(true, 'error', 'Error', 'No data found');
      }
      else {
        this.setState({ GetDBAccounts: tempdata, sticky_show: false })
      }
    }
  }
  render() {
    return (
      <div className='loginpagebg'>
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />
                :
                <></>
            }
          </>
        </div>
        <div className="container">
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="web-hosting" role="tabpanel" aria-labelledby="web-hosting-tab">
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-9">
                  <div className="section-title text-center mb-70">
                    <h2 className="title">Account List</h2>
                  </div>
                </div>
              </div>
              <div className="row pricing-box-wrap justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <Table
                    dataSource={this.state.GetDBAccounts}
                    className='tablestriped'
                    columns={this.columns}
                    pagination={false}
                    scroll={{ x: 1300, y: `calc(100vh - 250px)` }}
                  />;
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={this.state.spin_loading ? "spin_class_login" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>

        {/* <div className="col-lg-12 col-md-12 col-sm-12">
          <Table
            dataSource={this.state.GetDBAccounts}
            className='tablestriped'
            columns={this.columns}
            pagination={false}
            // scroll={{ x: 1300, y: `calc(100vh - 250px)` }}
          />;
        </div> */}
        {/* <div >
            <Table
              columns={this.columns}
              dataSource={this.state.GetDBAccounts}
              className='tablestriped'
              pagination={{
                // pageSizeOptions: [],
                showSizeChanger: true,
                defaultPageSize: 25,
                hideOnSinglePage: this.state.GetDBAccounts !== undefined ? this.state.GetDBAccounts.length <= 25 ? true : false : true
              }}
            />
        </div> */}
      </div>
    )
  }
}
