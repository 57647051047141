import { Col, Row} from 'antd';
import React, { Component } from 'react';
import CloseOutlined  from '@ant-design/icons/lib/icons/CloseOutlined';
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined';
//import CloseCircleOutlined from '@ant-design/icons/lib/icons/CloseCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';

interface IState {
  display?:boolean;
  sticky_type:any;
  sticky_title?:string;
  sticky_message?: string;
  sendData: (value: boolean) => void;
}

interface IProps {
  display?:boolean;
  sticky_type?: string;
  sticky_title?: string;
  sticky_message?: string;
}

export default class StickyText extends Component<IState, IProps>
{

  state = {
    display: this.props.display,
    sticky_type : this.props.sticky_type,
    sticky_title : this.props.sticky_title,
    sticky_message : this.props.sticky_message
  }
  Close() {
     this.setState({ display: false }) ;
     if(!this.state.display){
      this.props.sendData(false);
    }
  }
  componentWillReceiveProps() {
    this.setState({ display : this.props.display})
    if(!this.props.display){
      this.props.sendData(false);
    }
    setTimeout( () => 
    {
      this.setState({display: false})
    }
      , 3000);
  }  
  
  componentDidMount() {
    setTimeout( () => 
    {
      this.setState({display: false})
    }
      , 3000);
  }
  render() {
    const display  = this.state.display;
    const sticky_type = this.props.sticky_type;
    //const sticky_title = this.props.sticky_title;
    const sticky_message = this.props.sticky_message;
    var mystyle = {};
    var antIcon = {};
    var icon = <></>;
    if (sticky_type === 'warning') {
      mystyle = {
        background: "#ff1010"
      };
      antIcon = {
        color: "#fff"
      };
      icon = <ExclamationCircleOutlined   style={antIcon}/>
    }
    else if (sticky_type === 'success') {
      mystyle = {
        background: "#16c900"
      };
      antIcon = {
        color: "#fff"
      };
      icon = <CheckCircleOutlined  style={antIcon}/>
    }
    else if (sticky_type === 'error') {
      mystyle = {
        background: "#ff1010"
      };
      antIcon = {
        color: "#fff"
      };
      icon = <ExclamationCircleOutlined  style={antIcon}/>
    }

    return (
      <>
        {
          display  ?
          <>
              <div className="sticky">
                <div style={mystyle} className="stickyBody">
                  <Row gutter={16}>
                    <Col span={3} className='stickyIcon' style={{margin:'0px !important'}}>
                      {icon}
                    </Col>
                    <Col span={18} className="stickyMessage"  style={{margin:'0px !important'}}>
                      {/* {sticky_title}<br /> */}
                      {sticky_message}
                    </Col>
                    <Col span={3} style={{margin:'0px !important'}}>
                        <CloseOutlined onClick={() => this.Close()}/>
                    </Col>
                  </Row>
                </div>
              </div>
             
          </>
            :
          null
        }
      </>
    )
  }
}