import React, { Component } from 'react'
import { Service } from '../Common/Services.service';
import "./ManageAddons.css"
import { GetManageAddOnDataEntityObject } from '../Common/Interface';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import { NavLink } from 'react-router-dom';
import { Button, Card, Col, Row } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { reactLocalStorage } from 'reactjs-localstorage';
import StickyText from '../Common/StikeyText';
import { AesEncryption } from '../Common/EncryptionDecryption';
interface AddOnData {
  AddOnData: any
  spin_loading: boolean
  IsActive: number
  SelectedData: any,
  SelectedKeys: any
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
}
export default class ManageAddons extends Component<any, AddOnData> {

  private Service: Service;

  constructor(props: any) {
    super(props)
    this.Service = new Service();
    this.state = {
      AddOnData: [],
      spin_loading: false,
      IsActive: -1,
      SelectedData: [],
      SelectedKeys: [],
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: ''
    }

  }
  SelectAddon = (i: number) => {
    
    var keyString = i;
    // this.setState({ ...this.state, IsActive: i })
    var card = document.getElementsByClassName('ManageAddons_data')[i]
    card.classList.toggle('ManageAddons_data_active')
    var keys: any = this.state.SelectedKeys;
    if (!this.state.SelectedKeys.length) {
      keys.push(keyString)
      this.setState({ SelectedKeys: keys })
    }
    else {
      var ind = keys.indexOf(i)
      if (ind === -1) {
        keys.push(keyString)
        this.setState({ SelectedKeys: keys })
      }
      else {
        keys.splice(ind, 1);
        this.setState({ SelectedKeys: keys })
      }
    }
  }
  getDataFromSticky(show: boolean) {
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
   setTimeout(()=>{
    this.setState({
      sticky_show: false,
      sticky_type: "",
      sticky_title: "",
      sticky_message: ""
    })
   },2000)
    return false;
  }
  Manageaddons = async () => {
   
    // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
    
    if (!this.state.AddOnData.length) {
      this.stickyText(true, 'error', 'Error', 'Please select atleast one');
      return false;
    }
    if (!this.state.SelectedKeys.length) {
      this.stickyText(true, 'error', 'Error', 'Please select atleast one');
      return false;
    }
    if (localTemp.length) {
      const local: any = localTemp ? JSON.parse(localTemp) : []
    //   var Addons1 = []
      var Addons2 = []
    //   for (var i = 0; i < this.state.SelectedKeys.length; ++i) {
    //     Addons1.push({
    //       AddonCode: this.state.AddOnData[i].addon_code,
    //       NoOfUsers: local.NumberOfConcurrentUsers,
    //       NoOfWarhouses: local.NumberOfWarehouses,
    //       UserID: local.Apps_MST_Account_ID
    //     })
    //   }
      for (var ii = 0; ii < this.state.SelectedKeys.length; ++ii) {
        Addons2.push({
          addon_code: this.state.AddOnData[this.state.SelectedKeys[ii]].addon_code,
        })
      }
    //   let ManageAddOnDataEntityObject = {
    //     IsActive: "0",
    //     PlanCode: local.PlanCode,
    //     SubID: local.SubscriptionValue,
    //     Apps_MST_Account_ID:local.Apps_MST_Account_ID,
    //     ZohoCustomer_ID:local.ZOHO_Customer_ID,
    //     inputDataXml:  AesEncryption.encryptJson(Addons1),
    //     NoOfWarhousesSubs:local.NumberOfWarehouses,
    //     NoOfUsersSubs:local.NumberOfConcurrentUsers
         
    

    var UpdateSubscriptionPalnEntityObject: any = {
      subscription_id: local.SubscriptionValue,
      addons:Addons2,
      Apps_MST_Account_ID:local.Apps_MST_Account_ID,
      PlanCode:local.PlanCode,
      PlanName:local.PlanCode,
      
  }
  this.setState({ spin_loading: true })
  let res: any = await this.Service.AddonsPalnService(UpdateSubscriptionPalnEntityObject)
  this.setState({ spin_loading: false })
  const tempdata = res.data ? res.data : [];
  if (tempdata.hostedpage) {
    window.location.href = tempdata.hostedpage.url;
  }
  else {
    this.stickyText(true, 'error', 'Error', tempdata.message);
     
  }
    }
  }
  componentDidMount = async () => {
    this.setState({ spin_loading: true })
    // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
    
    if (localTemp.length) {
      const local: any = localTemp ? JSON.parse(localTemp) : []
      let GetManageAddOnDataEntityObject: GetManageAddOnDataEntityObject = {
        planCode: local.PlanCode
      }
      let res: any = await this.Service.GetManageAddOnDataService(GetManageAddOnDataEntityObject)
      this.setState({ spin_loading: false })
      const tempdata = res.data ? res.data : [];
      this.setState({ AddOnData: tempdata })
    }
  }
  render() {
    //console.log(this.state.SelectedKeys)
    return (
      <>
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />
                :
                <></>
            }
          </>
        </div>
        <div className={this.state.spin_loading ? "spin_class2" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>
        <Card className='Subscription_card'>
          <Row gutter={16}>
            <Col className='item' span={6}><NavLink to={'/Subscription'}>Current Plan</NavLink></Col>
            <Col className='item' span={6}><NavLink to={'/ManagePlans'}>Manage Plans</NavLink></Col>
            <Col className='item active' span={6}><NavLink to={'/ManageAddons'}>Manage Addons</NavLink></Col>
            <Col className='item' span={6}><NavLink to={'/CancelSubscription'}>Cancel Subscription</NavLink></Col>
          </Row>
        </Card>
        <div className='ManageAddons'>
          <div className='ManageAddons-sub'>
            <Row>
              <Col>
                <h4>Available Addons</h4>
              </Col>
            </Row>
            <Row>
              {
                this.state.AddOnData.map((res: any, i: any) => (
                  <Col span={6}>
                    <Card onClick={() => this.SelectAddon(i)} key={i} className="ManageAddons_data">
                      <div>
                        <span>Addon Code : <span className='ManageAddons_dbdata'>{res.addon_code}</span></span><br />
                        <span>Status : <span className='ManageAddons_dbdata'>{res.status}</span></span><br />
                        <span>Price : <span className='ManageAddons_dbdata'>{res.price_brackets[0].price}</span></span><br />
                      </div>
                    </Card>
                  </Col>
                ))
              }
            </Row>
            <Row className='pt-20'>
              <Col>
                <Button className='addon-btn cursor-pointer' onClick={() => this.Manageaddons()}>
                  <PlusCircleOutlined /> Addons Add
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </>
    )
  }
}
