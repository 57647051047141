import React, { Component } from 'react'
import "./Subscription.css"
import { NavLink } from 'react-router-dom';
import { Service } from '../Common/Services.service';
import { reactLocalStorage } from 'reactjs-localstorage';
import StickyText from '../Common/StikeyText';
import { DateFormatStore } from '../Common/DateConversion';
import {  Card, Col,  Row } from 'antd';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import { LoginStoreObj } from '../Common/Store.store';

interface GetSubscriptionData {
  GetSubscriptionData: any
  AddonCode: string
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
  setIsModalVisible: boolean
  spin_loading: boolean
}

export default class subscription extends Component<any, GetSubscriptionData> {

  private Service: Service;

  constructor(props: any) {
    super(props)
    this.Service = new Service();
    this.state = {
      GetSubscriptionData: [],
      AddonCode: "",
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
      setIsModalVisible: false,
      spin_loading: false
    }

  }
  ShowModal = () => {
    this.setState({ setIsModalVisible: true, sticky_show: false })
  };
  handleCancel = () => {
    this.setState({ setIsModalVisible: false, sticky_show: false })
  };
  getDataFromSticky(show: boolean) {
    
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  
  componentDidMount = async () => {
    this.props.parentCallback('1');
    await LoginStoreObj.GetLoginDataStore();
    const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
    // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    
    if (localTemp.length) {
      const local: any = localTemp ? JSON.parse(localTemp) : []
      this.setState((prevState: any) => ({
        ...prevState,
        GetSubscriptionData: [{
          PlanName: local.PlanName,
          ZOHO_Plan: local.ZOHO_Plan,
          PlanCode: local.PlanCode,
          ZOHO_Status: local.ZOHO_Status,
          SubscriptionValidFrom: local.SubscriptionValidFrom,
          SubscriptionValidTo: local.SubscriptionValidTo,
          NumberOfWarehouses: local.NumberOfWarehouses,
          NumberOfConcurrentUsers: local.NumberOfConcurrentUsers
        }]
      }))
    }
  }

  render() {
    const GetSubscriptionData = this.state.GetSubscriptionData[0];
    return (
      <>
        <div className={this.state.spin_loading ? "spin_class2" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />

                :
                <></>
            }
          </>
        </div>
        <Card className='Subscription_card'>
          <Row gutter={16}>
            <Col className='item active' span={6}><NavLink to={'/Subscription'}>Current Plan</NavLink></Col>
            <Col className='item' span={6}><NavLink to={'/ManagePlans'}>Manage Plans</NavLink></Col>
            <Col className='item' span={6}><NavLink to={'/ManageAddons'}>Manage Addons</NavLink></Col>
            <Col className='item' span={6}><NavLink to={'/CancelSubscription'}>Cancel Subscription</NavLink></Col>
          </Row>
        </Card>
        <div className='subscriptionBG'>
          <div className='subscriptionBG_content'>
            <div className='CurrentPlan' >
              <span className='Head'>
                Current Plan - {GetSubscriptionData ? GetSubscriptionData.PlanName : ""}
              </span>
            </div>
            <div className='' style={{ borderBottom: "1px dotted black", height: "50px", paddingTop: "15px" }}>
              <span className='currentspan' style={{ float: "left" }}>
                Plan Code
              </span>
              <span className='currentspan' style={{ float: "right" }}>
                {GetSubscriptionData ? GetSubscriptionData.PlanCode : ""}
              </span>
            </div>
            <div className='' style={{ borderBottom: "1px dotted black", height: "50px", paddingTop: "15px" }}>
              <span className='currentspan' style={{ float: "left" }}>
                Status
              </span>
              <span className='currentspan' style={{ float: "right" }}>
                {GetSubscriptionData ? GetSubscriptionData.ZOHO_Status : ""}
              </span>
            </div>
            <div className='' style={{ borderBottom: "1px dotted black", height: "50px", paddingTop: "15px" }}>
              <span className='currentspan' style={{ float: "left" }}>
                Valid From
              </span>
              <span className='currentspan' style={{ float: "right" }}>
                {GetSubscriptionData ? DateFormatStore.DateStringToNum(GetSubscriptionData.SubscriptionValidFrom) : ""}
              </span>
            </div>
            <div className='' style={{ borderBottom: "1px dotted black", height: "50px", paddingTop: "15px" }}>
              <span className='currentspan' style={{ float: "left" }}>
                Valid To
              </span>
              <span className='currentspan' style={{ float: "right" }}>
                {GetSubscriptionData ? DateFormatStore.DateStringToNum(GetSubscriptionData.SubscriptionValidTo) : ""}
              </span>
            </div>
            <div className='' style={{ borderBottom: "1px dotted black", height: "50px", paddingTop: "15px" }}>
              <span className='currentspan' style={{ float: "left" }}>
                Users
              </span>
              <span className='currentspan' style={{ float: "right" }}>
                {GetSubscriptionData ? GetSubscriptionData.NumberOfConcurrentUsers : ""}
              </span>
            </div>
            <div className='' style={{ borderBottom: "1px dotted black", height: "50px", paddingTop: "15px" }}>
              <span className='currentspan' style={{ float: "left" }}>
                Warehouses
              </span>
              <span className='currentspan' style={{ float: "right" }}>
                {GetSubscriptionData ? GetSubscriptionData.NumberOfWarehouses : ""}
              </span>
            </div>
            <div className='' style={{ borderBottom: "1px dotted black", height: "50px", paddingTop: "15px" }}>
              <span className='currentspan' style={{ float: "left" }}>
                Addons
              </span>
              <span className='currentspan' style={{ float: "right" }}>
                {this.state.AddonCode}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
}
