import React, { Component } from 'react'
import Distribution from "../../Assests/img/distribution.png";
import Manufacturing from "../../Assests/img/manufacturing.png";
import Tpl from "../../Assests/img/3pl.png";
import FooterPagesFooter from '../Footer/FooterPagesFooter';
import Header from './header';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import Doubalarrow from "../../Images/double_arrow.svg"
interface Price {
    basic: number;
    standard: number;
    platinum: number;
    currency: String;
    visible: boolean;
    title: string;
    // props:string
}
export default class Segments extends Component<any,Price> {


    constructor(props: any) {
        super(props);

        this.state = {
            visible: false,
            basic: 89,
            standard: 99,
            platinum: 129,
            currency: "$",
            title: "",
        };
    }
    setVisible = () => {
        this.setState({ visible: false });
    };
   
    modelvisible = (title: any) => {
        
        if (title === "Distribution") {
            this.setState({ visible: true, title: "Distribution Industry" });
        } else if (title === "Manufacturing") {
            this.setState({ visible: true, title: "Manufacturing" });
        } else if (title === "3pl") {
            this.setState({ visible: true, title: "3PL" });
        } else if (title === "Surgical") {
            this.setState({ visible: true, title: "Pharma & Surgical" });
        } else if (title === "Automobile") {
            this.setState({ visible: true, title: "Automobile" });
        } else if (title === "Fast-moving") {
            this.setState({ visible: true, title: "Fast-moving Consumer Goods" });
        } else if (title === "Fashion") {
            this.setState({ visible: true, title: "Fashion and Retail" });
        }
    };
  render() {
    return (
      <>
      <Header/>
            <div className="container">
                <p className='bedc'>
                    <Link to="/">
                        <span className='home_bedcle'>Home</span>

                    </Link> <img src={Doubalarrow} className="bed_arrow" alt="arrow" /> Segments
                </p>
            </div>

            <section id="Segments"  className="services-area position-relative pt-500" style={{ paddingBottom: "150px" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-title text-center">
                                <h2 className="title title1 Tital_pricing">Segments</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row services-item-wrap justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-7">
                            <div className="services-item mb-70">
                                <div className="services-icon">
                                    <img src={Distribution} alt="" />
                                </div>
                                <div className="services-content">
                                    <h4>
                                        <a href="# ">Distribution Center</a>
                                    </h4>
                                    <p>
                                        Small and medium Distribution Center operations normally
                                        lack precise and seamless traceability, Batch/LOT
                                        control, and recall management capabilities
                                    </p>
                                    <span
                                        className="know-more"
                                        onClick={() => this.modelvisible("Distribution")}
                                    >
                                        know More...
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-7">
                            <div className="services-item mb-70">
                                <div className="services-icon">
                                    <img src={Manufacturing} alt="" />
                                </div>
                                <div className="services-content">
                                    <h4>
                                        <a href="# ">Manufacturing</a>
                                    </h4>
                                    <p>
                                        The manufacturing is a highly regulated industry
                                        globally where attention to detail is critical to help
                                        ensure stringent
                                    </p>
                                    <span
                                        className="know-more "
                                        onClick={() => this.modelvisible("Manufacturing")}
                                    >
                                        know More...
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-7">
                            <div className="services-item mb-70">
                                <div className="services-icon">
                                    <img src={Tpl} alt="" />
                                </div>
                                <div className="services-content">
                                    <h4>
                                        <a href="# ">3PL</a>
                                    </h4>
                                    <p>
                                        SKUBIQ helps small and medium third-party logistics
                                        (3PL) companies to manage multiple tenant customers,
                                        processes and billing schedules.
                                    </p>
                                    <span
                                        className="know-more"
                                        onClick={() => this.modelvisible("3pl")}
                                    >
                                        know More...
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="service-line-shape two"></div>
                        <div className="service-line-shape three"></div>
                    </div>
                </div>
                <div className="service-shape one">
                    <img src="img/images/services_shape01.png" alt="" />
                </div>
                <div className="service-shape two">
                    <img src="img/images/services_shape02.png" alt="" />
                </div>
            </section>
                    <FooterPagesFooter />
                    <>
                    <Modal
                        className="footerhomepage"
                        title={this.state.title}
                        centered
                        visible={this.state.visible}
                        onCancel={() => this.setVisible()}
                        footer={[]}
                        width={800}
                    >
                        {this.state.title === "Distribution Industry" ? (
                            <div>
                                <p>
                                    Distributors normally lack precise and seamless traceability,
                                    lot control, and recall management capabilities jeopardizing
                                    the inventory thereby putting their businesses at risk of
                                    compliance failures and legal liabilities. In addition, they
                                    are constantly challenged by the emerging consumer demands for
                                    omni-channel commerce, specialized products, and more
                                    convenient delivery options
                                </p>
                                <p>
                                    SKUBIQ is trusted worldwide for supply chain management and
                                    visibility. But Why? Simply because our uniquely adaptable
                                    software solutions help companies like you stay on top of this
                                    fast-changing market.
                                </p>
                                <p>
                                    SKUBIQ helps address the complete process of fulfilling
                                    complex, multi-temperature home delivery orders. SKUBIQ has
                                    the inbuilt flexibility in helping emerging online retailers
                                    and distributors a wide range of specialty products through
                                    traditional eCommerce fulfillment models.
                                </p>
                                <p>
                                    As one of the market leaders in warehouse management (WMS) for
                                    cold-storage, third-party-logistics companies that play a
                                    critical role distribution, we bridge inventory and
                                    distribution between some of the world’s largest producers and
                                    their customers.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Manufacturing" ? (
                            <div>
                                <p className="text-justify text-muted1 mb-2">
                                    The manufacturing is a highly regulated industry globally
                                    where attention to detail is critical to help ensure stringent
                                    requirements for product quality and deadlines are met. Its
                                    become imperative for organizations to achieve a lean
                                    environment in which they have visibility to and control over
                                    these details is where competitive advantage often resides.
                                    SKUBIQ partners with major manufacturing companies globally to
                                    improve efficiency and reduce costs, such as:
                                </p>
                                <ol className="pllist">
                                    <li>
                                        Support of lean manufacturing initiatives by including
                                        inventory management capabilities within manufacturing
                                    </li>
                                    <li>Initiating supplier re-orders based on demand signals</li>
                                    <li>
                                        Compliance with industry requirements for quality, product
                                        tracking, safety, and recall management
                                    </li>
                                    <li>Integrated RFID, including asset tracking</li>
                                    <li>Quality assurance and inspection</li>
                                    <li>Managing a large product catalog / SKU proliferation</li>
                                    <li>Addressing Customer OEM parts packaging</li>
                                    <li>Core stratification and remanufacturing</li>
                                    <li>
                                        Wave planning and small order pick optimization to reduce
                                        picker travel
                                    </li>
                                    <li>Serialized inventory tracking</li>
                                    <li>EDI / ASN integration</li>
                                </ol>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "3PL" ? (
                            <div>
                                <p>
                                    The SKUBIQ is a cloud based Warehouse management system and is
                                    designed for third-party logistics companies to manage
                                    multiple customers, processes and billing schedules. The
                                    system enables access to real-time information and provides
                                    integrations with warehouse management technologies, including
                                    EDI, barcode scanning, and e-commerce shopping carts. The
                                    software scalability helps companies to manage different stock
                                    levels in warehouses, streamline business, and satisfy
                                    customers.
                                </p>
                                <p>
                                    SKUBIQ is designed to help logistics companies automate
                                    processes and bill items accurately. The software provides
                                    features which allow the user to easily add and remove
                                    customers and products. The software is designed to help
                                    logistics providers satisfy customers’ need for updated
                                    information and increase profits through process automation.
                                </p>
                                <p>
                                    The SKUBIQ can be integrated with any line of business
                                    application or ERP thereby allowing users to synchronize
                                    items, inventory, purchase orders, and receipts.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Pharma & Surgical" ? (
                            <div>
                                <p>
                                    The SKUBIQ is a cloud based Warehouse management system and is
                                    designed for third-party logistics companies to manage
                                    multiple customers, processes and billing schedules. The
                                    system enables access to real-time information and provides
                                    integrations with warehouse management technologies, including
                                    EDI, barcode scanning, and e-commerce shopping carts. The
                                    software scalability helps companies to manage different stock
                                    levels in warehouses, streamline business, and satisfy
                                    customers.
                                </p>
                                <p>
                                    SKUBIQ is designed to help logistics companies automate
                                    processes and bill items accurately. The software provides
                                    features which allow the user to easily add and remove
                                    customers and products. The software is designed to help
                                    logistics providers satisfy customers’ need for updated
                                    information and increase profits through process automation.
                                </p>
                                <p>
                                    The SKUBIQ can be integrated with any line of business
                                    application or ERP thereby allowing users to synchronize
                                    items, inventory, purchase orders, and receipts.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Automobile" ? (
                            <div>
                                <p>
                                    Distributors normally lack precise and seamless traceability,
                                    lot control, and recall management capabilities jeopardizing
                                    the inventory thereby putting their businesses at risk of
                                    compliance failures and legal liabilities. In addition, they
                                    are constantly challenged by the emerging consumer demands for
                                    omni-channel commerce, specialized products, and more
                                    convenient delivery options
                                </p>
                                <p>
                                    SKUBIQ is trusted worldwide for supply chain management and
                                    visibility. But Why? Simply because our uniquely adaptable
                                    software solutions help companies like you stay on top of this
                                    fast-changing market.
                                </p>
                                <p>
                                    SKUBIQ helps address the complete process of fulfilling
                                    complex, multi-temperature home delivery orders. SKUBIQ has
                                    the inbuilt flexibility in helping emerging online retailers
                                    and distributors a wide range of specialty products through
                                    traditional eCommerce fulfillment models.
                                </p>
                                <p>
                                    As one of the market leaders in warehouse management (WMS) for
                                    cold-storage, third-party-logistics companies that play a
                                    critical role distribution, we bridge inventory and
                                    distribution between some of the world’s largest producers and
                                    their customers.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Fast-moving Consumer Goods" ? (
                            <div>
                                <p>
                                    The manufacturing is a highly regulated industry globally
                                    where attention to detail is critical to help ensure stringent
                                    requirements for product quality and deadlines are met. Its
                                    become imperative for organizations to achieve a lean
                                    environment in which they have visibility to and control over
                                    these details is where competitive advantage often resides.
                                    SKUBIQ partners with major manufacturing companies globally to
                                    improve efficiency and reduce costs, such as:
                                </p>
                                <ol className="pllist">
                                    <li>
                                        Support of lean manufacturing initiatives by including
                                        inventory management capabilities within manufacturing
                                    </li>
                                    <li>Initiating supplier re-orders based on demand signals</li>
                                    <li>
                                        Compliance with industry requirements for quality, product
                                        tracking, safety, and recall management
                                    </li>
                                    <li>Integrated RFID, including asset tracking</li>
                                    <li>Quality assurance and inspection</li>
                                    <li>Managing a large product catalog / SKU proliferation</li>
                                    <li>Addressing Customer OEM parts packaging</li>
                                    <li>Core stratification and remanufacturing</li>
                                    <li>
                                        Wave planning and small order pick optimization to reduce
                                        picker travel
                                    </li>
                                    <li>Serialized inventory tracking</li>
                                    <li>EDI / ASN integration</li>
                                </ol>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Fashion and Retail" ? (
                            <div>
                                <p>
                                    The SKUBIQ is a cloud based Warehouse management system and is
                                    designed for third-party logistics companies to manage
                                    multiple customers, processes and billing schedules. The
                                    system enables access to real-time information and provides
                                    integrations with warehouse management technologies, including
                                    EDI, barcode scanning, and e-commerce shopping carts. The
                                    software scalability helps companies to manage different stock
                                    levels in warehouses, streamline business, and satisfy
                                    customers.
                                </p>
                                <p>
                                    SKUBIQ is designed to help logistics companies automate
                                    processes and bill items accurately. The software provides
                                    features which allow the user to easily add and remove
                                    customers and products. The software is designed to help
                                    logistics providers satisfy customers’ need for updated
                                    information and increase profits through process automation.
                                </p>
                                <p>
                                    The SKUBIQ can be integrated with any line of business
                                    application or ERP thereby allowing users to synchronize
                                    items, inventory, purchase orders, and receipts.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                    </Modal>
                </>
      </>
    )
  }
}
