import React, { Component } from "react";
import "../../Footer/Footer.css";
import FooterPagesHeader from "../FooterPagesHeader";
import FooterPagesFooter from "../FooterPagesFooter";

export default class TermsAndConditions extends Component {
  isSticky = () => {
    const header: any = document.getElementById("sticky-header");
    //const navigation: any = document.getElementById("navigation");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("sticky-header")
      : header.classList.remove("sticky-header");
    scrollTop >= 250
      ? header.classList.add("navigationclr")
      : header.classList.remove("navigationclr");
  };

  componentDidMount() {
    window.addEventListener("scroll", this.isSticky);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <FooterPagesHeader text="Terms Of Service" />
        <section className="content-block">
          <div className="container">
            <article className="FooterArticle">
              <h3>SKUBIQ Terms Of Service</h3>
              <p>
                These terms of service (“Terms of Service” or “TOS”) form a
                contract between (referred to herein as “Customer”, “you”,
                “your” or “Merchant”) and Avya Inventrax Private
                Limited,DBA:Inventrax fully owned and the represetative of
                www.skubiq.com(“SKUBIQ”) that governs your access and use of the
                SKUBIQ Services . By using any of the SKUBIQ Services , you
                agree to be bound by these Terms of Service. If you are using
                the SKUBIQ Services on behalf of an organization, you are
                agreeing to these Terms of Service for that organization and
                representing to SKUBIQ that you have the authority to bind that
                organization to these Terms of Service (in which event, “you” or
                “Merchant” will refer to the organization). You may use the
                SKUBIQ Services only in compliance with these Terms of Service
                and only if you have the power and right to form a contract with
                SKUBIQ
              </p>
            </article>
            <article>
              <h3>Accounts, SKUBIQ Service, And Policies</h3>
              <p>
                <u>
                  <b>Accounts:</b>
                </u>{" "}
                In order to create an account with SKUBIQ, you will provide
                SKUBIQ with identifying information, a password, company
                information and information about your Inventory (“Registration
                Information”). You agree that you will provide accurate
                Registration Information and will promptly update such
                Registration Information as necessary, but in no event later
                than 30 days after any applicable change. Upon SKUBIQ acceptance
                of your request to register for an account (“Account”), you will
                be sent validation and activation instructions. Access to, and
                use of, the Account is restricted to authorized users only. You
                agree not to share your password(s), Account information, or
                Account access information. You are responsible for maintaining
                the confidentiality of password(s) and Account information, and
                you are responsible for all activities that occur under your
                password(s) or Account(s) or as a result of your access to the
                Account(s). You agree to notify SKUBIQ immediately of any
                unauthorized use of your Account. SKUBIQ shall not be liable for
                any unauthorized use of your Account. Through your Account you
                can select SKUBIQ Services . For purposes of these Terms, “Good
                Standing” means that the Account has a positive balance and that
                you are not be in breach of these Terms or Policies.
              </p>
              <p>
                <u>
                  <b>SKUBIQ Services :</b>
                </u>{" "}
                Subject to your compliance with these Terms and the Policies (as
                defined below in Section 1(c)), SKUBIQs hall perform the SKUBIQ
                Services as selected and authorized by you in your Account. For
                purposes of these Terms, “SKUBIQ Services ” means the SKUBIQ
                products and services and any other features, software,
                technologies and/or functionalities offered by SKUBIQ and
                ordered by you through your Account. You may order SKUBIQ
                Services through your Account (“SKUBIQ Services Orders”). All
                SKUBIQ Services Orders are deemed incorporated into, and
                governed by, these Terms. SKUBIQ helps you accept shipments
                from, and make shipments to, third parties. SKUBIQ is an
                independent contractor for all purposes. SKUBIQ acts as your
                agent only with respect to the custody of your merchandise
                (hereinafter referred to as “Inventory”).
              </p>
              <p>
                <u>
                  <b>Customer Policies and Use of SKUBIQ Services .</b>
                </u>{" "}
                SKUBIQ has defined policies that govern your use of the SKUBIQ
                Services (“Policies”). The Policies are linked below and are
                hereby incorporated by reference. In the event of any conflict
                or inconsistency between these Terms and any of the Policies,
                these Terms shall control. Your breach of any of these Terms or
                the Policies shall excuse SKUBIQ’s performance of the applicable
                SKUBIQ Service.
              </p>
              <ul>
                <li>
                  <p>Acceptable Use Policy</p>
                </li>
                <li>
                  <p>Privacy Policy</p>
                </li>
                <li>
                  <p>Inbound Goods Policy</p>
                </li>
                <li>
                  <p>Storing and Shipment of Goods Policy</p>
                </li>
                <li>
                  <p>Accounts Policy</p>
                </li>
                <li>
                  <p>Service Levels Policy</p>
                </li>
                <li>
                  <p>Merchant Protection Policy</p>
                </li>
                <li>
                  <p>Projects Policy</p>
                </li>
                <li>
                  <p>Restricted Activities Policy</p>
                </li>
                <li>
                  <p>Fees, Minimum Balance, and Ancillary Charges Policy</p>
                </li>
                <li>
                  <p>Disputes Policy</p>
                </li>
                <li>
                  <p>
                    You acknowledge that your breach of the Policies or the
                    Terms may result in you incurring additional fees from
                    SKUBIQ or third parties for the applicable SKUBIQ Services .
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>Account Balance And Fees.</h3>
              <p>
                <u>Account Balances.</u> SKUBIQ may set a minimum balance that
                you must maintain on your Account (“Minimum”). SKUBIQ reserves
                the right to increase or to decrease the Minimum on your Account
                immediately upon notice to you. Your SKUBIQ Account must be
                funded at or above the Minimum in order to receive SKUBIQ
                Services. The funds that you maintain in your SKUBIQ Account
                constitute your “Account Balance”. As SKUBIQ Services charges
                are incurred, SKUBIQ will deduct these from your Account
                Balance. SKUBIQ reserves the right to request deposits to
                receive Inventory to cover costs such as receiving costs and
                enforce minimum Account Balances. If your Account is closed for
                any reason, SKUBIQ reserves the right to hold the Minimum
                Balance for up to 180 Days.
              </p>
              <p>
                <u>Usage Fees.</u> “Usage Fees” are the fees for any SKUBIQ
                Services and any third-party fees (including, but not limited to
                carrier fees, shipping fees, rates of duty, international
                brokerage charges, freight charges, insurance premiums or other
                charges given during your use of SKUBIQ Services) ordered
                through your Account and any special or additional fees assessed
                against your Account as permitted in these Terms or the
                Policies. Note that the Usage Fees for SKUBIQ Services are
                billed to the month in which the SKUBIQ Services are performed.
                Usage Fees are subject to change. If you are on a billing plan,
                should you change plans during or at the end of any month, your
                previous pricing plan may no longer be available.
              </p>
              <p>
                <u>Estimates.</u> Quotations for SKUBIQ Services and Usage Fees
                are for informational purposes only and are subject to change
                without notice and shall not under any circumstances be binding
                upon SKUBIQ. Quotations accepted through SKUBIQ Services’ online
                interface are estimates based on the information available at
                the time made. The final rates and service fees may vary based
                upon the shipment actually tendered, the work actually
                performed, or a number of factors such as carrier shipping
                prices, the actual characteristics of your product, the delivery
                address, and services requested during the normal course of
                delivery. SKUBIQ reserves the right to bill the Usage Fees based
                on actual charges at any time after the SKUBIQ Services are
                rendered. SKUBIQ specifically disclaims liability for any
                shipping rate errors due to inaccurate or incomplete
                information, such as dimensions and weights.
              </p>
              <p>
                <u>Additional Item Fees/Credits.</u> For some legacy SKUBIQ
                plans, there may be a need to pay for additional item fees when
                the total items shipped during a month exceed plan allowance.
                Additional item fees are listed in the merchant Account, under
                Account plan preferences. Merchants on legacy SKUBIQ pricing
                plans that use item allowances understand that plan items must
                be used during the specified billing period or will be
                automatically forfeited. This applies to items purchased as part
                of plan upgrades, as well as to any unused additional item
                credits.
              </p>
              <p>
                <u>Currency Fluctuations.</u> SKUBIQ reserves the right to
                adjust its pricing in response to currency fluctuations,
                including but not limited to, currency conversion rate changes,
                conversion fee changes, and/or discount rate changes.
              </p>
              <p>
                <u>Usage Fee Disputes</u>. Should you disagree with any Usage
                Fees (other than carrier or third-party fees) charged against
                your Account, you must submit the dispute to SKUBIQ within 90
                days of the fee being charged (“Dispute Period”). SKUBIQ will
                not review Customer requests for Usage Fee adjustments that are
                received after the Dispute Period. The dispute timing allowed
                for third-party fees and carrier fees shall be set by the
                applicable third-party or carrier and such time period may be
                substantially shorter than the SKUBIQ Dispute Period. If you
                have a dispute with a third-party fee or carrier fee, please
                contact SKUBIQ immediately.
              </p>
              <p>
                <u>Account Balance Disputes</u>. If SKUBIQ becomes aware of, or
                is notified of, a dispute relating to your Account Balance, then
                SKUBIQ will promptly review the dispute. Within 5 business days
                after the resolution of the dispute, SKUBIQ will credit or debit
                your Account Balance accordingly, if appropriate. SKUBIQ will
                not review Customer requests for Account Balance adjustments
                that are received more than 90 days after the amount in dispute
                is posted to your Account Balance.
              </p>
              <p>
                <u>Abandoned Account and Liquidation</u>. If your Usage Fees
                remain unpaid for a period greater than 30 days, then SKUBIQ
                reserves the right, at its sole discretion to reclassNameify
                your Account as an “Abandoned Account.” Additionally, any
                Account that remains unpaid for greater than 60 days will
                automatically be deemed an Abandoned Account. Upon an Account
                becoming an Abandoned Account, all rights to ownership of the
                Account Balance and Inventory would then immediately be
                forfeited by you. Inventory will become immediately and
                irrevocably unavailable to you, and liquidation proceedings
                would begin. You agree the Inventory would be free and clear of
                liability, and that you would assume any liability therefore.
                You would have no rights to the liquidation proceeds. You would
                also remain liable for any pending Usage Fees above and beyond
                the liquidation proceeds.
              </p>
              <p>
                <u>Taxes.</u> You acknowledge and agree that all fees, charges
                and any other rates or amounts charged by SKUBIQ to you
                hereunder are exclusive of applicable value added, sales/use or
                Inventory and service taxes (“Transaction Taxes”) which may be
                levied in connection with the supply by SKUBIQ of the SKUBIQ
                Services to you. Where applicable, you shall pay all Transaction
                Taxes arising in respect of the Usage Fees or other amounts
                charged by SKUBIQ to you.
              </p>
              <p>
                <u>Currency.</u> All dollar amounts stated in these Terms and
                the Policy’s will be in US dollars unless otherwise specified.
              </p>
            </article>
            <article>
              <h3>Developers And API License.</h3>
              <p>
                <u>License Grant.</u> If you are using SKUBIQ software such as
                an application programing interface (API), developer’s toolkit
                or other software application (such as, but not limited to, an
                e-commerce module developed by SKUBIQ) (“Developer Tools”), then
                SKUBIQ grants you a revocable, non-exclusive, non-transferable
                license to use Developer Tools in accordance with the
                documentation for your internal business purposes only. You may
                not rent, lease or otherwise transfer your rights in the
                Developer Tools to any third party. SKUBIQ provides the
                Developer Tools solely on an “AS IS” basis and disclaims all
                warranties and liability for your use of the Developer Tools.
                SKUBIQ may change or discontinue any Developer Tools in its
                reasonable discretion.
              </p>
              <p>
                <u>Third Party Software.</u> Any third party software
                application you use on the SKUBIQ website, to connect to SKUBIQ
                Services, or related to the SKUBIQ Services (“Third Party
                Software”) is solely subject to any third party software
                provider software licenses. SKUBIQ does not own, control or have
                any responsibility or liability for any Third Party Software.
              </p>
            </article>
            <article>
              <h3>Intellectual Property.</h3>
              <p>
                <u>SKUBIQ Property.</u> For purposes of this Agreement, “SKUBIQ
                Property” shall mean (a) SKUBIQ’s methodology for the provision
                of SKUBIQ Services; (b) the Developer Tools; and (c) SKUBIQ’s
                ideas, web site, processes, code, technology, software,
                copyrights, logos, domain names, patents, trade secrets,
                trademarks, products and materials. SKUBIQ hereby retains all
                worldwide right, title and interest in and to the SKUBIQ
                Property. Any rights not expressly granted herein to the SKUBIQ
                Property shall be retained by SKUBIQ. You acknowledge that all
                right, title and interest to the SKUBIQ Property is owned by
                SKUBIQ.
              </p>
              <p>
                <u>Additional Restrictions.</u> Other than as permitted herein,
                you shall not (and you shall not permit others), directly or
                indirectly, to modify, to translate, to decompile, to
                disassemble, or to reverse engineer any part of the SKUBIQ
                Property, or otherwise to attempt to discern the functioning or
                operation of the website or SKUBIQ Services; or to copy, to
                rent, to lease, to distribute, or to otherwise transfer any or
                the rights that you receive hereunder. For clarity, all page
                headers, custom graphics, button icons, and scripts are service
                marks, trademarks, and/or trade dress of SKUBIQ and you shall
                not copy, imitate, or use them without our express prior written
                consent. You may use HTML logos provided by SKUBIQ through our
                merchant services, auction tools features or affiliate programs
                without prior written consent solely for the purpose of
                directing web traffic to SKUBIQ.com. You shall not alter, modify
                or change such HTML logos in any way, use them in a manner that
                is disparaging or otherwise adverse to SKUBIQ or the SKUBIQ
                Service, or display them in any manner that implies SKUBIQ’s
                sponsorship or endorsement. You shall not (and you shall not
                permit others to): (i) use any robot, spider, scraper or other
                automated means to access the SKUBIQ website or SKUBIQ Services
                for any purpose without SKUBIQ’s express written permission,
                (ii) interfere or attempt to interfere with the proper working
                of our website or any activities conducted on the website, or
                (iii) bypass any measures SKUBIQ may use to prevent or restrict
                access to the SKUBIQ website or the SKUBIQ Services.
              </p>
              <p>
                <u>Client Property.</u> No Confidential Information obtained by
                SKUBIQ from you shall become SKUBIQ Property. All materials
                provided by you under any SKUBIQ Services Orders shall be deemed
                “Client Property” for purposes of the Agreement. You grant to
                SKUBIQ a non-exclusive license to the Client Property solely as
                needed to provide the SKUBIQ Services. No other licenses express
                or implied, under any intellectual property rights are granted
                by you to SKUBIQ under these Terms.
              </p>
              <p>
                <u>Data Security.</u> The Service is currently provided from
                India. Registration Information, Account Data, information and
                other data (“Data”) is currently stored and processed in India.
                SKUBIQ has implemented and will maintain appropriate physical,
                electronic, and managerial procedures intended to protect
                against the loss, misuse, unauthorized access, alteration or
                disclosure of Data. These measures include encryption of Data
                during transmission to the SKUBIQ Service and encryption of
                backups of Data and authentication credentials at rest. SKUBIQ
                will use reasonable efforts to promptly notify Account owner of
                any unauthorized access to, or use of, Data that comes to
                SKUBIQ’s attention. You must immediately notify SKUBIQ of any
                suspected security breach at security@skubiq.com followed by
                contacting SKUBIQ customer support.
              </p>
            </article>
            <article>
              <h3>Confidential Information.</h3>
              <p>
                <u>Definition.</u> Each party (“Recipient”) acknowledges that it
                may receive Confidential Information as defined herein. For
                purposes of these Terms and subject to the Exclusions set forth
                below, Confidential Information means any information provided
                to it by the other party (“Discloser”) that is marked, labeled
                or otherwise designated as confidential or proprietary, or that
                Recipient knew, or should have known, was confidential due to
                the circumstances surrounding the disclosure.
              </p>
              <p>
                <u>Exclusion.</u> Information that is subject to one of the
                exclusions below shall not be Confidential Information. The
                exclusions include the following: (a) Non-transactional
                Confidential Information (as defined below), (b) information
                publicly known at the time of disclosure, (c) information
                received by Recipient without restriction from a third party,
                (d) information published or otherwise made known to the public
                by Discloser, (e) information that was generated independently
                without reference to the Discloser’s Confidential Information,
                or (f) information that is required to be disclosed under a
                court order or pursuant to any applicable governmental rule,
                regulation or statute, provided that Recipient provide Discloser
                with prior written notice of such disclosure, (as permitted by
                law) and the timing for response set forth in the request.
              </p>
              <p>
                <u>Non-transactional Confidential Information.</u> Other than
                transaction information absolutely required for SKUBIQ to
                provide, or for you to use the SKUBIQ Services, SKUBIQ does not
                require nor desire any of your proprietary information
                (“Non-transactional Confidential Information”). You agree not to
                provide SKUBIQ with any Non-transactional Confidential
                Information, including, but not limited to, prototypes of new
                products, without SKUBIQ’s express prior written consent. In the
                event that you send such Non-transactional Confidential
                Information to SKUBIQ without SKUBIQ’s prior written consent,
                then SKUBIQ shall not be obligated to treat such information as
                Confidential Information.
              </p>
              <p>
                <u>Standard of Care.</u> Recipient shall not use the
                Confidential Information for any purpose other than as required
                by these Terms. Recipient shall not disclose the Confidential
                Information to any third party, other than as required to
                perform the SKUBIQ Services. Recipient shall use at least the
                same standard of care with the Discloser’s Confidential
                Information as it does with its own Confidential Information,
                but in no event with less than reasonable care. Each party
                acknowledges that breach of this provision would result in
                irreparable harm to the other party, for which money damages
                would be an insufficient remedy, and therefore that the other
                party will be entitled to seek injunctive relief to enforce the
                provisions of this Section.
              </p>
              <p>
                <u>Return or Destruction.</u> Other than transactional
                information that is retained in the ordinary course of a party’s
                business, each party shall either promptly return all
                Confidential Information, or confirm that such Confidential
                Information has been destroyed promptly after receipt of written
                request from the other party.
              </p>
              <p>
                <u>Aggregate Use.</u> Subject to the Terms herein, you hereby
                acknowledge and agree that SKUBIQ may compile aggregate results
                from all of, or a selection of your use of the SKUBIQ Services,
                provided that SKUBIQ shall not disclose any information that
                would individually identify you (“Aggregate Information”). Such
                Aggregate Information shall be deemed to be SKUBIQ’s
                Confidential Information. You also hereby agree that SKUBIQ may
                review and use your individual use of the SKUBIQ Services in
                order to provide SKUBIQ Services to you, to evaluate SKUBIQ’s
                provision of the SKUBIQ Services, and to improve SKUBIQ’s
                service offerings.
              </p>
            </article>
            <article>
              <h3>Indemnity</h3>
              <p>
                You agree to indemnify and to hold harmless SKUBIQ, its parent
                corporation, and their officers, directors, employees and agents
                from and against any and all claims, liabilities, damages,
                losses or expenses, including reasonable attorneys’ fees and
                costs, due to or arising out of your use of the SKUBIQ Services
                and/or your violation of the Terms or the Policies. Whether
                SKUBIQ accepts or refuses Inventory you agree to indemnify and
                hold harmless SKUBIQ from any and all claims for transportation,
                storage, handling and other charges relating to such Inventory,
                including undercharges, rail demurrage, truck/intermodal
                detention and other charges of any nature.
              </p>
            </article>
            <article>
              <h3>Disclaimers</h3>
              <p>
                <b>As is.</b> While, SKUBIQ will endeavor to provide the SKUBIQ
                services in accordance with these terms, the SKUBIQ services and
                the SKUBIQ web site are provided on an “as is” and “as
                available” basis. SKUBIQ and SKUBIQ’s suppliers expressly
                disclaim all warranties of any kind, whether express or implied,
                including, but not limited to, the implied warranties of
                merchantability, fitness for a particular purpose and
                non-infringement for the SKUBIQ services, the SKUBIQ web site
                and any third party services. The use of the SKUBIQ services,
                SKUBIQ web site, or third party services is at your own risk.
              </p>
              <p>
                <b>Inventory Disclaimer.</b> SKUBIQ is not the importer of
                record for Inventory stored at SKUBIQ. SKUBIQ shall not be held
                liable for complying with your instructions through the SKUBIQ
                Services. You understand that SKUBIQ does not inspect your
                Inventory nor does SKUBIQ take responsibility for the business
                decisions that you make and implement through the SKUBIQ
                Services. For example, SKUBIQ cannot control or ensure that a
                buyer or seller with whom you do business will remit payment for
                Inventory in accordance with your agreement with them. For
                purposes of clarity, SKUBIQ is not the Merchant of Record for
                any of your Inventory. SKUBIQ is not responsible for items
                damaged during the pick and pack process or for breakage of
                items during transit that have been picked and packed by SKUBIQ.
              </p>
              <p>
                No Continuous Access. SKUBIQ does not guarantee continuous,
                uninterrupted or secure access to the SKUBIQ Service. Operation
                of the SKUBIQ Services may be interfered with by numerous
                factors outside of our control. SKUBIQ will make reasonable
                efforts to process requests for receiving or shipping
                merchandise in a timely manner but SKUBIQ makes no
                representations or warranties regarding the amount of time
                needed to complete processing because our Service is dependent
                upon many factors outside of our control, such as delays caused
                by third parties.
              </p>
            </article>
            <article>
              <h3>Limitation Of Liability</h3>
              <p>
                <b>Third Party Liability.</b> By using the SKUBIQ Services, you
                acknowledge and agree that SKUBIQ disclaims all liability for
                the acts or omissions (including, without limitation, any
                negligence or willful misconduct) of any third party whether or
                not selected by or retained by SKUBIQ.
              </p>
              <p>
                <b>Annual Inventory Shrink-Allowance</b>. Handling physical
                products could result in loss or damage of Inventory. We
                maintain high standards, however occasionally facilities
                experience concealed shortages, product damages, mislabeled,
                miss-picked Inventory and/or cross-shipments. SKUBIQ maintains
                merchant friendly receiving and product labeling requirements.
                You agree that SKUBIQ will have a 1.5% shrink allowance based on
                the value of your account’s Inventory known to be in the
                facility based on the stated cost value measured on an annual
                basis and subject to Limitations of Liability in Section 8
                below. Explainable, offsetting Inventory adjustments based on
                miss-marked Inventory or receiving Inventory errors will not be
                deemed an Inventory shrinkage event or lost Inventory.
              </p>
              <p>
                <b>
                  Waiver of Consequential Damages and Limitation of Liability
                </b>
                . In no event shall either party be liable for any indirect,
                incidental, special or consequential damages, or damages for
                loss, loss of profits, revenue, data or use, incurred by you,
                whether in an action in contract or tort, arising from either
                party’s access to, or use of, the site, any content, or any
                third party sites and content. other than as set forth below, in
                no event shall SKUBIQ’s liability under this agreement exceed
                the monies paid or payable by you to SKUBIQ for the applicable
                SKUBIQ services excluding carrier fees or other third party fees
                (“damages cap”). in the event of an unauthorized transaction by
                a SKUBIQ employee or agent, SKUBIQ is only liable for the
                damages cap. SKUBIQ must be notified within five (5) days after
                any unauthorized transaction or you waive all damages from
                SKUBIQ.
              </p>
              <p>
                <b>Exclusive Remedy.</b> SKUBIQ’s liability referred to below
                shall be your exclusive remedy against SKUBIQ for any claim or
                cause of action whatsoever relating to loss, damage and/or
                destruction of inventory and shall apply to all claims including
                inventory shortage and mysterious disappearance claims unless
                you prove by affirmative evidence that SKUBIQ converted the
                inventory to its own use. You waive any rights to rely upon any
                presumption of conversion imposed by law.
              </p>
              <p>
                <b>Damage or Loss of Inventory.</b> Inventory insurance is
                available. in the event of loss, damage or destruction of
                inventory (“inventory loss”) for which SKUBIQ is legally liable,
                you declare that SKUBIQ’s liability shall be limited Paise 50
                per Rupee for said lost, damaged, and/or destroyed inventory.
                provided, however that within a reasonable time after inventory
                is received, you must add inventory insurance on part or all of
                the inventory in which case an increased charge will be made
                based upon such increased coverage; further provided that no
                such coverage shall be valid unless paid for before loss, damage
                or destruction to any portion of the inventory has occurred. in
                no event shall SKUBIQ be liable for any lost sales revenue from
                inventory loss. SKUBIQ’s maximum liability for inventory loss
                will be capped at 5% of the total product value or one month of
                the average billable storage, whichever is lower (“inventory
                damages cap”). for greater coverage, you must add inventory
                insurance in your account settings on the inventory that you
                wish covered by insurance, in which case an increased charge
                will be made based upon such increased coverage; further
                provided that no such coverage shall be valid unless paid for
                before loss, damage or destruction to any portion of the
                inventory has occurred.
              </p>
              <p>
                <b>Inventory Count Inaccuracies.</b> In the event of inventory
                loss in excess of the annual inventory shrinkage allowance due
                to inventory count inaccuracies, inaccurate inventory counts
                during receiving or inventory count inaccuracies at any time
                that SKUBIQ is in possession of inventory for which the clauses
                above is determined to be inapplicable and SKUBIQ is held
                legally liable, you agree that it will be considered an
                “inventory loss” and SKUBIQ’s liability shall be limited as
                stated in 8.e. above. In no event shall SKUBIQ be liable for any
                lost sales revenue from the inventory loss due to inventory
                count inaccuracies.
              </p>
              <p>
                <b>Projects.</b> In the event of a loss due to a project defined
                as an hourly or piece work done on expected or held inventory
                (“project”), you declare that SKUBIQ’s liability shall be
                limited to the hourly charge you paid to SKUBIQ for the project.
              </p>
            </article>
            <article>
              <h3>Termination And Closing Your Account.</h3>
              <p>
                <b>Held Accounts.</b> A held Account will be inaccessible to
                you, and all activity will be suspended. SKUBIQ reserves the
                right to place an Account on hold for number reasons,{" "}
              </p>
              <p>including but not limited to the following</p>
              <ul>
                <li>
                  <p>Insufficient funds or negative Balance.</p>
                </li>
                <li>
                  <p>Suspicious activity on or through the Account.</p>
                </li>
                <li>
                  <p>
                    If anyone using your Account uses abusive language or
                    otherwise threatens SKUBIQ or its staff.
                  </p>
                </li>
                <li>
                  <p>
                    To allow time to resolve or investigate a third party
                    complaint of a violation of these Terms.
                  </p>
                </li>
                <li>
                  <p>
                    To allow time for investigation or resolution of an
                    unauthorized transaction, customer complaint, dispute or
                    accusation.
                  </p>
                </li>
                <li>
                  <p>
                    To allow time for SKUBIQ to comply with your extraordinary
                    support requests.
                  </p>
                </li>
              </ul>
              <p>
                <b>Immediate termination.</b> Immediate termination is where we
                immediately close your Account and ship remaining Inventory to
                the billing address listed in your Account, at your expense. If
                no address is available, we will liquidate your Inventory (See
                Abandoned Account and Liquidation for more information). SKUBIQ
                reserves the right to immediately terminate an Account for a
                number reasons including but not limited to the following:
              </p>
              <ul>
                <li>
                  <p>Ignoring a warning of misuse of the SKUBIQ Services.</p>
                </li>
                <li>
                  <p>
                    Violation of SKUBIQ’s Policies (including, without
                    limitation, the Acceptable Use Policy), these Terms or any
                    other conditions of use.
                  </p>
                </li>
              </ul>
              <p>
                <b>
                  <u>Termination with 30 days notice.</u>
                </b>{" "}
                SKUBIQ reserves the right to terminate an Account for any reason
                upon 30 days notice (“Termination Notice Period”) which SKUBIQ
                shall send to you via email. It is your responsibility to make
                sure SKUBIQ has a working email for you. You may have full or
                limited use of your Account during the Termination Notice Period
                in our discretion, with the intention of allowing you to expire
                existing inventory without it being shipped back to you. Any
                Inventory that remains in Inventory at the expiration of the
                Termination Notice Period will be shipped to the address on file
                and, if address is not on file, the billing address on your
                credit card, at your expense. If no address is available or no
                balance is available to pay for shipment back to you, we will
                liquidate your remaining Inventory. (See Abandoned Account and
                Liquidation Policy for more information.)
              </p>
              <p>
                <b>
                  <u>Payment for Services prior to Inventory Removal.</u>
                </b>{" "}
                Prior to Merchant removing all Inventory following termination
                and as stated herein, Merchant shall pay SKUBIQ i) all current
                amounts outstanding for Service fees, transportation, storage
                and other fees and ii) a good faith estimate of amounts that
                will become due for transportation, storage and Service and
                other fees that will be incurred prior to the termination date.
              </p>
              <p>
                <u>
                  <b>Additional Actions.</b>
                </u>{" "}
                If you violate the Policies or these Terms, we may close, put on
                hold, or limit access to your Account or the SKUBIQ Services as
                set forth above. Without limiting any of remedies under law or
                equity, we may also take any actions we deem necessary or
                advisable, including, without limitation, any of the following
                actions:
              </p>
              <ul>
                <li>
                  <p>
                    Contact buyers who have received Inventory that we shipped
                    on your half, contact your bank or credit card issuer, and
                    warn other users, law enforcement, or impacted third parties
                    of your actions.
                  </p>
                </li>
                <li>
                  <p>Update inaccurate information.</p>
                </li>
                <li>
                  <p>
                    We may refuse to provide the SKUBIQ Services to you in the
                    future.
                  </p>
                </li>
                <li>
                  <p>
                    We may hold your funds and Inventory for up to 180 days if
                    reasonably needed to protect against the risk of liability;
                    and We may take legal action against you.
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>Disputes</h3>
              <p>
                Please note, while disagreements sometimes arise, SKUBIQ wants
                to address any complaint with the SKUBIQ Service in a proactive
                manner. Please log a support case for resolution, you can send
                an e-mail to{" "}
                <a href="mailto:customercare@skubiq.com">
                  customercare@skubiq.com
                </a>
                . If you feel an escalation is merited please e-mail{" "}
                <a href="mailto:legal@skubiq.com">legal@skubiq.com</a> regarding
                your unresolved case and your concern. For purposes of clarity,
                this does not waive the notice requirement set forth below.
              </p>
              <ul>
                <li>
                  <p>
                    Merchant Protection is Available. SKUBIQ strongly urges you
                    to appreciate that errors happen with storage and shipping,
                    and that the best defense is protecting yourself ahead of
                    time. SKUBIQ offers multiple merchant protection services
                    that can be taken advantage of anytime. Please note these
                    services may not be applied retroactively, and are thus best
                    used at the time of Account setup.
                  </p>
                  <p>
                    Insurance is Available. Insurance is available, and strongly
                    recommended. Inventory insurance and outbound shipping
                    insurance are available options in your Account and may
                    offer remedies in the event of an error. To see details,
                    please visit the Help Center on www.MWMSC20LandingSite.com{" "}
                  </p>
                  <p>
                    Customer Care is Available. SKUBIQ offers support services
                    to active Accounts in Good Standing. Support services are
                    provided for informational purposes and is not a guarantee.
                    SKUBIQ specifically disclaims any liability for opinions
                    followed or not followed by merchants.
                  </p>
                  <p>
                    Promptly Review Your SKUBIQ Charges. SKUBIQ urges you to
                    review your SKUBIQ service and Usage fees regularly so that
                    we can work with you in a timely manner should there be a
                    discrepancy or disagreement as to a charge. Please see
                    section 5.5 that limits the billing review period to 90 days
                    for SKUBIQ fees and less time for some carrier charges due
                    to carrier or insurance terms of service.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Mandatory Mediation and Arbitration of Disputes.</b>{" "}
                    Except for disputes relating to payment for SKUBIQ Services
                    or as otherwise expressly provided in these Terms, all
                    disputes arising under these Terms, any dispute, claim or
                    controversy arising out of or relating to these Terms or the
                    breach, termination, enforcement, interpretation or validity
                    thereof, including the determination of the scope or
                    applicability of this agreement to arbitrate, or otherwise
                    from your use of or access to the website or Services, shall
                    be determined by arbitration in the Visakhapatnam , Andhra
                    Pradesh, India (using the English language), before one
                    arbitrator. The arbitration shall be administered by JAMS
                    pursuant to its Streamlined Arbitration Rules and Procedures
                    then in effect or such other rules as may be stipulated to
                    by the parties. Judgment on the Award may be entered in any
                    court having jurisdiction. This clause shall not preclude
                    parties from seeking provisional remedies in aid of
                    arbitration from a court of appropriate jurisdiction. The
                    arbitrator may, in the Award, allocate all or part of the
                    costs of the arbitration, including the fees of the
                    arbitrator and the reasonable attorneys’ fees of the
                    prevailing party.
                  </p>
                  <p>
                    Prior to the appointment of the arbitrator, and within 10
                    days from the date of commencement of the arbitration, the
                    parties shall submit the dispute to JAMS for mediation. The
                    parties will cooperate with JAMS and with one another in
                    selecting a mediator from JAMS panel of neutrals, and in
                    promptly scheduling the mediation proceedings. The parties
                    covenant that they will participate in the mediation in good
                    faith, and that they will share equally in its costs. All
                    offers, promises, conduct and statements, whether oral or
                    written, made in the course of the mediation by any of the
                    parties, their agents, employees, experts and attorneys, and
                    by the mediator or any JAMS employees, are confidential,
                    privileged and inadmissible for any purpose, including
                    impeachment, in any arbitration or other proceeding
                    involving the parties, provided that evidence that is
                    otherwise admissible or discoverable shall not be rendered
                    inadmissible or non-discoverable as a result of its use in
                    the mediation. If the dispute is not resolved within 30 days
                    from the date of the submission of the dispute to mediation
                    (or such later date as the parties may mutually agree in
                    writing), the administration of the arbitration shall
                    proceed forthwith. The mediation may continue, if the
                    parties so agree, after the appointment of the arbitrators.
                    Unless otherwise agreed by the parties, the mediator shall
                    be disqualified from serving as arbitrator in the case. The
                    pendency of a mediation shall not preclude a party from
                    seeking provisional remedies in aid of the arbitration from
                    a court of appropriate jurisdiction, and the parties agree
                    not to defend against any application for provisional relief
                    on the ground that a mediation is pending.
                  </p>
                </li>
              </ul>
            </article>
            <article>
              <h3>Governing Law And Venue</h3>
              <p>
                These Terms and performance by the parties hereunder shall be
                construed in accordance with the applicable laws of the State of
                Andhra Pradesh and the laws of Union of India without regard to
                conflicts of law provisions thereof, or, as appropriate the
                federal laws. Any action or proceeding arising from or relating
                to these Terms must be brought in a High Court of Andhra
                Pradesh, Hyderabad, India. You and SKUBIQ consent to the
                exclusive jurisdiction of, and venue in, the state of Andhra
                Pradesh, India. Notwithstanding the foregoing, SKUBIQ may seek
                injunctive or other equitable relief to protect SKUBIQ’s
                intellectual property rights in any court of competent
                jurisdiction.
              </p>
            </article>
            <article>
              <h3>General Provisions</h3>
              <p>
                <u>
                  <b>Severability.</b>
                </u>{" "}
                If any provision of these Terms is held invalid or unenforceable
                by a court of competent jurisdiction, such provision shall be
                modified to the extent necessary to make such provision valid
                and enforceable and the remaining provisions of these Terms
                shall remain in effect and enforceable in accordance with their
                terms.
              </p>
              <p>
                <b>
                  <u>No Waiver.</u>
                </b>{" "}
                Failure or delay of SKUBIQ to exercise a right or power under
                these Terms shall not operate as a waiver thereof, nor shall any
                single or partial exercise of a right or power preclude any
                other future exercise thereof.
              </p>
              <p>
                <b>
                  <u>Notices to You.</u>
                </b>{" "}
                You agree that SKUBIQ may provide notice to you by posting it on
                our website, emailing it to the email address listed in your
                Account, or mailing it to the street address listed in your
                Account. Such notice shall be considered to be received by you
                within 24 hours of the time it is posted to our website or
                emailed to the email address listed in your Account. If the
                notice is sent by mail, we will consider it to have been
                received by you three Business Days after it is sent. SKUBIQ
                reserves the right to terminate your Account if you withdraw
                your consent to receive electronic communications.
              </p>
              <p>
                <b>
                  <u>Notices to SKUBIQ.</u>
                </b>{" "}
                Except as otherwise stated, legal notices to SKUBIQ must be sent
                by postal mail to: Avya Inventrax Private Limited,, Attention:
                Legal Department,{" "}
                <b>
                  <i>
                    Sampath Vinyagur, D.No. 50-94-14/3, 1st & 2nd
                    Floor,Santhipuram, Visakhapatnam, Andhra Pradesh, India-
                    530016.
                  </i>
                </b>{" "}
                , and shall not be deemed to be received until actually received
              </p>
              <p>
                <b>
                  <u>Assignment</u>
                </b>
                . You may not transfer or assign any rights or obligations you
                have under this Agreement without SKUBIQ’s prior written consent
                and any attempted assignment without that consent will be void.
                SKUBIQ reserves the right to transfer or assign this Agreement
                or any right or obligation under this Agreement at any time.
                Subject to the foregoing provisions of this Section, these Terms
                shall be binding on and inure to the benefit of the parties’
                successors and assigns.
              </p>
              <p>
                <b>
                  <u>Conflict of Terms.</u>
                </b>{" "}
                If there is a conflict between these Terms and the terms on any
                air waybill, bill of lading or other transit documentation set
                forth by the contracted carrier, the carrier’s terms will
                control. If not stated within the carrier’s terms, the Terms as
                stated herein shall control.
              </p>
              <p>
                <b>
                  <u>
                    Entire Agreement and Modifications to Terms and Policies.
                  </u>
                </b>{" "}
                These Terms and the Policies are the entire statement of the
                terms that govern your use of the SKUBIQ Services and the SKUBIQ
                Website. SKUBIQ MAY MAKE CHANGES TO THESE TERMS AND/OR THE
                POLICIES FROM TIME TO TIME IN SKUBIQ’S SOLE DISCRETION. YOU
                ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SKUBIQ SERVICES OR
                THE SKUBIQ WEB SITE AFTER SKUBIQ POSTS OR OTHERWISE MAKES
                AVAILABLE MODIFIED TERMS AND/OR POLICIES WILL CONSTITUTE YOUR
                ACCEPTANCE OF THOSE MODIFIED TERMS AND POLICIES. SKUBIQ will
                endeavor to provide you with notice of any changes to these
                Terms or the Policies, but SKUBIQ’s failure to do so shall not
                excuse your obligation to comply with such modified Terms and
                Policies.
              </p>
              <p>
                <b>
                  <u>Attorneys’ Fees and Costs.</u>
                </b>{" "}
                SKUBIQ shall have the right to collect from you its reasonable
                costs and necessary disbursements and attorneys’ fees incurred
                in enforcing these Terms.
              </p>
              <p>
                <b>
                  <u>Force Majeure.</u>
                </b>{" "}
                SKUBIQ shall not be liable for any delays or inability to
                perform the SKUBIQ Services caused by forces beyond SKUBIQ’s
                control including, without limitation, acts of God or acts of
                third party service providers including but not limited to
                carriers and postage systems. When Inventory is ordered out, in
                the case of acts of God, war, terrorism, public enemies, seizure
                under legal process, strikes, lockouts, riots and civil
                commotions, or any reason beyond SKUBIQ’s control, or because of
                loss or destruction of Inventory for which SKUBIQ is not liable,
                or because of any other excuse provided by law, SKUBIQ shall not
                be liable for failure to carry out such instructions and
                Inventory remaining in storage will continue to be subject to
                regular storage charges. If SKUBIQ has exercised reasonable care
                and is unable, due to causes beyond its control, to affect
                delivery before expiration of the current storage period, the
                Inventory will be subject to storage charges for each succeeding
                storage period.
              </p>
            </article>
          </div>
        </section>

        {/* <!-- footer-area --> */}
        <FooterPagesFooter />
      </>
    );
  }
}
