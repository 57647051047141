import React, { Component } from 'react'
import "../HomePage/HomePage.css";
import "../HomePage/responsivelandingsite.css";
import "../HomePage/stylelandingsite.css";
import "../HomePage/mybootstrap.css";
// import DownArrrow from "../../Assests/img/arrow.svg";
import FooterPagesFooter from '../Footer/FooterPagesFooter';
import Header from './header'
import { Link } from 'react-router-dom';
import Doubalarrow from "../../Images/double_arrow.svg"
import { CheckOutlined } from '@ant-design/icons';
// import dat from "../HomePage/HomePage"

interface IFeatures {
    ViewLess: boolean
}
export default class Keyfeatures extends Component<any, IFeatures> {
    constructor(props: any) {
        super(props)
        this.state = {
            ViewLess: false
        }
    }
    ViewMoreFeatures = () => {
        
        var ele: any = document.getElementById('viewMore');
        ele.classList.add('ViewMoreFeatures');
        this.setState({ ViewLess: true })
    }
    ViewLessFeatures = () => {
        var ele: any = document.getElementById('viewMore');
        ele.classList.remove('ViewMoreFeatures');
        this.setState({ ViewLess: false })
    }
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <p className='bedc'>
                        <Link to="/">
                            <span className='home_bedcle'>Home</span>

                        </Link> <img src={Doubalarrow} className="bed_arrow" alt="arrow" /> Keyfeatures
                    </p>
                </div>
                <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="section-title text-left mb-40">
                                        <h2 className="title">Key Features</h2>
                                    </div>
                                    <div>
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Master Data Management
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Suppliers</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Customer</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Address Book</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Item Master</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Unit of Measurement</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Purchase Order</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Sales Orders</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Transfer Orders</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Warehouses</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Users</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Inbound
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;PO/Invoice/ASN based receipts{" "}</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Blind Receipts</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Gate Entry</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Dock Sequencing/Assignment</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Palletisation/Cartonisation</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;System Suggested Putaway</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;GRN Confirmation</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Crossdocking</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Inward Quality Control</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Inward Discrepancy Capture</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Outbound
                                                    </h4>
                                                    <div className="fc-li outBond">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;SO/STO based dispatch's</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Stock reservation</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Vehicle load planning</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Dispatch dock Assignment</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Dispatch note/Delivery challan</span></li>
                                                        </ul>
                                                        <span className='picking-1'>Picking</span>
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Batch/Wave/Zone picking</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;SLED/FEFO/FIFO/LIFO</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Pick skipping</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bin/SKU audit log on pick skip</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Inventory
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Batch/lot traceability</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Inventory ageing control</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Rule based inventory reservations</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Multi-UoM conversions</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Kitting/Dekitting</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bin Level tracking</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Serialized inventory</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Handling Units</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Shelf-life control</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Ageing control</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="viewMore">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Housekeeping
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Perpetual stock counts</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Stock Adjustments</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Stock Transfers</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bin-to-bin transfers</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Fast moving bin replenishment</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bin Consolidations</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Lost & found reconciliations </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>K3PL Billing
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Configurable 3PL billing</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;VAS calculation</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;GST/VAT based invoice</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Multi-currency</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Site based pricing</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Special Price configuration</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Contract Management</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Auto Data Capture
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul className="auto-data">
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Automatic data capture via handheld mobile devices</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Android App. for standard HHT's</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;GS1/EAN128, CODE128, CODE39, QR/2D/Datamatrix Standards</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bin / Pallet/ Carton / SKU's</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Location Management
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bulk Location Creation</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Aisle/Rack/Column/Level/Bin Hierarchy Management</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Location Label Printing</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Dock/Aisle Card Printing</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Real Time 2D Virtual Warehouse/Inventory View</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>3rd Party API Integration
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul className="integra">
                                                            <li className="first-li">
                                                                <span className='leftText'>JSON REST API’s</span></li>
                                                            <li>
                                                                <span className='leftText'>XML based SOAP Services{" "}</span></li>
                                                            <li>
                                                                <span className='leftText'>Master Data API’s</span></li>
                                                            <li>
                                                                <span className='leftText'>Order sync. API’s</span></li>
                                                            <li>
                                                                <span className='leftText'>Inventory sync API’s</span></li>
                                                            <li>
                                                                <span className='leftText'>Product Information sync API’s</span></li>
                                                            <li>
                                                                <span className='leftText'>Webhooks for event based posting to 3rd Part Apps.</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>eCommerce Integration
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul className="e-commerce">
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Amazon Marketplace</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Flipkart</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Shopify</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Last Mile Delivery Integration
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul className="la-mile">
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;eCart</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;DTDC</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Delivery</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;DHL</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;FedEx</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;UPS</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bluedart</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Reporting & Dashboards
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Dashboard with widgets rendering graphical data of store activities</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Suppliers, Operators performance reports</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Inward quality check & demand forecast report</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Daily closing stock report & misc. transactions report</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Forward, backward material traceability report</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='MobileModeKeyFeatures'>
                                        {this.state.ViewLess === false ?
                                            <span
                                                className="know-more"
                                                onClick={() => this.ViewMoreFeatures()}
                                            >
                                                View More...
                                            </span>
                                            :
                                            <span
                                                className="know-more"
                                                onClick={() => this.ViewLessFeatures()}
                                            >
                                                View Less...
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                <FooterPagesFooter />
            </>
        )
    }
}
