import React from 'react';
// import "../homepage/responsive.css";
import "./../HomePage/HomePage.css";
import "./../HomePage/responsivelandingsite.css";
import "./../HomePage/stylelandingsite.css";
import "./../HomePage/mybootstrap.css";
import { Service } from '../Common/Services.service';
import { Tooltip } from 'antd';
// import Home_Icon_WH from './../../Assests/img/Home_Icon_WH.svg';
import { LogoutOutlined, HomeOutlined, UserAddOutlined, EyeOutlined } from '@ant-design/icons';
import AdminDashboard from '../AdminDashboard/AdminDashboard';
import AdminCreateAccount from '../AdminCreateAccount/AdminCreateAccount';
import DBAccounts from "../DBAllocatedAccounts/DBAccounts"



interface Price {
  IsShowAdminCreateAccount: boolean,
  IsShowAdminDashboard: boolean,
  IsShowDBAccount: boolean
}
export default class AdminDashboardRoute extends React.Component<any, Price> {
  private Service: Service;
  columns: any;
  columnsmodel: any;
  AwsEntityObject: any = [];
  constructor(props: any) {
    super(props);
    this.Service = new Service();
    this.state = {
      IsShowAdminCreateAccount: false,
      IsShowAdminDashboard: true,
      IsShowDBAccount: false,

    }

  }

  componentDidMount = async () => {


  }
  logout = () => {
    setTimeout(() => {
      window.localStorage.clear();
      window.location.href = "/";
    }, 1000);
  };
  create = (id: any) => {
    if (id === 1) {
      this.setState({ IsShowDBAccount: true, IsShowAdminCreateAccount: false, IsShowAdminDashboard: false })
    }
    else if (id === 2) {
      this.setState({ IsShowAdminCreateAccount: true, IsShowAdminDashboard: false, IsShowDBAccount: false })
    }
    else if (id === 3) {
      this.setState({ IsShowAdminDashboard: true, IsShowAdminCreateAccount: false, IsShowDBAccount: false })
    }
  };
  render() {
    return (
      <>

        <Tooltip title="Account List">
          <span className="homestyle3" onClick={() => this.create(1)}>
            <EyeOutlined />
          </span>
        </Tooltip>
        <Tooltip title="Create Account">
          <span className="homestyle2" onClick={() => this.create(2)}>
            <UserAddOutlined />
          </span>
        </Tooltip>
        <Tooltip title="Dashboard">
          <span className="homestyle1" onClick={() => this.create(3)}>
            <HomeOutlined />
          </span>
        </Tooltip>
        <Tooltip title="Logout">
          <span className="homestyle" onClick={this.logout}>
            {/* <img src={Home_Icon_WH} alt="Home_Icon_WH" /> */}
            <LogoutOutlined />
          </span>
        </Tooltip>
        {
          this.state.IsShowDBAccount === true ?
            <DBAccounts /> : <></>
        }
        {
          this.state.IsShowAdminCreateAccount === true ?
            <AdminCreateAccount /> : <></>
        }
        {
          this.state.IsShowAdminDashboard === true ?
            <AdminDashboard /> : <></>
        }
      </>
    )
  }
}

