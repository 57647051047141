import React, { Component } from "react";
import "../loginpage/login.css";
// import Home_Icon_WH from './../../Assests/img/Home_Icon_WH.svg';
import "../../Modules/Common/AutoComplete/MerlinAutoComplete.css"
import "../AccountDetails/AccountDetails.css";
import { NavLink } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import { Service } from '../Common/Services.service';
import StickyText from "../Common/StikeyText";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
interface LoginEntity {
  spin_loading: boolean

  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string

  oldpassword: string
  newpassword: string
  repassword: string
  passwordShown: boolean
  newpasswordShown: boolean,
  repasswordShown: boolean
}
export default class ChangePassword extends Component<any, LoginEntity> {
  private Service: Service;
  constructor(props: any) {
    super(props);
    this.Service = new Service();
    this.state = {
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',

      spin_loading: false,

      oldpassword: '',
      newpassword: '',
      repassword: '',
      passwordShown: false,
      newpasswordShown: false,
      repasswordShown: false
    };
  }
  getDataFromSticky(show: boolean) {
    
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  ChangePassword = async () => {
    if (this.state.oldpassword === "" || this.state.oldpassword === undefined || this.state.oldpassword === null) {
      this.stickyText(true, 'error', 'Error', 'Please Enter Old Password');
      return false;
    }
    if (this.state.newpassword === "" || this.state.newpassword === undefined || this.state.newpassword === null) {
      this.stickyText(true, 'error', 'Error', 'Please Enter New Password');
      return false;
    }
    if (this.state.repassword === "" || this.state.repassword === undefined || this.state.repassword === null) {
      this.stickyText(true, 'error', 'Error', 'Please Enter Re-enter New Password');
      return false;
    }
    if (this.state.newpassword !== this.state.repassword) {
      this.stickyText(true, 'error', 'Error', 'Please Check Password');
      return false;
    }
    const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
    // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    
    if (localTemp.length) {
      const local: any = localTemp ? JSON.parse(localTemp) : []
      let ChangePasswordEntityObject: any = {
        Email: local.email,
        OldPassword: this.state.oldpassword,
        NewPassword: this.state.newpassword
      }
      this.setState({ spin_loading: true })
      let res: any = await this.Service.ChangePasswordService(ChangePasswordEntityObject);
      this.setState({ spin_loading: false, oldpassword: "", newpassword: "", repassword: "" });
      var ID = res.data.length ? res.data[0].N : 0
      if (ID === 1) {
        this.stickyText(true, 'success', 'success', 'Password updated successfully')
      }
      if (ID === 4) {
        this.stickyText(true, 'error', 'Error', 'Old password not matched')
      }
      // 4 - old password not matched
    }
  }
  handleChange = (e: any) => {  
    this.setState({ ...this.state, [e.target.name]: e.target.value, sticky_show: false })
  };

  togglePassword = (val:any,e: any) => {
    if (val === 'old') {
      setTimeout(() => {
        this.setState({
          passwordShown: !this.state.passwordShown, sticky_show: false
        })
      }, 2000);
      this.setState({
        passwordShown: !this.state.passwordShown, sticky_show: false
      })
    }
    else if (val === 'newp') {
      setTimeout(() => {
        this.setState({
          newpasswordShown: !this.state.newpasswordShown, sticky_show: false
        })
      }, 2000);
      this.setState({
        newpasswordShown: !this.state.newpasswordShown, sticky_show: false
      })
    }
    else if(val === 'rep') {
      setTimeout(() => {
        this.setState({
          repasswordShown: !this.state.repasswordShown, sticky_show: false
        })
      }, 2000);
      this.setState({
        repasswordShown: !this.state.repasswordShown, sticky_show: false
      })
    }
  };

  render() {
    return (
      <div className="">
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />
                :
                <></>
            }
          </>
        </div>
        <div className={this.state.spin_loading ? "spin_class2" : "spin_class1"}>
          <img src={skubiq_loader} alt="loading..." height={80} width={80} />
        </div>
        <div className="ChangePassword">
          <div className="login1">
            <div className="login">
              <div className="title">
                {/* <img src={logo} style={{ marginBottom: "0px" }} alt=""/> */}
              </div>

              <form action="#">
                <div className="title" style={{marginBottom:'20px'}}>
                  <h4>Change Password</h4>
                </div>
                <div className="input-box" style={{marginBottom:'20px'}}>
                  <div className="divFlex">
                    <input
                      type={this.state.passwordShown ? "text" : "password"}
                      required
                      name="oldpassword"
                      value={this.state.oldpassword}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    {this.state.passwordShown ? <EyeInvisibleFilled onClick={(e: any) => this.togglePassword('old', e)} /> : <EyeFilled onClick={(e: any) => this.togglePassword('old', e)} />}
                    <label>Old-Password </label>
                    <span className="required-label"></span>
                  </div>
                </div>
                <div className="input-box" style={{marginBottom:'20px'}}>
                  <div className="divFlex">
                    <input
                      type={this.state.newpasswordShown ? "text" : "password"}
                      required
                      name="newpassword"
                      value={this.state.newpassword}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                    {this.state.newpasswordShown ? <EyeInvisibleFilled onClick={(e: any) => this.togglePassword('newp', e)} /> : <EyeFilled onClick={(e: any) => this.togglePassword('newp', e)} />}
                    <label>New-Password </label>
                    <span className="required-label"></span>
                  </div>
                </div>
                <div className="input-box" style={{marginBottom:'20px'}}>
                  <div className="divFlex">
                    <input
                      type={this.state.repasswordShown ? "text" : "password"}
                      required
                      name="repassword"
                      value={this.state.repassword}
                      onChange={this.handleChange}
                      autoComplete="off"
                    // iconRender={(visible: any) =>
                    //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                    />
                    {this.state.repasswordShown ? <EyeInvisibleFilled onClick={(e: any) => this.togglePassword('rep', e)} /> : <EyeFilled onClick={(e: any) => this.togglePassword('rep', e)} />}
                    <label className="active">Re-enter New-Password </label>

                    <span className="required-label"></span>
                  </div>
                </div>
                <br />

                {/* <div className="input-box button">
                <input type="button" name="" value="Sign In" onClick={() =>this.Login()}/>
              </div> */}
              </form>
              {/* <div className="option">or Connect With Social Media</div> */}
              <div style={{ display: "flow-root", marginTop: "10px" }}>
                <NavLink to="/MyProfile">
                  <span className="btn-left">
                    Previous
                  </span>
                </NavLink>
                <span
                  className="btn-right"
                  onClick={() => this.ChangePassword()}
                  style={{ float: "right" }}
                >
                  Change <i className="material-icons right"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
