import React, { Component } from 'react'
import { reactLocalStorage } from 'reactjs-localstorage';
import { Service } from '../Common/Services.service';
import "./ManagePlans.css";
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';
import { Button, Card, Col, Row } from 'antd';
import { UpdateSubscriptionPalnEntityObject } from './../Common/Interface';
import StickyText from '../Common/StikeyText';
import { NavLink } from 'react-router-dom';
interface GetSilverData {
    GetLiteData:any
    GetSilverData: any
    GetGoldData: any
    GetPlatinumData: any
    SilverButtonText: string
    LiteButtonText: string
    GoldButtonText: string
    PlatinumButtonText: string
    spin_loading: boolean
    sticky_show: boolean
    sticky_message: string
    sticky_type: string
    sticky_title: string
}
export default class Manageplans extends Component<any, GetSilverData> {
    private Service: Service;

    constructor(props: any) {
        super(props)
        this.Service = new Service();
        this.state = {
            LiteButtonText:'',
            SilverButtonText: "",
            GoldButtonText: "",
            PlatinumButtonText: "",
            GetLiteData:[],
            GetGoldData: [],
            GetSilverData: [],
            GetPlatinumData: [],
            sticky_show: false,
            sticky_message: '',
            sticky_type: '',
            sticky_title: '',
            spin_loading: false
        }
    }
    getDataFromSticky(show: boolean) {
        
        this.setState({ sticky_show: show })
    }
    stickyText(show: boolean, type: string, title: string, message: string) {
        this.setState({
            sticky_show: show,
            sticky_type: type,
            sticky_title: title,
            sticky_message: message
        })
        return false;
    }
    UpdateSubscriptionPaln = async (id: number) => {
        // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
        const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
        
        if (localTemp.length) {
            const local: any = localTemp ? JSON.parse(localTemp) : []
            var PlanCode = "";
            var PlanName = "";
            if (id === 0) {
                PlanCode = this.state.GetLiteData.plan_code;
                PlanName = this.state.GetLiteData.name;
            }
           else if (id === 1) {
                PlanCode = this.state.GetSilverData.plan_code;
                PlanName = this.state.GetSilverData.name;
            }
            else if (id === 2) {
                PlanCode = this.state.GetGoldData.plan_code;
                PlanName = this.state.GetGoldData.name;
            }
            else {
                PlanCode = this.state.GetPlatinumData.plan_code;
                PlanName = this.state.GetPlatinumData.name;
            }
            var UpdateSubscriptionPalnEntityObject: UpdateSubscriptionPalnEntityObject = {
                SubscriptionID: local.SubscriptionValue,
                PlanCode: PlanCode,
                PlanName: PlanName,
                Apps_MST_Account_ID: local.Apps_MST_Account_ID
            }
            this.setState({ spin_loading: true })
            let res: any = await this.Service.UpdateSubscriptionPalnService(UpdateSubscriptionPalnEntityObject)
            
            this.setState({ spin_loading: false })
            const tempdata = res.data ? res.data : [];
            if (!tempdata.length) {
                this.stickyText(true, 'error', 'Error', 'Something went wrong');
            }
            else {
                window.location.href = tempdata;
            }
        }

    }
    componentDidMount = async () => {
        
        this.setState({ spin_loading: true })
        const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
        // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
        
        if (localTemp.length) {
            const local: any = localTemp ? JSON.parse(localTemp) : []

            // var num = "771398000003477305";
            let TransationEntityObject: any = {

            }
            let res: any = await this.Service.GetManagePlanDataService(TransationEntityObject)
            this.setState({ spin_loading: false })
            if(res.data.length){
                var lite = res.data.findIndex((obj:any) => obj.plan_code === "SKU23-1W2U");
                var silver = res.data.findIndex((obj:any) => obj.plan_code === "SKU22-1W5U");
                var gold = res.data.findIndex((obj:any) => obj.plan_code === "SKU22-2W10U");
                var platinum = res.data.findIndex((obj:any) => obj.plan_code === "SKU22-3W20U");
                var litedata :any= [];
                var silverdata :any= [];
                var golddata:any = [];
                var platinumdata :any= [];
                if(lite > -1) {
                    litedata = res.data[lite];
                }
                if(silver > -1) {
                    silverdata = res.data[silver];
                }
                if(gold > -1) {
                    golddata = res.data[gold];
                }
                if(platinum > -1) {
                    platinumdata = res.data[platinum];
                }
                
                var seq = local.SequenceValue;
                var LiteButtonText = seq === "0" ? "Current Plan" : seq === "2" ? "Downgrade": seq === "3" ? "Downgrade" : seq === "4" ?   "Downgrade" :  "Upgrade" ;
                var SilverButtonText = seq === "0" ? "Upgrade" : seq === "2" ? "Current Plan" : seq === "3" ? "Downgrade" : seq === "4" ?   "Downgrade" :  "Upgrade" ;
                var GoldButtonText = seq === "0" ? "Upgrade" : seq === "2" ? "Upgrade" : seq === "3" ? "Current Plan" : seq === "4" ?   "Downgrade" :  "Upgrade" ;
                var PlatinumButtonText = seq === "0" ? "Upgrade" :  seq === "2" ? "Upgrade" : seq === "3" ? "Upgrade" : seq === "4" ?   "Current Plan" :  "Upgrade" ;
                this.setState({ 
                    GetLiteData: litedata, 
                    GetSilverData: silverdata, 
                    GetGoldData: golddata, 
                    GetPlatinumData: platinumdata, 
                    LiteButtonText:LiteButtonText,
                    SilverButtonText: SilverButtonText, 
                    GoldButtonText: GoldButtonText, 
                    PlatinumButtonText: PlatinumButtonText
                })
                
            }
        }
    }
    render() {
        return (
            <>
                <div className='stikey'>
                    <>
                        {
                            this.state.sticky_show ?
                                <StickyText
                                    display={this.state.sticky_show}
                                    sticky_type={this.state.sticky_type}
                                    sticky_title={this.state.sticky_title}
                                    sticky_message={this.state.sticky_message}
                                    sendData={this.getDataFromSticky}
                                    key={this.state.sticky_title}
                                />

                                :
                                <></>
                        }
                    </>
                </div>
                <div className={this.state.spin_loading ? "spin_class2" : "spin_class1"}>
                    <img src={skubiq_loader} alt="loading..." height={80} width={80} />
                </div>

                <Card className='Subscription_card'>
                    <Row gutter={16}>
                        <Col className='item' span={6}><NavLink to={'/Subscription'}>Current Plan</NavLink></Col>
                        <Col className='item active' span={6}><NavLink to={'/ManagePlans'}>Manage Plans</NavLink></Col>
                        <Col className='item' span={6}><NavLink to={'/ManageAddons'}>Manage Addons</NavLink></Col>
                        <Col className='item' span={6}><NavLink to={'/CancelSubscription'}>Cancel Subscription</NavLink></Col>
                    </Row>
                </Card>
                <div>
                    <div className='managepans'>
                        <table style={{ width: "100%", padding: "20px 10px" }}>
                            <tr style={{ height: "100px", borderBottom: "1px solid #bebebe" }} className='managepanshead'>
                                <th style={{ width: "25%" }}>
                                </th>
                                <th>
                                    <div className='plans' style={{ backgroundColor: "#d3eeec" }}>
                                        <span>
                                            {this.state.GetLiteData.name !== undefined ? this.state.GetLiteData.name :""}
                                        </span><br />
                                        <span>
                                            Rs.{this.state.GetLiteData.recurring_price !== undefined ? this.state.GetLiteData.recurring_price :""}
                                        </span>
                                    </div>
                                </th>
                                <th>
                                    <div className='plans' style={{ backgroundColor: "#dfd8ff" }}>
                                        <span>
                                            {this.state.GetSilverData.name !== undefined ? this.state.GetSilverData.name :""}
                                        </span><br />
                                        <span>
                                            Rs.{this.state.GetSilverData.recurring_price !== undefined ? this.state.GetSilverData.recurring_price :""}
                                        </span>
                                    </div>
                                </th>
                                <th>
                                    <div className='plans' style={{ backgroundColor: "#FFE9D0" }}>
                                        <span>
                                            {this.state.GetGoldData.name !== undefined ? this.state.GetGoldData.name :""}
                                        </span><br />
                                        <span>
                                            Rs.{this.state.GetGoldData.recurring_price !== undefined ? this.state.GetGoldData.recurring_price :""}
                                        </span>
                                    </div>
                                </th>
                                <th>
                                    <div className='plans' style={{ backgroundColor: "#ffcdcd" }}>
                                        <span>
                                            {this.state.GetPlatinumData.name !== undefined ? this.state.GetPlatinumData.name :""}
                                        </span><br />
                                        <span>
                                            Rs.{this.state.GetPlatinumData.recurring_price !== undefined ? this.state.GetPlatinumData.recurring_price :""}
                                        </span>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th className='first_left'>
                                    Plan Code
                                </th>
                                 <td>
                                    {this.state.GetLiteData.plan_code !== undefined ? this.state.GetLiteData.plan_code :""}
                                </td>
                                <td>
                                    {this.state.GetSilverData.plan_code !== undefined ? this.state.GetSilverData.plan_code :""}
                                </td>
                                <td>

                                    {this.state.GetGoldData.plan_code !== undefined ? this.state.GetGoldData.plan_code :""}
                                </td>
                                <td>
                                    {this.state.GetPlatinumData.plan_code !== undefined ? this.state.GetPlatinumData.plan_code :""}
                                </td>
                            </tr>
                            <tr style={{ background: '#f3f6ff' }}>
                                <th className='first_left'>
                                    Users
                                </th>
                                <td>2</td>
                                <td>5</td>
                                <td>10</td>
                                <td>20</td>
                            </tr>
                            <tr >
                                <th className='first_left'>
                                    Warehouse
                                </th>
                                <td>1</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                            </tr>

                            <tr className='pro_desc' style={{ background: '#f3f6ff' }}>
                                <th className='first_left'>
                                    Description
                                </th>
                                <td>
                                    Our professionals are experienced in troubleshooting issues
                                </td>
                                <td>
                                    Our professionals are experienced in troubleshooting issues
                                </td>
                                <td>
                                    Our professionals are experienced in troubleshooting issues
                                </td>
                                <td>
                                    Our professionals are experienced in troubleshooting issues
                                </td>
                            </tr>

                            <tr style={{ height: "100px" }}>
                                <th style={{ width: "25%" }}>

                                </th>
                                <th className='df_ac'>
                                    <Button
                                        className={this.state.LiteButtonText === 'Current Plan' ? 'plansbtns0C' : 'plansbtns0'}
                                        onClick={() => this.UpdateSubscriptionPaln(0)}
                                    >
                                        {this.state.LiteButtonText === 'Current Plan' ? 'Renewal' : this.state.LiteButtonText}
                                    </Button>
                                </th>
                                <th className='df_ac'>
                                    <Button
                                        className={this.state.SilverButtonText === 'Current Plan' ? 'plansbtns1C' : 'plansbtns1'}
                                        onClick={() => this.UpdateSubscriptionPaln(1)}
                                    >
                                        {this.state.SilverButtonText === 'Current Plan' ? 'Renewal' : this.state.SilverButtonText}
                                    </Button>
                                </th>
                                <th className='df_ac'>
                                    <Button
                                        className={this.state.GoldButtonText === 'Current Plan' ? 'plansbtns2C' : 'plansbtns2'}
                                        onClick={() => this.UpdateSubscriptionPaln(2)}
                                    >
                                        {this.state.GoldButtonText === 'Current Plan' ? 'Renewal' : this.state.GoldButtonText}
                                    </Button>
                                </th>
                                <th className='df_ac'>
                                    <Button
                                        // disabled={this.state.PlatinumButtonText === 'Current Plan' ? true : false}
                                        className={this.state.PlatinumButtonText === 'Current Plan' ? 'plansbtns3C' : 'plansbtns3'}
                                        onClick={() => this.UpdateSubscriptionPaln(3)}
                                    >
                                        {this.state.PlatinumButtonText === 'Current Plan' ? 'Renewal' : this.state.PlatinumButtonText}
                                    </Button>
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}
