import { Card, Table } from 'antd'
import React, { Component } from 'react'
import { Service } from '../Common/Services.service';
import { TransationEntityObject } from '../Common/Interface'
// import { LoginStoreObj } from '../Common/Store.store';
import { reactLocalStorage } from 'reactjs-localstorage';
import StickyText from '../Common/StikeyText';
import { DateFormatStore } from '../Common/DateConversion';
import skubiq_loader from './../../Assests/img/skubiq_loader.gif';

interface GetTransactionData {
  GetTransactionData: any
  sticky_show: boolean
  sticky_message: string
  sticky_type: string
  sticky_title: string
  spin_loading:boolean
}
export default class TransactionHistory extends Component<any, GetTransactionData> {
  /**
   * name
   */
  public columns: any = []
  private Service: Service;

  constructor(props: any) {
    super(props)
    this.Service = new Service();
    this.state = {
      GetTransactionData: [],
      sticky_show: false,
      sticky_message: '',
      sticky_type: '',
      sticky_title: '',
      spin_loading:false
    }
    this.columns = [
      {
        title: <span style={{ fontWeight: 'bold' }}>Reference ID</span>,
        dataIndex: 'reference_id',
        key: 'reference_id',
      },
      {
        title:<span style={{ fontWeight: 'bold' }}>Status</span>,
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: <span style={{ fontWeight: 'bold' }}>Type</span>,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: <span style={{ fontWeight: 'bold' }}>Date</span>,
        dataIndex: 'date',
        key: 'date',
        render: (text: any, record: any, index: any) =>
              <span>{record.date ? DateFormatStore.DateStringToNum(record.date ) : ""}</span>
      },
    ];
  }
  getDataFromSticky(show: boolean) {
    
    this.setState({ sticky_show: show })
  }
  stickyText(show: boolean, type: string, title: string, message: string) {
    this.setState({
      sticky_show: show,
      sticky_type: type,
      sticky_title: title,
      sticky_message: message
    })
    return false;
  }
  componentDidMount = async () => {
    this.props.parentCallback('2');
    this.setState({spin_loading:true,sticky_show:false})
    // const localTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    const localTemp: any = reactLocalStorage.get("LandingSiteLoginUserInfo");
    
    if (localTemp.length) {
      const local: any = localTemp ? JSON.parse(localTemp) : []
      let TransationEntityObject: TransationEntityObject = {
        ZohoCustomer_ID: local.ZOHO_Customer_ID
      }
      if(local.ZOHO_Customer_ID === undefined){
        this.stickyText(true, 'error', 'Error', 'Something went wrong');
        return false;
      }
      else{
        let res: any = await this.Service.GetTransactionDataService(TransationEntityObject)
        
        this.setState({spin_loading:false,sticky_show:false})
        const tempdata = res.data ? res.data : [];
        if (!tempdata.length) {
          this.stickyText(true, 'error', 'Error', 'No data found');
        }
        else {
          this.setState({ GetTransactionData: tempdata,sticky_show:false })
        }
      }
    }
  }
  render() {
    return (
      <div>
        <div className={this.state.spin_loading ? "spin_class2" : "spin_class1"}>
            <img src={skubiq_loader} alt="loading..." height={80} width={80}/>
        </div>
        <div className='stikey'>
          <>
            {
              this.state.sticky_show ?
                <StickyText
                  display={this.state.sticky_show}
                  sticky_type={this.state.sticky_type}
                  sticky_title={this.state.sticky_title}
                  sticky_message={this.state.sticky_message}
                  sendData={this.getDataFromSticky}
                  key={this.state.sticky_title}
                />

                :
                <></>
            }
          </>
        </div>
        <Card className='mb_30' style={{margin:"10px"}}>
          <Table
            columns={this.columns}
            dataSource={this.state.GetTransactionData}
            className='tablestriped'
            pagination={{
              // pageSizeOptions: [],
              showSizeChanger: true,
              defaultPageSize: 25,
              hideOnSinglePage: this.state.GetTransactionData !== undefined ? this.state.GetTransactionData.length <= 25 ? true : false : true
            }}
          />
        </Card>
      </div>
    )
  }
}
