import React from "react";
// import "../homepage/responsive.css"
import "./HomePage.css";
import "./responsivelandingsite.css";
import "./stylelandingsite.css";
import "./mybootstrap.css";
import LOgo from "../../Assests/img/logo_skubiq.svg";
import Banner_img from "../../Assests/img/banner_img.webp";
import Faq from "../../Assests/img/faq_img.webp";
import Inventory from "../../Assests/img/inventory_i.webp";
import Manufacturing_I from "../../Assests/img/manufacturing_i.png";
import KPI_I from "../../Assests/img/kpi_i.webp";
import Distribution from "../../Assests/img/distribution.png";
import Manufacturing from "../../Assests/img/manufacturing.png";
import Tpl from "../../Assests/img/3pl.png";
import Automobile from "../../Assests/img/automobile.png";
import Pharma from "../../Assests/img/pharma.png";
import Consumer from "../../Assests/img/Consumer.png";
import Retail from "../../Assests/img/retail.png";
import Basic from "../../Assests/img/basic-1.png";
import Standard from "../../Assests/img/standard-1.png";
import Premium from "../../Assests/img/premium-1.png";
import UnlocK from "../../Assests/img/unlocK.svg";
import DownArrrow from "../../Assests/img/arrow.svg";
import glandpharma_logo from "../../Assests/img/clients/glandpharma_logo.webp"
import swastik from "../../Assests/img/clients/swastiks.webp"
import star_quick from "../../Assests/img/clients/star_quick.webp"
import cci_logistics from "../../Assests/img/clients/cci-logistics-logo.webp"
import nova from "../../Assests/img/clients/Red-nova-logo.webp"
import runwal from "../../Assests/img/clients/runwal_agro_foods.webp"
import alpha from "../../Assests/img/clients/alpha_logistics.webp"
import tech_mahindra from "../../Assests/img/clients/tech_mahindra.webp"
import inbound from "../../Assests/img/inbound.webp"
import Outbound from "../../Assests/img/Outbound.webp"
import Cycle_Counting from "../../Assests/img/Cycle_Counting.webp"
import PL_Billing from "../../Assests/img/3PL_Billing.webp"
import play_btn from "../../Assests/img/play_btn.png"

import signup from "../../Assests/img/signup.webp"
import master_data from "../../Assests/img/master_data_configuration.webp"
import print_tag from "../../Assests/img/print_tag_arcode_labels.webp"
import stocktake from "../../Assests/img/digital_stocktake.webp"
import go_live from "../../Assests/img/go_live.webp"



import { Link } from "react-router-dom";
// import Footer from '../Footer/Footer'
import FooterPagesFooter from "../Footer/FooterPagesFooter";
import { Modal } from "antd";
import { MenuOutlined, DownOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";


// import { CloseOutlined } from '@ant-design/icons';

interface Price {
    basic: number;
    standard: number;
    platinum: number;
    currency: String;
    Lite:number;
    visible: boolean;
    title: string;
    ViewLess: boolean
    CurrencyId:number,
    open: boolean,
    src:string
}
export default class Homepage extends React.Component<any, Price> {
    constructor(props: any) {
        super(props);

        this.state = {
            visible: false,
            basic: 89,
            standard: 99,
            platinum: 129,
            Lite:24,
            currency: "$",
            title: "",
            ViewLess: false,
            CurrencyId:2,
            open: false,
            src:""
        };
    }
    // isSticky = () => {
    //     
    //     const header: any = document.getElementById("sticky-header");
    //     var sticky: any = header.offsetTop
    //     if (window.scrollY > sticky) {
    //         
    //         header.classList.add("sticky-header")
    //         header.classList.add("navigationclr")
    //     }
    //     else {
    //         header.classList.remove("sticky-header")
    //         header.classList.remove("navigationclr")
    //     }
    // };
    usd() {
        var element: any = document.getElementById("usd");
        var element1: any = document.getElementById("inr");
        var element2: any = document.getElementById("eur");
        element1.classList.remove("active-usd");
        element2.classList.remove("active-usd");
        element.classList.add("active-usd");
        this.setState({ standard: 109, basic: 99, platinum: 139, Lite:49,currency: "$" ,CurrencyId:2});
    }
    inr() {
        var element: any = document.getElementById("usd");
        var element1: any = document.getElementById("inr");
        var element2: any = document.getElementById("eur");
        element.classList.remove("active-usd");
        element1.classList.add("active-usd");
        element2.classList.remove("active-usd");
        this.setState({
            standard: 8499,
            basic: 7499,
            platinum: 10499,
            Lite:2499,
            currency: "₹",CurrencyId:1
        });
    }
    eur() {
        var element: any = document.getElementById("usd");
        var element1: any = document.getElementById("inr");
        var element2: any = document.getElementById("eur");
        element.classList.remove("active-usd");
        element1.classList.remove("active-usd");
        element2.classList.add("active-usd");
        this.setState({ standard: 109, basic: 99, platinum: 139, Lite:49,currency: "€" ,CurrencyId:3});
    }
    setVisible = () => {
        this.setState({ visible: false });
    };
    modelvisible = (title: any) => {
        
        if (title === "Distribution") {
            this.setState({ visible: true, title: "Distribution Industry" });
        } else if (title === "Manufacturing") {
            this.setState({ visible: true, title: "Manufacturing" });
        } else if (title === "3pl") {
            this.setState({ visible: true, title: "3PL" });
        } else if (title === "Surgical") {
            this.setState({ visible: true, title: "Pharma & Surgical" });
        } else if (title === "Automobile") {
            this.setState({ visible: true, title: "Automobile" });
        } else if (title === "Fast-moving") {
            this.setState({ visible: true, title: "Fast-moving Consumer Goods" });
        } else if (title === "Fashion") {
            this.setState({ visible: true, title: "Fashion and Retail" });
        }
    };
    menu = (id: any) => {
        var id1 = id.currentTarget.id;
        var element: any = document.getElementById(id1);
        if (id1 === "home") {
            element.classList.toggle('active')
        } else if (id1 === "Features") {
            element.classList.toggle('active')
        } else if (id1 === "about") {
            element.classList.toggle('active')
        } else if (id1 === "Segments") {
            element.classList.toggle('active')
        } else if (id1 === "Pricing") {
            element.classList.toggle('active')
        } else if (id1 === "contacts") {
            element.classList.toggle('active')
        }
        this.closemenu();
    };
    closemenu() {
        
        var element: any = document.getElementById("mblmenu");
        element.classList.remove("closemenu");
    }
    openmenu() {
        var element: any = document.getElementById("mblmenu");
        element.classList.add("closemenu");
    }
    ResourcesMenu = () => {
        
        var element: any = document.getElementById("submenu");
        element.classList.toggle("showResources");
    }
    ViewMoreFeatures = () => {
        
        var ele: any = document.getElementById('viewMore');
        ele.classList.add('ViewMoreFeatures');
        this.setState({ ViewLess: true })
    }
    ViewLessFeatures = () => {
        var ele: any = document.getElementById('viewMore');
        ele.classList.remove('ViewMoreFeatures');
        this.setState({ ViewLess: false })
    }
    handleOpenModal = (number:any) => {
        if(number === 1){
            this.setState({ open: true , src:"https://www.youtube.com/embed/2yEeJz-ni8E?si=EJ7eKaLtdtmoCpbJ"});
        }
        else if(number === 2){
            this.setState({ open: true , src:"https://www.youtube.com/embed/fv7Tz9njztk?si=jivViSe7aBHc8ct-"});
        }
        else if(number === 3){
            this.setState({ open: true , src:"https://www.youtube.com/embed/AStkFa3PEbE?si=eHuuHLOP50OYwXOK"});
        }
        else if(number === 4){
            this.setState({ open: true , src:"https://www.youtube.com/embed/xzNNtLZiQbc?si=kRGZR3e0C5x1tGfF"});
        }
      };
    
      handleCloseModal = () => {
        this.setState({ open: false,src:"" });
        this.forceUpdate();
      };
    componentDidMount() {
        window.scrollTo(0, 0);
        // window.addEventListener("scroll", this.isSticky);
        //window.onscroll = () => { this.isSticky(); }
    }
    render() {
        return (
            <>
                <main id="Home">
                    {/* <!-- banner-area --> */}
                    <section className="banner-area banner-bg fix">
                        <header>
                            <div id="mblmenu" className="mennnu">
                                <div className="menu-backdrop"></div>
                                <div className="close-btn">
                                    <i className="fas fa-times"></i>
                                </div>
                                <nav className="menu-box">
                                    <div className="nav-logom">
                                        <img src={LOgo} alt="skubiq" />
                                    </div>
                                    <div className="menu-outer">
                                        <CloseOutlined onClick={() => this.closemenu()} />
                                        {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
                                    </div>
                                    <div className="social-links">
                                        <ul className="navigationmbl">
                                            <li id="home" onClick={(id) => this.menu(id)}><a href="#Home">Home</a></li>
                                            <li id="features" onClick={(id) => this.menu(id)}><a href="#Features">Features</a></li>
                                            <li id="segments" onClick={(id) => this.menu(id)}><a href="#Segments">Segments</a></li>
                                            <li id="pricing" onClick={(id) => this.menu(id)}><a href="#Pricing">Pricing</a></li>
                                            <li className="dropdown" onClick={this.ResourcesMenu}>Resources <span className="DownOutlined"> &nbsp;
                                                <DownOutlined />
                                            </span>
                                                <ol id="submenu">
                                                    <li><a href="https://www.skubiq.com/blog/">Blog</a></li>
                                                    {/* <li><a href="# ">Videos</a></li> */}
                                                    <li id="about" onClick={(id) => this.menu(id)}><a href="#About">About</a></li>
                                                    <li><a href="https://www.skubiq.com/saas-wms-india/">SaaS WMS India</a></li>
                                                </ol>
                                                <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div></li>
                                            <li id="contacts" onClick={(id) => this.menu(id)}><a href="https://www.skubiq.com/contact-us/">Contact Us</a></li>
                                        </ul>
                                        <div className=" header-actionmbl">
                                            <ul>
                                                {/* <!-- <li className="header-search"><a href="# " >Need Help? &nbsp;  +91 7674978928</a></li> --> */}
                                                <li className="header-btn">
                                                    <Link to={"/Login"}>
                                                        <span className="btn">
                                                            {/* <img src={UnlocK} alt="" /> */}
                                                            <span style={{ color: "#d41324" }}>Login</span>
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <div id="sticky-header" className="main-header menu-area">
                                <div className="container custom-container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mobile-nav-toggler">
                                                <i className="fas fa-bars"></i>
                                            </div>
                                            <div className="menu-wrap">
                                                <nav className="menu-nav show">
                                                    <div className="logo">
                                                        <Link to="/">
                                                            <img src={LOgo} alt="skubiq" />
                                                        </Link>
                                                    </div>
                                                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                                                        <ul id="navigation" className="navigation">
                                                            <li
                                                                className=""
                                                                id="home"
                                                                onClick={(id) => this.menu(id)}
                                                            >
                                                                <Link to="/">
                                                                    <span>Home</span>
                                                                </Link>
                                                            </li>
                                                            <li
                                                                id="features"
                                                                onClick={(id) => this.menu(id)}
                                                                className=""
                                                            >
                                                                <Link to="/Keyfeatures">
                                                                    <span>Features</span>
                                                                </Link>

                                                            </li>
                                                            <li
                                                                id="segments"
                                                                onClick={(id) => this.menu(id)}
                                                                className=""
                                                            >
                                                                <Link to="/Segments">
                                                                    <span>
                                                                        Segments
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                            <li id="pricing" onClick={(id) => this.menu(id)}>
                                                                <Link to="/Pricing">
                                                                    <span>Pricing</span>
                                                                </Link>
                                                            </li>
                                                            <li className="dropdown">
                                                                <a href="# ">
                                                                    Resources{" "}
                                                                    <div className="DownOutlined">
                                                                        {" "}
                                                                        &nbsp;
                                                                        <DownOutlined />
                                                                    </div>
                                                                </a>
                                                                <ol className="submenu">
                                                                    <li>
                                                                        <a href="https://www.skubiq.com/blog/">BLOG</a>
                                                                    </li>
                                                                    {/* <li>
                                                                        <a href="# ">VIDEOS</a>
                                                                    </li> */}
                                                                   
                                                                    <li
                                                                        id="about"
                                                                        onClick={(id) => this.menu(id)}
                                                                    >
                                                                        <Link to="/About">
                                                                            <span>ABOUT</span>
                                                                        </Link>

                                                                    </li>
                                                                    <li><a href="https://www.skubiq.com/saas-wms-india/">SaaS WMS India</a></li>
                                                                </ol>
                                                                <div className="dropdown-btn">
                                                                    <span className="fas fa-angle-down"></span>
                                                                </div>
                                                            </li>
                                                            <li id="contacts" onClick={(id) => this.menu(id)}>
                                                                <a href="https://www.skubiq.com/contact-us/">Contact Us</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="header-action ">
                                                        <ul>
                                                            {/* <!-- <li className="header-search"><a href="# " >Need Help? &nbsp;  +91 7674978928</a></li> --> */}
                                                            <li className="header-btn">
                                                                <Link to={"/Login"}>
                                                                    <span className="btn">
                                                                        {/* <img src={UnlocK} alt="" /> */}
                                                                        <span style={{ color: "#d41324" }}>
                                                                            Login
                                                                        </span>
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="mobilmenuopen">
                                                        <MenuOutlined onClick={() => this.openmenu()} />
                                                    </div>
                                                </nav>
                                            </div>
                                            {/* <!-- Mobile Menu  --> */}
                                            <div className="mobile-menu">
                                                <div className="menu-backdrop"></div>
                                                <div className="close-btn">
                                                    <i className="fas fa-times"></i>
                                                </div>

                                                <nav className="menu-box">
                                                    <div className="nav-logo">
                                                        <a href="index.html">
                                                            <img src="img/logo/w_logo.png" alt="" title="" />
                                                        </a>
                                                    </div>
                                                    <div className="menu-outer">
                                                        {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
                                                    </div>
                                                    <div className="social-links">
                                                        <ul className="clearfix">
                                                            <li>
                                                                <a href="# ">
                                                                    <span className="fab fa-twitter"></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="# ">
                                                                    <span className="fab fa-facebook-square"></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="# ">
                                                                    <span className="fab fa-pinterest-p"></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="# ">
                                                                    <span className="fab fa-instagram"></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="# ">
                                                                    <span className="fab fa-youtube"></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </nav>
                                            </div>
                                            {/* <!-- End Mobile Menu --> */}
                                            {/* <!-- Modal Search --> */}
                                            <div
                                                className="modal fade"
                                                id="search-modal"
                                                role="dialog"
                                                aria-hidden="true"
                                            >
                                                <div className="modal-dialog" role="document">
                                                    <div className="modal-content">
                                                        <form>
                                                            <input type="text" placeholder="Search here..." />
                                                            <button>
                                                                <i className="fa fa-search"></i>
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>

                        <div className="container-fluid custom-container">
                            <div
                                className="row align-items-center"
                                style={{ paddingTop: "5%" }}
                            >
                                <div className="col-lg-5 order-0 order-lg-2">
                                    <div
                                        className="banner-img wow fadeInRight"
                                        data-wow-delay="1s"
                                    >
                                        <img src={Banner_img} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="banner-content">
                                        {/* <!-- <span className="wow fadeInUp" data-wow-delay=".3s">MARKETING STRATEGY</span> --> */}
                                        <h1>
                                        Omni-Channel Fulfilment and Warehousing Solution
                                        </h1>
                                        <div className="">
                                            <img src={Manufacturing_I} className="star-res" alt="" />

                                            <p className="inventory-p">
                                            Experience seamless Omni-Channel Excellence with Our Cutting-Edge Order fulfillment and Warehousing Solution!
                                            </p>
                                        </div>
                                        <div className="">
                                            <img src={Inventory} className="star-res" alt="" />

                                            <p className="inventory-p">
                                                {" "}
                                                Unify Your Inventory, Assets, Data, Insights, and Operations for Streamlined Excellence in a Single, Seamless Ecosystem
                                            </p>
                                        </div>
                                        <div className="">
                                            <img src={KPI_I} className="star-res" alt="" />

                                            <p className="inventory-p">
                                                {" "}
                                                Unlock Seamless Integration with Your Entire Suite of Business Applications through Our Comprehensive Range of APIs
                                            </p>
                                        </div>
                                        <Link to={`/trial/SKU22-T1`}>
                                            <span className="btn startfree">
                                                Start Your Free Trial
                                            </span>
                                        </Link>

                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.inventrax.skubiq"
                                            className="btn downlodeapp"
                                        >
                                            Download App
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- banner-area-end --> */}


                    {/* Client Logos */}
                    <section id="clients" className="services-area position-relative pb-25 client_logo_m">
                        <div className="container">
                            <div className='row'>
                                <div className='col-md-6 mobile-res'>
                                    <div className="row">
                                        <div className='col-md-3 col-sm-6 col-sm-6-m col-xs-6'>
                                            <span className='client_logo'><img src={glandpharma_logo} alt="arrow-back" /></span>
                                        </div>
                                        <div className='col-md-3 col-sm-6 col-sm-6-m col-xs-6'>
                                            <span className='client_logo'><img src={swastik} alt="arrow-back" /></span>
                                        </div>
                                        <div className='col-md-3 col-sm-6 col-sm-6-m col-xs-6'>
                                            <span className='client_logo'><img src={star_quick} alt="arrow-back" /></span>
                                        </div>
                                        <div className='col-md-3 col-sm-6 col-sm-6-m col-xs-6'>
                                            <span className='client_logo'><img src={cci_logistics} alt="arrow-back" /></span>
                                        </div>
                                    </div>    
                                </div>
                                <div className='col-md-6 mobile-res'>
                                <div className="row">
                                        <div className='col-md-3 col-sm-6 col-sm-6-m col-xs-6'>
                                            <span className='client_logo'><img src={nova} alt="arrow-back" /></span>
                                        </div>
                                        <div className='col-md-3 col-sm-6 col-sm-6-m col-xs-6'>
                                            <span className='client_logo'><img src={runwal} alt="arrow-back" /></span>
                                        </div>
                                        <div className='col-md-3 col-sm-6 col-sm-6-m col-xs-6'>
                                            <span className='client_logo'><img src={alpha} alt="arrow-back" /></span>
                                        </div>
                                        <div className='col-md-3 col-sm-6 col-sm-6-m col-xs-6'>
                                            <span className='client_logo'><img src={tech_mahindra} alt="arrow-back" /></span>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>    
                    </section>

                    
                    {/* <!-- 5 steps --> */}

                    <section id="clients" className="services-area position-relative pb-25 client_logo_m">
                        <div className="container">
                        <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="section-title text-center mb-70">
                                        <h2 className="title">Our 5 Step Onboarding Odyssey</h2>
                                        <p>Unleash fulfilment excellence with SKUBIQ’s effortless 5-Step onboarding process!</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-7 mobile-res'>
                                    <div className="row">
                                        <div className='col-md-4 steps'>
                                            <img src={signup} alt="arrow-back" />
                                            <div className="signup_title">
                                                <span className="signip_t">Signup</span>
                                                <li className="step_pb li-s">Signup your choice of package / Try Free Trail</li>
                                            </div>
                                        </div>
                                        <div className='col-md-4 steps'>
                                            <img src={master_data} alt="arrow-back" />
                                            <div>
                                                <span className="signip_t">Master Data Configuration</span>
                                                <ul className="li-s">
                                                    <li className="step_pb"><CheckOutlined />&nbsp; Suppliers</li>
                                                    <li><CheckOutlined />&nbsp; Customers</li>
                                                    <li><CheckOutlined />&nbsp; SKU Master</li>
                                                    <li><CheckOutlined />&nbsp; Warehouse</li>
                                                    <li><CheckOutlined />&nbsp; Zones/Docks</li>
                                                    <li><CheckOutlined />&nbsp; Bins/Locations</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-md-4 steps'>
                                            <img src={print_tag} alt="arrow-back" />
                                            <div>
                                                <span className="signip_t">Print & Tag Barcode Labels</span>
                                                <ul className="li-s">
                                                    <li className="step_pb"><CheckOutlined />&nbsp; Bin/Location Labels</li>
                                                    <li><CheckOutlined />&nbsp; Pallet Labels</li>
                                                    <li><CheckOutlined />&nbsp; SKU Labels</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                                <div className='col-md-5 mobile-res'>
                                    <div className="row">
                                        <div className='col-md-6 steps steps-4'>
                                            <img src={stocktake} alt="arrow-back" />
                                            <div>
                                                <span className="signip_t">Digital Stocktake</span>
                                                <ul className="li-s">
                                                    <li className="step_pb"><CheckOutlined />&nbsp; For a running warehouse </li>
                                                    <li className="st-bt">Scan Bin/Pallet/SKU</li>
                                                    <li><CheckOutlined />&nbsp; barcodes to map the</li>
                                                    <li className="st-bt">physical locations</li>
                                                    <li><CheckOutlined />&nbsp; Reconcile with your </li>
                                                    <li className="st-bt">ERP/Data on hand</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-md-6 steps steps-5'>
                                            <img src={go_live} alt="arrow-back" />
                                            <div>
                                                <span className="signip_t">Go-Live</span>
                                                <li className="step_pb li-s">Start receiving, storage, housekeeping & shipping</li>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                            </div>
                        </div>    
                    </section> 

                    {/* <!-- About --> */}
                    <section id="About" className="cloud-hosting-area  pb-100">
                        <div className="container container-about">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="section-title text-center mb-70">
                                        <h2 className="title">We've made order fulfilment super easy!</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="faq-img text-center">
                                        <img src={Faq} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="cloud-hosting-list">
                                        <ul>
                                            <li>
                                                <div className="cloud-hosting-content">
                                                    <p>
                                                    SKUBIQ is an endeavor to provide a unified operating platform for all the inventory & warehouse operation needs, earned through months of research & development, and years of listening to our customer feedback.
                                                    </p>
                                                    <br />
                                                    <p>
                                                    SKUBIQ SaaS WMS Software caters to Distribution / eCommerce fulfillment centers, manufacturing facilities, and Contract Logistics through a wide array of integrations to seamlessly serve their customers.
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="cloud-hosting-content">
                                                    <h4>Value Proposition:</h4>
                                                    <ol>
                                                        <li className="ab-li">
                                                            <img
                                                                src={DownArrrow}
                                                                className="rightarrow-o"
                                                                style={{}}
                                                                alt=""
                                                            />
                                                            This platform helps you consistently reduce operational costs and gain a clear insight to make better decisions.
                                                        </li>
                                                        <li className="ab-li">
                                                            <img                                                            src={DownArrrow}
                                                                className="rightarrow-o"
                                                                style={{}}
                                                                alt=""
                                                            />
                                                            Reducing inventory variability to improve customer service and lower working capital 
                                                        </li>
                                                        <li className="ab-li">
                                                            <img src={DownArrrow} className="rightarrow-o" style={{}} alt=""/>
                                                            Deliver value to your end customers with high inventory availability, faster fulfillment times, and on-time in-full (OTIF)deliveries.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- about-end --> */}

                    {/* <!-- Videos --> */}
                    <section id="videos" className="sbout-area pt-60 pb-60">
                        <div className="container">
                            <div className="inbound">
                                <div className="row ">
                                    <div className="col-lg-6 videos_flex">
                                        <div className="main-title">
                                            <h2 className="m-title">Simplified Inward Receipts</h2>
                                            <span>
                                            SKUBIQ believes in maintaining a clean inbound process to ensure a clean outbound. We make inbound goods receipts seamless .
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="main-title">
                                            <img src={inbound} alt="inbound" />
                                        </div>
                                        <div className="play_btn">
                                            <img src={play_btn} alt="" onClick={() => this.handleOpenModal(1)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="Outbound">
                                <div className="row">
                                <div className="col-lg-6" >
                                        <div className="main-title">
                                            <img src={Outbound} alt="Outbound" />
                                        </div>
                                        <div className="play_btn2">
                                            <img src={play_btn} alt="" onClick={() => this.handleOpenModal(2)}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 videos_flex">
                                        <div className="main-title">
                                            <h2 className="m-title">Efficient Fulfilment and Dispatch</h2>
                                            <span>
                                            SKUBIQ accelerates fulfilment & dispatch by ensuring precise picking, packing, and loading through its advanced AI algorithms, minimizing the chance of errors.
                                            </span>
                                        </div>
                                    </div>                                
                                </div>
                            </div>

                            <div className="Cycle_Counting">
                                <div className="row ">
                                    <div className="col-lg-6 videos_flex">
                                        <div className="main-title">
                                            <h2 className="m-title">SKUBIQ's Mastering Accuracy with Cycle Counting</h2>
                                            <span>
                                            Inventory accuracy is achieved through digital(scan) perpetual cycle counting, ensuring synchronization between logical and physical stocks
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="main-title">
                                            <img src={Cycle_Counting} alt="inbound" />
                                        </div>
                                        <div className="play_btn3">
                                            <img src={play_btn} alt="" onClick={() => this.handleOpenModal(3)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="PL_Billing">
                                <div className="row">
                                <div className="col-lg-6" >
                                        <div className="main-title">
                                            <img src={PL_Billing} alt="Outbound" />
                                        </div>
                                        <div className="play_btn4">
                                            <img src={play_btn} alt="" onClick={() => this.handleOpenModal(4)}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 videos_flex">
                                        <div className="main-title">
                                            <h2 className="m-title">Efficient Fulfilment and Dispatch</h2>
                                            <span>
                                            SKUBIQ accelerates fulfilment & dispatch by ensuring precise picking, packing, and loading through its advanced AI algorithms, minimizing the chance of errors.
                                            </span>
                                        </div>
                                    </div>                                
                                </div>
                            </div>

                        </div>
                    </section>
                    {/* <!-- about-end --> */}

                    {/* <!-- faq-area --> */}

                    {/* <!-- faq-area-end --> */}

                    {/* <!-- exclusive-services-area --> */}
                    <section className="exclusive-service es-bg">
                        <div className="container">
                            {/* <!-- <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
                <div className="section-title white-title text-center mb-70">
                    <span className="sub-title">ALWAYS IN THE RIGHT</span>
                    <h2 className="title">Marketing For Everyone</h2>
                </div>
            </div>
        </div> --> */}
                            
                        </div>
                        <div className="exclusive-services-shape">
                            <img src="img/images/services_circle_shape.png" alt="" />
                        </div>
                    </section>

                    <section className="industry">
                        <div className="container">
                            <div className="section-title text-center mb-30">
                                <h2 className="title">Industry</h2>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-3 col-md-6 col-sm-9">
                                    <div className="services-item exclusive-services-item services-responsive mb-30">
                                        <div className="services-icon">
                                            <img src={Pharma} alt="" />
                                        </div>
                                        <div className="services-content mb_30">
                                            <h4>
                                                <a href="# ">Pharma & Surgical</a>
                                            </h4>
                                            <p>
                                                The Pharma industry faces some very unique challenges
                                                covers a lot of territory...{" "}
                                                <span style={{ display: "none" }}>
                                                    {" "}
                                                    No matter what distribution model you follow,
                                                    customers, and product catalog pose a unique set of
                                                    problems for your supply chain and hence we have
                                                    teamed up with a lot of Pharma companies in developing
                                                    a unique solution called SKUBIQ to specifically
                                                    address the issues of this industry.:
                                                </span>
                                            </p>
                                            <span
                                                className="know-more"
                                                onClick={() => this.modelvisible("Surgical")}
                                            >
                                                Read More...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-9">
                                    <div className="services-item exclusive-services-item services-responsive mb-30">
                                        <div className="services-icon">
                                            <img src={Automobile} alt="" />
                                        </div>
                                        <div className="services-content mb_30">
                                            <h4>
                                                <a href="# ">Automobile</a>
                                            </h4>
                                            <p>
                                                In the highly regulated industry of Automative
                                                manufacturing, attention...
                                                <span style={{ display: "none" }}>
                                                    to detail is critical to help ensure stringent
                                                    requirements for product quality and deadlines to be
                                                    met. Ones ability to achieve a lean environment in
                                                    which one would have visibility to and control over
                                                    these details is where competitive advantage often
                                                    resides. SKUBIQ has been designed in partnership with
                                                    many automative
                                                </span>
                                            </p>
                                            <span
                                                className="know-more"
                                                onClick={() => this.modelvisible("Automobile")}
                                            >
                                                Read More...
                                            </span>
                                            {/* <a href="# " className="read-more">Read More...</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-9">
                                    <div className="services-item exclusive-services-item services-responsive mb-30">
                                        <div className="services-icon">
                                            <img src={Consumer} alt="" />
                                        </div>
                                        <div className="services-content">
                                            <h4>
                                                <a href="# ">Fast-moving Consumer Goods</a>
                                            </h4>
                                            <p>
                                                Omni-Channel and internet retail growth is putting the
                                                spotlight on warehouse...{" "}
                                                <span style={{ display: "none" }}>
                                                    performance in the consumer packaged goods industry as
                                                    well. Large distribution center replenishment orders,
                                                    direct to store and direct to consumer shipment
                                                    coordination from charge-back happy retailers
                                                    consumers are creating new pressures.
                                                </span>
                                            </p>
                                            <span
                                                className="know-more"
                                                onClick={() => this.modelvisible("Fast-moving")}
                                            >
                                                Read More...
                                            </span>
                                            {/* <a href="# " className="read-more">Read More...</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-9">
                                    <div className="services-item exclusive-services-item services-responsive mb-30">
                                        <div className="services-icon">
                                            <img src={Retail} alt="" />
                                        </div>
                                        <div className="services-content mb_30">
                                            <h4>
                                                <a href="# ">Fashion and Retail</a>
                                            </h4>
                                            <p>
                                                The era when the majors effectively ruled this industry
                                                is the bygone. The fashion...{" "}
                                                <span style={{ display: "none" }}>
                                                    and retail industry is no longer dominated by a select
                                                    group of large retailers with hundreds of
                                                    brick-and-mortar stores. People have taken to online
                                                    shopping as duck takes to water and has become
                                                    extremely popular even as a number
                                                </span>
                                            </p>
                                            <span
                                                className="know-more"
                                                onClick={() => this.modelvisible("Fashion")}
                                            >
                                                Read More...
                                            </span>
                                            {/* <a href="# " className="read-more">Read More...</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <!-- package-pricing-area --> */}
                    <section
                        id="Pricing"
                        className="pricing-area gray-bg position-relative pt-100"
                    >
                        <div className="container">
                            <div className="tab-content" id="myTabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="web-hosting"
                                    role="tabpanel"
                                    aria-labelledby="web-hosting-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-xl-7 col-lg-9">
                                            <div className="section-title text-center mb-30">
                                                <h2 className="title">Pricing</h2>
                                                <p>
                                                    Start building today with our 14 day trial. No
                                                    commitment. No credit card required.
                                                </p>
                                                <div>
                                                <span
                                                        className="inr active-usd"
                                                        id="inr"
                                                        onClick={() => this.inr()}
                                                    >
                                                        INR
                                                    </span>
                                                    <span
                                                        className="usd"
                                                        onClick={() => this.usd()}
                                                        id="usd"
                                                    >
                                                        USD
                                                    </span>
                                                    
                                                    <span
                                                        className="eur"
                                                        id="eur"
                                                        onClick={() => this.eur()}
                                                    >
                                                        EUR
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pricing-box-wrap justify-content-center">

                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="pricing-box pricing-responding mb-30">
                                                <div className="pricing-head">
                                                    <h6 style={{ color: "#7CB9E8" }}>Lite</h6>
                                                    <div
                                                        className="pricing-icon services-icon"
                                                        style={{ backgroundColor: "white" }}
                                                    >
                                                        {/* <i className="flaticon-hosting"></i> */}
                                                        <img
                                                            src={Basic}
                                                            alt=""
                                                            style={{ width: "40px", height: "40px" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="pricing-list price-basic mb-30">
                                                    <h5>features</h5>
                                                    <ul  >
                                                        <li style={{fontSize:12}}>Starts with 2 Users</li>
                                                        <li style={{fontSize:12}}>1 Warehouse</li>
                                                        <li style={{fontSize:12}}>3 Hours On call Training - One time</li>
                                                        <li style={{fontSize:12}}>30 Hours Help Desk Support - Per Annum</li>
                                                        <li style={{fontSize:12}}>Shared Database</li>
                                                        <li style={{fontSize:12}}>24,000 transactions - Per Annum </li>
                                                        <li style={{fontSize:12}}>Handheld App. 2D/1D Barcode Scanning</li>
                                                    </ul>
                                                </div>
                                                <div className="price price-basic mb-20">
                                                    <h2>
                                                        {this.state.currency}
                                                        {this.state.Lite}
                                                        <span>per user/per month</span>
                                                    </h2>
                                                </div>
                                                <div className="pricing-btn">
                                                    <Link to={`/signup/2U1W-INR/${this.state.CurrencyId}`}>
                                                        <span className="btn Getstart">
                                                            Get start
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className='Trail_or'>Or</div>
                                            <Link to={`/trial/SKU23-1W2U-L`}>
                                                <span id="Trial_link">
                                                    Start Your Free Trial
                                                </span>
                                            </Link>
                                            </div>
                                        </div>


                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="pricing-box pricing-responding mb-30">
                                                <div className="pricing-head">
                                                    <h6 style={{ color: "#3ab2bd" }}>Silver</h6>
                                                    <div
                                                        className="pricing-icon services-icon"
                                                        style={{ backgroundColor: "white" }}
                                                    >
                                                        {/* <i className="flaticon-hosting"></i> */}
                                                        <img
                                                            src={Basic}
                                                            alt=""
                                                            style={{ width: "40px", height: "40px" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="pricing-list price-basic mb-30">
                                                    <h5>features</h5>
                                                    <ul  >
                                                        <li style={{fontSize:12}}>Starts with 5 Users</li>
                                                        <li style={{fontSize:12}}>1 Warehouse</li>
                                                        <li style={{fontSize:12}}>3 Hours On call Training - One time</li>
                                                        <li style={{fontSize:12}}>30 Hours Help Desk Support - Per Annum</li>
                                                        <li style={{fontSize:12}}>Shared Database</li>
                                                        <li style={{fontSize:12}}>24,000 transactions - Per Annum </li>
                                                        <li style={{fontSize:12}}>Handheld App. 2D/1D Barcode Scanning</li>
                                                    </ul>
                                                </div>
                                                
                                                <div className="price price-basic mb-20">
                                                    <h2>
                                                        {this.state.currency}
                                                        {this.state.basic}
                                                        <span>per user/per month</span>
                                                    </h2>
                                                </div>
                                                <div className="pricing-btn">
                                                    <Link to={`/signup/5U1W-INR/${this.state.CurrencyId}`}>
                                                        <span className="btn Getstart">
                                                            Get start
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className='Trail_or'>Or</div>
                                            <Link to={`/trial/SKU22-1W5U-S`}>
                                                <span id="Trial_link">
                                                    Start Your Free Trial
                                                </span>
                                            </Link>
                                            </div>
                                        </div>


                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="pricing-box pricing-responding mb-30">
                                                <div className="pricing-head">
                                                    <h6 style={{ color: "#5d1a87" }}>Gold</h6>
                                                    <div
                                                        className="pricing-icon services-icon"
                                                        style={{ backgroundColor: "white" }}
                                                    >
                                                        {/* <i className="flaticon-hosting"></i> */}
                                                        <img
                                                            src={Standard}
                                                            alt=""
                                                            style={{ width: "40px", height: "40px" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="pricing-list price-standard mb-30">
                                                    <h5>features</h5>
                                                    <ul  >
                                                        <li style={{fontSize:12}}>Starts with 10 Users</li>
                                                        <li style={{fontSize:12}}>2 Warehouse</li>
                                                        <li style={{fontSize:12}}>5 Hours On call Training - One time</li>
                                                        <li style={{fontSize:12}}>45 Hours Help Desk Support - Per Annum</li>
                                                        <li style={{fontSize:12}}>Shared Database</li>
                                                        <li style={{fontSize:12}}>52,000 transactions - Per Annum </li>
                                                        <li style={{fontSize:12}}>Handheld App. 2D/1D Barcode Scanning</li>
                                                        <li style={{fontSize:12}}>3PL Billing Support</li>
                                                        <li style={{fontSize:12}}>API Integration with ERP</li>
                                                    </ul>
                                                </div>
                                                
                                                <div className="price price-standard mb-20">
                                                    <h2>
                                                        {this.state.currency}
                                                        {this.state.standard}
                                                        <span>per user/per month</span>
                                                    </h2>
                                                </div>
                                                <div className="pricing-btn">
                                                    <Link to={`/signup/10U2W-INR/${this.state.CurrencyId}`}>
                                                        <span className="btn Getstart">
                                                            Get start
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className='Trail_or'>Or</div>
                                            <Link to={`/trial/SKU22-2W10U-G`}>
                                                <span id="Trial_link">
                                                    Start Your Free Trial
                                                </span>
                                            </Link>
                                            </div>
                                        </div>


                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="pricing-box pricing-responding mb-30">
                                                <div className="pricing-head">
                                                    <h6>Platinum</h6>
                                                    <div
                                                        className="pricing-icon services-icon"
                                                        style={{ backgroundColor: "white" }}
                                                    >
                                                        {/* <i className="flaticon-hosting"></i> */}
                                                        <img
                                                            src={Premium}
                                                            alt=""
                                                            style={{ width: "40px", height: "40px" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="pricing-list mb-30">
                                                    <h5>features</h5>
                                                    <ul  style={{fontSize:12}}>
                                                        <li style={{fontSize:12}}>Starts with 15 Users</li>
                                                        <li style={{fontSize:12}}>3 Warehouse</li>
                                                        <li style={{fontSize:12}}>10 Hours On call Training - One time</li>
                                                        <li style={{fontSize:12}}>60 Hours Help Desk Support - Per Annum</li>
                                                        <li style={{fontSize:12}}>Shared Database</li>
                                                        <li style={{fontSize:12}}>1,20,000 transactions - Per Annum </li>
                                                        <li style={{fontSize:12}}>Handheld App. 2D/1D Barcode Scanning</li>
                                                        <li style={{fontSize:12}}>3PL Billing Support</li>
                                                        <li style={{fontSize:12}}>API Integration with ERP</li>
                                                        <li style={{fontSize:12}}>API Integration with MarketPlace*</li>
                                                    </ul>
                                                </div>
                                                
                                                <div className="price mb-20">
                                                    <h2>
                                                        {this.state.currency}
                                                        {this.state.platinum}
                                                        <span>per user/per month</span>
                                                    </h2>
                                                </div>
                                                <div className="pricing-btn">
                                                    <Link to={`/signup/20U3W-INR/${this.state.CurrencyId}`}>
                                                        <span className="btn Getstart">
                                                            Get start
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className='Trail_or'>Or</div>
                                            <Link to={`/trial/SKU22-3W20U-P`}>
                                                <span id="Trial_link">
                                                    Start Your Free Trial
                                                </span>
                                            </Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

{/* <!-- segments-area --> */}
<section
                        id="Segments"
                        className="services-area position-relative pt-500 pb-25"
                    >
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6 col-lg-8">
                                    <div className="section-title text-center">
                                        <h2 className="title title1 ">Segments</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row services-item-wrap justify-content-center">
                                <div className="col-lg-4 col-md-6 col-sm-7">
                                    <div className="services-item mb-70">
                                        <div className="services-icon">
                                            <img src={Distribution} alt="" />
                                        </div>
                                        <div className="services-content">
                                            <h4>
                                                <a href="# ">Distribution Center</a>
                                            </h4>
                                            <p>
                                            Helps manage distribution center operations seamlessly with high visibility and increased productivity.
                                            </p>
                                            <span
                                                className="know-more"
                                                onClick={() => this.modelvisible("Distribution")}
                                            >
                                                know More...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-7">
                                    <div className="services-item mb-70">
                                        <div className="services-icon">
                                            <img src={Manufacturing} alt="" />
                                        </div>
                                        <div className="services-content">
                                            <h4>
                                                <a href="# ">Manufacturing</a>
                                            </h4>
                                            <p>
                                            Empowers small and medium manufacturing facilities with lean inventory practices with lower…
                                            </p><br></br>
                                            <span
                                                className="know-more "
                                                onClick={() => this.modelvisible("Manufacturing")}
                                            >
                                                know More...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-7">
                                    <div className="services-item mb-70">
                                        <div className="services-icon">
                                            <img src={Tpl} alt="" />
                                        </div>
                                        <div className="services-content">
                                            <h4>
                                                <a href="# ">3PL</a>
                                            </h4>
                                            <p>
                                            Equips third-party logistics (3PL) companies to manage multi-tenant inventories efficiently with optimal…
                                            </p>
                                            <span
                                                className="know-more"
                                                onClick={() => this.modelvisible("3pl")}
                                            >
                                                know More...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="service-line-shape two"></div>
                                <div className="service-line-shape three"></div>
                            </div>
                        </div>
                        <div className="service-shape one">
                            <img src="img/images/services_shape01.png" alt="" />
                        </div>
                        <div className="service-shape two">
                            <img src="img/images/services_shape02.png" alt="" />
                        </div>
                    </section>
                    {/* <!-- segments-end --> */}

                    <section
                        id="Features"
                        className="faq-area pt-100 pb-100"
                    >
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="section-title text-left mb-40">
                                        <h2 className="title">Key Features</h2>
                                    </div>
                                    <div>
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Master Data Management
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Suppliers</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Customer</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Address Book</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Item Master</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Unit of Measurement</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Purchase Order</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Sales Orders</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Transfer Orders</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Warehouses</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Users</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Inbound
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;PO/Invoice/ASN based receipts{" "}</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Blind Receipts</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Gate Entry</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Dock Sequencing/Assignment</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Palletisation/Cartonisation</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;System Suggested Putaway</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;GRN Confirmation</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Crossdocking</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Inward Quality Control</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Inward Discrepancy Capture</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Outbound
                                                    </h4>
                                                    <div className="fc-li outBond">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;SO/STO based dispatch's</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Stock reservation</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Vehicle load planning</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Dispatch dock Assignment</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Dispatch note/Delivery challan</span></li>
                                                        </ul>
                                                        <span className='picking-1'>Picking</span>
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Batch/Wave/Zone picking</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;SLED/FEFO/FIFO/LIFO</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Pick skipping</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bin/SKU audit log on pick skip</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Inventory
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Batch/lot traceability</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Inventory ageing control</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Rule based inventory reservations</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Multi-UoM conversions</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Kitting/Dekitting</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bin Level tracking</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Serialized inventory</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Handling Units</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Shelf-life control</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Ageing control</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="viewMore">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Housekeeping
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Perpetual stock counts</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Stock Adjustments</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Stock Transfers</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bin-to-bin transfers</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Fast moving bin replenishment</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bin Consolidations</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Lost & found reconciliations </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>K3PL Billing
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Configurable 3PL billing</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;VAS calculation</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;GST/VAT based invoice</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Multi-currency</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Site based pricing</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Special Price configuration</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Contract Management</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Auto Data Capture
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul className="auto-data">
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Automatic data capture via handheld mobile devices</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Android App. for standard HHT's</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;GS1/EAN128, CODE128, CODE39, QR/2D/Datamatrix Standards</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bin / Pallet/ Carton / SKU's</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Location Management
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bulk Location Creation</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Aisle/Rack/Column/Level/Bin Hierarchy Management</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Location Label Printing</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Dock/Aisle Card Printing</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Real Time 2D Virtual Warehouse/Inventory View</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>3rd Party API Integration
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul className="integra">
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;JSON REST API’s</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;XML based SOAP Services{" "}</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Master Data API’s</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Order sync. API’s</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Inventory sync API’s</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Product Information sync API’s</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Webhooks for event based posting to 3rd Part Apps.</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>eCommerce Integration
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul className="e-commerce">
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Amazon Marketplace</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Flipkart</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Shopify</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Last Mile Delivery Integration
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul className="la-mile">
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;eCart</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;DTDC</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Delivery</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;DHL</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;FedEx</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;UPS</span>
                                                            </li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Bluedart</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12">
                                                <div className="feature-card">
                                                    <h4>Reporting & Dashboards
                                                    </h4>
                                                    <div className="fc-li">
                                                        <ul>
                                                            <li className="first-li">
                                                                <span className='leftText'><CheckOutlined />&nbsp;Dashboard with widgets rendering graphical data of store activities</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Suppliers, Operators performance reports</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Inward quality check & demand forecast report</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Daily closing stock report & misc. transactions report</span></li>
                                                            <li>
                                                                <span className='leftText'><CheckOutlined />&nbsp;Forward, backward material traceability report</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='MobileModeKeyFeatures'>
                                        {this.state.ViewLess === false ?
                                            <span
                                                className="know-more"
                                                onClick={() => this.ViewMoreFeatures()}
                                            >
                                                View More...
                                            </span>
                                            :
                                            <span
                                                className="know-more"
                                                onClick={() => this.ViewLessFeatures()}
                                            >
                                                View Less...
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </section>
                    {/* <!-- package-pricing-area-end --> */}
                </main>
                <>
                    <Modal
                        className="footerhomepage"
                        title={this.state.title}
                        centered
                        visible={this.state.visible}
                        onCancel={() => this.setVisible()}
                        footer={[]}
                        width={800}
                    >
                        {this.state.title === "Distribution Industry" ? (
                            <div>
                                <p>
                                    Distributors normally lack precise and seamless traceability,
                                    lot control, and recall management capabilities jeopardizing
                                    the inventory thereby putting their businesses at risk of
                                    compliance failures and legal liabilities. In addition, they
                                    are constantly challenged by the emerging consumer demands for
                                    omni-channel commerce, specialized products, and more
                                    convenient delivery options
                                </p>
                                <p>
                                    SKUBIQ is trusted worldwide for supply chain management and
                                    visibility. But Why? Simply because our uniquely adaptable
                                    software solutions help companies like you stay on top of this
                                    fast-changing market.
                                </p>
                                <p>
                                    SKUBIQ helps address the complete process of fulfilling
                                    complex, multi-temperature home delivery orders. SKUBIQ has
                                    the inbuilt flexibility in helping emerging online retailers
                                    and distributors a wide range of specialty products through
                                    traditional eCommerce fulfillment models.
                                </p>
                                <p>
                                    As one of the market leaders in warehouse management (WMS) for
                                    cold-storage, third-party-logistics companies that play a
                                    critical role distribution, we bridge inventory and
                                    distribution between some of the world’s largest producers and
                                    their customers.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Manufacturing" ? (
                            <div>
                                <p className="text-justify text-muted1 mb-2">
                                    The manufacturing is a highly regulated industry globally
                                    where attention to detail is critical to help ensure stringent
                                    requirements for product quality and deadlines are met. Its
                                    become imperative for organizations to achieve a lean
                                    environment in which they have visibility to and control over
                                    these details is where competitive advantage often resides.
                                    SKUBIQ partners with major manufacturing companies globally to
                                    improve efficiency and reduce costs, such as:
                                </p>
                                <ol className="pllist">
                                    <li>
                                        Support of lean manufacturing initiatives by including
                                        inventory management capabilities within manufacturing
                                    </li>
                                    <li>Initiating supplier re-orders based on demand signals</li>
                                    <li>
                                        Compliance with industry requirements for quality, product
                                        tracking, safety, and recall management
                                    </li>
                                    <li>Integrated RFID, including asset tracking</li>
                                    <li>Quality assurance and inspection</li>
                                    <li>Managing a large product catalog / SKU proliferation</li>
                                    <li>Addressing Customer OEM parts packaging</li>
                                    <li>Core stratification and remanufacturing</li>
                                    <li>
                                        Wave planning and small order pick optimization to reduce
                                        picker travel
                                    </li>
                                    <li>Serialized inventory tracking</li>
                                    <li>EDI / ASN integration</li>
                                </ol>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "3PL" ? (
                            <div>
                                <p>
                                    The SKUBIQ is a cloud based Warehouse management system and is
                                    designed for third-party logistics companies to manage
                                    multiple customers, processes and billing schedules. The
                                    system enables access to real-time information and provides
                                    integrations with warehouse management technologies, including
                                    EDI, barcode scanning, and e-commerce shopping carts. The
                                    software scalability helps companies to manage different stock
                                    levels in warehouses, streamline business, and satisfy
                                    customers.
                                </p>
                                <p>
                                    SKUBIQ is designed to help logistics companies automate
                                    processes and bill items accurately. The software provides
                                    features which allow the user to easily add and remove
                                    customers and products. The software is designed to help
                                    logistics providers satisfy customers’ need for updated
                                    information and increase profits through process automation.
                                </p>
                                <p>
                                    The SKUBIQ can be integrated with any line of business
                                    application or ERP thereby allowing users to synchronize
                                    items, inventory, purchase orders, and receipts.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Pharma & Surgical" ? (
                            <div>
                                <p>
                                    The SKUBIQ is a cloud based Warehouse management system and is
                                    designed for third-party logistics companies to manage
                                    multiple customers, processes and billing schedules. The
                                    system enables access to real-time information and provides
                                    integrations with warehouse management technologies, including
                                    EDI, barcode scanning, and e-commerce shopping carts. The
                                    software scalability helps companies to manage different stock
                                    levels in warehouses, streamline business, and satisfy
                                    customers.
                                </p>
                                <p>
                                    SKUBIQ is designed to help logistics companies automate
                                    processes and bill items accurately. The software provides
                                    features which allow the user to easily add and remove
                                    customers and products. The software is designed to help
                                    logistics providers satisfy customers’ need for updated
                                    information and increase profits through process automation.
                                </p>
                                <p>
                                    The SKUBIQ can be integrated with any line of business
                                    application or ERP thereby allowing users to synchronize
                                    items, inventory, purchase orders, and receipts.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Automobile" ? (
                            <div>
                                <p>
                                    Distributors normally lack precise and seamless traceability,
                                    lot control, and recall management capabilities jeopardizing
                                    the inventory thereby putting their businesses at risk of
                                    compliance failures and legal liabilities. In addition, they
                                    are constantly challenged by the emerging consumer demands for
                                    omni-channel commerce, specialized products, and more
                                    convenient delivery options
                                </p>
                                <p>
                                    SKUBIQ is trusted worldwide for supply chain management and
                                    visibility. But Why? Simply because our uniquely adaptable
                                    software solutions help companies like you stay on top of this
                                    fast-changing market.
                                </p>
                                <p>
                                    SKUBIQ helps address the complete process of fulfilling
                                    complex, multi-temperature home delivery orders. SKUBIQ has
                                    the inbuilt flexibility in helping emerging online retailers
                                    and distributors a wide range of specialty products through
                                    traditional eCommerce fulfillment models.
                                </p>
                                <p>
                                    As one of the market leaders in warehouse management (WMS) for
                                    cold-storage, third-party-logistics companies that play a
                                    critical role distribution, we bridge inventory and
                                    distribution between some of the world’s largest producers and
                                    their customers.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Fast-moving Consumer Goods" ? (
                            <div>
                                <p>
                                    The manufacturing is a highly regulated industry globally
                                    where attention to detail is critical to help ensure stringent
                                    requirements for product quality and deadlines are met. Its
                                    become imperative for organizations to achieve a lean
                                    environment in which they have visibility to and control over
                                    these details is where competitive advantage often resides.
                                    SKUBIQ partners with major manufacturing companies globally to
                                    improve efficiency and reduce costs, such as:
                                </p>
                                <ol className="pllist">
                                    <li>
                                        Support of lean manufacturing initiatives by including
                                        inventory management capabilities within manufacturing
                                    </li>
                                    <li>Initiating supplier re-orders based on demand signals</li>
                                    <li>
                                        Compliance with industry requirements for quality, product
                                        tracking, safety, and recall management
                                    </li>
                                    <li>Integrated RFID, including asset tracking</li>
                                    <li>Quality assurance and inspection</li>
                                    <li>Managing a large product catalog / SKU proliferation</li>
                                    <li>Addressing Customer OEM parts packaging</li>
                                    <li>Core stratification and remanufacturing</li>
                                    <li>
                                        Wave planning and small order pick optimization to reduce
                                        picker travel
                                    </li>
                                    <li>Serialized inventory tracking</li>
                                    <li>EDI / ASN integration</li>
                                </ol>
                            </div>
                        ) : (
                            ""
                        )}
                        {this.state.title === "Fashion and Retail" ? (
                            <div>
                                <p>
                                    The SKUBIQ is a cloud based Warehouse management system and is
                                    designed for third-party logistics companies to manage
                                    multiple customers, processes and billing schedules. The
                                    system enables access to real-time information and provides
                                    integrations with warehouse management technologies, including
                                    EDI, barcode scanning, and e-commerce shopping carts. The
                                    software scalability helps companies to manage different stock
                                    levels in warehouses, streamline business, and satisfy
                                    customers.
                                </p>
                                <p>
                                    SKUBIQ is designed to help logistics companies automate
                                    processes and bill items accurately. The software provides
                                    features which allow the user to easily add and remove
                                    customers and products. The software is designed to help
                                    logistics providers satisfy customers’ need for updated
                                    information and increase profits through process automation.
                                </p>
                                <p>
                                    The SKUBIQ can be integrated with any line of business
                                    application or ERP thereby allowing users to synchronize
                                    items, inventory, purchase orders, and receipts.
                                </p>
                            </div>
                        ) : (
                            ""
                        )}
                    </Modal>
                    <Modal className="youtube_modal"
          title=""
          centered
          visible={this.state.open}
          onCancel={() => this.handleCloseModal()}
          width={880}
          footer={null} 
          style={{ top: 0, padding: 0, margin: 0 }}
          >
            <iframe
              title="YouTube Video"
              width="100%"
              height="450"
              src={this.state.src}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ border: 0 }}
            ></iframe>
        </Modal>
                </>
                <FooterPagesFooter />
            </>
        );
    }
}
