import React, { Component } from 'react'
import { reactLocalStorage } from 'reactjs-localstorage';
import "../loginpage/login.css";
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

export default class Welcometoskubiq extends Component<any> {
  componentDidMount = async () => {
    
    this.props.parentCallback('0');
    // const tokenData:any = sessionStorage.getItem("LandingSiteTokenInfo");
    // const UserDataTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    var tokenData: any = reactLocalStorage.get('LandingSiteTokenInfo');
    var UserDataTemp: any = reactLocalStorage.get('LandingSiteLoginUserInfo');
    var UserData: any = UserDataTemp !== 'undefined' ? JSON.parse(UserDataTemp) : "";
    if (tokenData === "" || tokenData === 'undefined' || tokenData === null || UserData === "" || UserData === 'undefined' || UserData === null ) {
      window.localStorage.clear();
      // sessionStorage.clear()
      window.location.href = '/';
    }
  }
  logout = () => {
    setTimeout(() => {
      window.localStorage.clear();
      // sessionStorage.clear()
      window.location.href = "https://www.skubiq.com/";
    }, 1000);
  };
  render() {
    // const UserDataTemp:any = sessionStorage.getItem("LandingSiteLoginUserInfo");
    let CurrentDate = new Date()
    let CurrentYear= CurrentDate.getFullYear()
    const UserDataTemp: any = reactLocalStorage.get('LandingSiteLoginUserInfo');
    var UserData: any = UserDataTemp === 'undefined' || UserDataTemp === undefined || UserDataTemp === null  ? [] : JSON.parse(UserDataTemp);
    return (
        <>
           <div className='DashBord_bg'>
      <div>
      <div className='DashBord_bg_Headder'>
              <span className='cursor-pointer' onClick={() => this.logout()}>
                <span className='profileicons1'>
                  <LogoutOutlined />
                </span>
                Logout&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              </span>
              <NavLink to='/MyProfile'>
                <span className='cursor-pointer'>
                {/* &nbsp; */}
                  <span className='profileicons1'>
                    <SettingOutlined />
                  </span>
                  My Profile&nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;</span>
              </NavLink>
              <span className='cursor-pointer'>
              
                <span className='profileicons1'>
                  <UserOutlined />
                </span>
                {UserData ? UserData.company_name : ""}
              </span>
            </div>
      </div>
          <div className='DashBord_bg_footer'>
         
            <span>
            {" Copyright© " +CurrentYear+ " by SKUBIQ. All rights reserved."} 
            </span>
          </div>
          </div>
          <span style={{ fontSize: "15px", fontWeight: 500}}>
            {
              UserData ? UserData.SubscriptionStatus === 0 ? 
                  UserData.ZOHO_Status === 'cancelled' ? 
                    <div className='SubscriptionStatus_Waring'>
                      <p> Note : Subscription Cancelled</p>
                    </div>
                    : 
                    UserData.ZOHO_Status === 'expired' ? 
                      <div className='SubscriptionStatus_Waring'>
                        <p> Note : Subscription Expired</p>
                      </div>
                      :<></>
                        : <></>
                          :<></>
            }
          </span>
        </>
    )
  }
}
